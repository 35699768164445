.simple-poi-list {
  padding: rem(10px) rem(5px) rem(50px) rem(5px);

  &__item {
    display         : flex;
    margin-right    : -15px;
    margin-left     : -15px;
    background-color: white;
    box-shadow      : 0 0 rem(3px) rgba(black, 0.3);
    border-radius   : rem(5px);
    padding         : rem(7.5px) rem(5px);
    flex-wrap       : nowrap;
    align-items     : center;

    &+.simple-poi-list__item {
      margin-top: rem(7.5px);
    }

    &--added {
      background-color: $topcolor-black;

      .simple-poi-list {

        &__poi-title,
        &__poi-author,
        &__poi-category {
          color: white;

          svg {
            fill: white;
          }
        }

        &__add-btn {
          cursor          : inherit;
          background-color: white;

          svg {
            fill: $topcolor-blue;
          }
        }
      }
    }

    &--red {
      border: rem(3px) solid $topcolor-red;
    }
  }

  &__poi {


    .imagePreview {
      height             : 3.125rem;
      width              : 4.375rem;
      margin-bottom      : 0;
      background-repeat  : no-repeat;
      background-size    : cover;
      background-position: center;
      background-color   : white;
    }

    background-color: transparent;
    border          : none;
    height          : 100%;

    &-image {
      height             : rem(50px);
      width              : rem(70px);
      margin-bottom      : 0;
      background-repeat  : no-repeat;
      background-size    : cover;
      background-position: center;
      background-color   : white;
    }

    &-title,
    &-author,
    &-category {
      white-space  : nowrap;
      text-overflow: ellipsis;
      overflow     : hidden;
      text-align   : left;
      margin       : 0;
      max-width    : calc(100vw - 160px);
    }

    &-title {
      font-family: 'Roboto-bold';
      font-weight: 500;
      font-size  : rem(20px);
    }

    &-author,
    &-category {
      font-family   : 'Oswald';
      text-transform: uppercase;
      color         : $topcolor-light-black;
      font-size     : rem(15px);
      line-height   : rem(24px);

      svg {
        height      : rem(22px);
        width       : auto;
        display     : inline-block;
        margin-right: rem(5px);
      }
    }
  }

  &__add-btn {
    background-color: $topcolor-blue;
    border-radius   : 50%;
    border          : none;
    padding         : rem(5px);
    line-height     : 1;

    svg {
      fill  : white;
      width : rem(20px);
      height: rem(20px);
    }
  }
}