.profile-list {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .profile-list {
    &__items {
      padding: 0;
      .content-card {
        flex-basis: calc(50% - 10px);
      }
    }
  }
}