.recommendation-images-box {
  background-color: white;
  padding: rem(5px) rem(15px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  padding-bottom: rem(30px);
  margin-bottom: rem(35px);
  &__header {
    align-items: center;
    padding-top: rem(10px);
    &-btn {
      padding: 0;
      background-color: $topcolor-black;
      border: none;
      border-radius: 50%;
      height: rem(40px);
      width: rem(40px);
      line-height: 1;
      svg {
        height: 50%;
        width: 50%;
        fill: white;
      }
    }
    &:after {
      display: block;
      content: '';
      width: calc(100% - 30px);
      margin: 0 auto;
      border-bottom: rem(1px) solid $topcolor-black;
      padding-bottom: rem(10px);
      margin-bottom: rem(7.5px);
    }
  }
  &__content {
    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $topcolor-black;
    scrollbar-track-color: $topcolor-blue;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      width: rem(5px);
      &-thumb {
        background: $topcolor-blue;
      }
    }
  }
  &__title {
    font-size: rem(22px);
    text-align: right;
    margin-bottom: 0;
  }
  &__image {
    width: 100%;
    height: auto;
    & + .recommendation-images-box__image {
      margin-top: rem(5px);
    }
    &-wrapper {
      padding: 0;
    }
  }
  &__footer {
    &-btn {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: rem(-65px);
      height: rem(40px);
      width: rem(140px);
      margin: 0 auto;
      z-index: 1;
      margin: auto;
    }
  }
}

@media screen and (min-width: 992px) {
  .recommendation-images-box {
    padding: rem(0 15px 35px 15px);
    margin: rem(60px) auto 0 auto;
  }
}