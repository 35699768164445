.tour-content-time-box {
  background-color: white;
  padding: rem(10px) rem(15px);
  position: relative;
  margin-top: rem(25px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500;
  border-radius: rem(10px);
  &__icon {
    display: inline-block;
    line-height: rem(40px);
    margin-right: rem(10px);
    svg {
      height: rem(30px);
      width: auto;
    }
  }
  &__time {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    display: inline-block;
    line-height: rem(40px);
    color: $topcolor-black;
    margin-bottom: 0;
    font-size: rem(21px);
    span{
      font-weight: 700;
    }
  }
  &__duration {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    display: inline-block;
    line-height: rem(40px);
    vertical-align: middle;
    color: $topcolor-black;
    margin-bottom: 0;
    max-width: 100%;
    font-size: rem(21px);
    /* &--with-arrival {
      margin-left: rem(50px);
    } */
    span{
      font-weight: 700;
    }
  }
  &__duration-title,
  &__time-title {
    color: $topcolor-blue;
  }
  .b-white{
    background-color: white;
    position: relative;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .hour{
    margin-right: 10px;
  }
  .time{
    margin-left: 10px;
  }
  .lineTime{
    border: 0.5px dashed black;
    position: absolute;
    right: 0px;
    left: 0px;
    max-width: 100% !important;
  }
  .allTime{
    position: relative;
  }
}

@media screen and (min-width: 767px) {
  .tour-content-time-box {
    &__duration {
      text-align: center;
      &--with-arrival {
        margin-left: 0;
      }
    }
    .hour{
      margin-right: 20px;
    }
    .time{
      margin-left: 20px;
    }
    /* .b-white{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    } */
  }
}

@media screen and (min-width: 992px) {
  .tour-content-time-box {
    padding: rem(10px) rem(35px);
    margin-top: rem(25px);
    &__duration{
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 630px) {
  .tour-content-time-box {
    border-radius: 0;
    .lineTime {
      display: none;
    }
    .b-white {
      box-sizing: border-box;
      flex-direction: column;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
    .allTime {
      justify-content: flex-start !important;
    }
    &__duration,
    &__time{
      font-size: 17px;
    }
    &__icon{
      svg{
        height: 20px;
      }
    }
    img{
      height: 20px;
    }
  }
}
