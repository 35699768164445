.modal-newsletter {
  padding: rem(40px) 0 0 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    overflow-y: auto;
  }
  &__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    z-index: 1;
    svg {
      width: rem(40px);
      height: rem(40px);
    }
  }
  &__image {
    text-align: center;
    svg {
      width: 90%;
      max-width: rem(250px);
      height: auto;
    }
  }
  &__title {
    text-align: right;
    font-family: 'Roboto-bold';
    font-weight: bold;
  }
}

@media screen and (min-width: 992px) {
  .modal-newsletter {
    width: rem(992px);
    max-width: 90vw;
    height: rem(370px);
    min-height: unset;
    overflow-y: auto;
    background-color: #fff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: rem(50px);
    &.ReactModal__Content--after-open {
      animation: fade-in .3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    &__overlay {
      background-color: rgba($topcolor-black, 0.9);
    }
    &__image {
      svg {
        width: auto;
        height: rem(250px);
      }
    }
    &__title {
      font-size: rem(32px);
    }
    &__text {
      font-size: rem(18px);
    }
  }
}