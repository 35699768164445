.modal-duplicated-content {
  display: flex;
  align-items: center;
  max-height: rem(90vh);
  max-height: calc(var(--vh, 1) * 90);
  background-color: #fff;
  margin: auto;
  padding: rem(45px) 0;
  position: absolute;
  top: rem(50px);
  bottom: rem(50px);
  left: rem(15px);
  right: rem(15px);
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
    &-thumb {
      background: $topcolor-blue;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    
    background-color: rgba($topcolor-black, 0.9);
  }

  &__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    z-index: 1;
    svg {
      width: rem(30px);
      height: rem(30px);
    }
  }
  &__main-image {
    display: block;
    width: 100%;
    height: auto;
    max-height: rem(240px);
    margin: 0 auto;
  }
  &__content-title,
  &__content-text {
    color: $topcolor-blue;
    text-align: center;
  }
  &__content-title {
    text-transform: uppercase;
    font-size: rem(28px);
    font-weight: bold;
    font-family: 'Roboto-Regular';
  }
  &__content-text {
    font-size: rem(18px);
    font-weight: bold;
    font-family: 'Roboto-bold';
  }
  .custom-checkbox {
    font-weight: 500;
  }
  &.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &--fullscreen {
    max-height: unset;
    min-height: 100vh;
    min-height: calc(var(--vh, 1) * 100);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    align-items: unset;
  }
}

@media screen and (min-width: 768px) {
  .modal-duplicated-content {
    padding: rem(30px) rem(40px);
    max-width: rem(950px);
    width: 90vw;
    height: rem(655px);
    overflow-y: auto;

    &__main-image {
      max-height: unset;
      width: rem(200px);
    }
    &__content-title,
    &__content-text {
      text-align: left;
    }

    &__content-title {
      font-size: rem(66px);
    }
  }
}
