.filters-modal {
  .LimiteEspacios{
    line-height: 1.5rem !important;
    }
  padding: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  background-color: $topcolor-grey;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
    &-thumb {
      background: $topcolor-blue;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
  }

  &__content {
    padding: rem(5px);
  }
  &__category-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: rem(15px);
  }
  &__category-item {
    background-color: white;
    padding-left: rem(10px);
    & + .filters-modal__category-item {
      margin-top: rem(3px);
    }
    .custom-checkbox {
      font-size: rem(16px);
      line-height: rem(40px);
      i {
        border: none;
      }
    }
  }
  .day-select {
    max-width: rem(200px);
  }
  footer{
    display: flex;
    justify-content: center;
    button{
      width: 150px;
    }
    }
}

@media screen and (min-width: 992px) {
  .filters-modal {
    max-width: 40vw;
    max-height: rem(810px);
    min-height: unset;
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: auto;
    &.ReactModal__Content--after-open {
      animation: fade-in .3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    &__overlay {
      background-color: rgba($topcolor-black, 0.9);
    }
    &__content {
      padding: 0 rem(25px) rem(20px) rem(25px);
    }
    &__header {
      position: relative;
      padding-top: rem(50px);
    }
    &__close-btn {
      position: absolute;
      right: rem(-10px);
      top: rem(15px);
      padding: 0;
      background-color: $topcolor-black;
      border: none;
      border-radius: 50%;
      height: rem(40px);
      width: rem(40px);
      line-height: 1;
      svg {
        height: 50%;
        width: 50%;
        fill: white;
      }
    }
    &__title {
      position: relative;
      padding-bottom: rem(20px);
      font-weight: 500;
      font-family: 'Roboto-bold';
      &:after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        height: rem(1px);
        background-color: $topcolor-black;
      }
    }
    &__category {
      &-list {
        padding-left: rem(30px);
      }
      &-item {
        & + .filters-modal__category-item {
          margin-top: rem(5px);
        }
      }
      &-title {
        font-family: 'Roboto-bold';
        font-weight: bold;
        font-size: rem(22px);
        margin: rem(20px) 0 rem(15px) 0;
      }
    }
    .custom-checkbox {
      font-size: rem(16px);
      line-height: rem(25px);
      padding-left: rem(40px);
      i {
        border: rem(1px) solid $topcolor-black;
        border-radius: 0;
      }
      i,
      i:after {
        width: rem(25px);
        height: rem(25px);
      }
    }
    footer{
    display: flex;
    justify-content: center;
    /* position: fixed;
    bottom: 36px;
    background-color: white;
    width: 37vw;
    padding: 15px; */
    }
  }
}
@media screen and (max-height: 860px) and (min-width: 992px ){
  .filters-modal{
    footer{
    display: flex;
    justify-content: center;
    position: fixed;
    width: 37%;
    bottom: 0px;
    background-color: white;
    height: 66px;
    button{
      margin-bottom: 14px;
      margin-top: 14px;
    }
    }
    &__category-list {
      margin-bottom: rem(46px);
    }
  }
}
@media screen and (max-width: 992px ) and  (max-height: 860px){
  .filters-modal {
    &__category-list {
      margin-bottom: 50px;
    }
    footer{
      align-items: center;
      background-color: white;
      width: 100%;
      height: 66px;
      display: flex;
      justify-content: center;
      position: fixed;
      z-index: 1;
      bottom: 0px;
      right: 0px;
      left: 0px;
      .btn{
        width: 160px;
        height: 40px;
      }
    }
  }
}

@media screen and (min-height: 860px) and (min-width: 992px ) {
  .filters-modal {
    footer{
      position: sticky;
    bottom: 0px;
    background-color: white;
    padding: 10px;
    }
  }
}


