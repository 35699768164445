.content-card {
  cursor                    : pointer;
  font-family               : 'Roboto-Regular' !important;
  flex-grow                 : 0;
  flex-basis                : calc(50% - 30px);
  padding                   : rem(6px);
  margin                    : 0 rem(5px) rem(15px) rem(5px);
  background-color          : white;
  border                    : none;
  border-top-left-radius    : rem(10px);
  border-bottom-right-radius: rem(10px);
  border                    : 4px solid transparent;
  box-shadow                : 0 0 rem(5px) rgba(black, 0.2);
  position                  : relative;
  color                     : $topcolor-black;

  &:hover {
    text-decoration: none;
    color          : $topcolor-black;
  }

  &--red {
    border-color: $topcolor-red;
    cursor      : not-allowed;
  }

  &--selected {
    background-color: $topcolor-light-blue;
  }

  &--highlighted {
    border-color: $topcolor-light-blue;
  }

  &--staff-pick {
    &:after {
      background     : url('/assets/images/iconStar.svg') no-repeat;
      background-size: contain;
      content        : '';
      position       : absolute;
      top            : rem(10px);
      left           : rem(10px);
      height         : rem(30px);
      width          : rem(30px);
    }
  }

  &--iconSelectionPoi {
    &:after {
      background     : url('/assets/images/iconSelectionPoi.svg') no-repeat;
      background-size: contain;
      content        : '';
      position       : absolute;
      top            : rem(10px);
      right          : rem(5px);
      height         : rem(30px);
      width          : rem(30px);
    }

    .logoPoiCampaign {
      margin-top: 20px;
      object-fit: cover;
      width     : 100%;
    }

    .content-card__title {
      // color      : #6f6f6f !important;
      // font-size  : 16px !important;
      // font-family: 'Roboto', sans-serif !important;

      text-align: center;
      font-family: 'Roboto-Regular' !important;
      font-size: 1rem;
      margin: 0;
      line-height: 1.25;
      overflow: hidden;
      color: #6f6f6f;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
    }

    .content-card__textSelection {
      text-transform: uppercase;
      font-size     : 16px !important;
      font-family   : 'Oswald';
      color         : #6f6f6f;
    }
  }

  &--selection {
    width     : 512px;
    max-width : 512px !important;
    flex-basis: auto !important;
    min-width: 512px !important;

    .content-card__title {
      color      : rgb(0, 108, 198);
      font-size  : 20px;
      font-family: 'Roboto-bold';
    }

    .content-card__textSelection {
      text-align: center;
      text-transform: uppercase;
      font-size     : 18px;
      font-family   : 'Oswald';
      color         : #6f6f6f;
    }
  }

  .likesTour {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    text-transform: uppercase;
    font-size     : 18px;
    font-family   : 'Oswald';
    margin-right  : 4px;

    svg {
      width: 20px;
      fill : #81d4fb;
    }
  }

  .iconStar {
    display         : flex;
    width           : 30px;
    height          : 30px;
    background-color: #40444B;
    justify-content : center;
    align-items     : center;
    border-radius   : 4px;
    margin-bottom   : 10px;

    svg {
      height: 18px;
      width : auto;
      fill  : #ffcb02;
    }
  }

  &--added {
    background-color: $topcolor-black;

    .content-card__visualizations,
    .content-card__author,
    .content-card__title {
      font-family: 'Roboto-Regular', sans-serif;
      color      : white;
    }

    .content-card {
      &__state {
        background-color: white;

        svg {
          fill: $topcolor-light-blue;
        }
      }

      &__visualizations {
        &-value {
          color: white;
        }

        svg {
          fill: white;
        }
      }
    }
  }

  &--with-overlayer {
    .content-card {
      &__title {
        margin-top : 0;
        font-family: 'Roboto-Regular', sans-serif;
      }

      &__content {
        padding-top: rem(35px);
      }
    }
  }

  &--transparent {
    opacity: 0.5;
  }

  &__carrousel {
    height          : rem(180px);
    width           : 100%;
    object-fit      : cover;
    position        : relative;
    margin-bottom   : 0;
    background-color: white;

    &:hover {
      .content-card__carrousel-control {
        display: block;
      }
    }

    &-control {
      display         : none;
      position        : absolute;
      top             : 0;
      bottom          : 0;
      width           : rem(30px);
      height          : rem(30px);
      margin          : auto;
      background-color: transparent;
      border          : none;
      padding         : 0;

      &:before {
        content      : '';
        display      : block;
        width        : 100%;
        height       : 100%;
        border-bottom: rem(3px) solid white;
        border-left  : rem(3px) solid white;
      }

      &--back {
        left     : rem(15px);
        transform: rotate(45deg);
      }

      &--next {
        right    : rem(15px);
        transform: rotate(-135deg);
      }
    }
  }

  &__visualizations,
  &__visualizations-value,
  &__avatar-wrapper,
  &__author {
    display       : inline-block;
    vertical-align: middle;
    color         : $topcolor-black;
  }

  &__visualizations {
    font-size    : 0.9rem;
    padding-right: rem(5px);
    text-align   : left;
    margin       : rem(10px) 0 0 0;
    line-height  : 1;

    svg {
      width : rem(23px);
      height: auto;
    }

    &-value {
      font-family: 'Roboto', sans-serif !important;
      // font-weight: 100;
      margin-left: rem(5px);
    }
  }

  &__avatar {
    object-fit   : cover;
    border-radius: 50%;
    border       : 3px solid white;
    height       : 100%;
    width        : 100%;
    z-index      : 1;
    margin-bottom: 0;
    position     : relative;

    &-wrapper {
      margin-top: rem(-30px);
      height    : rem(60px);
      width     : rem(60px);
    }
  }

  &__author {
    font-size     : 0.9rem;
    margin-bottom : 0;
    width         : calc(50% - 40px);
    padding-left  : rem(5px);
    white-space   : nowrap;
    text-overflow : ellipsis;
    overflow      : hidden;
    text-transform: uppercase;
    font-family   : 'Oswald';
    text-align    : left;
  }

  .opac {
    opacity   : 0;
    /* display: none; */
  }

  &__state {
    position        : absolute;
    right           : rem(10px);
    top             : rem(-10px);
    background-color: $topcolor-light-blue;
    border-radius   : 50%;
    border          : none;
    width           : rem(40px);
    height          : rem(40px);
    z-index         : 2;
    text-align      : center;
    padding         : 0;
    line-height     : 0;

    svg {
      height  : 65%;
      width   : auto;
      fill    : white;
      position: absolute;
      left    : 0;
      right   : 0;
      margin  : auto;
      top     : 0;
      bottom  : 0;
    }

    &:hover {
      background-color: $topcolor-blue;
    }

    &--remove-btn {
      right           : unset;
      left            : rem(10px);
      background-color: $topcolor-light-red;

      svg {
        fill: white;
      }

      &:hover {
        background-color: $topcolor-red;
      }
    }
  }

  &__content {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : rem(56px);

    &--counter {
      display: flex;

      .content-card__title {
        font-family: 'Roboto-Regular', sans-serif;
        flex       : 1 1 0;
      }

      .content-card__counter {
        display    : flex;
        // flex: 0 0 auto;
        // padding: 0 rem(6px);
        font-family: 'Roboto', sans-serif !important;
        font-size  : rem(15px);
        color      : $topcolor-dark-grey;

        svg {
          display     : inline-block;
          width       : rem(14px);
          margin-right: rem(3px);
          fill        : $topcolor-dark-grey;
        }
      }
    }
  }

  &__title {
    text-align        : center;
    font-family       : 'Roboto-Regular' !important;
    // font-weight       : 100;
    font-size         : rem(16px);
    margin            : 0;
    line-height       : 1.25;
    overflow          : hidden;
    color             : $topcolor-dark-grey;
    display           : -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow     : ellipsis;
  }

  &__footer {
    margin-top : rem(10px);
    padding-top: rem(10px);
    height     : rem(50px);
    position   : relative;

    &:before {
      content : '';
      display : block;
      width   : 90%;
      margin  : 0 auto;
      border  : rem(1px) dashed $topcolor-grey !important;
      position: absolute;
      top     : 0;
      left    : 0;
      right   : 0;
    }
  }

  &__partner-logo {
    display   : block;
    margin    : 0 auto;
    max-height: 100%;
    max-width : 100%;

    &-wrapper {
      display        : flex;
      justify-content: center;
      align-items    : center;
      height         : 100%;
    }
  }

  &__overlayer {
    position       : absolute;
    top            : rem(185px);
    text-align     : center;
    width          : calc(100% - 20px);
    display        : flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 576px) {
  .content-card {
    max-width: rem(255px);
    min-width: rem(250px);
  }
}

@media screen and (min-width: 991px) {
  .content-card {
    transition: all .3s ease;

    &__visualizations {
      width : calc(50% - 40px);
      margin: 0;
    }

    &__overlayer {
      display: block;
    }

    &:hover {
      box-shadow: 0 2px rem(12px) rgba(black, .6);
    }
  }
}

@media screen and (min-width: 1300px) {
  .content-card {
    flex-basis: calc(33% - 30px);
  }
}

@media screen and (min-width: 1920px) {
  .content-card {
    flex-basis: calc(25% - 30px);
  }
}

@media screen and (max-width: 420px) {
  .content-card {
    &--selection {
      flex-basis: calc(100% - 50px) !important;
      min-width: auto !important;
    }
    &.homeCardView {
      min-width: 100% !important;
    }

    &__title {
      margin-top: 10px;
      font-size : 16px !important;
    }

    &__textSelection {
      text-align: center;
    }
  }
}