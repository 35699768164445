.tour-edition-page {
  background-color: #DDDDDD;
  min-height: calc(100vh - 50px);
  min-height: calc(var(--vh, 1vh) * 100 - 50px);
}

@media screen and (min-width: 992px) {
  .tour-edition-page {
    min-height: calc(100vh - 70px);
    min-height: calc(var(--vh, 1vh) * 100 - 70px);
  }
}
