.dropzone {
  border: rem(1px) dotted;
  min-height: rem(200px);
  min-width: rem(200px);

  & .poi-info-page__previous-image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
  }
  .icon.ion-upload {
    display: none;
  }
  & .progressPercentageBarWrapper {
    position: absolute;
    height: rem(24px);
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    opacity: 0.8;
    & .progressPercentageBarProgress {
      display: block;
      height: 100%;
      z-index: 2;
      background-color: $topcolor-blue;
      padding: 0 rem(10px);
      font-weight: bold;
    }
  }
}

.fine-uploader {
  &__files-wrapper {
    padding: rem(40px) rem(20px);
  }
  &__upload-input {
    display: block !important;
    max-width: rem(300px);
    margin: 0 auto;
  }
}

.thumbnails-wrapper {
  &__box {
    position: relative;
    & + .thumbnails-wrapper__box {
      margin-top: rem(10px);
    }
  }
  &__remove-btn {
    position: absolute;
    top: rem(3px);
    right: rem(3px);
    background-color: $topcolor-red;
    z-index: 1;
    border: 0;
    border-radius: 50%;
    width: rem(35px);
    height: rem(35px);
    svg {
      width: 90%;
      height: auto;
      fill: white;
    }
    &:hover {
      background-color: darken($topcolor-red, 15);
    }
  }
  &__preview {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}
