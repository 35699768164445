.search-map {
  margin: 0 rem(-15px) 0 rem(-15px);
  position: relative;
  height: 100%;
  flex-grow: 1;

  &__button {
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    margin: 0 auto;
    width: rem(100px);
  }
  .simple-map {
    height: calc(100vh - 225px);
    height: calc((var(--vh, 1vh) * 100) - 225px);
  }
  .custom-checkbox {
    margin-bottom: 0;
    position: absolute;
    top: rem(5px);
    left: rem(5px);
    z-index: 1;
    padding: rem(0 3px 0 35px);
    background-color: rgba(white, 0.66);
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(15px);
    border-radius: rem(3px);
    line-height: rem(14px);
    text-align: center;
    i,
    i:after {
      width: rem(26px);
      height: rem(26px);
    }
    i {
      left: rem(1px);
    }
  }
}
.whatever-alex-wants {
  background: rgba(255,255,255,0.5);
  padding-top: 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.spinner-border {
  border-color: $topcolor-blue;
}

@media screen and (min-width: 992px) {
  .search-map {
    width: rem(400px);
    flex-basis: rem(400px);
    flex-shrink: 0;
    z-index: 1;
    margin: 0 rem(-15px) 0 0;
    min-width: rem(350px);
    .custom-checkbox {
      margin-bottom: 0;
      position: absolute;
      top: rem(5px);
      left: rem(5px);
      z-index: 1;
      padding: rem(0 3px 0 35px);
      background-color: rgba(white, 0.66);
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: rem(18px);
      border-radius: rem(3px);
      line-height: rem(16px);
      text-align: center;
      i,
      i:after {
        width: rem(30px);
        height: rem(30px);
      }
      i {
        left: rem(1px);
      }
    }
    .simple-map {
      height: 100%;
    }
  }  
}

@media screen and (min-width: 1200px) {
  .search-map {
    width: rem(500px);
    flex-basis: rem(500px);
    flex-shrink: 0;
  }
}
