.responsive-img {
  display: block;
  margin-bottom: 0;
  position: relative;
  &__invisible-btn{
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:none;
    border:none;
    opacity: 0;
  }
  &--loading {
    // &:before {
    //   content: '';
    //   display: block;
    //   position: absolute;
      
      
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background-size: rem(30px);
    //   z-index: 1;
    // }
    img {
      background-color: $topcolor-light-blue;
      background-image: url('/assets/images/spinner.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &--blue {
    .responsive-img__icon {
      display: block;
      background-color: $topcolor-light-blue;
      position: relative;
      svg {
        fill: white;
        width: rem(45px);
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  &--inverse {
    img {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('/assets/images/spinner-primary.svg');
    }
  }
}
