.tour-config-main {
  &__label {
    margin-left: rem(10px);
    font-family: 'Roboto-bold';
    font-size: rem(18px);
    margin-bottom: rem(7px);
    &-icon {
      svg {
        height: rem(20px);
        width: rem(20px);
        fill: $topcolor-blue;
        margin-right: rem(10px);
      }
    }
    &.custom-checkbox {
      display: inline-block;
      & + .tour-config-modal__label {
        margin-left: rem(15px);
      }
    }
  }

  &__images {
    display: flex;
    & > * + * {
      margin-left: rem(15px);
    }
  }
  .autosuggest-wrapper__input {
    margin-top: 0;
  }
  .thumbnails-wrapper__remove-btn {
    display: none;
  }
  .fine-uploader__files-wrapper {
    display: inline-block;
    padding: 0;
  }
  .fileInput {
    .thumbnails-wrapper__preview {
      height: rem(75px);
      width: rem(145px);
    }
    & + .fileInput {
      .thumbnails-wrapper__preview {
        width: rem(45px);
      }
    }
  }
  .thumbnails-wrapper__box {
    padding-bottom: rem(30px);
    height: rem(105px);
  }
  .progressPercentageBarWrapper {
    height: rem(30px);
    background-color: rgba($topcolor-blue, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .progressPercentageBarProgress {
    display: block;
    height: 100%;
    background-color: $topcolor-blue;
    color: white;
    padding: 0 rem(10px);
    line-height: rem(30px);
    font-weight: bold;
    font-family: 'Roboto-bold';
  }
  &__input {
    &-photo {
      display: inline-block;
      vertical-align: middle;
      height: rem(75px);
      background-color: rgba($topcolor-light-blue, 0.3);
      position: relative;
      margin-bottom: 0;
      font-family: 'Oswald';
      text-transform: uppercase;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &--desktop {
        width: rem(145px);
      }
      &--mobile {
        width: rem(50px);
      }
    }
    &-msg {
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: rem(30px);
      line-height: rem(30px);
      text-align: center;
    }
    &-icon {
      display: inline-block;
      vertical-align: middle;
      background-color: $topcolor-blue;
      border-radius: 50%;
      width: rem(30px);
      height: rem(30px);
      line-height: rem(30px);
      text-align: center;
      margin-left: rem(3px);
      svg {
        fill: white;
        width: auto;
        height: 55%;
      }
    }
  }
  &__rich-text-editor {
    width: 100%;
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-editor {
      min-height: rem(82px);
    }
  }
  &__state {
    color: white;
    font-size: rem(16px);
    margin-bottom: 0;
    &-btn {
      width: rem(98px);
      margin-left: rem(10px);
      padding: rem(5px) rem(15px);
      border: none;
    }
    &-description {
      text-align: right;
      margin: 0;
      color: $topcolor-black;
      font-size: rem(16px);
    }
  }
  .custom-state-select {
    width: rem(98px);
    position: absolute;
    right: rem(15px);
    z-index: 10;
    &__list {
      display: flex;
      flex-direction: column;
      height: rem(37px);
      label {
        color: white;
        cursor: pointer;
        line-height: rem(37px);
        text-align: center;
        margin: 0;
      }
    }
    //hides radio button in the list, only shows the labels
    input {
      position: absolute;
      visibility: hidden;
    }
  }
  &__state-btn,
  .custom-state-select__list-item {
    &--edition {
      background-color: $topcolor-blue;
      color: white;
    }
    &--published {
      background-color: $topcolor-orange;
      color: white;
    }
    &--approved {
      background-color: #28a745;
      color: white;
    }
    &--hidden {
      background-color: $topcolor-dark-grey;
      color: white;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-config-main {
    &__input {
      &-photo {
        height: rem(120px);
        &--desktop {
          width: rem(220px);
        }
        &--mobile {
          width: rem(80px);
        }
      }
    }
    .fileInput {
      .thumbnails-wrapper__preview {
        width: rem(220px);
        height: rem(120px);
      }
      & + .fileInput {
        .thumbnails-wrapper__preview {
          width: rem(80px);
        }
      }
    }
    .thumbnails-wrapper__box {
      height: rem(150px);
    }
  }
}