.service-premium {
  background-color: white;
  padding: rem(5px) rem(15px) rem(15px) rem(15px);
  position: relative;
  margin-top: rem(30px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__header {
    align-items: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: 0;
      text-align: center;
      svg {
        padding-top: rem(3px);
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__owner {
    font-size: rem(11px);
    margin: 0 0 0 rem(-10px);
    font-weight: bold;
    color: $topcolor-dark-grey;
    &-name {
      display: inline-block;
      vertical-align: bottom;
      font-family: 'Oswald';
      font-weight: 100;
      text-transform: uppercase;
      font-size: rem(14px);
      margin-left: rem(5px);
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: rem(3px);
        height: rem(14px);
        width: auto;
        fill: $topcolor-dark-grey;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .service-premium {

  }
}
