$topcolor-black: #2c2c2c; // header
$topcolor-light-black: #303030;
$topcolor-blue: #00affe; // primary
$topcolor-light-blue: #81d4fb;
$topcolor-dark-blue: #3366cc;
$topcolor-grey: #dedede;
$topcolor-light-grey: #f4f4f4;
$topcolor-dark-grey: #6f6f6f;
$topcolor-light-red: #d6696c;
$topcolor-red: #d34c50;
$secondary-blue: #163d7b;
$topcolor-yellow: #ffcb02;
$topcolor-orange: #fd7e14;
$topcolor-green: #21B14F; // Recommendations caption

.blue-bg {
  background-color: $topcolor-light-blue;
}
