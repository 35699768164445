.modal-log-out {
  /*   position     : absolute; */
  top               : 40px;
  left              : 40px;
  right             : 40px;
  bottom            : 40px;
  border            : 1px solid rgb(204, 204, 204);
  background        : rgb(255, 255, 255);
  overflow          : auto;
  border-radius     : 4px;
  outline           : none;
  padding           : 20px;
  width             : 70%;
  height            : 80%;

  &__overlay {
    justify-content : center;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529D4
  }

  .contentModal {
    flex-direction: column;

    .nav {
      width: 100%;

      .closeIcon {
        background-color: transparent;
        display         : inline-block;
        border          : none;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          height: rem(24px);
          width : rem(24px);
          fill  : black;
        }
      }
    }

    .contentCenter {
      margin        : 60px;
      flex-direction: column;

      h3 {
        font-size    : 26px;
        font-family  : 'Roboto',sans-serif !important;
        font-weight  : 700;
        margin-bottom: 15px;
      }

      p {
        font-size  : 19px;
        font-family: 'Roboto',sans-serif !important;
        font-weight: 100;

      }
    }

    .contentBot {
      margin-right: 100px;

      .acceso,
      .registrarme {
        align-items     : center;
        display         : flex;
        justify-content : center;
        margin          : 3px;
        height          : 35px;
        min-width       : 150px;
      }
    }
    .contentAlert{
      display: none;
    }
  }

}

@media screen and (max-width: 768px) {
  .modal-log-out {
    width : 100%;
    height: 100%;

    .contentCenter {
      margin: 20px !important;
    }

    .contentBot {
      margin: 0px !important;
    }
  }
}

@media screen and (max-width: 350px) {
  .modal-log-out {
    .contentBot {
      flex-direction: column;
    }
  }
}
@media screen and (min-width: 992px) {
  .contentAlert{
    width: 200px;
    position: relative;
    bottom: 85px;
    left: -21px;
    height: 100px;
    display: block !important;
  }
}
@media screen and (min-width: 992px) and (min-height: 800px) {
  .modal-log-out {
    width : 80%;
    height: 40%;
  }
}
@media screen and (min-width: 1200px) and (min-height: 800px) {
  .modal-log-out {
    width : 60%;
    height: 60%;
  }
}
@media screen and (min-width: 1800px) and (min-height: 900px) {
  .modal-log-out {
    width : 50%;
    height: 50%;
  }
}
@media screen and (min-width: 2100px) and (min-height: 900px) {
  .modal-log-out {
    width : 35%;
    height: 40%;
  }
}