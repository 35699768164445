.poi-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-15px) 0 rem(-15px);
  padding: rem(5px) rem(5px) rem(50px) rem(5px);
  h6 {
    color: white;
  }
  &__poi {
    padding: rem(5px);
    flex: 0 0 33.33333%;
    height: calc(33vw - 1.81px);
    border: none;
    background-color: transparent;
    &-image,
    &-image-wrapper {
      height: 100%;
      width: 100%;
    }
    &-image {
      object-fit: cover;
      &-wrapper {
        margin: 0;
        &--red {
          border: rem(3px) solid $topcolor-red;
        }
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .poi-gallery {
    &__poi {
      flex: 0 0 25%;
      height: calc(25vw - 6.24px);
    }
  } 
}
