$medium-size: 20px;

.text-medium {
  font-size: rem($medium-size);
}

.f-oswald {
  font-family: 'Oswald';
}
.f-quicksand {
  font-family: 'Roboto-bold';
}

.f-opensans {
  font-family: 'Roboto-Regular';
}

.text-standard-small {
  font-family: 'Oswald';
  font-size: rem(16px);
  line-height: rem(19px);
  color: $topcolor-dark-grey;
}

.text-standard {
  font-family: 'Oswald';
  font-weight: 100;
  line-height: rem(29px);
  font-size: rem(19px);
  color: $topcolor-black;
}

.customized-text {
  font-family: 'Oswald';
  white-space: pre-line;
  line-height: rem(29px);
  font-size: rem(19px);
  color: $topcolor-black;
  overflow: visible;
  margin-bottom: 0;
  strong {
    color: $topcolor-blue;
    font-weight: 100;
  }
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
}

.data-tip {
  font-family: 'Roboto-bold';
  max-width: rem(300px);
  margin-bottom: 0;
  font-size: rem(15px);
  text-align: justify;
  & + .data-tip {
    margin-top: rem(5px);
  }
}