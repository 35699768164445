@font-face {
  font-family: 'Roboto-bold';
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/Oswald-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family:'Roboto-Regular' ;
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family:'Roboto', sans-serif !important ;
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family:'Roboto-Italic' !important ;
  src: url('/assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family:'Roboto', sans-serif !important ;
  src: url('/assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('/assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
}

// src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
// src: url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
// src: url('/assets/fonts/Roboto-Light.ttf') format('truetype');
// src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');