div.top-select {
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: uppercase;
  font-family: 'Oswald';
  &.top-select--icon {
    width: rem(100px);
    .top-select {
      &__single-value {
        width: auto;
      }
      &__option {
        text-align: center;
      }
    }
    svg {
      height: rem(35px);
      width: auto;
    }
  }
  &__value-container {
    height: calc(1.5em + 0.75rem + 2px);
    background-color: rgba(129, 212, 251, 0.3);
    border-radius: 0;
    background-clip: padding-box;

    &:focus {
      color: #495057;
      background-color: rgba(129, 212, 251, 0.3);
      border-color: #7fd7ff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25);
    }
  }
  &__single-value {
    width: 100%;
    font-size: rem(18px);
    text-align: center;
  }
  &__control {
    border: none;
    &:focus,
    &:hover {
      box-shadow: none;
    }
    &--menu-is-open {
      box-shadow: none;
      .top-select__dropdown-indicator-img {
        transform: rotate(180deg);
        
      }
    }
  }
  &__dropdown-indicator {
    padding: 0;
    margin-left: rem(10px);
  }
}

span.top-select__indicator-separator {
  display: none;
}

.top-select__dropdown-indicator-img {
  transition-duration: 0.2s;
  width: rem(35px);
  height: rem(35px);
}
