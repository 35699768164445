.sponsor-place,
.payForm {
  .botonabajo {
    margin   : 30px 0;
    min-width: 200px;
  }

  .pagarButtonSponsor {
    min-width: 200px;
  }



  .mobile {
    display: none;
  }

  .tour {
    border: 1px solid red !important;
  }

  // width: 100vw;
  .div1 {
    width           : 100%;
    height          : 180px;
    background-color: #78caf9;

    .title1 {
      padding-left : 5%;
      padding-right: 5%;
      font-size    : 1.5rem;
      font-family  : 'Roboto-bold';
      color        : white;
      margin-bottom: 5px;
    }

    .title2 {
      padding-left : 5%;
      padding-right: 5%;
      font-size    : 1rem;
      font-family  : 'Roboto-Regular';
    }
  }

  .div2 {
    width              : 100%;
    // height             : 350px;
    padding: 30px 20px 40px 20px;
    background-color   : #f5f5f5;
    position           : relative;
    // padding-top     : 20px;
    // padding-bottom  : 60px;
    font-family        : 'Roboto-Regular' !important;

    .ItemDiv2 {
      width       : 335px;
      // height      : 240px;
      margin-left : 15px;
      margin-right: 15px;

      // border: 1px solid black;
      .msg2 {
        text-align: center;
        margin    : 0px 10px 0px 10px;

        p {
          line-height: 1.3;
          font-size  : 14px;
          margin-top : 10px;
        }
      }
    }

    .I1 {
      font-family: 'Oswald';

      .divPatrocinar {
        span {
          position: absolute;
          top     : -25px;
        }

        position: relative;

        .Img2 {
          width     : 100%;
          object-fit: cover;
          height: 220px;
          object-position: center;
          position  : absolute;
        }

        .img-link1 {
          position: absolute;
          right   : 10px;
          top     : 10px;
          cursor  : pointer;
        }
      }
    }

    .I2 {
      .title2 {
        text-align      : center;
        width           : 90%;
        font-family     : 'Roboto-bold';
        border-bottom   : 1px dashed #333333;
        // border-bottom: 1px dashed darkgrey;
      }

      .itemList {
        // display    : table-row-group !important;
        display       : block;
        margin        : 0px 0px 0px 0px;
        margin-top    : 10px;
        font-family   : 'Oswald';
        text-transform: uppercase;

        svg {
          width : 20px !important;
          height: 20px !important;
        }
      }

      .icono2 {
        height: auto;
      }

      .img-link2 {
        cursor: pointer;
      }
    }

    .I3 {
      background-color: #7bcffe;
      position        : relative;

      .Title2-3 {
        font-family: 'Roboto-bold';
        height     : 60px;
      }

      .bloque-2-I3 {
        .SubTitle2-3 {
          font-family: 'Roboto-bold';
          height     : 50px;
          color      : white;
          font-size  : 70px;
        }

        .Anuales {
          width      : 81%;
          text-align : right;
          font-family: 'Oswald';
        }

        .PrecioProm {
          font-size: 13px;
        }
      }

      .DirectAutor {
        margin-top : 10px;
        margin-bottom: 45px;
        font-weight: bold !important;
        font-size  : 24px;
      }

      .NombrePersona {
        width      : fit-content;
        position   : absolute;
        bottom     : 0px;
        left       : 150px;
        font-family: 'Oswald';
      }

      .img-foto {
        border-radius: 100%;
        position     : absolute;
        height       : 60px;
        width        : 60px;
        object-fit   : cover;
        bottom       : -30px;
        left         : 80px;background-color: white;
      }
    }

    .botonMitad {
      position: absolute;
      bottom  : -17px;
    }
  }

  .div3 {
    width              : 100%;
    // height          : 70px;
    // background-color: white;
    position           : relative;
    margin             : 50px 0 0;

    h3 {
      font-family     : 'Roboto-bold';
      // margin-bottom: 0px;
    }
  }

  .div4 {
    width      : 100%;
    height     : 400px;
    // position: relative;

    .ItemDiv4 {
      width : 340px;
      border: 1.5px solid #7bcffe;
      margin: 3px;
      display: flex;
      justify-content: space-between;
      align-items: space-between;
      flex-direction: column;

      .title2 {
        margin-top   : 15px;
        padding-left : 5%;
        padding-right: 5%;
        font-size    : 1rem;
        font-family  : 'Roboto-Regular';
      }

      .title22 {
        margin-top   : 15px;
        padding-left : 5%;
        padding-right: 5%;
        font-size    : 1rem;
        font-family  : 'Oswald';
      }

      .Titulo {
        // height       : 10%;
        text-align   : center;
        width        : 90%;
        font-family  : 'Roboto-bold';
        border-bottom: 1px dashed #333333;
        margin       : 0 auto;
        padding-bottom: 5px;
        margin-top   : 45px;
      }

      .divbotones {
        margin-top: 70px;
        height    : 150px;

        .botones1 {
          //   margin-top: 10px;
          height         : 80px;
        }

        .botones {
          width : 70px;
          margin: 10px;
        }
      }
    }
  }

  .div5 {
    width: 100%;

    .div6 {
      width: 55%;

      p {
        margin-bottom: 0px;
        font-family  : 'Roboto-bold';
        font-size    : 1rem;
      }

      .title2 {
        font-size  : 1rem;
        font-family: 'Roboto-Regular';
      }
    }

    p {
      margin-top : 10px;
      font-family: 'Roboto-bold';
      font-size  : 1.5rem;
    }
  }

  .div7 {
    width: 100%;

    .div6 {
      width: 34%;

      p {
        margin-top : 20px;
        font-family: 'Roboto-bold';
        font-size  : 1.5rem;
        color      : #1fb6ff;
      }
    }
  }

  .botonabajo {
    margin: 30px;
  }

  //Movil
  @media screen and (max-width: 991px) {
    .div1 {
      width              : 100%;
      // height          : 160px;
      height: auto;
      padding            : 30px 0;
      box-sizing         : unset;
      background-color   : #78caf9;

      .Title2 {
        text-align: left !important;
      }

      h3 {
        font-size: 18px;
      }

      h6 {
        font-size: 16px;
      }
    }

    .div2 {
      // height: 1200px;
      padding-bottom: 170px;
      .I1 {
        .divPatrocinar {
          margin-top: 30px;
        }
      }

      .I2 {
        padding   : 250px 0 0px;
        box-sizing: unset;

        .msg2 {
          text-align       : justify;
          // padding-bottom: 40px;
          // margin-top    : 140px;
          box-sizing       : unset;
        }

        .itemList {
          font-size  : 15px;
          font-weight: 600;

          svg {
            width : 30px !important;
            height: 30px !important;
          }
        }
      }

      .I3 {
        margin   : 40px 0 0;
        width    : 100%;
        // height: auto;
        height   : 420px;

        .img-foto {
          bottom : -30px;
          left   : 0;
          right  : 0;
          margin : auto;
          z-index: 2;
        }

        .NombrePersona {
          position      : absolute;
          bottom        : -55px;
          left          : 0;
          right         : 0;
          margin        : auto;
          font-weight   : bold;
          text-transform: capitalize;
        }

        .BackGround-Blue {
          background-color: #7bcffe;
          // position     : relative;
          z-index         : 2;
        }

        .Title2-3 {
          margin-bottom: 15px;

          h3 {
            font-size: 1.4rem;
          }
        }

        .bloque-2-I3 {
          margin-bottom: 20px;

          .SubTitle2-3 {
            margin-top: 10px;
          }

          .Anuales {
            width      : 96%;
            font-weight: bold;
          }

          .PrecioProm {
            text-align    : end;
            width         : 172px;
            display       : flex;
            flex-direction: column;
          }
        }

        .DirectAutor {
          position: relative;
          .Eur50 {
            font-size: 70px;
          }

          .DirectAutor2 {
            position  : absolute;
            text-align: center;
            bottom    : -31px;
            width     : 132px;
            font-size : 18px;
            text-align: end;
          }
        }

        .LineVertical {
          position   : absolute;
          height     : 100%;
          border-left: 1.6px dashed white;
          z-index    : 1;
        }
      }

      .botonMitad {
        width : 200px;
        bottom: 25px;
      }
    }

    //Duban parte 2
    .div33 {
      width        : 100%;
      position     : relative;
      border-bottom: 1px dashed #7bcffe;
      height       : 30px;
      margin-bottom: 30px;

      .titulomobile {
        position  : absolute;
        bottom    : -18px;
        background: white;
      }
    }

    .divVentajas {
      margin-bottom: 0px;
    }

    .flip1 {
      padding      : 10px 5px;
      text-align   : left;
      color        : #7bcffe;
      font-family  : 'Roboto-bold';
      border-bottom: 1px solid #7bcffe;
      padding      : 0px;
      margin       : 20px;
      height       : 25px;

      .imgdown {
        background: red;
      }
    }

    .panel {
      margin : 20px;
      display: none;

      .title2 {
        margin     : 0px;
        font-size  : 0.8rem;
        font-family: 'Roboto-Regular';
      }

      .divbotones {
        .botones {
          height: 50px;
          width : 90px;
          margin: 10px;
        }
      }

      .title22 {
        margin-top   : 10px;
        margin-bottom: 0px;
        padding-left : 5%;
        padding-right: 5%;
        font-size    : 1rem;
        font-family  : 'Oswald';
      }
    }

    .div7 {
      width: 100%;

      .div66 {
        p {
          margin-top : 20px;
          font-family: 'Roboto-bold';
          font-size  : 1.3rem;
          color      : #1fb6ff;
        }
      }
    }

    .div55 {
      width: 100%;

      .div66 {
        width : 100%;
        margin: 20px;

        p {
          margin-bottom: 0px;
          font-family  : 'Roboto-bold';
          font-size    : 1rem;
        }

        .title2 {
          font-size  : 1rem;
          font-family: 'Roboto-Regular';
        }
      }

      p {
        margin-top : 10px;
        font-family: 'Roboto-bold';
        font-size  : 1.5rem;
      }
    }

    .botonabajo2 {
      margin: 10px;
      width : 50%;
    }

    .img2 {
      fill: white;
    }
  }
}

.tinyButtons {
  min-width: 40px !important;
  width: 40px !important;
  margin: auto 2px;
}
.transparentTitle {
  color: transparent;
}

.modalImagesSponsor { 
  top       : 40px;
  left      : 40px;
  right     : 40px;
  bottom    : 40px;
  background-color : white;
  overflow  : auto;
  outline   : none;
  padding   : 10px 20px 20px 20px;
  width     : auto;
  height    : auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 991px) {
      align-items: center;
    }

  &__contentModal {
    justify-content : flex-end;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529d4;
  }
  img {
    width: auto;
    height: 800px;
    @media (max-width: 991px) {
      width: 95%;
      height: auto;
    }
  }

  .closeIcon {
      background-color: transparent;
      display         : inline-block;
      border          : none;
      vertical-align  : middle;
      line-height     : 1;

      svg {
        width : 30px;
        height: 30px;
        fill  : black;
      }
    }
}

/// julian
.modalPay {
  top       : 40px;
  left      : 40px;
  right     : 40px;
  bottom    : 40px;
  background: white;
  overflow  : auto;
  outline   : none;
  padding   : 10px 10px 20px 10px;
  width     : 50%;
  height    : 100%;

  &__contentModal {
    justify-content : flex-end;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529d4;
  }

  .content {
    .infoTours {
      h3 {
        font-weight: 700;
      }

      .p {
        width:20%;
      }

      img.portada {
        height: 100%;
        object-fit: cover;
        // width: 140px !important;
      }

       h5{
        font-weight: 700;
        margin: 0;
      }
      h4{
        font-weight: 700;
      }

      .previewImages {
        width: 100%;
      }

      .img-link2 {
        cursor: pointer;
      }
    }

    .infoPay {
      h3, h4 {
        font-weight: 700;
      }
    }

    .division {
      background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: top;
      background-size    : 6px 1px;
      background-repeat  : repeat-x;
      padding-top        : 10px;
    }

    .division2 {
      background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size    : 6px 1px;
      background-repeat  : repeat-x;
      padding-bottom     : 10px;
      margin             : 10px;
    }

    .closeIcon {
      background-color: transparent;
      display         : inline-block;
      border          : none;
      vertical-align  : middle;
      line-height     : 1;

      svg {
        width : 40px;
        height: 40px;
        fill  : black;
      }
    }

    .contentPay {
      padding: 30px;

      .top {
        .circle {
          width        : 80px;
          height       : 80px;
          border-radius: 50%;
          box-shadow   : 0px 1px 3px #888888;
          overflow: hidden;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .textBlue {
          color: #1fb6ff;
        }
      }

      .center {
        .payService {
          background-color: #68cdff;
          padding         : 20px;
          margin-top      : 18px;

          h1 {
            font-size  : 80px;
            font-weight: 700;
            color      : white;
          }

          h3 {
            font-weight: 700;
            color      : black;
            text-align : center;
          }
        }

        .notice {
          display   : flex;
          text-align: center;
          color     : #7c959c;
        }

        .mastercard {
          width      : 40px;
          height     : 26px;
          padding    : 2px;
          border     : 1px solid lightgray;
          margin-left: 30px;
        }

        .visa {
          width        : 40px;
          height       : 26px;
          padding-right: 2px;
          border       : 1px solid lightgray;
          margin-left  : 30px;
        }

        .amex {
          width      : 45px;
          margin-left: 28px;
        }

        .inputCard {
          background     : url(/assets/images/cardgray.svg) no-repeat scroll 7px 7px;
          padding-left   : 60px;
          background-size: 45px;
          border         : 1px solid lightgray;
        }

        .form-control {
          background-color: white !important;
          border          : 1px solid lightgray;
        }
      }
    }

    .paymentConfirmed {
      .textBlue {
        color: #1fb6ff;
      }

      .grafismo {
        width: 250px;
      }
    }
  }

  // .btn-primary {
  //   background-color: #68cdff;
  //   border-color    : #68cdff;
  // }
}

@media screen and (max-width: 1480px) {
  .modalPay {
    width: 60%;
  }
}

@media screen and (max-width: 992px) {
  .modalPay {
    width        : 90%;
    height       : 90%;
    border-radius: 20px;

    &__contentModal {
      justify-content: center;
    }

    .closeIcon {
      svg {
        width : 35px !important;
        height: 35px !important;
        fill  : #68cdff !important;
      }
    }

    .infoPay {
      h3 {
        text-align: center;
      }
    }

    // .btn-primary {
    //   background-color: #68cdff;
    //   border-color    : #68cdff;
    // }

    .contentPay {
      .buttonPay {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modalPay {
    .textBlue {
      font-size  : 20px;
      font-weight: 700;
    }

    .center {
      flex-direction: column;

      .col-6 {
        max-width: 100% !important;
      }

      .payService {
        background-color: white !important;
        padding         : 0px !important;

        h3 {
          width      : 100%;
          font-weight: 100 !important;
          font-size  : 16px;
          text-align : left !important;
        }

        h1 {
          width           : 100%;
          background-color: #68cdff;
          text-align      : center;
        }
      }

      .sub {
        font-weight: 700;
      }

      .textPay {
        font-size  : 20px;
        font-weight: 700;
      }
    }

    .contentPay {
      padding: 10px 0px !important;
      margin : 20px;

      .top {
        justify-content: center;
      }
    }
  }

  .paymentConfirmed {
    &__content {
      flex-direction: column-reverse;
      align-items   : center;
      margin-bottom : 25px;
    }

    &__buttons {
      flex-direction: column;
      align-items   : center;

      button {
        width : 205px;
        margin: 10px 0px !important;
      }
    }
  }

  .botonabajo2 {
    margin: 10px;
    width : 50%;
  }

  .img2 {
    fill: red;
  }
}



// credit-card
input,
.StripeElement {
  height            : 50px;
  padding           : 16px 12px;
  color             : #32325d;
  background-color  : white;
  border            : 1px solid lightgray;
  border-radius     : 2px;
  font-size         : 1.1875rem;
  font-family       : 'Oswald' !important;
  box-shadow        : 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition        : box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}


.__PrivateStripeElement-input {

  font-size     : 18px;
  text-transform: uppercase;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}