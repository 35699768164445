.tour-content-poi-form {
  &__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(white, 0.5);
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: $topcolor-black;
      stroke: $topcolor-black;
    }
  }
  &__title {
    font-size: rem(20px);
    font-family: 'Roboto-bold';
  }
  &__main-image {
    height: auto;
    width: 100%;
    // object-fit: cover;
    
    &-wrapper {
      display: inline-block;
      // height: rem(100px);
      width: rem(200px);
      margin-bottom: 0;
      position: relative;
    }
  }
  &__text,
  &__text-input {
    font-size: rem(14px);
    line-height: rem(15px);
    margin: rem(10px) 0 rem(15px) 0;
  }
  &__author {
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 210px);
    font-size: rem(13px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 0 rem(10px);
    &-name {
      display: block;
      font-family: 'Oswald';
      text-transform: uppercase;
      margin-top: 0;
      svg {
        margin-left: rem(5px);
        display: inline-block;
        vertical-align: bottom;
        width: rem(15px);
        height: auto;
      }
    }
  }
  &__separator {
    &:after {
      content: '';
      display: block;
      margin: rem(15px) auto;
      width: 90%;
      height: rem(1px);
      border-bottom: rem(1px) dotted $topcolor-dark-grey;
    }
  }
  &__list {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    margin: 0 rem(-10px);
    &-item {
      list-style: none;
      z-index: 1010;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      margin: rem(3px);
      position: relative;
      padding-top: rem(25px);
    }
    &-btn {
      padding: 0;
      background-color: transparent;
      border: rem(1px) dashed $topcolor-dark-grey;
      position: relative;
      width: calc(33vw - 10px);
      height: calc(33vw - 10px);
    }
    &-img {
      padding: rem(5px) rem(5px) 0 rem(5px);
      width: 100%;
      height: 100%;
      object-fit: cover;
      &--icon {
        width: 70%;
        height: auto;
      }
    }
    &-remove,
    &-show {
      position: absolute;
      top: 0;
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        width: rem(25px);
        height: rem(25px);
      }
    }
    &-show {
      left: rem(3px);
    }
    &-remove {
      right: rem(3px);
      svg {
        fill: $topcolor-red;
      }
    }
    &-add {
      & + input {
        cursor: pointer;
      }
    }
  }
  &__contents {
    &-title {
      font-family: 'Roboto-bold';
      font-weight: bold;
      line-height: 1.2;
      font-size: rem(18px);
      padding-right: rem(50px);
      position: relative;
    }
    &-subtitle {
      
      display: block;
      font-style: rem(16px);
    }
  }
  &__add-btn {
    & .fine-uploader__file-input {
      position: unset !important;
    }
    border-radius: 50%;
    width: rem(45px);
    height: rem(45px);
    padding: 0;
    border: none;
    background-color: $topcolor-blue;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: white;
      width: rem(25px);
      height: rem(25px);
    }
  }
  &__images-amount {
    position: absolute;
    bottom: 0;
    right: rem(5px);
    width: rem(30px);
    height: rem(30px);
    line-height: rem(30px);
    background-color: $topcolor-blue;
    border-radius: 50%;
    color: white;
    font-size: rem(16px);
    font-family: 'Roboto-bold';
    font-weight: bold;
    z-index: 1;
  }
  .fine-uploader__files-wrapper {
    padding: 0;
    .tour-content-poi-form__list-add {
      margin-bottom: 0;
    }
    .circular-bar {
      width: 100%;
      margin: 0 auto;
      background-color: white;
      svg {
        width: rem(50px);
        height: rem(50px);
      }
    }
  }
  .thumbnails-wrapper__box {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .tour-content-poi-form {
    &__list {
      &-btn {
        width: calc(25vw - 10px);
        height: calc(25vw - 10px);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .tour-content-poi-form {
    .btn {
      border-radius: rem(5px);
      font-size: rem(16px);
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-poi-form {
    &__main-image {
      &-wrapper {
        display: block;
        width: 100%;
      }
    }
    &__text {
      line-height: rem(15px);
      background-color: white;
      padding: 0 rem(10px);
    }
    &__author {
      display: block;
      max-width: unset;
      font-size: rem(16px);
      text-align: right;
      margin-top: rem(3px);
      &-name {
        margin-top: rem(3px);
      }
    }
    &__text-wrapper {
      // border: rem(2px) solid $topcolor-blue;
      padding: 0 rem(10px);
      max-width: calc(100% - 30px);
      margin: rem(5px) auto rem(50px) auto;
      &--no-border {
        border: none;
      }
      .btn {
        margin-bottom: rem(-20px);
      }
    }
    &__separator {
      &:after {
        display: none;
      }
    }

    &__list {
      &-btn {
        width: rem(140px);
        height: rem(140px);
        cursor: grab !important;
      }
      &-item {
        &:last-of-type {
          border: none;
        }
      }
      &-add {
        width: rem(140px);
        height: rem(140px);
        background-color: $topcolor-blue;
        border: none;
        margin-bottom: rem(20px);
        svg {
          width: 40%;
          height: 40%;
          fill: white;
        }
      }
    }
    &__contents {
      &-title {
        font-weight: bold;
        padding-right: 0;
      }
    }

    .circular-bar {
      width: rem(140px);
      height: rem(140px);
    }
  }
}
