.commento-root {
  width: 90% !important;
}
.commento-root .commento-submit-button {
  background: #00affe!important;
}
.commento-root .commento-sort-policy-buttons-container .commento-sort-policy-buttons .commento-sort-policy-button-selected {
  color: #00affe!important;
}
.commento-root .commento-footer .commento-logo-container .commento-logo {
  display: none !important;
}
.custom-radio, .custom-checkbox {
  position: relative;
  display: block;
  padding-left: 3rem;
  line-height: 2.5rem;
  cursor: pointer;
  font-size: 1.3rem;

  svg.svg-icon:first-child {
    margin-right: 1rem;
    vertical-align: sub;
  }

  &.right {
    padding-right: 3rem;
    padding-left: 0;
    i {
      right: 0;
      left: auto;
    }
  }
}
.radio:last-child, .custom-checkbox:last-child {
  margin-bottom: 0;
}
.custom-radio input, .custom-checkbox input {
  position: absolute;
  left: -9999px;
}
.custom-radio i, .custom-checkbox i {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 2rem;
  height: 2rem;
  outline: none;
  background-color: white;
  border-radius: 0.3rem;
  border: rem(1px) solid $topcolor-grey;
}
.custom-radio i {
  border-radius: 50%;
}
.custom-radio input + i:after, .custom-checkbox input + i:after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s;
}
.custom-radio input + i:after {
  content: '\2022';
  font: normal 26px/17px Arial;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 0.9rem;
}
.custom-checkbox input + i:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300A34E' viewBox='0 0 14 14'%3E%3Cpath d='M13.055 4.422q0 .312-.219.531l-6.719 6.719q-.219.219-.531.219t-.531-.219L1.164 7.781Q.945 7.562.945 7.25t.219-.531l1.062-1.062q.219-.219.531-.219t.531.219l2.297 2.305 5.125-5.133q.219-.219.531-.219t.531.219l1.062 1.062q.219.219.219.531z' /%3E%3C/svg%3E");
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
}

.custom-radio input:checked + i:after, .custom-checkbox input:checked + i:after {
  opacity: 1;
}

.custom-checkbox input:checked + i{
  background-color: white;
  color: white;

  &:after{
    font-size: 1.4rem;
  }
}

.input-range {
  &__track {
    background-color: rgba($topcolor-grey, 0.5);
    border-radius: rem(12px);
    height: rem(30px);
    position: relative;
    &--background {
      left: 0;
      right: 0;
    }
    &--active {
      background-color: $topcolor-blue;
    }
  }
  &__slider {
    background-color: white;
    border: rem(1px) solid $topcolor-grey;
    border-radius: 50%;
    height: rem(30px);
    width: rem(30px);
    box-shadow: black 0px 0px 3px;
    margin-left: rem(-15px);
    &-container {
      top: 0;
      bottom: 0;
      transition: left 0.3 ease-out;
    }
  }
  &__label {
    display: none;
    &--value {

    }
    &-container {

    }
  }
  &__active-track {

  }
  &--disabled {
      
  }
  &__input-range {
    width: 100%;
    height: rem(30px);
    position: relative;
    
  }
}
