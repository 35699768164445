.virtual-shop-box {
  background-color: white;
  padding: rem(15px) rem(15px) rem(15px) rem(15px);
  position: relative;
  margin-top: rem(45px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__category {
    font-size: rem(14px);
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f;
  }

  &__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(30px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(5px) 0 0 0;
      text-align: center;
      svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__title {
    display: inline-block;
    font-size: rem(24px);
    color: $topcolor-dark-grey;
    position: relative;
    padding-right: rem(35px);
    font-family: 'Roboto-bold';
    font-weight: 500;
    margin-bottom: rem(5px);
  }
  &__text {
    margin-bottom: rem(15px);
    font-size: rem(19px);
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    line-height: 1.25;
  }
  &__product-name,
  &__price {
    font-family: 'Roboto-bold';
    font-weight: 500;
    font-size: rem(24px);
    line-height: 1.2;
  }
  &__product-name {
    margin-bottom: 0;
  }
  &__price {
    color: $topcolor-red;
  }
  &__carousel-wrapper,
  &__video-wrapper {
    padding: 0;
  }
  &__carousel-wrapper {
    margin-top: rem(10px);
    & > div {
      height: 100%;
    }
    .carousel.carousel-slider {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-bottom: rem(35px);
      .slider,
      .slider-wrapper {
        overflow: visible;
        height: 100%;
      }
      .control-arrow {
        padding: 0;
        width: rem(40px);
        height: rem(40px);
        margin: auto rem(5px);
        border-radius: 50%;
        opacity: 0.3;
        background-color: rgba(white, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.control-prev:before,
        &.control-next:before {
          width: rem(15px);
          height: rem(15px);

          border-top: none;
          border-right: none;
          border-bottom: rem(3px) solid $topcolor-black;
          border-left: rem(3px) solid $topcolor-black;
        }
        &.control-prev:before {
          margin-left: rem(10px);
          transform: rotate(50deg) skewY(-15deg);
        }
        &.control-next:before {
          margin-right: rem(10px);
          transform: rotate(-135deg);
        }
        &:hover {
          opacity: 1;
        }
      }
      .control-dots {
        padding-left: 0;
        .dot {
          box-shadow: none;
          background-color: $topcolor-dark-grey;
          width: rem(6px);
          height: rem(6px);
          margin: 0 rem(2px);
          &.selected {
            background-color: $topcolor-black;
          }
        }
      }
      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
      }
    }
  }
  &__image {
    height: 100vw;
    max-height: rem(400px);
    max-width: 100%;
    object-fit: cover;
    &--no-photo {
      display: block;
      height: rem(250px);
      margin: 0 auto;
      padding: rem(20px);
      object-fit: contain;
    }
    &-wrapper {
      height: 100%;
    }
  }
  &__video-wrapper {
    height: 55vw;
  }
  &__buy-btn {
    display: block;
    width: rem(150px);
    min-width: unset;
    margin: rem(15px) auto;
  }
  &__aside {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow-y: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: $topcolor-black;
    scrollbar-track-color: $topcolor-blue;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      height: rem(5px);
    }
    &::-webkit-scrollbar-thumb {
      background: $topcolor-blue;
    }
    &-product {
      display: inline-block;
      background-color: transparent;
      padding: 0 0 rem(10px) 0;
      border: none;
      width: rem(120px);
      text-align: left;
      cursor: pointer;
      & + .virtual-shop-box__aside-product {
        margin-left: rem(5px);
      }
      &:first-of-type {
        margin-left: rem(10px);
      }
      &:last-of-type {
        margin-right: rem(10px);
      }
    }
    &-image {
      border: rem(1px) solid $topcolor-grey;
      width: rem(120px);
      height: rem(120px);
      object-fit: cover;
      &--no-photo {
        padding: rem(20px);
      }
    }
    &-name,
    &-price {
      padding: rem(0 7px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-name {
      font-family: 'Roboto-bold';
      margin: rem(10px) 0 rem(3px) 0;
      font-size: rem(14px);
    }
    &-price {
      font-size: rem(18px);
    }
  }
}

@media screen and (min-width: 768px) {
  .virtual-shop-box {
    &__buy-btn {
      margin-top: 0;
    }
    &__carousel-wrapper {
      height: rem(500px);
      padding: 0 rem(5px) 0 rem(15px);
      margin-top: 0;
    }
    &__image {
      height: 100%;
      max-height: none;
    }
    &__video-wrapper {
      padding: 0 rem(15px) rem(35px) rem(5px);
      height: initial;
    }
  }
}

@media screen and (min-width: 992px) {
  .virtual-shop-box {
    margin-top: rem(60px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(25px) rem(20px) rem(35px);
    min-height: rem(600px);
    height: auto;

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;
      &-icon {
        display: inline-block;
        width: rem(75px);
        height: rem(40px);
        background-color: white;
        border-top-left-radius: rem(5px);
        border-top-right-radius: rem(5px);
        margin-right: rem(5px);
        padding: rem(10px) 0 rem(3px) 0;
        text-align: center;
        svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline;
        }
      }
    }
    &__product-data {
      &:before {
        content: '';
        height: 100%;
        width: rem(1px);
        border-left: rem(2px) dashed $topcolor-grey;
        position: absolute;
        left: rem(24px);
        top: 0;
        bottom: 0;
      }
    }
    &__title {
      font-size: rem(24px);
    }
    .marginTopTitle {
      margin-top: rem(37px);
    }
    &__text {
      padding: rem(5px) 0;
      max-height: rem(170px);
      margin-bottom: rem(30px);
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: $topcolor-black;
      scrollbar-track-color: $topcolor-blue;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        background-color: $topcolor-black;
        width: rem(5px);
      }
      &::-webkit-scrollbar-thumb {
        background: $topcolor-blue;
      }
    }
    &__carousel-wrapper {
      height: 100%;
      padding-left: 0;
      & > div {
        height: 100%;
      }
      .carousel .control-dots {
        margin-bottom: 0;
      }
    }
    &__video-wrapper {
      height: rem(160px);
      padding: 0 rem(15px);
    }
    &__author {
      &-avatar,
      &-toptraveler {
        width: rem(100px);
      }
      &-avatar {
        height: rem(100px);
        border-radius: 50%;
        object-fit: cover;
        margin-left: rem(-60px);
      }
      &-toptraveler {
        display: block;
        height: auto;
        margin: 0;
        position: absolute;
        top: 0;
        left: rem(60px);
      }
    }
    &__product-name,
    &__price {
      display: block;
      font-size: rem(26px);
    }
    &__product-name {
      margin-top: rem(10px);
    }
    &__aside {
      padding-right: rem(5px);
      max-height: rem(560px);
      &::-webkit-scrollbar {
        background-color: $topcolor-black;
        width: rem(5px);
      }
      &-product {
        display: block;
        padding-bottom: 0;
        & + .virtual-shop-box__aside-product {
          margin-left: 0;
          margin-top: rem(5px);
        }
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
