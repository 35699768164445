.poi-file {
  background-color: white;
  position: relative;
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  .buttonLike, .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    img {
      width: 90%;
    }
  }
  
  .poi-file__routes {
    p {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 400;
      font-size: 20px;
      box-sizing: border-box;
    }
    span {
      font-family: 'Roboto' !important;
      font-weight: 400;
      font-size: 20px;
      color: rgb(0, 175, 254);;
    }
    .routesWhite {
      background: transparent;
      width: 50px;
      height: 50px;
      border-width: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
    .externalBlack {
      background: transparent;
      width: 25px;
      height: 25px;
      border-width: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
  }

  .likePoi {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    border-left: rem(1px) dashed $topcolor-dark-grey;
    border-right: rem(1px) dashed $topcolor-dark-grey;
    @media (max-width: 600px) {
      padding: 0 8px;
      margin: 0 8px 0 10px !important;
    }
  }
  &__wrapper {
    margin: auto;
    max-width: rem(1366px);
    padding: rem(15px) 0;
  }
  &__cover {
    position: relative;
    margin-bottom: 0;
    background-color: $topcolor-black;
    &-image {
      width: 100%;
      height: auto;
      max-height: calc(100vh - 50px);
      max-height: calc(var(--vh, 1vh) * 100 - 50px);
      object-fit: contain;
    }
    &-author {
      display: inline-block;
      max-width: 78%;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: rem(5px) rem(10px) rem(5px) rem(30px);
      > svg {
        height: rem(44px);
        margin: rem(-12px) rem(-6px) rem(-13px) 0;
        stroke-width: rem(10px);
        #heart-path {
          fill: none;
          stroke: $topcolor-black;
          stroke-width: rem(26px);
        }
        circle {
          display: none;
        }
      }
      &-avatar {
        position: absolute;
        bottom: rem(-10px);
        left: rem(10px);
        margin-left: rem(-40px);
        margin-bottom: 0;
        height: rem(50px);
        width: rem(50px);
        border: rem(2px) solid white;
        background-color: white;
        border-radius: 50%;
        object-fit: cover;
      }
      &-name {
        font-family: 'Oswald';
        text-transform: uppercase;
        font-size: rem(15px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
        display: inline-block;
      }
    }
  }
  &__title,
  &__address {
    color: $topcolor-black;
  }
  &__title {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(20px);
    margin-bottom: 0;
  }
  &__address {
    font-family: 'Oswald';
    text-transform: uppercase;
    font-size: rem(16px);
    margin-bottom: rem(15px);
  }
  &__info {
    list-style: none;
    padding-left: 0;
    margin: 0;
    &-item {
      display: inline-block;
      vertical-align: middle;
      color: $topcolor-black;
      font-size: rem(16px);
      line-height: 1;
      svg {
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        height: rem(16px);
        width: auto;
        max-width: rem(20px);
        fill: $topcolor-dark-grey;
        margin-right: rem(3px);
      }
      & + .poi-file__info-item {
        margin-left: rem(15px);
      }
    }
  }
  &__description {
    margin: rem(15px) 0;
    font-family:'Roboto', sans-serif !important ;
      font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;
  }
  &__tags {
    padding-left: 0;
    list-style: none;
    margin-top: rem(10px);
    &-item {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 1.2;
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: rem(15px);
      color: $topcolor-black;
      margin-right: rem(10px);
      &:before {
        content: '#';
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  &__data-title,
  &__data-text,
  &__data-link {
    font-family: 'Roboto-bold';
    font-weight: 500;
    line-height: 1.2;
  }
  &__data-title {
    color: $topcolor-black;
    font-size: rem(15px);
    margin-bottom: 0;
  }
  &__data-text,
  &__data-link {
    display: inline-block;
    color: $topcolor-dark-grey;
    font-size: rem(14px);
    margin-bottom: rem(15px);
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__data-link {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__rrss {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 600px) {
      justify-content: center;
    }
    // padding: 0 0 rem(25px) 0;
    // border-bottom: rem(1px) dotted $topcolor-dark-grey;
    // margin-bottom: rem(25px);
    p {
      font-family: 'Oswald';
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px;
    }
    &-item {
      display: inline-block;
      
    }
    &-link {
      display: inline-block;
      cursor: pointer;
      & + .poi-file__rrss-link {
        margin-left: rem(10px);
      }
      &:hover {
        circle {
          fill: $topcolor-blue;
        }
      }
    }
  }
  &__author {
    display: flex;
    // HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
    // border-left: rem(1px) solid $topcolor-dark-grey;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    &-avatar {
      display: inline-block;
      margin: 0 rem(50px) rem(5px) 0;
      width: 100%;
      height: 100%;
      border: rem(5px) solid white;
      box-shadow: rem(1px) rem(1px) rem(3px) rgba($topcolor-black, 0.5);
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      &-wrapper {
        width: rem(100px);
        height: rem(100px);
      }
    }
    &-name {
      font-family: 'Oswald';
      text-transform: uppercase;
      font-size: rem(18px);
      margin: rem(10px) 0 0 0;
    }
  }
  &__flag-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    right: rem(-50px);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    svg {
      fill: $topcolor-dark-grey;
      width: rem(40px);
      height: rem(40px);
    }
  }
  .tour-content-images-box {
    padding: 0;
    &__img-author {
      display: none;
    }
  }
  &__action-btn {
    margin: auto;
    max-width: rem(240px);
  }
}

@media screen and (min-width: 992px) {
  .poi-file {
    box-shadow: none;
    padding-bottom: 0;
    &__wrapper {
      padding: rem(30px) 0;
    }
    &__cover {
      &-image {
        max-height: 135vh;
        max-height: calc(var(--vh, 1vh) * 135);
      }
      &-author {
        top: rem(50px);
        bottom: unset;
        padding: rem(10px) rem(10px) rem(10px) rem(35px);
        > svg {
          height: rem(60px);
          margin: rem(-6px);
        }
        &-avatar {
          top: 0;
          bottom: 0;
          left: rem(5px);
          height: rem(67px);
          width: rem(67px);
          border: rem(6px) solid white;
        }
        &-name {
          max-width: rem(150px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: rem(30px);
          vertical-align: middle;
        }
      }
      &-type {
        display: block;
        text-align: right;
        font-size: rem(16px);
        text-transform: none;
        line-height: 1.1;
        font-weight: 100;
      }
    }
    &__title {
      font-size: rem(28px);
      font-weight: 500;
    }
    &__data-link {
      max-width: rem(600px);
    }
    &__tags {
      margin-top: rem(10px);
      &-item {
        font-size: rem(20px);
      }
    }
    &__info {
      padding-bottom: rem(3px);
      &-item {
        & + .poi-file__info-item {
          margin-left: rem(20px);
        }
      }
    }
    &__rrss {
      margin-bottom: 0;
      // border-bottom: none;
    }
    &__map {
      padding: 0;
      margin: rem(-30px) 0;
      height: calc(100% + 60px);
      .mapWrapper { // this is required
        height: 100%;
      }
    }
    &__footer-title,
    &__author-name {
      display: inline-block;
      width: 50%;
      vertical-align: middle;
      color: white;
      font-size: rem(18px);
      margin: 0;
    }
    &__footer {
      background-color: $topcolor-black;
      padding-top: rem(5px);
      padding-bottom: rem(5px);
      &-title {
        font-family: 'Oswald';
        text-transform: uppercase;
        text-align: right;
        padding-right: rem(60px);
      }
    }
    &__author-name {
      padding-left: rem(60px);
      svg {
        fill: white;
        height: rem(25px);
        width: auto;
        margin-left: rem(3px);
      }
    }
    &__author-avatar {
      box-shadow: none;
      margin: 0;
      background-color: white;
      border: none;
      &-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .tour-content-images-box {
    margin-top: rem(20px);
  }
}

@media screen and (min-width: 1200px) {
}
