.recommendations-tabs-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  &__btn {
    background: transparent;
    border: none;
    position: relative;
    flex-grow: 1;
    height: rem(80px);
    + .recommendations-tabs-bar__btn {
      &::before {
        content: '';
        display: inline-block;
        width: rem(1px);
        height: rem(50px);
        border-right: rem(1px) $topcolor-black dashed;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    svg {
      fill: black;
      width: rem(50px);
      height: rem(50px);
    }
    &--active {
      svg {
        fill: $topcolor-blue;
      }
    }
    &--disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

@media only screen and (min-width: 992px) {
  .recommendations-tabs-bar {
    justify-content: center;
    &__btn {
      max-width: rem(100px);
      height: rem(100px);
      + .recommendations-tabs-bar__btn {
        &::before {
          height: rem(65px);
        }
      }
    }
  }
}
