.map-box {
  background-color: white;
  padding: rem(5px) rem(15px);
  position: relative;
  margin-top: rem(45px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__header {
    align-items: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(3px) 0 0 0;
      text-align: center;
      svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__category {
    font-size: rem(14px);
    font-family: 'Oswald';
    text-transform: uppercase;
    color: $topcolor-dark-grey;
  }
}

@media screen and (min-width: 992px) {
  .map-box {
    margin-top: rem(60px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(35px);

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;
      &-icon {
        display: inline-block;
        width: rem(75px);
        height: rem(45px);
        background-color: white;
        border-top-left-radius: rem(5px);
        border-top-right-radius: rem(5px);
        margin-right: rem(5px);
        text-align: center;
        svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline;
        }
      }
    }
  }
}
