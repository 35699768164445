.partner-box {
  background-color: white;
  padding: rem(5px) rem(15px) rem(15px) rem(15px);
  position: relative;
  margin-top: rem(60px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  height: auto;
  // max-height: rem(500px);
  &__header {
    align-items: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(3px) 0 0 0;
      text-align: center;
      svg {
        height: 75%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__image {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    // &--default {
    //   width: 100%;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   margin: auto;
    // }
  }
  &__category {
    font-size: rem(14px);
    font-family: 'Oswald';
    text-transform: uppercase;
    color: $topcolor-dark-grey;
  }
  
}
.tour-sponsor-box{
  .botonWhite{
    color: white !important;
  }
}

@media screen and (min-width: 992px) {
  .partner-box {
    margin-top: rem(75px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(35px);
    // height: 60vw;
    // max-height: rem(500px);

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;
      &-icon {
        display: inline-block;
        width: rem(75px);
        height: rem(45px);
        margin-right: rem(5px);
        padding-top: rem(10px);
      }
    }
    &__image{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.ModalVideo {
  z-index: 2000;
  /* padding-top: 10px; */
    position: relative;
    border-bottom: none;
    overflow: auto;
    width: 1045px !important;
    &::-webkit-scrollbar {
      background-color: #2c2c2c;
    width: 0.3125rem;
    
  }

  &::-webkit-scrollbar-thumb {
    background: #00affe;
  }
  .imagePartner{
    width: 100%;
  }
  .buttonClose{
    position: absolute;
    right: 5px;
    top: 5px; 
  }
  .alturaImagen{
    height: inherit;
    padding-top: 36px !important;
    margin: 0px !important;
  }
    .twoH{
      .buttonActive{
      border-bottom: 5px solid rgb(171, 218, 253);
      padding-bottom: 32px;
      }
    }
  .threeH{
    width: auto;

    /* position: absolute;
    top: 2px;
    right: 8px; */
  }
  .info{
    margin-bottom: 50px;
    width: 90%;
  }
  .info-modal {
    margin: 10px;
    h4 {
      margin-right: 5px;
      margin-bottom: 0px;
    }

    p {
      margin-bottom: 0px;

    }

  }
  background-color: transparent;
  outline: none;

  .actionIcon {
    background: transparent;
    width: 35px;
    height: 35px;
    border-width: 0;
    padding: 0;
    margin: 0px 10px 0px 0px;
    text-align: center;
    cursor: pointer;

    img {
      width: 80%;
    }
  }


  .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;

    img {
      width: 80%;
    }
  }

  .closeIcon {
    background-color: transparent;
    display: inline-block;
    border: none;
    vertical-align: middle;
    line-height: 1;

    svg {
      height: rem(28px);
      width: rem(28px);
      fill: white;
    }
  }

  .head-map-modal {
    width: 1045px !important;
    /* margin-left: 15px !important;
    margin-right: 15px !important; */

  }

  .modal-mapa-imagenes {
    height: 70%;

    /* .pdg0 {
      padding-left: 0px;
      padding-right: 35px;
    } */

/*     img {
      width: inherit;
      padding-left: 0px;
      margin-right: 11px;

    } */
  }
  .MapaImage{
    width: auto !important;
    height: auto !important;
    flex-grow: 1;
    padding: 10px;
/*     width: 70%; */
    height: 100%;
    .simple-map{
      height: 100% !important;
    }
  }
  .contentOneTwo{
    display: flex;
    justify-content: space-between;
    width: auto;
    flex-grow: 1;
    .oneH{
      -webkit-flex-grow: 1;
    }
  }
  .mapRight{
    width: 280px;
    height: 690px;
    max-width: 280px;
    flex-basis: unset;
    padding-top: 10px !important;
    padding-left: 5px !important;
    padding-bottom: 10px;
    padding-right: 5px !important;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
/*     width: 30%;
    height: 100%; */
    div{
      text-align: center;
    }
    p{
      /* height: 30%; */
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: start;
      font-size: 15px;
    }
    .partnerImage{
      width: 250px;
      height: 180px;
    }
    .collage{
      width: 250px;
    object-fit: cover;
    height: 400px;
    }
    &__top{
      width: 250px;
    }
    &__bot{
      margin-bottom: 10px;
      width: 250px;
      /* height: 100%; */
    }
  }
}