footer.main-footer {
    background-color: $topcolor-black;
    color           : white;
    font-family     : 'Roboto', sans-serif !important;
    font-weight     : 100;
    font-size       : .9rem;
    margin-top      : 200px;
    position        : relative;

    a {
        color: $topcolor-grey;
    }

    svg {
        min-height: 380px;
        position  : absolute;
        /* top    : -180px; */
        left      : 0;
        bottom    : 0px;
        width     : 100%;
    }

    img {
        display: none;
    }

    .row {
        padding-bottom: 50px
    }
}

.font-foot {
    font-family: 'Oswald';
}

.site-map {
    font-size: 1rem;

    a {
        font-family: "Roboto-Regular";

    }

    h4 {
        font-size     : 1rem;
        font-family   : 'Oswald';
        text-transform: uppercase;
    }

    ul {
        list-style-type: none;
        padding-left   : 0;

        li {
            padding-left: rem(20px);
        }
    }
}

@media screen and (min-width: 768px) {
    footer.main-footer img {
        display: inline-block;
        width  : 65%;
    }

    .site-map {
        border-left    : 1px dashed white;
        display        : flex;
        flex-wrap      : wrap;
        justify-content: space-between;

        ul {
            padding-left: 2rem;
        }
    }
}