.tour-content-edition-modal {
  width: 100%;
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: rem(50px);
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010;
  }

  &__header {
    background-color: $topcolor-black;
    margin-bottom: rem(20px);
    height: rem(50px);
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    &-actions {
      position: absolute;
      right: rem(20px);
      top: rem(5px);
      bottom: 0;
      margin: auto 0;
      width: auto;
      padding: 0;
    }
    &-btn {
      padding: 0;
      border: none;
      border-radius: 50%;
      height: rem(40px);
      width: rem(40px);
      line-height: 1;
      background-color: $topcolor-black;
      border: rem(2px) solid $topcolor-blue;
      vertical-align: middle;
      svg {
        height: 60%;
        width: 60%;
        fill: white;
      }
      &--blue {
        background-color: $topcolor-blue;
      }
      &--highlighted {
        fill: greenyellow;
      }
      & + .tour-content-edition-modal__header-btn {
        margin-left: rem(10px);
      }
      & .promise-button__bounce {
        width: rem(10px);
        height: rem(10px);
      }
    }
  }
  &__form-wrapper {
    height: 100%;
    padding: rem(15px) 0 rem(10px) 0;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $topcolor-black;
    scrollbar-track-color: $topcolor-blue;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      width: rem(5px);
    }
    &::-webkit-scrollbar-thumb {
      background: $topcolor-blue;
    }
  }
}

@media screen and (max-width: 374px) {
  .tour-content-edition-modal {
    &__header {
      text-align: left;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-edition-modal {
    width: rem(900px);
    margin: 6vh auto 0 auto;
    margin-top: calc(var(--vh, 1vh) * 6);
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    padding-top: rem(70px);
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    &__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($topcolor-black, 0.9);
      z-index: 1010;
    }

    &__header {
      border-bottom: rem(1px) solid $topcolor-black;
      background-color: transparent;
      text-align: right;
      margin: 0 rem(15px);
      padding: rem(15px) 0;
      height: auto;
      position: absolute;
      &-title {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(22px);
        margin: 0 rem(10px) 0 0;
      }
      &-actions {
        position: unset;
      }
      &-btn {
        border: none;
        & + .tour-content-edition-modal__header-btn {
          margin-left: rem(5px);
        }
      }
    }
    &__form-wrapper {
      max-height: 100%;
      padding: rem(15px) rem(30px) rem(10px) rem(30px);
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-edition-modal {
    width: rem(1000px);
  }
}
