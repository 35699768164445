.tour-config-shop {
  &__list {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    margin: 0 rem(-10px);
    &-item {
      list-style: none;
      z-index: 1010;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      margin: rem(3px);
      position: relative;
      padding-top: rem(25px);
    }
    &-btn {
      padding: 0;
      background-color: transparent;
      border: rem(1px) solid $topcolor-dark-grey;
      position: relative;
      width: calc(33vw - 10px);
      height: calc(33vw - 10px);
    }
    &-add {
      width: calc(33vw - 10px);
      height: calc(33vw - 10px);
      background-color: $topcolor-blue;
      border: none;
      margin-bottom: rem(20px);
      svg {
        width: 40%;
        height: 40%;
        fill: white;
      }
    }
    &-img {
      padding: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &--icon {
        width: 70%;
        height: auto;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    &-remove,
    &-show {
      position: absolute;
      top: 0;
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        width: rem(25px);
        height: rem(25px);
      }
    }
    &-show {
      left: rem(3px);
    }
    &-remove {
      right: rem(3px);
      svg {
        fill: $topcolor-red;
      }
    }
  }
  &__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: rgba(255, 255, 255, 0.5);    
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: $topcolor-black;
      stroke: $topcolor-black;
    }
  }
}

@media screen and (min-width: 576px) {
  .tour-config-shop {
    &__list {
      &-btn {
        width: calc(25vw - 10px);
        height: calc(25vw - 10px);
      }
      &-add {
        width: calc(25vw - 10px);
        height: calc(25vw - 10px);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .tour-config-shop {
    &__list {
      &-btn {
        width: calc(20vw - 10px);
        height: calc(20vw - 10px);
      }
      &-add {
        width: calc(20vw - 10px);
        height: calc(20vw - 10px);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-config-shop {
    &__list {
      padding: 0 rem(20px);
      &-btn {
        width: rem(140px);
        height: rem(140px);
      }
      &-item {
        &:last-of-type {
          border: none;
        }
      }
      &-add {
        width: rem(140px);
        height: rem(140px);
      }
    }
  }
}
