@charset "UTF-8";
@import url(~react-responsive-carousel/lib/styles/carousel.min.css);
@import url(~react-datepicker/dist/react-datepicker.css);
@import url(~react-image-lightbox/style.css);
body, html {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100; }

.blue-bg {
  background-color: #81d4fb; }

@font-face {
  font-family: 'Roboto-bold';
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-bold';
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Oswald';
  src: url("/assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto', sans-serif !important;
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Italic' !important;
  src: url("/assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Roboto', sans-serif !important;
  src: url("/assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url("/assets/fonts/Roboto-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype"); }

/** COLORS **/
/** FORMS **/
/** Buttons **/
/** NAVBAR **/
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #00affe;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d34c50;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00affe;
  --secondary: #163d7b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #d34c50;
  --light: #f8f9fa;
  --dark: #303030;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Oswald";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00affe;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #007ab2;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 960px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 960px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e9ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ad5fe; }

.table-hover .table-primary:hover {
  background-color: #9fe1ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fe1ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #bec9da; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #869aba; }

.table-hover .table-secondary:hover {
  background-color: #aebcd1; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #aebcd1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3cdce; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e8a2a4; }

.table-hover .table-danger:hover {
  background-color: #eeb8ba; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eeb8ba; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c5c5; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #939393; }

.table-hover .table-dark:hover {
  background-color: #b8b8b8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b8b8b8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(129, 212, 251, 0.3);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: rgba(129, 212, 251, 0.3);
    border-color: #7fd7ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(129, 212, 251, 0.3); }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") rgba(129, 212, 251, 0.3) no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d34c50; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(211, 76, 80, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d34c50;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d34c50' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d34c50' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d34c50;
    box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d34c50;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d34c50' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d34c50' stroke='none'/%3e%3c/svg%3e") rgba(129, 212, 251, 0.3) no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d34c50;
    box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d34c50; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d34c50; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d34c50; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #dd7578;
  background-color: #dd7578; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d34c50; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d34c50; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d34c50;
  box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00affe;
  border-color: #00affe; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0095d8;
    border-color: #008ccb; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0095d8;
    border-color: #008ccb;
    box-shadow: 0 0 0 0.2rem rgba(38, 187, 254, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00affe;
    border-color: #00affe; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #008ccb;
    border-color: #0083be; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 187, 254, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #163d7b;
  border-color: #163d7b; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #102d5b;
    border-color: #0e2850; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #102d5b;
    border-color: #0e2850;
    box-shadow: 0 0 0 0.2rem rgba(57, 90, 143, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #163d7b;
    border-color: #163d7b; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0e2850;
    border-color: #0c2245; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 90, 143, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d34c50;
  border-color: #d34c50; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c83136;
    border-color: #bd2f33; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c83136;
    border-color: #bd2f33;
    box-shadow: 0 0 0 0.2rem rgba(218, 103, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d34c50;
    border-color: #d34c50; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2f33;
    border-color: #b32c30; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 103, 106, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #303030;
  border-color: #303030; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #171717; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #171717;
    box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #303030;
    border-color: #303030; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #171717;
    border-color: #101010; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5); }

.btn-outline-primary {
  color: #00affe;
  border-color: #00affe; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00affe;
    border-color: #00affe; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00affe;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00affe;
    border-color: #00affe; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.5); }

.btn-outline-secondary {
  color: #163d7b;
  border-color: #163d7b; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #163d7b;
    border-color: #163d7b; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 61, 123, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #163d7b;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #163d7b;
    border-color: #163d7b; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 61, 123, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #d34c50;
  border-color: #d34c50; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d34c50;
    border-color: #d34c50; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d34c50;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d34c50;
    border-color: #d34c50; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #303030;
  border-color: #303030; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #303030;
    border-color: #303030; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #303030;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #303030;
    border-color: #303030; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00affe;
  text-decoration: none; }
  .btn-link:hover {
    color: #007ab2;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00affe; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00affe;
    background-color: #00affe; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #7fd7ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b2e7ff;
    border-color: #b2e7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: rgba(129, 212, 251, 0.3);
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00affe;
  background-color: #00affe; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 175, 254, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 175, 254, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 175, 254, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: rgba(129, 212, 251, 0.3);
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 175, 254, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: rgba(129, 212, 251, 0.3) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #7fd7ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: rgba(129, 212, 251, 0.3); }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7fd7ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(129, 212, 251, 0.3);
  border: 1px solid #ced4da;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #00affe;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b2e7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #00affe;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b2e7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #00affe;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b2e7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00affe; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.625rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.625rem;
        padding-left: 1.625rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.625rem;
        padding-left: 1.625rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.625rem;
        padding-left: 1.625rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.625rem;
        padding-left: 1.625rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.625rem;
      padding-left: 1.625rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: white; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: white;
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: white; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00affe;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #007ab2;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00affe;
  border-color: #00affe; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00affe; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #008ccb; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #163d7b; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #0e2850; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 61, 123, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d34c50; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2f33; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(211, 76, 80, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #303030; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #171717; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #005b84;
  background-color: #ccefff;
  border-color: #b8e9ff; }
  .alert-primary hr {
    border-top-color: #9fe1ff; }
  .alert-primary .alert-link {
    color: #003851; }

.alert-secondary {
  color: #0b2040;
  background-color: #d0d8e5;
  border-color: #bec9da; }
  .alert-secondary hr {
    border-top-color: #aebcd1; }
  .alert-secondary .alert-link {
    color: #040a14; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #6e282a;
  background-color: #f6dbdc;
  border-color: #f3cdce; }
  .alert-danger hr {
    border-top-color: #eeb8ba; }
  .alert-danger .alert-link {
    color: #491a1c; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #191919;
  background-color: #d6d6d6;
  border-color: #c5c5c5; }
  .alert-dark hr {
    border-top-color: #b8b8b8; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00affe;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #00affe;
    border-color: #00affe; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #005b84;
  background-color: #b8e9ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #005b84;
    background-color: #9fe1ff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #005b84;
    border-color: #005b84; }

.list-group-item-secondary {
  color: #0b2040;
  background-color: #bec9da; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #0b2040;
    background-color: #aebcd1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #0b2040;
    border-color: #0b2040; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #6e282a;
  background-color: #f3cdce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6e282a;
    background-color: #eeb8ba; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6e282a;
    border-color: #6e282a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #191919;
  background-color: #c5c5c5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #191919;
    background-color: #b8b8b8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #191919;
    border-color: #191919; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00affe !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #008ccb !important; }

.bg-secondary {
  background-color: #163d7b !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0e2850 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #d34c50 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2f33 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #303030 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #171717 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00affe !important; }

.border-secondary {
  border-color: #163d7b !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #d34c50 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #303030 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00affe !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #007ab2 !important; }

.text-secondary {
  color: #163d7b !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0a1d3a !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #d34c50 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a92a2d !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #303030 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0a0a0a !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

label.col-form-label {
  padding-bottom: 0.1875rem; }

.form-control {
  border-color: transparent;
  font-family: 'Oswald';
  line-height: 1.8125rem;
  font-size: 1.1875rem;
  color: #2c2c2c; }

.btn {
  font-family: 'Roboto-bold';
  font-weight: 500; }

.alert-primary {
  background-color: #81d4fb;
  color: white;
  font-family: 'Roboto', sans-serif !important; }

@media screen and (min-width: 992px) {
  .btn {
    min-width: 11.25rem; } }

button:focus, button:active {
  outline: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.bounce-in-top {
  animation: bounce-in-top 1.5s both; }

@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0; }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out; }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
    opacity: 1; }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out; }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in; }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out; }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in; }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out; } }

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.3s linear 0.3s both;
  animation: swing-in-top-fwd 0.3s linear 0.3s both; }

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; } }

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; } }

.swing-out-top-bck {
  -webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
  animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both; }

@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; } }

@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; } }

.slit-in-vertical {
  -webkit-animation: slit-in-vertical 0.45s ease-out both;
  animation: slit-in-vertical 0.45s ease-out both; }

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0; }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0); } }

@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0; }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0); } }

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-top 1s cubic-bezier(0.23, 1, 0.32, 1) both; }

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }

@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1; } }

.fade-in {
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.btn-resetted {
  background: none;
  border: none;
  padding: 0; }
  .btn-resetted:hover {
    text-decoration: none; }

.btn-rounded {
  border: 0.0625rem solid;
  border-radius: 3.125rem; }
  .btn-rounded:hover {
    border: 0.0625rem solid; }

.disabled {
  pointer-events: none;
  cursor: not-allowed; }

.tour-content-poi-box .carousel .control-arrow, .tour-content-poi-box .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: white !important; }

.tour-content-poi-box .carousel .control-arrow, .tour-content-poi-box .carousel.carousel-slider .control-arrow {
  opacity: .7 !important;
  background-color: rgba(255, 255, 255, 0.7) !important; }

.modal-preview-content-tour .carousel .control-arrow, .modal-preview-content-tour .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: white !important; }

.modal-preview-content-tour .carousel .control-arrow, .modal-preview-content-tour .carousel.carousel-slider .control-arrow {
  opacity: .7 !important;
  background-color: rgba(255, 255, 255, 0.7) !important; }

.modal-preview-content .carousel .control-arrow, .modal-preview-content .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: white !important; }

.modal-preview-content.carousel .control-arrow, .modal-preview-content .carousel.carousel-slider .control-arrow {
  opacity: .7 !important;
  background-color: rgba(255, 255, 255, 0.7) !important; }

.text-medium {
  font-size: 1.25rem; }

.f-oswald {
  font-family: 'Oswald'; }

.f-quicksand {
  font-family: 'Roboto-bold'; }

.f-opensans {
  font-family: 'Roboto-Regular'; }

.text-standard-small {
  font-family: 'Oswald';
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #6f6f6f; }

.text-standard {
  font-family: 'Oswald';
  font-weight: 100;
  line-height: 1.8125rem;
  font-size: 1.1875rem;
  color: #2c2c2c; }

.customized-text {
  font-family: 'Oswald';
  white-space: pre-line;
  line-height: 1.8125rem;
  font-size: 1.1875rem;
  color: #2c2c2c;
  overflow: visible;
  margin-bottom: 0; }
  .customized-text strong {
    color: #00affe;
    font-weight: 100; }
  .customized-text a {
    text-decoration: underline; }
  .customized-text p {
    margin-bottom: 0; }

.data-tip {
  font-family: 'Roboto-bold';
  max-width: 18.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  text-align: justify; }
  .data-tip + .data-tip {
    margin-top: 0.3125rem; }

.commento-root {
  width: 90% !important; }

.commento-root .commento-submit-button {
  background: #00affe !important; }

.commento-root .commento-sort-policy-buttons-container .commento-sort-policy-buttons .commento-sort-policy-button-selected {
  color: #00affe !important; }

.commento-root .commento-footer .commento-logo-container .commento-logo {
  display: none !important; }

.custom-radio, .custom-checkbox {
  position: relative;
  display: block;
  padding-left: 3rem;
  line-height: 2.5rem;
  cursor: pointer;
  font-size: 1.3rem; }
  .custom-radio svg.svg-icon:first-child, .custom-checkbox svg.svg-icon:first-child {
    margin-right: 1rem;
    vertical-align: sub; }
  .custom-radio.right, .custom-checkbox.right {
    padding-right: 3rem;
    padding-left: 0; }
    .custom-radio.right i, .custom-checkbox.right i {
      right: 0;
      left: auto; }

.radio:last-child, .custom-checkbox:last-child {
  margin-bottom: 0; }

.custom-radio input, .custom-checkbox input {
  position: absolute;
  left: -9999px; }

.custom-radio i, .custom-checkbox i {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 2rem;
  height: 2rem;
  outline: none;
  background-color: white;
  border-radius: 0.3rem;
  border: 0.0625rem solid #dedede; }

.custom-radio i {
  border-radius: 50%; }

.custom-radio input + i:after, .custom-checkbox input + i:after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s; }

.custom-radio input + i:after {
  content: '\2022';
  font: normal 26px/17px Arial;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 0.9rem; }

.custom-checkbox input + i:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300A34E' viewBox='0 0 14 14'%3E%3Cpath d='M13.055 4.422q0 .312-.219.531l-6.719 6.719q-.219.219-.531.219t-.531-.219L1.164 7.781Q.945 7.562.945 7.25t.219-.531l1.062-1.062q.219-.219.531-.219t.531.219l2.297 2.305 5.125-5.133q.219-.219.531-.219t.531.219l1.062 1.062q.219.219.219.531z' /%3E%3C/svg%3E");
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem; }

.custom-radio input:checked + i:after, .custom-checkbox input:checked + i:after {
  opacity: 1; }

.custom-checkbox input:checked + i {
  background-color: white;
  color: white; }
  .custom-checkbox input:checked + i:after {
    font-size: 1.4rem; }

.input-range__track {
  background-color: rgba(222, 222, 222, 0.5);
  border-radius: 0.75rem;
  height: 1.875rem;
  position: relative; }
  .input-range__track--background {
    left: 0;
    right: 0; }
  .input-range__track--active {
    background-color: #00affe; }

.input-range__slider {
  background-color: white;
  border: 0.0625rem solid #dedede;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  box-shadow: black 0px 0px 3px;
  margin-left: -0.9375rem; }
  .input-range__slider-container {
    top: 0;
    bottom: 0;
    transition: left 0.3 ease-out; }

.input-range__label {
  display: none; }

.input-range__input-range {
  width: 100%;
  height: 1.875rem;
  position: relative; }

.circular-img {
  border: 0.3125rem solid white;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.15);
  width: 5.625rem;
  height: 5.625rem;
  object-fit: cover; }

.img-staff-pick:after {
  background: url("/assets/images/icono_staff_pick.svg") no-repeat 20px 0;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%; }

@media screen and (min-width: 992px) {
  .circular-img {
    box-shadow: none; } }

.social-icon circle {
  fill: #303030; }

/** THIRD PARTY COMPONENTS **/
.animated {
  animation-duration: 1s;
  animation-fill-mode: forwards; }
  .animated.bounceIn {
    animation-duration: .7s; }
  .animated.bounceOut {
    animation-duration: .5s; }
  .animated.bounceIn {
    animation-name: bounceIn; }
  .animated.bounceOut {
    animation-name: bounceOut; }
  .animated.fadeIn {
    animation-name: fadeIn;
    animation-duration: .7s; }
  .animated.fadeOut {
    animation-name: fadeOut;
    animation-duration: .3s; }
  .animated.bounceInDown {
    animation-name: bounceInDown; }
  .animated.bounceOutUp {
    animation-name: bounceOutUp; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
    color: #000; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
    .redux-toastr .toastr .close-toastr span {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      -webkit-border-radius: 0 0 0 4px;
      -moz-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

/** GENERAL COMPONENTS **/
.loading-spinner,
.loading-spinner__spinner svg,
.loading-spinner__text,
.loading-spinner__logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.loading-spinner {
  background-color: #2c2c2c; }
  .loading-spinner--inline {
    position: unset;
    background-color: transparent; }
    .loading-spinner--inline .loading-spinner__spinner svg {
      position: unset; }
  .loading-spinner__text {
    z-index: 2;
    font-family: 'Roboto-bold';
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.125rem;
    color: white;
    bottom: 30%;
    top: unset;
    margin: auto;
    text-align: center;
    opacity: 1;
    animation: flickerAnimation 1.5s infinite; }
  .loading-spinner__spinner svg {
    display: block;
    margin: auto;
    width: 50%;
    height: auto;
    z-index: 1;
    max-width: 12.5rem; }
    .loading-spinner__spinner svg circle {
      fill: #00affe; }
    .loading-spinner__spinner svg defs linearGradient stop {
      stop-color: #00affe; }
  .loading-spinner__logo {
    display: block;
    width: 33%;
    max-width: 9.375rem;
    height: auto;
    margin: auto; }

@media screen and (min-width: 992px) {
  .loading-spinner__text {
    bottom: 15%; } }

@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    opacity: 1; }
  50% {
    opacity: 0.1; }
  100% {
    opacity: 1; } }

.promise-button {
  position: relative; }
  .promise-button__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem; }
    .promise-button__spinner--primary .promise-button__bounce {
      background-color: #00affe; }
  .promise-button__bounce {
    width: 1.25rem;
    height: 1.25rem;
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
    .promise-button__bounce--first {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s; }
    .promise-button__bounce--second {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.responsive-img {
  display: block;
  margin-bottom: 0;
  position: relative; }
  .responsive-img__invisible-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    border: none;
    opacity: 0; }
  .responsive-img--loading img {
    background-color: #81d4fb;
    background-image: url("/assets/images/spinner.svg");
    background-repeat: no-repeat;
    background-position: center; }
  .responsive-img--blue .responsive-img__icon {
    display: block;
    background-color: #81d4fb;
    position: relative; }
    .responsive-img--blue .responsive-img__icon svg {
      fill: white;
      width: 2.8125rem;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .responsive-img--inverse img {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/images/spinner-primary.svg"); }

.autocomplete-tag {
  z-index: 2; }
  .autocomplete-tag__tag {
    display: inline-block;
    color: #00affe;
    font-family: 'Roboto-bold';
    font-weight: bold;
    padding: 0 0 0 1.25rem;
    font-size: 1.125rem;
    margin: 0.3125rem;
    transition: color 0.25s ease-in, background-color 0.25s ease-in, transform 0.3s ease-out;
    position: relative; }
    .autocomplete-tag__tag:hover .autocomplete-tag__delete-btn {
      display: block; }
    .autocomplete-tag__tag:active {
      transform: scale(0.95); }
  .autocomplete-tag__delete-btn {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    background-color: transparent;
    border: none;
    line-height: 1; }
    .autocomplete-tag__delete-btn svg {
      fill: #d34c50;
      width: 1.25rem;
      height: 1.25rem; }

@media screen and (max-width: 991px) {
  .autocomplete-tag__delete-btn {
    display: block; } }

/** COMPONENTS **/
.ql-editor {
  font-weight: 100;
  white-space: pre-wrap;
  line-height: 1.8125rem;
  font-size: 1.1875rem;
  color: #2c2c2c;
  overflow: visible;
  margin-bottom: 0; }
  .ql-editor strong {
    color: #00affe;
    font-weight: 100; }
  .ql-editor a {
    text-decoration: underline; }
  .ql-editor p {
    margin-bottom: 0; }

.navbar {
  padding: 0;
  height: 3.125rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001; }
  .navbar h3 {
    font-family: 'Roboto', sans-serif !important; }
  .navbar + * {
    margin-top: 3.125rem; }
  .navbar .descubre {
    border-top: 1px dotted #6f6f6f; }
    .navbar .descubre a {
      border-top: none; }
  .navbar .search-filters__toggle {
    align-items: center;
    display: flex;
    padding-right: 8px;
    background-color: transparent; }
    .navbar .search-filters__toggle svg {
      width: 40px;
      margin: 0px !important;
      fill: black; }
    .navbar .search-filters__toggle g {
      stroke: black; }
  .navbar .search-filters__toggle--active {
    align-items: center;
    display: flex;
    padding-right: 8px;
    background-color: #00affe; }
    .navbar .search-filters__toggle--active svg {
      width: 40px;
      margin: 0px !important;
      fill: white; }
    .navbar .search-filters__toggle--active g {
      stroke: white; }
  .navbar .user_not_approved p {
    font-size: 18px;
    text-transform: none; }
    .navbar .user_not_approved p.mobile {
      color: #00affe;
      font-size: 18px; }
  .navbar .user_not_approved .dropdown-menu {
    min-width: 450px;
    padding: 10px 15px;
    box-sizing: border-box;
    background: white; }
    .navbar .user_not_approved .dropdown-menu:before {
      border-bottom-color: white; }
    .navbar .user_not_approved .dropdown-menu p {
      font-size: 16px; }
    .navbar .user_not_approved .dropdown-menu h6 {
      text-align: right;
      color: #00affe;
      font-weight: 600; }
    .navbar .user_not_approved .dropdown-menu h5 {
      font-weight: 600;
      color: #00affe; }
  .navbar .navbar-brand {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 8.4375rem;
    height: 2.375rem; }
    .navbar .navbar-brand--text {
      font-family: 'Roboto-bold';
      font-weight: bold;
      max-width: calc(100% - 130px);
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center; }

.navbar-nav .nav-link {
  text-transform: uppercase;
  font-family: 'Oswald'; }
  .navbar-nav .nav-link:disabled {
    opacity: 0.6;
    cursor: not-allowed; }

.navbar-contextuals {
  padding: 0;
  flex-direction: row;
  z-index: 1; }
  .navbar-contextuals .nav-item {
    padding: 0; }
    .navbar-contextuals .nav-item--icon .nav-link {
      padding: 0.125rem;
      margin-right: 0; }
      .navbar-contextuals .nav-item--icon .nav-link svg {
        width: 2.25rem;
        height: 2.25rem; }
    .navbar-contextuals .nav-item--circle .nav-link {
      padding: 0;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1;
      background-color: #2c2c2c;
      border: 0.125rem solid #00affe;
      vertical-align: middle; }
      .navbar-contextuals .nav-item--circle .nav-link svg {
        height: 60%;
        width: 60%;
        fill: white; }
      .navbar-contextuals .nav-item--circle .nav-link--blue {
        background-color: #00affe; }
      .navbar-contextuals .nav-item--circle .nav-link .promise-button__bounce {
        width: 0.625rem;
        height: 0.625rem; }
    .navbar-contextuals .nav-item--circle + .nav-item {
      margin-left: 0.625rem; }
  .navbar-contextuals .nav-link {
    background-color: transparent;
    border: none;
    margin-right: 0.625rem; }
    .navbar-contextuals .nav-link svg {
      fill: white;
      width: 1.625rem;
      height: 1.625rem; }
    .navbar-contextuals .nav-link--active {
      background-color: #00affe; }

@media screen and (min-width: 375px) {
  .navbar-contextuals .nav-item--icon .nav-link svg {
    width: 2.875rem;
    height: 2.875rem; } }

.version-beta {
  display: flex;
  align-items: center;
  margin-right: -0.9375rem;
  margin-left: 0.9375rem;
  height: 3.5rem;
  overflow: hidden; }
  .version-beta h2 {
    color: white;
    font-family: 'Roboto-bold';
    font-size: 1.25rem;
    margin: 0.625rem 0.625rem 0;
    text-align: right;
    text-transform: uppercase; }
    .version-beta h2 span {
      display: block;
      font-size: .8rem;
      text-transform: initial; }
  .version-beta img {
    margin-bottom: -0.125rem; }

@media screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 0.9375rem; }
  .textButtonDrpdown {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    height: 3.5rem; }
  .navbar {
    height: 3.5rem;
    padding: 0 0.9375rem;
    position: unset;
    z-index: 2; }
    .navbar .navbar-collapse {
      height: 3.5rem; }
    .navbar + * {
      margin-top: 0; }
    .navbar .navbar-brand {
      position: unset;
      margin-right: 0.9375rem; }
      .navbar .navbar-brand img {
        height: 35px; }
    .navbar .form-inline {
      padding-left: 0.625rem; }
      .navbar .form-inline .form-control {
        border-radius: 0.9375rem;
        background-color: #666666;
        color: white;
        width: 100%;
        min-width: 6.25rem; }
    .navbar .nav-icon .svg-icon {
      margin-right: 0.625rem; }
    .navbar .nav-icon:hover .svg-icon {
      fill: white; }
    .navbar .nav-icon--white .svg-icon {
      fill: white; }
    .navbar .nav-icon--light-blue .svg-icon {
      margin-top: -0.3125rem;
      fill: #81d4fb; }
    .navbar .nav-icon .svg-icon,
    .navbar .nav-dropdown-toggle .svg-icon {
      display: inline-block;
      vertical-align: middle;
      height: 1.5rem;
      width: 1.5rem; }
    .navbar .dropdown-menu {
      background-color: #00affe;
      padding: 0.9375rem 0.3125rem;
      box-shadow: 0 0.1875rem 0.1875rem rgba(0, 0, 0, 0.3);
      margin-top: 0; }
      .navbar .dropdown-menu .dropdown-item {
        min-width: 12.5rem;
        font-family: 'Roboto-bold';
        font-weight: bold;
        color: white; }
        .navbar .dropdown-menu .dropdown-item + .dropdown-item {
          margin-top: 0.3125rem; }
        .navbar .dropdown-menu .dropdown-item:hover, .navbar .dropdown-menu .dropdown-item--active {
          background-color: #2c2c2c; }
      .navbar .dropdown-menu:after, .navbar .dropdown-menu:before {
        content: '';
        display: block;
        position: absolute;
        width: 1.875rem;
        border-right: 0.9375rem solid transparent;
        border-left: 0.9375rem solid transparent; }
      .navbar .dropdown-menu:before {
        border-bottom: 0.9375rem solid #00affe;
        top: -0.9375rem;
        z-index: 2; }
      .navbar .dropdown-menu:after {
        border-bottom: 0.9375rem solid #2c2c2c;
        top: -1rem;
        z-index: 1; }
      .navbar .dropdown-menu.dropdown-menu-right {
        right: -0.4375rem; }
        .navbar .dropdown-menu.dropdown-menu-right:before, .navbar .dropdown-menu.dropdown-menu-right:after {
          right: 0.5rem; }
      .navbar .dropdown-menu.dropdown-menu-left {
        left: -0.4375rem; }
        .navbar .dropdown-menu.dropdown-menu-left:before, .navbar .dropdown-menu.dropdown-menu-left:after {
          left: 2.5rem; }
    .navbar.dropdown.show .dropdown-toggle,
    .navbar.dropdown.show .nav-dropdown-toggle:hover {
      background-color: #2c2c2c; }
    .navbar .dropdown-toggle:disabled,
    .navbar .nav-dropdown-toggle:disabled {
      cursor: not-allowed; }
    .navbar .nav-dropdown-toggle {
      background-color: transparent;
      border: none;
      padding: 0.625rem 0.3125rem;
      height: 4.125rem; }
      .navbar .nav-dropdown-toggle:after {
        display: none; }
      .navbar .nav-dropdown-toggle svg {
        margin-right: 0;
        fill: white; }
  .navbar-nav {
    align-items: center; }
    .navbar-nav .nav-item .nav-link {
      display: flex;
      align-items: center;
      height: 3.5rem !important;
      font-size: 1.125rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 4.375rem;
      height: 4.375rem;
      background-color: transparent;
      border: none; }
      .navbar-nav .nav-item .nav-link:hover {
        background-color: #00affe; }
      .navbar-nav .nav-item .nav-link--active {
        background-color: #00affe;
        color: white; }
    .navbar-nav + .form-inline,
    .navbar-nav .nav-item + .nav-item {
      position: relative; }
      .navbar-nav + .form-inline::before,
      .navbar-nav .nav-item + .nav-item::before {
        display: block;
        content: '';
        width: 0.0625rem;
        height: 3.5rem !important;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-left: 0.0625rem dashed rgba(255, 255, 255, 0.3); } }

@media screen and (min-width: 1200px) {
  .navbar .form-inline {
    padding-left: 1.25rem; }
  .nav-icon svg,
  .nav-dropdown-toggle svg {
    margin-right: 15px; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 1.875rem; } }

.burguer-menu {
  padding-right: 3.125rem;
  top: 0; }
  .burguer-menu__button {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.625rem; }
  .burguer-menu__bars {
    background-color: white;
    height: 0.1875rem !important; }
    .burguer-menu__bars:first-of-type {
      top: 10% !important; }
    .burguer-menu__bars:last-of-type {
      top: 70% !important; }
  .burguer-menu .bm-cross {
    background-color: white;
    cursor: pointer;
    z-index: 9; }
    .burguer-menu .bm-cross-button {
      right: 0 !important;
      top: 0 !important;
      height: 3.125rem !important;
      width: 3.125rem !important; }
      .burguer-menu .bm-cross-button > span {
        right: 1.5625rem !important; }
        .burguer-menu .bm-cross-button > span span {
          height: 2.5rem !important; }
      .burguer-menu .bm-cross-button button {
        background-color: #00affe !important;
        width: 3.125rem !important;
        height: 3.125rem !important; }
  .burguer-menu .bm-menu {
    background-color: white;
    padding: 0.9375rem 0; }
  .burguer-menu__link {
    background-color: transparent;
    border: 0;
    border-top: 1px dotted #6f6f6f;
    color: #2c2c2c;
    display: block;
    font-family: 'Oswald';
    font-size: 1.125rem;
    padding: 0.9375rem 0.625rem 0.9375rem 0.625rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%; }
    .burguer-menu__link:focus {
      outline: none; }
    .burguer-menu__link:hover {
      color: #00affe;
      text-decoration: none; }
      .burguer-menu__link:hover svg {
        fill: #00affe; }
    .burguer-menu__link svg {
      height: 1.5625rem;
      margin-right: 1.25rem;
      width: auto; }
      .burguer-menu__link svg#youtube-icon path {
        fill: #d34c50; }
      .burguer-menu__link svg#info-color-icon > path:first-of-type {
        fill: none;
        stroke: #2c2c2c;
        stroke-width: 1.5625rem; }
      .burguer-menu__link svg#info-color-icon > path:nth-child(2) {
        fill: #2c2c2c; }
      .burguer-menu__link svg#add-blue {
        stroke: none; }
      .burguer-menu__link svg#angel-icon {
        height: 1.5625rem; }
    .burguer-menu__link--active {
      background-color: #00affe;
      color: white; }
      .burguer-menu__link--active svg {
        fill: white; }
  .burguer-menu__crea-items {
    display: flex;
    list-style-type: none;
    margin: 0.9375rem 0 0;
    padding: 0; }
    .burguer-menu__crea-items-btn {
      background: transparent;
      border: 0;
      padding: 0;
      text-align: center;
      width: 33%; }
    .burguer-menu__crea-items svg {
      height: 3.75rem;
      margin: 0 0.3125rem; }
    .burguer-menu__crea-items-text {
      display: block;
      font-family: 'Roboto-bold';
      font-size: 0.6875rem;
      text-decoration: underline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .burguer-menu__user {
    padding-bottom: 0.3125rem; }
    .burguer-menu__user:focus {
      outline: none; }
    .burguer-menu__user-name {
      font-family: 'Oswald';
      font-size: 0.9375rem;
      margin-top: 0.625rem;
      text-transform: uppercase; }
    .burguer-menu__user-avatar {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 5.625rem;
      margin: 0 auto; }

.bm-overlay {
  top: 0;
  left: 0; }

@media screen and (min-width: 992px) {
  .burguer-menu {
    display: none; }
    .burguer-menu__button {
      display: none; } }

.poi-edition-header__title {
  font-family: 'Roboto-bold';
  font-weight: bold;
  font-size: 1.375rem;
  padding-bottom: 0.3125rem;
  margin-bottom: 0; }

.poi-edition-header__description {
  line-height: 1.25;
  margin-bottom: 0; }

.poi-edition-header__content--desktop {
  display: none; }

.poi-edition-header__content--mobile {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background-color: white; }

@media screen and (min-width: 992px) {
  .poi-edition-header {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080"%3E%3Cpath fillRule="evenodd" clipRule="evenodd" fill="%23303030" d="M1437.65 438.75c-45.167-1.6-93.101-1.85-143.801-.75-98.767 2.133-247.616 11.133-446.55 27-94.2 7.533-192.1 10.533-293.7 9-78.867-1.2-168.3-5.383-268.3-12.55-32.167-2.333-74.233-5.617-126.2-9.85-60.667-4.934-101.233-8.184-121.7-9.75a5782.048 5782.048 0 0 0-35.85-2.625 765.5 765.5 0 0 1-3.1-.225V0h1922.2v451.15c-55.467 2.733-113.533 3.8-174.2 3.2-66.434-.633-125.05-3.067-175.851-7.3-49.164-4.1-93.481-6.867-132.948-8.3z"/%3E%3C/svg%3E');
    background-size: 100%;
    background-position: 0 -2vw;
    background-repeat: no-repeat;
    height: 23vw;
    margin-bottom: 3.75rem; }
    .poi-edition-header__content {
      padding-top: 5%; }
      .poi-edition-header__content--mobile {
        display: none; }
      .poi-edition-header__content--desktop {
        display: block; }
    .poi-edition-header__title {
      color: #81d4fb;
      font-size: 1.75rem;
      text-align: center;
      padding-top: 0; }
    .poi-edition-header__description {
      text-align: center;
      color: white; } }

@media screen and (min-width: 1201px) {
  .poi-edition-header {
    background-position: 0 -7vw;
    height: 18vw; } }

div.top-select {
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: uppercase;
  font-family: 'Oswald'; }
  div.top-select.top-select--icon {
    width: 6.25rem; }
    div.top-select.top-select--icon .top-select__single-value {
      width: auto; }
    div.top-select.top-select--icon .top-select__option {
      text-align: center; }
    div.top-select.top-select--icon svg {
      height: 2.1875rem;
      width: auto; }
  div.top-select__value-container {
    height: calc(1.5em + 0.75rem + 2px);
    background-color: rgba(129, 212, 251, 0.3);
    border-radius: 0;
    background-clip: padding-box; }
    div.top-select__value-container:focus {
      color: #495057;
      background-color: rgba(129, 212, 251, 0.3);
      border-color: #7fd7ff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 175, 254, 0.25); }
  div.top-select__single-value {
    width: 100%;
    font-size: 1.125rem;
    text-align: center; }
  div.top-select__control {
    border: none; }
    div.top-select__control:focus, div.top-select__control:hover {
      box-shadow: none; }
    div.top-select__control--menu-is-open {
      box-shadow: none; }
      div.top-select__control--menu-is-open .top-select__dropdown-indicator-img {
        transform: rotate(180deg); }
  div.top-select__dropdown-indicator {
    padding: 0;
    margin-left: 0.625rem; }

span.top-select__indicator-separator {
  display: none; }

.top-select__dropdown-indicator-img {
  transition-duration: 0.2s;
  width: 2.1875rem;
  height: 2.1875rem; }

.modal-warning {
  max-width: 90vw;
  height: 28.125rem;
  max-height: 95vh;
  max-height: calc(var(--vh, 1vh) * 95);
  width: 100%;
  background-color: #fff;
  margin: auto;
  padding: 8.75rem 0 1.25rem 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .modal-warning::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .modal-warning::-webkit-scrollbar-thumb {
      background: #00affe; }
  .modal-warning__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 1; }
    .modal-warning__close-btn svg {
      width: 1.875rem;
      height: 1.875rem; }
  .modal-warning__main-image {
    position: absolute;
    width: 75%;
    max-width: 17.1875rem;
    top: -2.5rem;
    left: 0.9375rem;
    z-index: 1001; }
  .modal-warning__content {
    max-height: calc(100vh - 100px);
    max-height: calc(var(--vh, 1vh) * 100 - 100px);
    overflow-y: auto; }
    .modal-warning__content-title, .modal-warning__content-text {
      padding-left: 25%; }
    .modal-warning__content-title {
      font-size: 1.125rem;
      margin: 0 0 0.625rem auto;
      text-align: right;
      font-weight: bold; }
    .modal-warning__content-text {
      text-align: justify;
      font-size: 1rem;
      line-height: 1.25; }
  .modal-warning__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(44, 44, 44, 0.9);
    z-index: 1010; }
  .modal-warning__cross-icon svg {
    fill: #d34c50;
    width: 3.125rem;
    height: 3.125rem; }

@media screen and (min-width: 992px) {
  .modal-warning {
    padding: 0.625rem 0 0 5.625rem;
    max-width: 53.125rem;
    height: 44.375rem;
    overflow-y: auto; }
    .modal-warning.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .modal-warning__main-image {
      top: 0.625rem;
      width: auto;
      max-width: 40%;
      margin-left: 4.375rem; }
    .modal-warning__content {
      padding: 6.25rem 2.8125rem 1.875rem 1.875rem;
      max-height: unset;
      overflow-y: unset; }
      .modal-warning__content-title {
        text-align: left;
        font-size: 1.75rem; }
      .modal-warning__content-title, .modal-warning__content-text {
        padding-left: 0; }
    .modal-warning__footer {
      margin-left: -6.25rem;
      font-size: 1.125rem; }
    .modal-warning__highlight {
      text-align: right;
      padding-right: 0.9375rem;
      margin-bottom: 0.3125rem; }
      .modal-warning__highlight span {
        background-color: #d34c50;
        padding: 0.3125rem;
        color: white;
        font-size: 1.25rem; } }

.poi-position-modal {
  max-width: 90vw;
  height: calc(100vh - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px);
  width: 100%;
  background-color: #fff;
  margin: 1.25rem auto 0 auto;
  padding: 1.25rem 0 0.625rem 0;
  position: relative;
  z-index: 1000;
  border-top-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem; }
  .poi-position-modal.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .poi-position-modal__title {
    color: #2c2c2c;
    font-weight: bold;
    margin-bottom: 0.9375rem;
    text-align: center; }
  .poi-position-modal__input, .poi-position-modal__suggestions {
    font-family: 'Oswald';
    text-transform: uppercase; }
  .poi-position-modal__input {
    position: relative; }
  .poi-position-modal__suggestions {
    line-height: 1.2;
    font-size: 1.125rem;
    border: 0.0625rem solid #303030;
    position: absolute;
    top: 2.5rem;
    left: 0.9375rem;
    right: 0;
    z-index: 2;
    background-color: white; }
    .poi-position-modal__suggestions-item {
      padding: 0.625rem; }
      .poi-position-modal__suggestions-item--active {
        background-color: #00affe;
        color: white; }
      .poi-position-modal__suggestions-item + .poi-position-modal__suggestions-item {
        border-top: 0.0625rem solid #303030; }
  .poi-position-modal__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(44, 44, 44, 0.9);
    z-index: 1010; }
  .poi-position-modal__map {
    padding: 0; }
    .poi-position-modal__map .mapWrapper {
      height: calc(100vh - 260px);
      height: calc((var(--vh, 1vh) * 100) - 260px);
      max-height: 100%; }

@media screen and (min-width: 992px) {
  .poi-position-modal {
    padding: 1.875rem 0 1.875rem 0;
    max-width: 53.125rem; }
    .poi-position-modal__map .mapWrapper {
      height: calc(100vh - 300px);
      height: calc((var(--vh, 1vh) * 100) - 300px);
      max-height: 100%; } }

.poi-image {
  padding: 0 0.3125rem;
  margin-top: 0.9375rem; }
  .poi-image__header {
    padding: 0.3125rem 1.25rem;
    border: 0.0625rem dashed #dedede;
    background-color: white; }
  .poi-image__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 70vw;
    height: 45vw;
    margin-bottom: 0; }
  .poi-image__button {
    background-color: white;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    padding: 0;
    line-height: 1;
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.3); }
    .poi-image__button svg {
      width: 1.5625rem;
      height: auto;
      fill: #6f6f6f; }
    .poi-image__button--delete svg {
      fill: #d34c50; }
    .poi-image__button--hightlight svg {
      fill: #ffc107; }
  .poi-image__body {
    padding: 0; }
  .poi-image__input {
    border: 0.0625rem dashed #f4f4f4;
    margin-top: 0.3125rem;
    background-color: white; }
    .poi-image__input:focus, .poi-image__input:active {
      border: none; }

@media screen and (min-width: 992px) {
  .poi-image__header {
    border: none;
    padding: 0 0.9375rem; }
  .poi-image__image {
    width: 18.75rem;
    height: 11.0625rem; }
  .poi-image__body {
    margin-left: 0.9375rem; }
  .poi-image__input {
    margin-top: 0;
    resize: none; }
    .poi-image__input:nth-child(2n) {
      background-color: #f4f4f4; }
    .poi-image__input + .poi-image__input {
      margin-top: 0.3125rem; } }

.dropzone {
  border: 0.0625rem dotted;
  min-height: 12.5rem;
  min-width: 12.5rem; }
  .dropzone .poi-info-page__previous-image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%; }
  .dropzone .icon.ion-upload {
    display: none; }
  .dropzone .progressPercentageBarWrapper {
    position: absolute;
    height: 1.5rem;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    opacity: 0.8; }
    .dropzone .progressPercentageBarWrapper .progressPercentageBarProgress {
      display: block;
      height: 100%;
      z-index: 2;
      background-color: #00affe;
      padding: 0 0.625rem;
      font-weight: bold; }

.fine-uploader__files-wrapper {
  padding: 2.5rem 1.25rem; }

.fine-uploader__upload-input {
  display: block !important;
  max-width: 18.75rem;
  margin: 0 auto; }

.thumbnails-wrapper__box {
  position: relative; }
  .thumbnails-wrapper__box + .thumbnails-wrapper__box {
    margin-top: 0.625rem; }

.thumbnails-wrapper__remove-btn {
  position: absolute;
  top: 0.1875rem;
  right: 0.1875rem;
  background-color: #d34c50;
  z-index: 1;
  border: 0;
  border-radius: 50%;
  width: 2.1875rem;
  height: 2.1875rem; }
  .thumbnails-wrapper__remove-btn svg {
    width: 90%;
    height: auto;
    fill: white; }
  .thumbnails-wrapper__remove-btn:hover {
    background-color: #a92a2d; }

.thumbnails-wrapper__preview {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; }

.poi-images-box__dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 25rem; }
  .poi-images-box__dropzone-img {
    display: block;
    width: 90%;
    max-width: 14.375rem;
    max-height: 9.375rem;
    margin: 0 auto; }
    .poi-images-box__dropzone-img--drag {
      display: none; }
  .poi-images-box__dropzone-text {
    margin: 1.25rem 0 0 0;
    font-family: 'Roboto-bold';
    text-align: center;
    font-size: 1.125rem;
    padding: 0 0.625rem;
    line-height: 1.2; }

.poi-images-box__images {
  padding: 1.875rem 1.875rem 6.25rem 1.875rem; }

.poi-images-box__image-wrap {
  margin-bottom: 0; }
  .poi-images-box__image-wrap--default {
    background-color: #00affe;
    height: 7.5rem;
    position: relative; }
    .poi-images-box__image-wrap--default svg {
      fill: white;
      width: 1.875rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
  .poi-images-box__image-wrap + .poi-images-box__image-wrap {
    margin-top: 0.3125rem; }

.poi-images-box__image {
  width: 100%;
  height: auto; }

.fine-uploader-dropzone-container.dropzone {
  padding-bottom: 0.625rem; }

.react-fine-uploader-dropzone-active .poi-images-box__dropzone-img--nodrag {
  display: none; }

.react-fine-uploader-dropzone-active .poi-images-box__dropzone-img--drag {
  display: block; }

.react-fine-uploader-dropzone-active .poi-images-box__dropzone-text {
  display: none; }

.tour-edition-header {
  background-color: #303030;
  position: fixed;
  top: 3.125rem;
  left: 0;
  right: 0;
  z-index: 1000; }
  .tour-edition-header__back {
    background: transparent;
    border: none;
    height: 3.125rem; }
    .tour-edition-header__back svg {
      fill: white;
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-edition-header__title {
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    line-height: 3.125rem;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1.125rem;
    max-width: calc(100% - 40px); }
  .tour-edition-header__keywords .autocomplete-tag {
    max-width: 90% !important; }
    .tour-edition-header__keywords .autocomplete-tag-placeholder {
      width: 100% !important;
      display: inline;
      font-weight: 100 !important;
      font-family: 'Roboto-Regular'; }
  .tour-edition-header__keywords .form-control {
    background: #f4f4f4; }

@media screen and (min-width: 992px) {
  .tour-edition-header {
    position: relative;
    margin-bottom: 3.75rem;
    padding-top: 3.125rem;
    z-index: 10;
    top: 0;
    max-width: unset; }
    .tour-edition-header:after {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080"%3E%3Cpath fillRule="evenodd" clipRule="evenodd" fill="%23303030" d="M1437.65 438.75c-45.167-1.6-93.101-1.85-143.801-.75-98.767 2.133-247.616 11.133-446.55 27-94.2 7.533-192.1 10.533-293.7 9-78.867-1.2-168.3-5.383-268.3-12.55-32.167-2.333-74.233-5.617-126.2-9.85-60.667-4.934-101.233-8.184-121.7-9.75a5782.048 5782.048 0 0 0-35.85-2.625 765.5 765.5 0 0 1-3.1-.225V0h1922.2v451.15c-55.467 2.733-113.533 3.8-174.2 3.2-66.434-.633-125.05-3.067-175.851-7.3-49.164-4.1-93.481-6.867-132.948-8.3z"/%3E%3C/svg%3E');
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      height: 25vw;
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      top: 6.25rem; }
    .tour-edition-header__tutorial {
      display: none;
      position: absolute;
      top: 3.75rem;
      left: 0.9375rem;
      text-align: center; }
      .tour-edition-header__tutorial-btn {
        background-color: transparent;
        border: none; }
      .tour-edition-header__tutorial-img {
        width: 11.4375rem;
        height: auto; }
      .tour-edition-header__tutorial-text {
        margin: 0.625rem 0 0 0;
        text-align: center;
        width: 13.75rem;
        color: #f4f4f4; }
    .tour-edition-header__content {
      min-height: 2.1875rem; }
    .tour-edition-header__top {
      text-align: center; }
    .tour-edition-header__title {
      display: inline-block;
      position: relative;
      font-family: 'Roboto-bold';
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 2.125rem;
      line-height: 4.6875rem;
      padding: 0.3125rem 4.375rem 0.3125rem 0;
      color: #81d4fb;
      margin-bottom: 0;
      max-width: 100%; }
    .tour-edition-header__preview {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: transparent;
      border: none; }
      .tour-edition-header__preview svg {
        width: 3.125rem;
        height: 3.125rem; }
    .tour-edition-header__keywords {
      text-align: center;
      color: white;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 1.125rem; }
      .tour-edition-header__keywords .autocomplete-tag {
        width: 80%;
        display: inline-block; }
        .tour-edition-header__keywords .autocomplete-tag-placeholder {
          width: 100% !important;
          display: inline;
          font-weight: 100 !important;
          font-family: 'Roboto-Regular'; }
        .tour-edition-header__keywords .autocomplete-tag__tag {
          color: white; }
          .tour-edition-header__keywords .autocomplete-tag__tag + .autocomplete-tag__tag {
            margin-left: 0.4375rem; }
      .tour-edition-header__keywords-btn {
        background-color: transparent;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
        padding: 0;
        border: none;
        text-align: center;
        margin-left: 1.25rem; }
      .tour-edition-header__keywords:after {
        content: '';
        display: inline-block;
        width: 80%;
        margin: 1.5625rem auto 0.625rem auto;
        border: 1px dashed white; }
    .tour-edition-header__config {
      background-color: transparent;
      border: none;
      color: white;
      font-size: 1.125rem; }
      .tour-edition-header__config span,
      .tour-edition-header__config svg {
        display: inline-block;
        vertical-align: middle; }
      .tour-edition-header__config svg {
        fill: white;
        width: 1.25rem;
        height: auto;
        margin-left: 0.4375rem; }
    .tour-edition-header__state {
      color: white;
      font-size: 1rem;
      margin-bottom: 0; }
      .tour-edition-header__state-btn {
        width: 6.125rem;
        margin-left: 0.625rem;
        padding: 0.3125rem 0.9375rem;
        border: none; }
      .tour-edition-header__state-description {
        text-align: left;
        margin: 0;
        color: #dedede;
        font-size: 1rem; }
  .custom-state-select {
    width: 6.125rem;
    position: absolute;
    left: 3.9375rem;
    z-index: 10; }
    .custom-state-select__list {
      display: flex;
      flex-direction: column;
      height: 2.3125rem; }
      .custom-state-select__list label {
        color: white;
        cursor: pointer;
        line-height: 2.3125rem;
        text-align: center;
        margin: 0; }
    .custom-state-select input {
      position: absolute;
      visibility: hidden; }
  .rejected {
    background-color: #d34c50; } }

.tour-edition-header__state-btn--edition,
.custom-state-select__list-item--edition {
  background-color: #00affe;
  color: white; }

.tour-edition-header__state-btn--published,
.custom-state-select__list-item--published {
  background-color: #fd7e14;
  color: white; }

.tour-edition-header__state-btn--approved,
.custom-state-select__list-item--approved {
  background-color: #28a745;
  color: white; }

.tour-edition-header__state-btn--hidden,
.custom-state-select__list-item--hidden {
  background-color: #6f6f6f;
  color: white; }

@media screen and (min-width: 1200px) {
  .tour-edition-header {
    position: relative;
    margin-bottom: 3.75rem;
    padding-top: 3.125rem;
    z-index: 10; }
    .tour-edition-header:after {
      top: 4.6875rem; } }

@media screen and (min-width: 1560px) {
  .tour-edition-header:after {
    top: 1.5625rem; } }

.day-content {
  margin: 12.5vw auto 0 auto; }
  .day-content__content {
    background-color: #b9b9b9; }
    .day-content__content--no-bg {
      background-color: transparent; }
  .day-content__captions {
    margin: 0 0 3.75rem 0; }
    .day-content__captions-item-text, .day-content__captions-item-icon {
      display: inline-block;
      vertical-align: middle; }
    .day-content__captions-item-icon {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      background-color: #00affe;
      margin-right: 0.625rem;
      text-align: center; }
      .day-content__captions-item-icon svg {
        width: 90%;
        height: auto;
        fill: white; }
    .day-content__captions-item-text {
      font-size: 1.125rem;
      font-family: 'Roboto-Regular'; }
    .day-content__captions-item--blue:before, .day-content__captions-item--green:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 6.25rem;
      height: 0.375rem;
      margin-right: 0.625rem; }
    .day-content__captions-item--blue:before {
      background-color: #00affe; }
    .day-content__captions-item--green:before {
      background-color: #21B14F; }
  .day-content__days {
    text-align: center;
    list-style: none;
    margin-top: -3.125rem;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c;
    scrollbar-track-color: #00affe;
    -webkit-overflow-scrolling: touch; }
    .day-content__days::-webkit-scrollbar {
      background-color: #2c2c2c;
      height: 0.3125rem; }
    .day-content__days::-webkit-scrollbar-thumb {
      background: #00affe; }
    .day-content__days-item {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0.625rem; }
      .day-content__days-item--active .day-content__days-btn {
        width: 12.5rem;
        background-color: #303030;
        color: white; }
      .day-content__days-item--action .day-content__days-btn {
        background-color: #303030;
        color: white;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        line-height: 1;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none; }
        .day-content__days-item--action .day-content__days-btn--red {
          background-color: #d34c50; }
        .day-content__days-item--action .day-content__days-btn svg {
          fill: white;
          width: 50%;
          height: auto; }
        .day-content__days-item--action .day-content__days-btn:disabled {
          opacity: 0.8;
          cursor: not-allowed; }
      .day-content__days-item + .day-content__days-item {
        margin-left: 0.3125rem; }
    .day-content__days-btn {
      font-family: 'Roboto-bold';
      font-weight: bold;
      color: #2c2c2c;
      width: 6.25rem;
      height: 2.5rem;
      line-height: 1;
      text-align: center;
      background-color: white;
      border: 0.0625rem solid #303030;
      border-radius: 0.625rem; }
    .day-content__days .promise-button__bounce {
      width: 0.625rem;
      height: 0.625rem; }
  .day-content__slider {
    overflow: hidden;
    display: flex;
    justify-content: center;
    max-height: 11.25rem; }
    .day-content__slider-wrapper {
      padding: 1.875rem 3.4375rem 1.25rem 3.4375rem;
      max-width: calc(100% - 250px); }
  .day-content__select-wrapper {
    position: absolute;
    bottom: 15px; }
    .day-content__select-wrapper-menu {
      top: 90%; }
  .day-content__list {
    padding: 1.875rem 0 11.25rem 0;
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    white-space: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    position: relative; }
    .day-content__list.ongoingDrag .day-content__list-item:before {
      display: none; }
    .day-content__list-item {
      display: inline-block;
      z-index: 10;
      position: relative; }
      .day-content__list-item--green .day-content__list-item-btn {
        border: 0.25rem solid #21B14F; }
      .day-content__list-item--blue .day-content__list-item-btn {
        border: 0.25rem solid #00affe; }
      .day-content__list-item + .day-content__list-item {
        margin-left: 0.625rem; }
        .day-content__list-item + .day-content__list-item:before {
          content: '';
          display: block;
          width: 1.875rem;
          height: 0.0625rem;
          border-bottom: 0.125rem dashed #2c2c2c;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -0.625rem;
          margin: auto;
          z-index: -1; }
      .day-content__list-item-btn {
        background-color: white;
        padding: 0.625rem;
        border-radius: 0.9375rem;
        border: none; }
        .day-content__list-item-btn--no-box .day-content__list-item-img {
          width: auto; }
      .day-content__list-item-img {
        width: 7.5rem;
        height: 7.5rem;
        object-fit: cover; }
        .day-content__list-item-img--icon {
          object-fit: contain;
          padding: 20%; }
      .day-content__list-item-balloon {
        width: 1.875rem;
        height: 1.875rem;
        line-height: 1.875rem;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 0.3125rem;
        bottom: 0.3125rem;
        background-color: #00affe;
        color: white;
        font-family: 'Roboto-bold';
        font-weight: bold;
        z-index: 2; }
        .day-content__list-item-balloon--icon {
          line-height: unset; }
          .day-content__list-item-balloon--icon svg {
            line-height: 1;
            fill: white;
            width: 90%;
            height: auto; }
  .day-content__hide-show-btn {
    position: absolute;
    top: -1.875rem;
    left: 0.625rem;
    padding: 0;
    background-color: transparent;
    border: none; }
    .day-content__hide-show-btn svg {
      width: 1.25rem;
      height: auto; }
  .day-content__remove-btn {
    position: absolute;
    top: -1.875rem;
    right: 0.625rem;
    padding: 0;
    background-color: transparent;
    border: none; }
    .day-content__remove-btn svg {
      fill: #d34c50;
      width: 1.25rem;
      height: auto; }
  .day-content__back, .day-content__forward {
    background-color: transparent;
    border: none;
    border-left: 0.3125rem solid #2c2c2c;
    border-bottom: 0.3125rem solid #2c2c2c;
    transform: rotate(45deg);
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    bottom: 5.3125rem;
    margin: auto; }
  .day-content__back {
    left: 0.9375rem; }
  .day-content__forward {
    right: 0.9375rem;
    transform: rotate(-135deg); }
  .day-content__action {
    background-color: #00affe;
    color: white;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    border: 0.375rem solid white;
    padding: 0.625rem;
    margin: 0.625rem 0 3.4375rem 0;
    font-size: 3.75rem;
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    text-align: center; }
    .day-content__action svg {
      display: block;
      fill: white;
      height: 100%;
      width: auto;
      margin: 0 auto; }
    .day-content__action--small-icon svg {
      height: 75%;
      width: 75%; }
    .day-content__action + .day-content__action {
      margin-left: 0.9375rem; }
  .day-content__no-visible-layer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.9375rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .day-content__no-visible-layer svg {
      width: 1.875rem;
      height: 1.875rem;
      fill: #2c2c2c;
      stroke: #2c2c2c; }
  .day-content__loading-spinner {
    display: block;
    text-align: center; }
    .day-content__loading-spinner-img {
      width: 1.875rem;
      height: auto;
      margin-bottom: 0.625rem; }

.search-filters {
  margin-top: 3.125rem;
  padding-top: 0.3125rem;
  background-color: #f4f4f4;
  align-items: center; }
  .search-filters__day .day-select {
    line-height: 1;
    border: none; }
    .search-filters__day .day-select__indicators {
      display: none; }
    .search-filters__day .day-select__control--is-focused, .search-filters__day .day-select__control--menu-is-open {
      outline: none;
      border: none; }
    .search-filters__day .day-select__value-container {
      background-color: #2c2c2c;
      position: relative;
      padding: 1.125rem 0 0.0625rem 0;
      width: 3.4375rem;
      height: 3.4375rem; }
    .search-filters__day .day-select__single-value {
      color: white;
      font-weight: bold;
      font-family: 'Roboto-bold';
      font-size: 2rem;
      max-width: none;
      text-align: center;
      width: 100%;
      margin: 0;
      transform: none;
      top: unset;
      bottom: 0.3125rem; }
    .search-filters__day .day-select__menu {
      width: 7.5rem; }
    .search-filters__day .day-select__option {
      display: block;
      font-size: 1.125rem;
      padding: 0.625rem 0.625rem;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      text-align: center; }
  .search-filters__day:before {
    content: 'dia';
    font-weight: bold;
    font-family: 'Roboto-bold';
    color: white;
    display: block;
    position: absolute;
    text-align: left;
    padding: 0.3125rem 0.625rem 0 0.3125rem;
    top: 0.0625rem;
    left: 0.3125rem;
    right: 0;
    font-size: 0.75rem;
    line-height: 1;
    z-index: 1; }
  .search-filters__search {
    display: block;
    padding-right: 2.1875rem;
    position: relative;
    margin-bottom: 0;
    font-weight: 100;
    z-index: 10;
    max-width: calc(100% - 20px); }
    .search-filters__search-input {
      background-color: white;
      border-radius: 0.625rem;
      height: 2.5rem;
      text-transform: uppercase; }
    .search-filters__search-icon {
      right: 0.625rem; }
  .search-filters__search-icon, .search-filters__tip {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.25rem; }
    .search-filters__search-icon svg, .search-filters__tip svg {
      height: 100%;
      width: auto;
      vertical-align: unset; }
  .search-filters__tip {
    right: 0.3125rem; }
    .search-filters__tip svg {
      fill: #00affe; }
  .search-filters__type {
    padding: 0.40625rem;
    position: relative; }
    .search-filters__type svg {
      width: 2.5rem;
      height: 2.5rem; }
    .search-filters__type--active {
      background-color: #00affe; }
      .search-filters__type--active:after {
        content: '';
        display: block;
        width: 0.9375rem;
        border-top: 0.625rem solid #00affe;
        border-left: 0.625rem solid transparent;
        border-right: 0.625rem solid transparent;
        position: absolute;
        bottom: -0.625rem;
        left: 0;
        right: 0;
        margin: auto; }
  .search-filters__footer {
    width: 100%;
    background-color: #dedede;
    margin-top: 0.625rem;
    padding: 0.625rem 0.9375rem; }
  .search-filters__view {
    display: block;
    width: 100%;
    background-color: white;
    border-radius: 0.3125rem;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1rem; }
    .search-filters__view:hover, .search-filters__view:focus, .search-filters__view--active {
      background-color: #00affe;
      color: white;
      border-color: transparent; }
  .search-filters__toggle {
    background-color: transparent;
    border: none;
    padding: 0; }
    .search-filters__toggle svg {
      width: 1.25rem;
      height: auto; }
    .search-filters__toggle + .search-filters__toggle {
      margin-left: 0.625rem; }
  .search-filters .__react_component_tooltip {
    z-index: 1010; }

@media screen and (max-width: 991px) {
  .search-filters__type {
    border-radius: 0; } }

@media screen and (min-width: 375px) {
  .search-filters__toggle svg {
    width: 1.875rem; } }

@media screen and (min-width: 992px) {
  .search-filters {
    margin-top: 0.625rem;
    margin-bottom: 2.5rem;
    align-items: flex-end;
    background-color: transparent; }
    .search-filters__active-list {
      list-style: none;
      display: inline-block;
      padding-left: 0;
      margin: 0 0 0 1.5625rem; }
      .search-filters__active-list-item {
        display: inline-block;
        text-transform: uppercase;
        background-color: white;
        padding: 0.625rem 1.875rem;
        font-family: 'Oswald'; }
        .search-filters__active-list-item + .search-filters__active-list-item {
          margin-left: 0.625rem; }
    .search-filters__toggle, .search-filters__active-list-item, .search-filters__origin {
      display: inline-block;
      vertical-align: middle; }
    .search-filters__type {
      background-color: #00affe;
      color: white; }
      .search-filters__type--active {
        background-color: #2c2c2c;
        color: white; }
        .search-filters__type--active:after {
          display: none; }
    .search-filters__toggle svg {
      width: 2.5rem; }
    .search-filters__map-toggle {
      display: block;
      font-family: 'Oswald';
      text-transform: uppercase;
      background-color: #fff; }
      .search-filters__map-toggle span {
        display: inline-block;
        vertical-align: middle; }
      .search-filters__map-toggle svg {
        height: 1rem;
        width: auto;
        margin-right: 0.3125rem; }
    .search-filters__search {
      padding-right: 0; }
      .search-filters__search-input {
        margin-top: 0.3125rem; }
    .search-filters__origin .custom-checkbox {
      margin-bottom: 0;
      margin-left: 0.625rem;
      background-color: white;
      padding: 0 1.25rem 0 3.125rem;
      font-size: 1.125rem;
      line-height: 2.75rem; }
      .search-filters__origin .custom-checkbox i {
        margin-left: 0.625rem; }
    .search-filters__origin-value {
      display: inline-block;
      text-transform: uppercase;
      font-family: 'Oswald'; } }

@media screen and (min-width: 1200px) {
  .search-filters {
    margin-left: 1.875rem; } }

.results-card-list {
  display: flex;
  /*   align-items: flex-start;
  flex-grow: 1;
  max-height: calc(100vh - 80px);
  max-height: calc(var(--vh, 1vh) * 100 - 80px); */
  overflow-y: auto;
  padding: 0.625rem 0.9375rem 0 0;
  flex-wrap: wrap;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch;
  /* grid-gap: 2px;
    display: grid !important;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, 260px);
    grid-template-rows: repeat(auto-fill, 340px);
    grid-template-rows: repeat(auto-fill, 280px); */ }
  .results-card-list::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .results-card-list::-webkit-scrollbar-thumb {
      background: #00affe; }

.content-card {
  cursor: pointer;
  font-family: 'Roboto-Regular' !important;
  flex-grow: 0;
  flex-basis: calc(50% - 30px);
  padding: 0.375rem;
  margin: 0 0.3125rem 0.9375rem 0.3125rem;
  background-color: white;
  border: none;
  border-top-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border: 4px solid transparent;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  position: relative;
  color: #2c2c2c; }
  .content-card:hover {
    text-decoration: none;
    color: #2c2c2c; }
  .content-card--red {
    border-color: #d34c50;
    cursor: not-allowed; }
  .content-card--selected {
    background-color: #81d4fb; }
  .content-card--highlighted {
    border-color: #81d4fb; }
  .content-card--staff-pick:after {
    background: url("/assets/images/iconStar.svg") no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    height: 1.875rem;
    width: 1.875rem; }
  .content-card--iconSelectionPoi:after {
    background: url("/assets/images/iconSelectionPoi.svg") no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    top: 0.625rem;
    right: 0.3125rem;
    height: 1.875rem;
    width: 1.875rem; }
  .content-card--iconSelectionPoi .logoPoiCampaign {
    margin-top: 20px;
    object-fit: cover;
    width: 100%; }
  .content-card--iconSelectionPoi .content-card__title {
    text-align: center;
    font-family: 'Roboto-Regular' !important;
    font-size: 1rem;
    margin: 0;
    line-height: 1.25;
    overflow: hidden;
    color: #6f6f6f;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis; }
  .content-card--iconSelectionPoi .content-card__textSelection {
    text-transform: uppercase;
    font-size: 16px !important;
    font-family: 'Oswald';
    color: #6f6f6f; }
  .content-card--selection {
    width: 512px;
    max-width: 512px !important;
    flex-basis: auto !important;
    min-width: 512px !important; }
    .content-card--selection .content-card__title {
      color: #006cc6;
      font-size: 20px;
      font-family: 'Roboto-bold'; }
    .content-card--selection .content-card__textSelection {
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      font-family: 'Oswald';
      color: #6f6f6f; }
  .content-card .likesTour {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'Oswald';
    margin-right: 4px; }
    .content-card .likesTour svg {
      width: 20px;
      fill: #81d4fb; }
  .content-card .iconStar {
    display: flex;
    width: 30px;
    height: 30px;
    background-color: #40444B;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 10px; }
    .content-card .iconStar svg {
      height: 18px;
      width: auto;
      fill: #ffcb02; }
  .content-card--added {
    background-color: #2c2c2c; }
    .content-card--added .content-card__visualizations,
    .content-card--added .content-card__author,
    .content-card--added .content-card__title {
      font-family: 'Roboto-Regular', sans-serif;
      color: white; }
    .content-card--added .content-card__state {
      background-color: white; }
      .content-card--added .content-card__state svg {
        fill: #81d4fb; }
    .content-card--added .content-card__visualizations-value {
      color: white; }
    .content-card--added .content-card__visualizations svg {
      fill: white; }
  .content-card--with-overlayer .content-card__title {
    margin-top: 0;
    font-family: 'Roboto-Regular', sans-serif; }
  .content-card--with-overlayer .content-card__content {
    padding-top: 2.1875rem; }
  .content-card--transparent {
    opacity: 0.5; }
  .content-card__carrousel {
    height: 11.25rem;
    width: 100%;
    object-fit: cover;
    position: relative;
    margin-bottom: 0;
    background-color: white; }
    .content-card__carrousel:hover .content-card__carrousel-control {
      display: block; }
    .content-card__carrousel-control {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1.875rem;
      height: 1.875rem;
      margin: auto;
      background-color: transparent;
      border: none;
      padding: 0; }
      .content-card__carrousel-control:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-bottom: 0.1875rem solid white;
        border-left: 0.1875rem solid white; }
      .content-card__carrousel-control--back {
        left: 0.9375rem;
        transform: rotate(45deg); }
      .content-card__carrousel-control--next {
        right: 0.9375rem;
        transform: rotate(-135deg); }
  .content-card__visualizations, .content-card__visualizations-value, .content-card__avatar-wrapper, .content-card__author {
    display: inline-block;
    vertical-align: middle;
    color: #2c2c2c; }
  .content-card__visualizations {
    font-size: 0.9rem;
    padding-right: 0.3125rem;
    text-align: left;
    margin: 0.625rem 0 0 0;
    line-height: 1; }
    .content-card__visualizations svg {
      width: 1.4375rem;
      height: auto; }
    .content-card__visualizations-value {
      font-family: 'Roboto', sans-serif !important;
      margin-left: 0.3125rem; }
  .content-card__avatar {
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid white;
    height: 100%;
    width: 100%;
    z-index: 1;
    margin-bottom: 0;
    position: relative; }
    .content-card__avatar-wrapper {
      margin-top: -1.875rem;
      height: 3.75rem;
      width: 3.75rem; }
  .content-card__author {
    font-size: 0.9rem;
    margin-bottom: 0;
    width: calc(50% - 40px);
    padding-left: 0.3125rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    font-family: 'Oswald';
    text-align: left; }
  .content-card .opac {
    opacity: 0;
    /* display: none; */ }
  .content-card__state {
    position: absolute;
    right: 0.625rem;
    top: -0.625rem;
    background-color: #81d4fb;
    border-radius: 50%;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
    text-align: center;
    padding: 0;
    line-height: 0; }
    .content-card__state svg {
      height: 65%;
      width: auto;
      fill: white;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0; }
    .content-card__state:hover {
      background-color: #00affe; }
    .content-card__state--remove-btn {
      right: unset;
      left: 0.625rem;
      background-color: #d6696c; }
      .content-card__state--remove-btn svg {
        fill: white; }
      .content-card__state--remove-btn:hover {
        background-color: #d34c50; }
  .content-card__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem; }
    .content-card__content--counter {
      display: flex; }
      .content-card__content--counter .content-card__title {
        font-family: 'Roboto-Regular', sans-serif;
        flex: 1 1 0; }
      .content-card__content--counter .content-card__counter {
        display: flex;
        font-family: 'Roboto', sans-serif !important;
        font-size: 0.9375rem;
        color: #6f6f6f; }
        .content-card__content--counter .content-card__counter svg {
          display: inline-block;
          width: 0.875rem;
          margin-right: 0.1875rem;
          fill: #6f6f6f; }
  .content-card__title {
    text-align: center;
    font-family: 'Roboto-Regular' !important;
    font-size: 1rem;
    margin: 0;
    line-height: 1.25;
    overflow: hidden;
    color: #6f6f6f;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis; }
  .content-card__footer {
    margin-top: 0.625rem;
    padding-top: 0.625rem;
    height: 3.125rem;
    position: relative; }
    .content-card__footer:before {
      content: '';
      display: block;
      width: 90%;
      margin: 0 auto;
      border: 0.0625rem dashed #dedede !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
  .content-card__partner-logo {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%; }
    .content-card__partner-logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; }
  .content-card__overlayer {
    position: absolute;
    top: 11.5625rem;
    text-align: center;
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between; }

@media screen and (min-width: 576px) {
  .content-card {
    max-width: 15.9375rem;
    min-width: 15.625rem; } }

@media screen and (min-width: 991px) {
  .content-card {
    transition: all .3s ease; }
    .content-card__visualizations {
      width: calc(50% - 40px);
      margin: 0; }
    .content-card__overlayer {
      display: block; }
    .content-card:hover {
      box-shadow: 0 2px 0.75rem rgba(0, 0, 0, 0.6); } }

@media screen and (min-width: 1300px) {
  .content-card {
    flex-basis: calc(33% - 30px); } }

@media screen and (min-width: 1920px) {
  .content-card {
    flex-basis: calc(25% - 30px); } }

@media screen and (max-width: 420px) {
  .content-card--selection {
    flex-basis: calc(100% - 50px) !important;
    min-width: auto !important; }
  .content-card.homeCardView {
    min-width: 100% !important; }
  .content-card__title {
    margin-top: 10px;
    font-size: 16px !important; }
  .content-card__textSelection {
    text-align: center; } }

@media screen and (max-width: 991px) {
  .location-search {
    background-color: #f4f4f4;
    min-height: calc(100vh - 100px);
    min-height: calc((var(--vh, 1vh) * 100) - 100px); } }

@media screen and (min-width: 992px) {
  .location-search__wrapper {
    display: flex;
    position: relative;
    min-height: calc(100vh - 80px);
    height: calc((var(--vh, 1vh) * 100) - 80px); }
  .location-search .results-card-list {
    justify-content: center;
    margin-right: 10px;
    height: auto;
    align-content: flex-start;
    align-items: flex-start; } }

.search-map {
  margin: 0 -0.9375rem 0 -0.9375rem;
  position: relative;
  height: 100%;
  flex-grow: 1; }
  .search-map__button {
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    margin: 0 auto;
    width: 6.25rem; }
  .search-map .simple-map {
    height: calc(100vh - 225px);
    height: calc((var(--vh, 1vh) * 100) - 225px); }
  .search-map .custom-checkbox {
    margin-bottom: 0;
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    z-index: 1;
    padding: 0 0.1875rem 0 2.1875rem;
    background-color: rgba(255, 255, 255, 0.66);
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 0.9375rem;
    border-radius: 0.1875rem;
    line-height: 0.875rem;
    text-align: center; }
    .search-map .custom-checkbox i,
    .search-map .custom-checkbox i:after {
      width: 1.625rem;
      height: 1.625rem; }
    .search-map .custom-checkbox i {
      left: 0.0625rem; }

.whatever-alex-wants {
  background: rgba(255, 255, 255, 0.5);
  padding-top: 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1; }

.spinner-border {
  border-color: #00affe; }

@media screen and (min-width: 992px) {
  .search-map {
    width: 25rem;
    flex-basis: 25rem;
    flex-shrink: 0;
    z-index: 1;
    margin: 0 -0.9375rem 0 0;
    min-width: 21.875rem; }
    .search-map .custom-checkbox {
      margin-bottom: 0;
      position: absolute;
      top: 0.3125rem;
      left: 0.3125rem;
      z-index: 1;
      padding: 0 0.1875rem 0 2.1875rem;
      background-color: rgba(255, 255, 255, 0.66);
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: 1.125rem;
      border-radius: 0.1875rem;
      line-height: 1rem;
      text-align: center; }
      .search-map .custom-checkbox i,
      .search-map .custom-checkbox i:after {
        width: 1.875rem;
        height: 1.875rem; }
      .search-map .custom-checkbox i {
        left: 0.0625rem; }
    .search-map .simple-map {
      height: 100%; } }

@media screen and (min-width: 1200px) {
  .search-map {
    width: 31.25rem;
    flex-basis: 31.25rem;
    flex-shrink: 0; } }

.tour-edition-nav {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 0.1875rem rgba(44, 44, 44, 0.1);
  z-index: 1000; }
  .tour-edition-nav__button {
    display: block;
    width: 100%;
    text-align: center;
    height: 3.125rem;
    border: none;
    padding: 0;
    background-color: transparent; }
    .tour-edition-nav__button svg {
      fill: #2c2c2c;
      height: 75%;
      width: auto; }
    .tour-edition-nav__button:hover svg, .tour-edition-nav__button--active svg {
      fill: #00affe; }

.content-tour-edition-nav-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.tour-edition-nav-desktop {
  position: fixed;
  left: 0;
  padding: 5px 0;
  background-color: white;
  width: 45px;
  height: auto;
  z-index: 1000;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .tour-edition-nav-desktop__button {
    display: block;
    width: 100%;
    text-align: center;
    height: 2.8125rem;
    border: none;
    padding: 0;
    background-color: transparent; }
    .tour-edition-nav-desktop__button svg {
      fill: #2c2c2c;
      height: 75%;
      width: auto; }

.tooltipNavEditionDesktop.__react_component_tooltip.type-dark {
  font-size: 15px;
  background: white !important;
  color: black; }

.tooltipNavEditionDesktop.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: white; }

.poi-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.9375rem 0 -0.9375rem;
  padding: 0.3125rem 0.3125rem 3.125rem 0.3125rem; }
  .poi-gallery h6 {
    color: white; }
  .poi-gallery__poi {
    padding: 0.3125rem;
    flex: 0 0 33.33333%;
    height: calc(33vw - 1.81px);
    border: none;
    background-color: transparent; }
    .poi-gallery__poi-image, .poi-gallery__poi-image-wrapper {
      height: 100%;
      width: 100%; }
    .poi-gallery__poi-image {
      object-fit: cover; }
      .poi-gallery__poi-image-wrapper {
        margin: 0; }
        .poi-gallery__poi-image-wrapper--red {
          border: 0.1875rem solid #d34c50; }

@media screen and (min-width: 768px) {
  .poi-gallery__poi {
    flex: 0 0 25%;
    height: calc(25vw - 6.24px); } }

.simple-poi-list {
  padding: 0.625rem 0.3125rem 3.125rem 0.3125rem; }
  .simple-poi-list__item {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    background-color: white;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.3);
    border-radius: 0.3125rem;
    padding: 0.46875rem 0.3125rem;
    flex-wrap: nowrap;
    align-items: center; }
    .simple-poi-list__item + .simple-poi-list__item {
      margin-top: 0.46875rem; }
    .simple-poi-list__item--added {
      background-color: #2c2c2c; }
      .simple-poi-list__item--added .simple-poi-list__poi-title, .simple-poi-list__item--added .simple-poi-list__poi-author, .simple-poi-list__item--added .simple-poi-list__poi-category {
        color: white; }
        .simple-poi-list__item--added .simple-poi-list__poi-title svg, .simple-poi-list__item--added .simple-poi-list__poi-author svg, .simple-poi-list__item--added .simple-poi-list__poi-category svg {
          fill: white; }
      .simple-poi-list__item--added .simple-poi-list__add-btn {
        cursor: inherit;
        background-color: white; }
        .simple-poi-list__item--added .simple-poi-list__add-btn svg {
          fill: #00affe; }
    .simple-poi-list__item--red {
      border: 0.1875rem solid #d34c50; }
  .simple-poi-list__poi {
    background-color: transparent;
    border: none;
    height: 100%; }
    .simple-poi-list__poi .imagePreview {
      height: 3.125rem;
      width: 4.375rem;
      margin-bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: white; }
    .simple-poi-list__poi-image {
      height: 3.125rem;
      width: 4.375rem;
      margin-bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: white; }
    .simple-poi-list__poi-title, .simple-poi-list__poi-author, .simple-poi-list__poi-category {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      margin: 0;
      max-width: calc(100vw - 160px); }
    .simple-poi-list__poi-title {
      font-family: 'Roboto-bold';
      font-weight: 500;
      font-size: 1.25rem; }
    .simple-poi-list__poi-author, .simple-poi-list__poi-category {
      font-family: 'Oswald';
      text-transform: uppercase;
      color: #303030;
      font-size: 0.9375rem;
      line-height: 1.5rem; }
      .simple-poi-list__poi-author svg, .simple-poi-list__poi-category svg {
        height: 1.375rem;
        width: auto;
        display: inline-block;
        margin-right: 0.3125rem; }
  .simple-poi-list__add-btn {
    background-color: #00affe;
    border-radius: 50%;
    border: none;
    padding: 0.3125rem;
    line-height: 1; }
    .simple-poi-list__add-btn svg {
      fill: white;
      width: 1.25rem;
      height: 1.25rem; }

.autosuggest-wrapper__input {
  position: relative;
  margin-top: 0.9375rem; }
  .autosuggest-wrapper__input-icon {
    fill: red;
    position: absolute;
    top: 0.75rem;
    left: 0.625rem;
    width: 1.5rem; }
    .autosuggest-wrapper__input-icon svg path {
      fill: red; }

.react-autosuggest {
  background-color: #00affe;
  color: white; }
  .react-autosuggest__container {
    display: inline-block;
    width: 100%; }
  .react-autosuggest__suggestions-container {
    position: relative; }
    .react-autosuggest__suggestions-container--open {
      background-color: white;
      border: 1px solid #00affe;
      margin-top: -1px; }
  .react-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: -0.0625rem;
    right: -0.0625rem;
    z-index: 100;
    border: 0.0625rem solid #2c2c2c; }
  .react-autosuggest__suggestion {
    background-color: #dedede;
    color: #303030;
    cursor: pointer; }
    .react-autosuggest__suggestion--highlighted {
      background-color: #00affe;
      color: white; }

.suggestion-list-item {
  padding: 0.625rem; }

.tour-content-modal {
  margin-top: 3.125rem;
  padding: 2.8125rem 0.9375rem 0.9375rem 0.9375rem;
  width: 100%;
  background-color: white;
  min-height: calc(100vh - 100px);
  min-height: calc((var(--vh, 1vh) * 100) - 100px);
  max-height: calc(100vh - 100px);
  max-height: calc((var(--vh, 1vh) * 100) - 100px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .tour-content-modal::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .tour-content-modal::-webkit-scrollbar-thumb {
    background: #00affe; }
  .tour-content-modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .tour-content-modal__tuto-icon {
    padding: 0 0.3125rem 0 0.9375rem; }
    .tour-content-modal__tuto-icon svg {
      width: auto;
      height: 3.125rem; }
  .tour-content-modal__tuto-title {
    font-family: 'Oswald';
    text-transform: uppercase;
    font-size: 1.0625rem;
    margin-bottom: 0.3125rem; }
  .tour-content-modal__tuto-text {
    line-height: 1.1;
    font-size: 0.75rem;
    margin-bottom: 0; }
  .tour-content-modal__day-list {
    margin-top: -2.5rem;
    list-style: none;
    padding-left: 0.3125rem; }
  .tour-content-modal__day-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0.1875rem; }
  .tour-content-modal__day-btn {
    background-color: #2c2c2c;
    color: white;
    font-family: 'Roboto-bold';
    border: none;
    position: relative;
    padding: 0;
    width: 2.1875rem;
    height: 2.1875rem;
    line-height: 2.1875rem; }
    .tour-content-modal__day-btn:disabled {
      opacity: 0.8;
      cursor: not-allowed; }
    .tour-content-modal__day-btn--active {
      height: 2.8125rem;
      width: 2.8125rem;
      font-size: 1.75rem;
      padding: 0;
      font-weight: bold;
      line-height: 1;
      padding: 0.9375rem 0 0.0625rem 0;
      background-color: #00affe; }
    .tour-content-modal__day-btn--circle svg {
      fill: white;
      height: 40%;
      width: 40%; }
    .tour-content-modal__day-btn .promise-button__bounce {
      width: 0.625rem;
      height: 0.625rem; }
  .tour-content-modal__day-header {
    display: block;
    position: absolute;
    text-align: left;
    padding: 0.3125rem 0.625rem 0 0.3125rem;
    top: 0;
    left: 0;
    right: 0;
    font-size: 0.75rem;
    line-height: 1; }
  .tour-content-modal__content-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    width: 100%;
    padding: 0.15625rem; }
    .tour-content-modal__content-list-item {
      flex-basis: calc(50vw - 10px);
      height: calc(50vw - 10px);
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0.15625rem;
      margin-bottom: 0.125rem;
      list-style: none;
      z-index: 5000; }
      .tour-content-modal__content-list-item--green .tour-content-modal__content-list-item-btn {
        border: 0.1875rem dashed #21B14F;
        background-color: rgba(33, 177, 79, 0.3); }
      .tour-content-modal__content-list-item--blue .tour-content-modal__content-list-item-btn {
        border: 0.1875rem dashed #00affe;
        background-color: rgba(0, 175, 254, 0.3); }
      .tour-content-modal__content-list-item > div {
        height: 100%; }
      .tour-content-modal__content-list-item-btn, .tour-content-modal__content-list-item-wrapper, .tour-content-modal__content-list-item-img {
        width: 100%;
        height: 100%;
        margin: 0; }
      .tour-content-modal__content-list-item-img {
        object-fit: contain;
        padding: 20%; }
        .tour-content-modal__content-list-item-img--cover {
          padding: 0.3125rem;
          object-fit: cover; }
      .tour-content-modal__content-list-item-btn {
        position: relative;
        background-color: transparent;
        padding: 0;
        border: 0.0625rem dashed #6f6f6f; }
      .tour-content-modal__content-list-item-balloon {
        position: absolute;
        bottom: 0.3125rem;
        right: 0.3125rem;
        width: 1.875rem;
        height: 1.875rem;
        line-height: 1.875rem;
        text-align: center;
        border-radius: 50%;
        background-color: #00affe;
        color: white;
        font-family: 'Roboto-bold';
        font-weight: bold; }
  .tour-content-modal__add-btn {
    border: none;
    background-color: #00affe;
    width: 100%;
    height: 100%;
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    padding: 0; }
    .tour-content-modal__add-btn svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white; }
  .tour-content-modal__select-wrapper {
    position: absolute;
    height: auto !important;
    top: 15px; }
    .tour-content-modal__select-wrapper-menu {
      top: 90%; }
  .tour-content-modal__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5); }
    .tour-content-modal__no-visible-layer svg {
      width: 1.875rem;
      height: 1.875rem;
      fill: #2c2c2c;
      stroke: #2c2c2c; }
  .tour-content-modal__action-wrapper {
    width: 100%; }
  .tour-content-modal__action-wrapper:after {
    clear: both; }
  .tour-content-modal__remove, .tour-content-modal__show {
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-content-modal__remove svg, .tour-content-modal__show svg {
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-content-modal__remove {
    float: right; }
    .tour-content-modal__remove svg {
      fill: #d34c50; }
  .tour-content-modal__show {
    float: left; }
  .tour-content-modal__captions {
    justify-content: center;
    margin: 0; }
    .tour-content-modal__captions-item-text, .tour-content-modal__captions-item-icon {
      display: inline-block;
      vertical-align: middle; }
    .tour-content-modal__captions-item-icon {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      background-color: #00affe;
      margin-right: 0.625rem;
      text-align: center; }
      .tour-content-modal__captions-item-icon svg {
        width: 90%;
        height: auto;
        fill: white; }
    .tour-content-modal__captions-item-text {
      font-size: 1rem;
      font-family: 'Roboto-Regular'; }
    .tour-content-modal__captions-item--blue:before, .tour-content-modal__captions-item--green:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 3.125rem;
      height: 0.375rem;
      margin-right: 0.625rem; }
    .tour-content-modal__captions-item--blue:before {
      background-color: #00affe; }
    .tour-content-modal__captions-item--green:before {
      background-color: #21B14F; }

@media screen and (min-width: 375px) {
  .tour-content-modal__day-list {
    padding-left: 0.625rem; }
  .tour-content-modal__day-btn {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem; }
    .tour-content-modal__day-btn--active {
      height: 3.125rem;
      width: 3.125rem;
      font-size: 1.875rem;
      line-height: 1;
      padding: 0.9375rem 0 0.0625rem 0; }
  .tour-content-modal__content-list-item {
    flex-basis: calc(33vw - 7.5px);
    height: calc(33vw - 7.5px);
    width: calc(33vw - 7.5px); } }

@media screen and (min-width: 576px) {
  .tour-content-modal__day-list {
    padding-left: 1.875rem; }
  .tour-content-modal__day-btn {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
    font-size: 1.5rem; }
    .tour-content-modal__day-btn--active {
      height: 4.6875rem;
      width: 4.6875rem;
      font-size: 3.4375rem;
      line-height: 1; }
  .tour-content-modal__day-header {
    font-size: 1rem; }
  .tour-content-modal__content-list-item {
    flex-basis: calc(25vw - 7.5px);
    height: calc(25vw - 7.5px); } }

@media screen and (min-width: 768px) {
  .tour-content-modal__content-list-item {
    flex-basis: calc(20vw - 7px);
    height: calc(20vw - 7px); } }

@media screen and (min-width: 992px) {
  .tour-content-modal {
    width: 56.25rem;
    margin: 6vh auto 0 auto;
    margin-top: calc(var(--vh, 1vh) * 6);
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    padding: 4.375rem 0.9375rem 0 0.9375rem;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .tour-content-modal.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .tour-content-modal__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(44, 44, 44, 0.9);
      z-index: 1010; }
    .tour-content-modal__header {
      align-items: center;
      text-align: center;
      z-index: 1;
      border-bottom: 0.0625rem solid #2c2c2c;
      text-align: right;
      margin: 0 0.9375rem;
      padding: 0.9375rem 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
      .tour-content-modal__header-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.375rem;
        margin: 0 0.625rem 0 0; }
      .tour-content-modal__header-btn {
        padding: 0;
        border: none;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        line-height: 1;
        background-color: #2c2c2c;
        border: 0.125rem solid #00affe;
        vertical-align: middle; }
        .tour-content-modal__header-btn svg {
          height: 60%;
          width: 60%;
          fill: white; }
    .tour-content-modal__tuto-text {
      line-height: 1.2;
      font-size: 1rem; }
    .tour-content-modal__content {
      max-height: 100%;
      overflow-y: auto;
      padding: 0.9375rem 0 0.625rem 0;
      scrollbar-width: thin;
      scrollbar-color: #2c2c2c;
      scrollbar-track-color: #00affe;
      -webkit-overflow-scrolling: touch; }
      .tour-content-modal__content::-webkit-scrollbar {
        background-color: #2c2c2c;
        width: 0.3125rem; }
      .tour-content-modal__content::-webkit-scrollbar-thumb {
        background: #00affe; }
    .tour-content-modal__day-list {
      margin: 0 auto;
      padding-left: 1.25rem; }
    .tour-content-modal__day-item {
      margin: 0.25rem 0.5rem 0.25rem 0; }
    .tour-content-modal__day-btn {
      height: 2.5rem;
      width: 5rem;
      background-color: #fff;
      color: #2c2c2c;
      border: 1px solid #2c2c2c;
      line-height: 2.5rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.25rem;
      border-radius: 0.46875rem;
      margin-top: 0.3125rem; }
      .tour-content-modal__day-btn--active {
        padding: 0;
        width: 10.9375rem;
        background-color: #2c2c2c;
        color: white; }
      .tour-content-modal__day-btn--circle {
        width: 2.5rem;
        border-radius: 50%;
        background-color: #2c2c2c;
        line-height: 1; }
        .tour-content-modal__day-btn--circle svg {
          height: 100%;
          width: 50%; }
    .tour-content-modal__day-header {
      display: inline-block;
      text-transform: uppercase;
      font-size: 1.25rem;
      position: unset;
      padding: 0;
      margin-right: 0.3125rem; }
    .tour-content-modal__content-list {
      padding: 0 3.125rem;
      overflow-y: unset; }
      .tour-content-modal__content-list-item {
        z-index: 5000;
        padding-top: 3.125rem;
        flex-basis: 9.5625rem;
        height: 12.6875rem;
        position: relative;
        margin-bottom: 0.15625rem; }
        .tour-content-modal__content-list-item-wrapper, .tour-content-modal__content-list-item-img {
          height: 100%; }
        .tour-content-modal__content-list-item--hid {
          opacity: 0.6; }
          .tour-content-modal__content-list-item--hid .tour-content-modal__show:before {
            content: '';
            display: block;
            width: 1.875rem;
            border: 0.0625rem solid #d34c50;
            transform: rotate(-45deg);
            position: absolute;
            top: 0.875rem;
            left: -0.125rem; }
          .tour-content-modal__content-list-item--hid .tour-content-modal__show svg {
            fill: #d34c50; }
    .tour-content-modal__remove, .tour-content-modal__show {
      position: absolute;
      top: 1.25rem;
      background-color: transparent;
      border: none;
      padding: 0; }
      .tour-content-modal__remove svg, .tour-content-modal__show svg {
        width: 1.5625rem;
        height: 1.5625rem; }
    .tour-content-modal__remove {
      right: 0; }
      .tour-content-modal__remove svg {
        fill: #d34c50; }
    .tour-content-modal__show {
      left: 0; }
    .tour-content-modal__dropdown-menu {
      position: absolute;
      top: 98%; }
    .tour-content-modal__captions {
      justify-content: left;
      margin-top: 0.9375rem; }
      .tour-content-modal__captions-item {
        margin-bottom: 0; }
        .tour-content-modal__captions-item-text {
          font-size: 1.125rem; } }

@media screen and (min-width: 1200px) {
  .tour-content-modal {
    width: 1000px; }
    .tour-content-modal__content-list-item {
      flex-basis: 10.8125rem;
      height: 13.9375rem; } }

.tour-config-modal {
  margin-top: 3.125rem;
  padding: 0;
  width: 100%;
  background-color: white;
  min-height: calc(100vh - 100px);
  min-height: calc((var(--vh, 1vh) * 100) - 100px);
  max-height: calc(100vh - 100px);
  max-height: calc((var(--vh, 1vh) * 100) - 100px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .tour-config-modal::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .tour-config-modal::-webkit-scrollbar-thumb {
    background: #00affe; }
  .tour-config-modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000 !important; }
  .tour-config-modal__content {
    padding-top: 0.9375rem; }
  .tour-config-modal .editCampaignButton {
    height: 22px;
    width: 22px;
    padding: 0;
    background-color: #00affe;
    border-radius: 50%;
    border: none;
    line-height: 0;
    margin-left: 5px; }
    .tour-config-modal .editCampaignButton svg {
      width: 12px;
      fill: white; }
  .tour-config-modal .externalCampaign {
    color: #212529;
    font-weight: 400;
    font-size: 16px; }
  .tour-config-modal .division2 {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
    margin: 10px; }
  .tour-config-modal .cursor {
    cursor: pointer; }
  .tour-config-modal .checkIcon {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #00affe;
    display: inline-block;
    border: none;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 1;
    margin-right: 5px; }
    .tour-config-modal .checkIcon svg {
      width: 60%;
      height: 60%;
      fill: white; }
  .tour-config-modal .closeIcon {
    height: 2.5rem;
    width: 2.5rem;
    background-color: black;
    display: inline-block;
    border: none;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 1; }
    .tour-config-modal .closeIcon svg {
      width: 60%;
      height: 60%;
      fill: white; }
  .tour-config-modal .dropzone {
    border: none;
    display: flex;
    align-items: center; }
  .tour-config-modal .logotipo {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
  .tour-config-modal .cuadrado {
    width: 250px;
    height: 230px;
    border: 1px solid black; }
    .tour-config-modal .cuadrado .dropzone {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .tour-config-modal .cuadrado .poi-images-box__dropzone {
      min-width: 150px;
      min-height: 175px; }
    .tour-config-modal .cuadrado .fine-uploader__upload-input {
      display: block !important;
      width: 50px; }
    .tour-config-modal .cuadrado img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .tour-config-modal .rectangulo {
    width: 250px;
    height: 400px;
    border: 1px solid black; }
    .tour-config-modal .rectangulo .dropzone {
      border: none;
      display: block;
      align-items: center; }
    .tour-config-modal .rectangulo .poi-images-box__dropzone {
      min-width: 200px;
      min-height: 175px; }
    .tour-config-modal .rectangulo .fine-uploader__upload-input {
      display: block !important;
      width: 50px; }
  .tour-config-modal .poi-images-box__dropzone {
    min-height: 10rem;
    min-width: 160px; }
  .tour-config-modal .fine-uploader__upload-input {
    width: 50px; }
  .tour-config-modal .textBlue {
    color: #00affe; }
  .tour-config-modal .left__category-item {
    list-style: none;
    background-color: white; }
  .tour-config-modal .fixedObject {
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: white;
    height: 60px;
    padding-right: 10px; }

@media screen and (min-width: 992px) {
  .tour-config-modal {
    width: 50vw;
    transition-duration: 0.5s;
    z-index: 1000;
    transform: translate(100vh, 0);
    transform: translate(calc(var(--vh, 1vh) * 100), 0);
    margin: 0 0 0 auto;
    padding: 4.375rem 0 0 0;
    height: 100vh;
    max-height: unset;
    height: calc((var(--vh, 1vh) * 100));
    position: relative;
    overflow-y: unset; }
    .tour-config-modal.ReactModal__Content--after-open {
      transform: translate(0, 0); }
    .tour-config-modal__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(44, 44, 44, 0.9);
      z-index: 1010 !important; }
    .tour-config-modal__header {
      margin: 0 0.9375rem;
      padding: 0.9375rem 0;
      height: auto;
      align-items: center;
      text-align: right;
      border-bottom: 0.0625rem solid #2c2c2c;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
      .tour-config-modal__header-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.375rem;
        margin: 0 0.625rem 0 0; }
      .tour-config-modal__header-actions {
        padding: 0; }
      .tour-config-modal__header-btn {
        padding: 0;
        border: none;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        line-height: 1;
        background-color: #2c2c2c;
        vertical-align: middle; }
        .tour-config-modal__header-btn svg {
          height: 60%;
          width: 60%;
          fill: white; }
        .tour-config-modal__header-btn--blue {
          background-color: #00affe; }
        .tour-config-modal__header-btn + .tour-config-modal__header-btn {
          margin-left: 0.3125rem; }
        .tour-config-modal__header-btn .promise-button__bounce {
          width: 0.625rem;
          height: 0.625rem; }
    .tour-config-modal__content {
      height: 100%;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #2c2c2c;
      scrollbar-track-color: #00affe;
      -webkit-overflow-scrolling: touch; }
      .tour-config-modal__content::-webkit-scrollbar {
        background-color: #2c2c2c;
        width: 0.3125rem; }
      .tour-config-modal__content::-webkit-scrollbar-thumb {
        background: #00affe; } }

@media screen and (min-width: 768px) {
  .profile-page__grey-bg--desktop {
    background-color: #dedede;
    position: relative; }
    .profile-page__grey-bg--desktop:after {
      content: "";
      background-color: inherit;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
  .profile-page__top--public {
    margin-top: 0; }
  .profile-page__top-traveler-wrapper {
    margin-top: -1.25rem; }
  .profile-page__social-list {
    text-align: left; }
  .profile-page__header {
    margin-bottom: -4.375rem;
    z-index: 0;
    height: auto;
    padding-top: 0;
    overflow: hidden;
    position: relative; }
    .profile-page__header svg {
      display: block;
      min-height: 12.5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1; }
  .profile-page__main-image {
    position: relative;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%; }
  .profile-page__user {
    margin-top: 0;
    border-right: 1px dashed #303030;
    text-align: right;
    min-height: 6.25rem; }
    .profile-page__user-name {
      position: relative;
      display: inline-block;
      padding-left: 3.125rem;
      line-height: 2.5rem;
      margin-top: 0;
      text-align: right;
      font-size: 1.75rem; }
    .profile-page__user-nickname {
      margin-top: 0;
      text-align: right;
      font-size: 1.25rem; }
    .profile-page__user-logo {
      position: relative; }
      .profile-page__user-logo svg {
        width: auto;
        height: 3.75rem;
        left: unset;
        right: 0; }
        .profile-page__user-logo svg .text {
          transform: none; }
  .profile-page__avatar {
    border: 0.1875rem solid black; }
    .profile-page__avatar-wrapper {
      top: -5.625rem; }
  .profile-page__angel-btn {
    margin-right: 0.625rem;
    left: -52px;
    bottom: 0;
    top: 0; }
    .profile-page__angel-btn svg {
      position: relative; }
  .profile-page__edit-btn {
    margin-right: 0.625rem;
    left: 0;
    bottom: 0;
    top: 0; }
    .profile-page__edit-btn svg {
      position: relative; }
  .profile-page__website {
    text-align: left;
    min-height: 1.625rem; }
    .profile-page__website--private {
      margin-left: 3.125rem; }
  .profile-page__description {
    font-family: 'Roboto', sans-serif;
    border: 0;
    padding-bottom: 0;
    text-align: center; }
  .profile-page__actions {
    margin: 0 0 0.9375rem 0; }
    .profile-page__actions + .profile-page__user .profile-page__user-name {
      margin-top: 0; }
  .profile-page__tabs-button svg {
    height: 6.25rem; }
  .profile-page__tabs-text {
    font-size: 1.1rem;
    text-transform: uppercase; } }

@media screen and (max-width: 992px) {
  .tour-config-modal {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background: white;
    overflow: auto;
    outline: none;
    padding: 10px 10px 20px 10px;
    width: 100%;
    height: 100%; }
    .tour-config-modal__contentModal {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #212529d4; }
    .tour-config-modal .navbarLeft {
      display: none !important;
      position: fixed;
      flex-direction: column;
      right: 60vw;
      background-color: transparent; }
      .tour-config-modal .navbarLeft__btn {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.4375rem;
        background-color: white;
        border: none;
        line-height: 0; }
        .tour-config-modal .navbarLeft__btn svg {
          width: 100%;
          height: auto;
          fill: black; }
        .tour-config-modal .navbarLeft__btn:hover, .tour-config-modal .navbarLeft__btn:active {
          background-color: #00affe; }
        .tour-config-modal .navbarLeft__btn--active {
          background-color: #00affe; }
          .tour-config-modal .navbarLeft__btn--active svg {
            fill: white; }
    .tour-config-modal .content .cursor {
      cursor: pointer; }
    .tour-config-modal .content .añadir_azul {
      margin-top: 10px; }
    .tour-config-modal .content .textBlue {
      color: #00affe; }
    .tour-config-modal .content .imageSponsor {
      width: 150px;
      height: 140px;
      object-fit: cover; }
    .tour-config-modal .content .division2 {
      background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
      padding-bottom: 10px;
      margin: 10px; }
    .tour-config-modal .content .division3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px; }
    .tour-config-modal .content .division4 {
      border-right: 0.0625rem dashed #6f6f6f; }
    .tour-config-modal .content .checkIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #00affe;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1;
      margin-right: 5px; }
      .tour-config-modal .content .checkIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .tour-config-modal .content .closeIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: black;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1; }
      .tour-config-modal .content .closeIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .tour-config-modal .content .bar {
      display: flex;
      padding: 5px;
      background-color: #E9EBEF; }
      .tour-config-modal .content .bar .barContent {
        position: fixed;
        flex-direction: column;
        left: 55.5rem;
        background-color: transparent; }
        .tour-config-modal .content .bar .barContent__btn {
          width: 80px;
          height: 80px;
          padding: 0.4375rem;
          background-color: white;
          border: none;
          line-height: 0; }
          .tour-config-modal .content .bar .barContent__btn svg {
            width: 60%;
            height: auto;
            fill: black; }
          .tour-config-modal .content .bar .barContent__btn:hover, .tour-config-modal .content .bar .barContent__btn:active {
            background-color: #00affe; }
          .tour-config-modal .content .bar .barContent__btn--active {
            background-color: #00affe; }
            .tour-config-modal .content .bar .barContent__btn--active svg {
              fill: white; }
            .tour-config-modal .content .bar .barContent__btn--active p {
              color: white; }
          .tour-config-modal .content .bar .barContent__btn p {
            margin: 20px 0px; }
    .tour-config-modal .content .left__category-item {
      list-style: none;
      background-color: white; }
      .tour-config-modal .content .left__category-item + .filters-modal__category-item {
        margin-top: 0.1875rem; }
      .tour-config-modal .content .left__category-item .custom-checkbox {
        font-size: 1rem;
        line-height: 1.5625rem;
        padding-left: 2.5rem; }
        .tour-config-modal .content .left__category-item .custom-checkbox i {
          border: 0.0625rem solid #2c2c2c;
          border-radius: 0; }
        .tour-config-modal .content .left__category-item .custom-checkbox i,
        .tour-config-modal .content .left__category-item .custom-checkbox i:after {
          width: 1.5625rem;
          height: 1.5625rem; }
    .tour-config-modal .content .left__label {
      text-align: center;
      font-family: 'Roboto-bold';
      font-weight: bold;
      line-height: 1.2;
      font-size: 1.125rem;
      margin-bottom: 0.625rem; }
    .tour-config-modal .content .left__text-editor {
      min-height: 9.375rem;
      min-width: 100%; }
      .tour-config-modal .content .left__text-editor .ql-toolbar {
        max-height: 2.8125rem; }
      .tour-config-modal .content .left__text-editor .ql-container {
        min-height: 6.25rem; }
    .tour-config-modal .content .left .dropzone {
      width: 80px;
      min-width: 80px;
      border: none;
      display: flex;
      align-items: center; }
    .tour-config-modal .content .left .poi-images-box__dropzone {
      min-height: 10rem;
      min-width: 160px;
      display: none; }
    .tour-config-modal .content .left .fine-uploader__upload-input {
      width: 50px; }
    .tour-config-modal .content .left .tour-content-video-form__supported-text {
      font-size: 13px !important; }
    .tour-config-modal .content .left .tour-content-video-form__supported-type svg {
      width: 1.5rem !important; }
    .tour-config-modal .content .divisorLateral {
      margin-top: 130px;
      height: 500px;
      width: 1px;
      border-left: 1px solid; }
    .tour-config-modal .content .right .logotipo {
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      .tour-config-modal .content .right .logotipo .cuadrado {
        width: 250px;
        height: 180px;
        border: 1px solid black; }
        .tour-config-modal .content .right .logotipo .cuadrado img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .tour-config-modal .content .right .logotipo .cuadrado .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .tour-config-modal .content .right .logotipo .cuadrado .poi-images-box__dropzone {
          min-width: 215px;
          min-height: 175px;
          display: none; }
        .tour-config-modal .content .right .logotipo .cuadrado .fine-uploader__upload-input {
          display: block !important;
          width: 50px; }
      .tour-config-modal .content .right .logotipo .rectangulo {
        border: 1px solid black; }
        .tour-config-modal .content .right .logotipo .rectangulo .dropzone {
          border: none;
          display: flex;
          align-items: center;
          min-width: 100px; }
        .tour-config-modal .content .right .logotipo .rectangulo .poi-images-box__dropzone {
          min-width: 118px;
          min-height: 175px;
          display: none; }
        .tour-config-modal .content .right .logotipo .rectangulo .fine-uploader__upload-input {
          display: block !important;
          width: 50px; } }

@media screen and (max-width: 768px) {
  .tour-config-modal {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background: white;
    overflow: auto;
    outline: none;
    padding: 10px 10px 20px 10px;
    width: 100%;
    height: 100%; }
    .tour-config-modal__contentModal {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #212529d4; }
    .tour-config-modal .navbarLeft {
      display: none !important;
      position: fixed;
      flex-direction: column;
      right: 60vw;
      background-color: transparent; }
      .tour-config-modal .navbarLeft__btn {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.4375rem;
        background-color: white;
        border: none;
        line-height: 0; }
        .tour-config-modal .navbarLeft__btn svg {
          width: 100%;
          height: auto;
          fill: black; }
        .tour-config-modal .navbarLeft__btn:hover, .tour-config-modal .navbarLeft__btn:active {
          background-color: #00affe; }
        .tour-config-modal .navbarLeft__btn--active {
          background-color: #00affe; }
          .tour-config-modal .navbarLeft__btn--active svg {
            fill: white; }
    .tour-config-modal .content .contentCenter {
      flex-direction: column-reverse; }
      .tour-config-modal .content .contentCenter .campaign {
        max-width: 100%; }
        .tour-config-modal .content .contentCenter .campaign h4 {
          margin-left: 0px; }
        .tour-config-modal .content .contentCenter .campaign h6 {
          padding-left: 0px !important; }
      .tour-config-modal .content .contentCenter .sponsored-destinations {
        max-width: 100%;
        margin-top: 15px; }
    .tour-config-modal .content .addCampaign {
      flex-direction: column; }
      .tour-config-modal .content .addCampaign .left {
        max-width: 100%; }
      .tour-config-modal .content .addCampaign .divisorLateral {
        height: 1px;
        width: 100%;
        border-bottom: 1px solid; }
      .tour-config-modal .content .addCampaign .right {
        max-width: 100%; }
    .tour-config-modal .content .seeCampaign {
      flex-direction: column; }
      .tour-config-modal .content .seeCampaign .left {
        max-width: 100%; }
      .tour-config-modal .content .seeCampaign .divisorLateral {
        height: 1px;
        width: 100%;
        border-bottom: 1px solid; }
      .tour-config-modal .content .seeCampaign .right {
        max-width: 100%; }
    .tour-config-modal .content .cursor {
      cursor: pointer; }
    .tour-config-modal .content .añadir_azul {
      margin-top: 10px; }
    .tour-config-modal .content .textBlue {
      color: #00affe; }
    .tour-config-modal .content .imageSponsor {
      width: 150px;
      height: 140px;
      object-fit: cover; }
    .tour-config-modal .content .division2 {
      background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
      padding-bottom: 10px;
      margin: 10px; }
    .tour-config-modal .content .division3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px; }
    .tour-config-modal .content .division4 {
      border-right: 0.0625rem dashed #6f6f6f; }
    .tour-config-modal .content .checkIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #00affe;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1;
      margin-right: 5px; }
      .tour-config-modal .content .checkIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .tour-config-modal .content .closeIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: black;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1; }
      .tour-config-modal .content .closeIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .tour-config-modal .content .bar {
      display: flex;
      padding: 5px;
      background-color: #E9EBEF; }
      .tour-config-modal .content .bar .barContent {
        position: fixed;
        flex-direction: column;
        left: 55.5rem;
        background-color: transparent; }
        .tour-config-modal .content .bar .barContent__btn {
          width: 80px;
          height: 80px;
          padding: 0.4375rem;
          background-color: white;
          border: none;
          line-height: 0; }
          .tour-config-modal .content .bar .barContent__btn svg {
            width: 60%;
            height: auto;
            fill: black; }
          .tour-config-modal .content .bar .barContent__btn:hover, .tour-config-modal .content .bar .barContent__btn:active {
            background-color: #00affe; }
          .tour-config-modal .content .bar .barContent__btn--active {
            background-color: #00affe; }
            .tour-config-modal .content .bar .barContent__btn--active svg {
              fill: white; }
            .tour-config-modal .content .bar .barContent__btn--active p {
              color: white; }
          .tour-config-modal .content .bar .barContent__btn p {
            margin: 20px 0px; }
    .tour-config-modal .content .left__category-item {
      list-style: none;
      background-color: white; }
      .tour-config-modal .content .left__category-item + .filters-modal__category-item {
        margin-top: 0.1875rem; }
      .tour-config-modal .content .left__category-item .custom-checkbox {
        font-size: 1rem;
        line-height: 1.5625rem;
        padding-left: 2.5rem; }
        .tour-config-modal .content .left__category-item .custom-checkbox i {
          border: 0.0625rem solid #2c2c2c;
          border-radius: 0; }
        .tour-config-modal .content .left__category-item .custom-checkbox i,
        .tour-config-modal .content .left__category-item .custom-checkbox i:after {
          width: 1.5625rem;
          height: 1.5625rem; }
    .tour-config-modal .content .left__label {
      text-align: center;
      font-family: 'Roboto-bold';
      font-weight: bold;
      line-height: 1.2;
      font-size: 1.125rem;
      margin-bottom: 0.625rem; }
    .tour-config-modal .content .left__text-editor {
      min-height: 9.375rem;
      min-width: 100%; }
      .tour-config-modal .content .left__text-editor .ql-toolbar {
        max-height: 2.8125rem; }
      .tour-config-modal .content .left__text-editor .ql-container {
        min-height: 6.25rem; }
    .tour-config-modal .content .left .dropzone {
      border: none;
      display: flex;
      align-items: center; }
    .tour-config-modal .content .left .poi-images-box__dropzone {
      min-height: 10rem;
      min-width: 160px;
      display: none; }
    .tour-config-modal .content .left .fine-uploader__upload-input {
      width: 50px; }
    .tour-config-modal .content .left .tour-content-video-form__supported-text {
      font-size: 13px !important; }
    .tour-config-modal .content .left .tour-content-video-form__supported-type svg {
      width: 1.5rem !important; }
    .tour-config-modal .content .divisorLateral {
      margin-top: 130px;
      height: 500px;
      width: 1px;
      border-left: 1px solid; }
    .tour-config-modal .content .right .logotipo {
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      .tour-config-modal .content .right .logotipo .cuadrado {
        width: 250px;
        height: 180px;
        border: 1px solid black; }
        .tour-config-modal .content .right .logotipo .cuadrado img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .tour-config-modal .content .right .logotipo .cuadrado .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .tour-config-modal .content .right .logotipo .cuadrado .poi-images-box__dropzone {
          min-width: 215px;
          min-height: 175px;
          display: none; }
        .tour-config-modal .content .right .logotipo .cuadrado .fine-uploader__upload-input {
          display: block !important;
          width: 50px; }
      .tour-config-modal .content .right .logotipo .rectangulo {
        border: 1px solid black; }
        .tour-config-modal .content .right .logotipo .rectangulo .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .tour-config-modal .content .right .logotipo .rectangulo .poi-images-box__dropzone {
          min-width: 118px;
          min-height: 175px;
          display: none; }
        .tour-config-modal .content .right .logotipo .rectangulo .fine-uploader__upload-input {
          display: block !important;
          width: 50px; } }

.tour-config-main__label {
  margin-left: 0.625rem;
  font-family: 'Roboto-bold';
  font-size: 1.125rem;
  margin-bottom: 0.4375rem; }
  .tour-config-main__label-icon svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: #00affe;
    margin-right: 0.625rem; }
  .tour-config-main__label.custom-checkbox {
    display: inline-block; }
    .tour-config-main__label.custom-checkbox + .tour-config-modal__label {
      margin-left: 0.9375rem; }

.tour-config-main__images {
  display: flex; }
  .tour-config-main__images > * + * {
    margin-left: 0.9375rem; }

.tour-config-main .autosuggest-wrapper__input {
  margin-top: 0; }

.tour-config-main .thumbnails-wrapper__remove-btn {
  display: none; }

.tour-config-main .fine-uploader__files-wrapper {
  display: inline-block;
  padding: 0; }

.tour-config-main .fileInput .thumbnails-wrapper__preview {
  height: 4.6875rem;
  width: 9.0625rem; }

.tour-config-main .fileInput + .fileInput .thumbnails-wrapper__preview {
  width: 2.8125rem; }

.tour-config-main .thumbnails-wrapper__box {
  padding-bottom: 1.875rem;
  height: 6.5625rem; }

.tour-config-main .progressPercentageBarWrapper {
  height: 1.875rem;
  background-color: rgba(0, 175, 254, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.tour-config-main .progressPercentageBarProgress {
  display: block;
  height: 100%;
  background-color: #00affe;
  color: white;
  padding: 0 0.625rem;
  line-height: 1.875rem;
  font-weight: bold;
  font-family: 'Roboto-bold'; }

.tour-config-main__input-photo {
  display: inline-block;
  vertical-align: middle;
  height: 4.6875rem;
  background-color: rgba(129, 212, 251, 0.3);
  position: relative;
  margin-bottom: 0;
  font-family: 'Oswald';
  text-transform: uppercase;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .tour-config-main__input-photo--desktop {
    width: 9.0625rem; }
  .tour-config-main__input-photo--mobile {
    width: 3.125rem; }

.tour-config-main__input-msg {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center; }

.tour-config-main__input-icon {
  display: inline-block;
  vertical-align: middle;
  background-color: #00affe;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  margin-left: 0.1875rem; }
  .tour-config-main__input-icon svg {
    fill: white;
    width: auto;
    height: 55%; }

.tour-config-main__rich-text-editor {
  width: 100%; }
  .tour-config-main__rich-text-editor .ql-toolbar {
    max-height: 2.8125rem; }
  .tour-config-main__rich-text-editor .ql-editor {
    min-height: 5.125rem; }

.tour-config-main__state {
  color: white;
  font-size: 1rem;
  margin-bottom: 0; }
  .tour-config-main__state-btn {
    width: 6.125rem;
    margin-left: 0.625rem;
    padding: 0.3125rem 0.9375rem;
    border: none; }
  .tour-config-main__state-description {
    text-align: right;
    margin: 0;
    color: #2c2c2c;
    font-size: 1rem; }

.tour-config-main .custom-state-select {
  width: 6.125rem;
  position: absolute;
  right: 0.9375rem;
  z-index: 10; }
  .tour-config-main .custom-state-select__list {
    display: flex;
    flex-direction: column;
    height: 2.3125rem; }
    .tour-config-main .custom-state-select__list label {
      color: white;
      cursor: pointer;
      line-height: 2.3125rem;
      text-align: center;
      margin: 0; }
  .tour-config-main .custom-state-select input {
    position: absolute;
    visibility: hidden; }

.tour-config-main__state-btn--edition,
.tour-config-main .custom-state-select__list-item--edition {
  background-color: #00affe;
  color: white; }

.tour-config-main__state-btn--published,
.tour-config-main .custom-state-select__list-item--published {
  background-color: #fd7e14;
  color: white; }

.tour-config-main__state-btn--approved,
.tour-config-main .custom-state-select__list-item--approved {
  background-color: #28a745;
  color: white; }

.tour-config-main__state-btn--hidden,
.tour-config-main .custom-state-select__list-item--hidden {
  background-color: #6f6f6f;
  color: white; }

@media screen and (min-width: 992px) {
  .tour-config-main__input-photo {
    height: 7.5rem; }
    .tour-config-main__input-photo--desktop {
      width: 13.75rem; }
    .tour-config-main__input-photo--mobile {
      width: 5rem; }
  .tour-config-main .fileInput .thumbnails-wrapper__preview {
    width: 13.75rem;
    height: 7.5rem; }
  .tour-config-main .fileInput + .fileInput .thumbnails-wrapper__preview {
    width: 5rem; }
  .tour-config-main .thumbnails-wrapper__box {
    height: 9.375rem; } }

.tour-config-nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white; }
  .tour-config-nav-menu__btn {
    background: transparent;
    border: none;
    position: relative;
    flex-grow: 1;
    height: 5rem; }
    .tour-config-nav-menu__btn svg {
      fill: black;
      width: 2.5rem;
      height: 2.5rem; }
    .tour-config-nav-menu__btn--active svg {
      fill: #00affe; }
    .tour-config-nav-menu__btn:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .tour-config-nav-menu__btn + .tour-config-nav-menu__btn::before {
      content: '';
      display: inline-block;
      width: 0.0625rem;
      height: 3.125rem;
      border-right: 0.0625rem #2c2c2c dashed;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto; }

@media screen and (min-width: 992px) {
  .tour-config-nav-menu {
    position: fixed;
    flex-direction: column;
    left: -4rem;
    top: 0.625rem;
    background-color: transparent; }
    .tour-config-nav-menu__btn {
      width: 3.75rem;
      height: 3.75rem;
      padding: 0.4375rem;
      background-color: white; }
      .tour-config-nav-menu__btn svg {
        width: 3.125rem;
        height: 3.125rem; }
      .tour-config-nav-menu__btn--active {
        background-color: #00affe; }
        .tour-config-nav-menu__btn--active svg {
          fill: white; }
      .tour-config-nav-menu__btn + .tour-config-nav-menu__btn {
        margin-top: 0.1875rem; }
        .tour-config-nav-menu__btn + .tour-config-nav-menu__btn::before {
          display: none; }
    .tour-config-nav-menu svg {
      width: 100%;
      height: auto; } }

.tour-config-tips__list {
  list-style: none;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 0 -0.625rem; }
  .tour-config-tips__list-item {
    list-style: none;
    z-index: 1010;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin: 0.1875rem;
    position: relative;
    padding-top: 1.5625rem; }
  .tour-config-tips__list-btn {
    padding: 0;
    background-color: transparent;
    border: 0.0625rem solid #6f6f6f;
    position: relative;
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    cursor: grab !important; }
  .tour-config-tips__list-add {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    background-color: #00affe;
    border: none;
    margin-bottom: 1.25rem; }
    .tour-config-tips__list-add svg {
      width: 40%;
      height: 40%;
      fill: white; }
  .tour-config-tips__list-img {
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .tour-config-tips__list-img--icon {
      width: 70%;
      height: auto;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .tour-config-tips__list-remove, .tour-config-tips__list-show {
    position: absolute;
    top: 0;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-config-tips__list-remove svg, .tour-config-tips__list-show svg {
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-config-tips__list-show {
    left: 0.1875rem; }
  .tour-config-tips__list-remove {
    right: 0.1875rem; }
    .tour-config-tips__list-remove svg {
      fill: #d34c50; }

.tour-config-tips__no-visible-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: rgba(255, 255, 255, 0.5); }
  .tour-config-tips__no-visible-layer svg {
    width: 1.875rem;
    height: 1.875rem;
    fill: #2c2c2c;
    stroke: #2c2c2c; }

@media screen and (min-width: 576px) {
  .tour-config-tips__list-btn {
    width: calc(25vw - 10px);
    height: calc(25vw - 10px); }
  .tour-config-tips__list-add {
    width: calc(25vw - 10px);
    height: calc(25vw - 10px); } }

@media screen and (min-width: 768px) {
  .tour-config-tips__list-btn {
    width: calc(20vw - 10px);
    height: calc(20vw - 10px); }
  .tour-config-tips__list-add {
    width: calc(20vw - 10px);
    height: calc(20vw - 10px); } }

@media screen and (min-width: 992px) {
  .tour-config-tips__list {
    padding: 0 1.25rem; }
    .tour-config-tips__list-btn {
      width: 8.75rem;
      height: 8.75rem; }
    .tour-config-tips__list-item:last-of-type {
      border: none; }
    .tour-config-tips__list-add {
      width: 8.75rem;
      height: 8.75rem; } }

.tour-config-shop__list {
  list-style: none;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 0 -0.625rem; }
  .tour-config-shop__list-item {
    list-style: none;
    z-index: 1010;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin: 0.1875rem;
    position: relative;
    padding-top: 1.5625rem; }
  .tour-config-shop__list-btn {
    padding: 0;
    background-color: transparent;
    border: 0.0625rem solid #6f6f6f;
    position: relative;
    width: calc(33vw - 10px);
    height: calc(33vw - 10px); }
  .tour-config-shop__list-add {
    width: calc(33vw - 10px);
    height: calc(33vw - 10px);
    background-color: #00affe;
    border: none;
    margin-bottom: 1.25rem; }
    .tour-config-shop__list-add svg {
      width: 40%;
      height: 40%;
      fill: white; }
  .tour-config-shop__list-img {
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .tour-config-shop__list-img--icon {
      width: 70%;
      height: auto;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .tour-config-shop__list-remove, .tour-config-shop__list-show {
    position: absolute;
    top: 0;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-config-shop__list-remove svg, .tour-config-shop__list-show svg {
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-config-shop__list-show {
    left: 0.1875rem; }
  .tour-config-shop__list-remove {
    right: 0.1875rem; }
    .tour-config-shop__list-remove svg {
      fill: #d34c50; }

.tour-config-shop__no-visible-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: rgba(255, 255, 255, 0.5); }
  .tour-config-shop__no-visible-layer svg {
    width: 1.875rem;
    height: 1.875rem;
    fill: #2c2c2c;
    stroke: #2c2c2c; }

@media screen and (min-width: 576px) {
  .tour-config-shop__list-btn {
    width: calc(25vw - 10px);
    height: calc(25vw - 10px); }
  .tour-config-shop__list-add {
    width: calc(25vw - 10px);
    height: calc(25vw - 10px); } }

@media screen and (min-width: 768px) {
  .tour-config-shop__list-btn {
    width: calc(20vw - 10px);
    height: calc(20vw - 10px); }
  .tour-config-shop__list-add {
    width: calc(20vw - 10px);
    height: calc(20vw - 10px); } }

@media screen and (min-width: 992px) {
  .tour-config-shop__list {
    padding: 0 1.25rem; }
    .tour-config-shop__list-btn {
      width: 8.75rem;
      height: 8.75rem; }
    .tour-config-shop__list-item:last-of-type {
      border: none; }
    .tour-config-shop__list-add {
      width: 8.75rem;
      height: 8.75rem; } }

.tour-config-sponsor {
  /*   padding-bottom: 100px; */ }
  .tour-config-sponsor__label {
    font-family: 'Roboto-bold';
    font-size: 1.125rem;
    margin-bottom: 0.4375rem; }
    .tour-config-sponsor__label--min {
      min-width: 9.375rem; }
  .tour-config-sponsor__creation-date {
    margin-bottom: 0;
    font-size: 1.125rem;
    font-family: 'Roboto-bold'; }
  .tour-config-sponsor .react-datepicker__close-icon::after {
    display: inline-block;
    background-color: #d34c50;
    padding: 0;
    font-size: 1.75rem;
    line-height: 1.25rem;
    height: 1.25rem;
    width: 1.25rem; }
  .tour-config-sponsor .fine-uploader__files-wrapper {
    padding: 0;
    text-align: center; }
  .tour-config-sponsor__logo {
    margin: 0 auto 0.9375rem auto;
    width: 30vw;
    max-width: 12.5rem; }
    .tour-config-sponsor__logo-img {
      width: 100%;
      height: auto; }
  .tour-config-sponsor__photos-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .tour-config-sponsor__photos-item {
    height: 7.8125rem;
    width: 6.25rem;
    display: inline-block;
    margin: 0 0.3125rem 0.3125rem 0;
    padding-top: 1.5625rem;
    cursor: grab;
    z-index: 1010; }
    .tour-config-sponsor__photos-item .fileInput {
      padding: 0; }
    .tour-config-sponsor__photos-item .fine-uploader__files-wrapper {
      padding: 0; }
    .tour-config-sponsor__photos-item .fileInput,
    .tour-config-sponsor__photos-item .fine-uploader__file-input,
    .tour-config-sponsor__photos-item .fine-uploader__files-wrapper,
    .tour-config-sponsor__photos-item .responsive-img {
      height: 100%; }
    .tour-config-sponsor__photos-item .thumbnails-wrapper__box {
      display: none; }
    .tour-config-sponsor__photos-item:last-of-type {
      cursor: pointer; }
  .tour-config-sponsor__photos-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; }
  .tour-config-sponsor__photos-add {
    border: none;
    background-color: #00affe;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
    .tour-config-sponsor__photos-add:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .tour-config-sponsor__photos-add svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white; }
  .tour-config-sponsor__photos-remove {
    position: absolute;
    top: -1.5625rem;
    right: 0.1875rem;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-config-sponsor__photos-remove svg {
      fill: #d34c50;
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-config-sponsor .footer {
    background-color: white;
    display: flex;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    justify-content: center; }

@media screen and (max-width: 992px) {
  .tour-config-sponsor .footer {
    margin-bottom: 50px;
    border-bottom: 1px solid lightgrey; } }

@media screen and (max-width: 768px) {
  .tour-config-sponsor .footer .contentF {
    width: 100%;
    justify-content: center; }
    .tour-config-sponsor .footer .contentF button {
      width: auto; }
    .tour-config-sponsor .footer .contentF div {
      flex: auto;
      width: auto;
      max-width: max-content; } }

.filters-modal {
  padding: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  background-color: #dedede;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .filters-modal .LimiteEspacios {
    line-height: 1.5rem !important; }
  .filters-modal::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .filters-modal::-webkit-scrollbar-thumb {
      background: #00affe; }
  .filters-modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010; }
  .filters-modal__content {
    padding: 0.3125rem; }
  .filters-modal__category-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0.9375rem; }
  .filters-modal__category-item {
    background-color: white;
    padding-left: 0.625rem; }
    .filters-modal__category-item + .filters-modal__category-item {
      margin-top: 0.1875rem; }
    .filters-modal__category-item .custom-checkbox {
      font-size: 1rem;
      line-height: 2.5rem; }
      .filters-modal__category-item .custom-checkbox i {
        border: none; }
  .filters-modal .day-select {
    max-width: 12.5rem; }
  .filters-modal footer {
    display: flex;
    justify-content: center; }
    .filters-modal footer button {
      width: 150px; }

@media screen and (min-width: 992px) {
  .filters-modal {
    max-width: 40vw;
    max-height: 50.625rem;
    min-height: unset;
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: auto; }
    .filters-modal.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .filters-modal__overlay {
      background-color: rgba(44, 44, 44, 0.9); }
    .filters-modal__content {
      padding: 0 1.5625rem 1.25rem 1.5625rem; }
    .filters-modal__header {
      position: relative;
      padding-top: 3.125rem; }
    .filters-modal__close-btn {
      position: absolute;
      right: -0.625rem;
      top: 0.9375rem;
      padding: 0;
      background-color: #2c2c2c;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1; }
      .filters-modal__close-btn svg {
        height: 50%;
        width: 50%;
        fill: white; }
    .filters-modal__title {
      position: relative;
      padding-bottom: 1.25rem;
      font-weight: 500;
      font-family: 'Roboto-bold'; }
      .filters-modal__title:after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 0.0625rem;
        background-color: #2c2c2c; }
    .filters-modal__category-list {
      padding-left: 1.875rem; }
    .filters-modal__category-item + .filters-modal__category-item {
      margin-top: 0.3125rem; }
    .filters-modal__category-title {
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: 1.375rem;
      margin: 1.25rem 0 0.9375rem 0; }
    .filters-modal .custom-checkbox {
      font-size: 1rem;
      line-height: 1.5625rem;
      padding-left: 2.5rem; }
      .filters-modal .custom-checkbox i {
        border: 0.0625rem solid #2c2c2c;
        border-radius: 0; }
      .filters-modal .custom-checkbox i,
      .filters-modal .custom-checkbox i:after {
        width: 1.5625rem;
        height: 1.5625rem; }
    .filters-modal footer {
      display: flex;
      justify-content: center;
      /* position: fixed;
    bottom: 36px;
    background-color: white;
    width: 37vw;
    padding: 15px; */ } }

@media screen and (max-height: 860px) and (min-width: 992px) {
  .filters-modal footer {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 37%;
    bottom: 0px;
    background-color: white;
    height: 66px; }
    .filters-modal footer button {
      margin-bottom: 14px;
      margin-top: 14px; }
  .filters-modal__category-list {
    margin-bottom: 2.875rem; } }

@media screen and (max-width: 992px) and (max-height: 860px) {
  .filters-modal__category-list {
    margin-bottom: 50px; }
  .filters-modal footer {
    align-items: center;
    background-color: white;
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    right: 0px;
    left: 0px; }
    .filters-modal footer .btn {
      width: 160px;
      height: 40px; } }

@media screen and (min-height: 860px) and (min-width: 992px) {
  .filters-modal footer {
    position: sticky;
    bottom: 0px;
    background-color: white;
    padding: 10px; } }

.tour-content-poi-form__no-visible-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(255, 255, 255, 0.5); }
  .tour-content-poi-form__no-visible-layer svg {
    width: 1.875rem;
    height: 1.875rem;
    fill: #2c2c2c;
    stroke: #2c2c2c; }

.tour-content-poi-form__title {
  font-size: 1.25rem;
  font-family: 'Roboto-bold'; }

.tour-content-poi-form__main-image {
  height: auto;
  width: 100%; }
  .tour-content-poi-form__main-image-wrapper {
    display: inline-block;
    width: 12.5rem;
    margin-bottom: 0;
    position: relative; }

.tour-content-poi-form__text, .tour-content-poi-form__text-input {
  font-size: 0.875rem;
  line-height: 0.9375rem;
  margin: 0.625rem 0 0.9375rem 0; }

.tour-content-poi-form__author {
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 210px);
  font-size: 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 0 0 0.625rem; }
  .tour-content-poi-form__author-name {
    display: block;
    font-family: 'Oswald';
    text-transform: uppercase;
    margin-top: 0; }
    .tour-content-poi-form__author-name svg {
      margin-left: 0.3125rem;
      display: inline-block;
      vertical-align: bottom;
      width: 0.9375rem;
      height: auto; }

.tour-content-poi-form__separator:after {
  content: '';
  display: block;
  margin: 0.9375rem auto;
  width: 90%;
  height: 0.0625rem;
  border-bottom: 0.0625rem dotted #6f6f6f; }

.tour-content-poi-form__list {
  list-style: none;
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 0 -0.625rem; }
  .tour-content-poi-form__list-item {
    list-style: none;
    z-index: 1010;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin: 0.1875rem;
    position: relative;
    padding-top: 1.5625rem; }
  .tour-content-poi-form__list-btn {
    padding: 0;
    background-color: transparent;
    border: 0.0625rem dashed #6f6f6f;
    position: relative;
    width: calc(33vw - 10px);
    height: calc(33vw - 10px); }
  .tour-content-poi-form__list-img {
    padding: 0.3125rem 0.3125rem 0 0.3125rem;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .tour-content-poi-form__list-img--icon {
      width: 70%;
      height: auto; }
  .tour-content-poi-form__list-remove, .tour-content-poi-form__list-show {
    position: absolute;
    top: 0;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-content-poi-form__list-remove svg, .tour-content-poi-form__list-show svg {
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-content-poi-form__list-show {
    left: 0.1875rem; }
  .tour-content-poi-form__list-remove {
    right: 0.1875rem; }
    .tour-content-poi-form__list-remove svg {
      fill: #d34c50; }
  .tour-content-poi-form__list-add + input {
    cursor: pointer; }

.tour-content-poi-form__contents-title {
  font-family: 'Roboto-bold';
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.125rem;
  padding-right: 3.125rem;
  position: relative; }

.tour-content-poi-form__contents-subtitle {
  display: block;
  font-style: 1rem; }

.tour-content-poi-form__add-btn {
  border-radius: 50%;
  width: 2.8125rem;
  height: 2.8125rem;
  padding: 0;
  border: none;
  background-color: #00affe;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .tour-content-poi-form__add-btn .fine-uploader__file-input {
    position: unset !important; }
  .tour-content-poi-form__add-btn svg {
    fill: white;
    width: 1.5625rem;
    height: 1.5625rem; }

.tour-content-poi-form__images-amount {
  position: absolute;
  bottom: 0;
  right: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  background-color: #00affe;
  border-radius: 50%;
  color: white;
  font-size: 1rem;
  font-family: 'Roboto-bold';
  font-weight: bold;
  z-index: 1; }

.tour-content-poi-form .fine-uploader__files-wrapper {
  padding: 0; }
  .tour-content-poi-form .fine-uploader__files-wrapper .tour-content-poi-form__list-add {
    margin-bottom: 0; }
  .tour-content-poi-form .fine-uploader__files-wrapper .circular-bar {
    width: 100%;
    margin: 0 auto;
    background-color: white; }
    .tour-content-poi-form .fine-uploader__files-wrapper .circular-bar svg {
      width: 3.125rem;
      height: 3.125rem; }

.tour-content-poi-form .thumbnails-wrapper__box {
  display: none; }

@media screen and (min-width: 576px) {
  .tour-content-poi-form__list-btn {
    width: calc(25vw - 10px);
    height: calc(25vw - 10px); } }

@media screen and (max-width: 991px) {
  .tour-content-poi-form .btn {
    border-radius: 0.3125rem;
    font-size: 1rem; } }

@media screen and (min-width: 992px) {
  .tour-content-poi-form__main-image-wrapper {
    display: block;
    width: 100%; }
  .tour-content-poi-form__text {
    line-height: 0.9375rem;
    background-color: white;
    padding: 0 0.625rem; }
  .tour-content-poi-form__author {
    display: block;
    max-width: unset;
    font-size: 1rem;
    text-align: right;
    margin-top: 0.1875rem; }
    .tour-content-poi-form__author-name {
      margin-top: 0.1875rem; }
  .tour-content-poi-form__text-wrapper {
    padding: 0 0.625rem;
    max-width: calc(100% - 30px);
    margin: 0.3125rem auto 3.125rem auto; }
    .tour-content-poi-form__text-wrapper--no-border {
      border: none; }
    .tour-content-poi-form__text-wrapper .btn {
      margin-bottom: -1.25rem; }
  .tour-content-poi-form__separator:after {
    display: none; }
  .tour-content-poi-form__list-btn {
    width: 8.75rem;
    height: 8.75rem;
    cursor: grab !important; }
  .tour-content-poi-form__list-item:last-of-type {
    border: none; }
  .tour-content-poi-form__list-add {
    width: 8.75rem;
    height: 8.75rem;
    background-color: #00affe;
    border: none;
    margin-bottom: 1.25rem; }
    .tour-content-poi-form__list-add svg {
      width: 40%;
      height: 40%;
      fill: white; }
  .tour-content-poi-form__contents-title {
    font-weight: bold;
    padding-right: 0; }
  .tour-content-poi-form .circular-bar {
    width: 8.75rem;
    height: 8.75rem; } }

.poi-map-popup {
  width: 100%;
  background-color: #fff;
  transition-duration: 0.5s;
  position: relative;
  transform: translate(-100vw, calc(100vh - 150px));
  transform: translate(-100vw, calc((var(--vh, 1vh) * 100) - 150px));
  height: 9.375rem; }
  .poi-map-popup .imagePreview {
    width: 8.75rem;
    height: 100%;
    object-fit: cover; }
  .poi-map-popup--added {
    background-color: #2c2c2c; }
    .poi-map-popup--added .poi-map-popup__title, .poi-map-popup--added .poi-map-popup__author {
      color: white; }
      .poi-map-popup--added .poi-map-popup__title svg, .poi-map-popup--added .poi-map-popup__author svg {
        fill: white; }
    .poi-map-popup--added .poi-map-popup__add-btn {
      background-color: white;
      cursor: inherit; }
      .poi-map-popup--added .poi-map-popup__add-btn svg {
        fill: #00affe; }
  .poi-map-popup.ReactModal__Content--after-open {
    transform: translate(0, calc(100vh - 150px));
    transform: translate(0, calc((var(--vh, 1vh) * 100) - 150px)); }
  .poi-map-popup__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(44, 44, 44, 0.9);
    z-index: 1010; }
  .poi-map-popup__content {
    padding: 0.3125rem;
    display: flex; }
  .poi-map-popup__content, .poi-map-popup__show-btn, .poi-map-popup__image, .poi-map-popup__image-wrapper {
    height: 100%; }
  .poi-map-popup__show-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    flex: 0 0 auto; }
  .poi-map-popup__image {
    width: 100%;
    object-fit: cover; }
    .poi-map-popup__image-wrapper {
      width: 8.75rem;
      background-color: white; }
  .poi-map-popup__info {
    flex: 1 1 100%;
    padding: 0 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .poi-map-popup__title {
    width: 100%;
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    padding-bottom: 0.3125rem;
    border-bottom: 0.0625rem dotted #6f6f6f;
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    line-height: 1.1; }
  .poi-map-popup__author {
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f;
    margin-bottom: 0;
    font-size: 1.125rem; }
    .poi-map-popup__author svg {
      display: inline-block;
      height: 1.375rem;
      width: auto;
      fill: #6f6f6f;
      margin-right: 0.4375rem; }
  .poi-map-popup__actions {
    align-self: center; }
  .poi-map-popup__add-btn {
    background-color: #00affe;
    border: none;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    padding: 0;
    line-height: 1; }
    .poi-map-popup__add-btn svg {
      width: 55%;
      height: auto;
      fill: white; }

.tour-file {
  background-color: #dedede;
  padding: 6.25rem 0 4.6875rem 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  margin-top: 0; }
  .tour-file__no-content-wrapper {
    text-align: center;
    margin-top: 3.75rem; }
  .tour-file .loadingScroll {
    width: 200px; }
  .tour-file .textHeader {
    background-color: #a7d5ff;
    margin-bottom: 10px;
    border-bottom: 1px solid white; }
    .tour-file .textHeader div {
      width: 70%;
      margin: 25px 0px 20px; }
      .tour-file .textHeader div h3 {
        color: white;
        font-weight: 400; }

@media screen and (min-width: 992px) {
  .tour-file {
    padding-top: 3.5rem; } }

@media screen and (min-width: 1200px) {
  .tour-file .container {
    max-width: 65.3125rem; } }

/* #appWrapper {
  color: white;
} */
.tour-header {
  position: relative; }
  .tour-header--staff-pick:before {
    content: '';
    background: url("/assets/images/icono_staff_pick.svg") no-repeat;
    background-size: contain;
    position: absolute;
    top: 0.125rem;
    left: 0.625rem;
    height: 4.375rem;
    width: 5rem;
    z-index: 2; }
  .tour-header .description_route {
    margin-bottom: 0.9375rem;
    font-size: 1.1875rem;
    font-family: 'Roboto',sans-serif !important;
    font-weight: 100; }
  .tour-header .buttonLike, .tour-header .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .tour-header .buttonLike img, .tour-header .buttonComment img {
      width: 90%; }
  .tour-header .likeTour {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: 0.0625rem dashed #6f6f6f;
    border-right: 0.0625rem dashed #6f6f6f; }
    @media (max-width: 600px) {
      .tour-header .likeTour {
        padding: 0 8px;
        margin: 0 0 0 10px !important; } }
  .tour-header__cover {
    background-color: white;
    position: relative;
    z-index: 1;
    margin-bottom: 0; }
    .tour-header__cover:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(44, 44, 44, 0.3);
      z-index: -1; }
    .tour-header__cover-data {
      position: absolute;
      top: 10vh;
      top: calc(var(--vh, 1vh) * 10);
      left: 0;
      right: 0; }
    .tour-header__cover-image {
      width: 100%;
      height: calc(100vh - 100px);
      height: calc(var(--vh, 1vh) * 100 - 100px);
      min-height: 15.625rem;
      object-fit: cover; }
    .tour-header__cover-title {
      text-align: center;
      font-family: 'Roboto-Regular';
      font-size: 1.625rem;
      color: white;
      padding: 0 0.625rem;
      line-height: 1;
      z-index: 2; }
    .tour-header__cover-author {
      display: block;
      margin: 0 auto;
      margin-top: 1.25rem;
      text-align: center;
      background: transparent;
      border: none; }
      .tour-header__cover-author-name {
        margin-top: 0.625rem;
        font-family: 'Oswald';
        color: white;
        text-transform: uppercase;
        font-size: 1rem; }
    .tour-header__cover-avatar {
      width: 100%;
      height: 100%;
      object-fit: cover; }
      .tour-header__cover-avatar-wrapper {
        margin: 0 auto;
        width: 4.6875rem;
        height: 4.6875rem;
        border-radius: 50%;
        overflow: hidden;
        background: white center no-repeat;
        border: 0.1875rem solid white; }
        .tour-header__cover-avatar-wrapper:before {
          border-radius: 50%; }
  .tour-header__date {
    color: white;
    font-family: 'Oswald';
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase; }
  .tour-header__body {
    background-color: #dedede;
    padding-bottom: 3.125rem;
    max-width: 85.375rem; }
    .tour-header__body-author-name, .tour-header__body-date {
      text-transform: uppercase;
      font-size: 1.125rem;
      margin-bottom: 0;
      text-align: center; }
    .tour-header__body-author {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.9375rem 0; }
    .tour-header__body-avatar {
      width: 4.6875rem;
      height: 4.6875rem;
      border-radius: 50%;
      background: white center no-repeat;
      background-size: cover;
      margin-bottom: 0.625rem; }
  .tour-header__rrss {
    padding: 1.5625rem 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: center;
    border-bottom: 0.0625rem dotted #6f6f6f;
    margin-bottom: 1.5625rem; }
    .tour-header__rrss p {
      margin-bottom: 10px;
      font-family: 'Oswald';
      text-align: center;
      font-size: 14px; }
    .tour-header__rrss-item {
      display: inline-block;
      text-align: center; }
      .tour-header__rrss-item + .tour-header__rrss-item {
        margin-left: 0.625rem; }
    .tour-header__rrss-link {
      display: inline-block;
      cursor: pointer; }
      .tour-header__rrss-link:hover circle {
        fill: #00affe; }
  .tour-header__title {
    text-align: center; }
  .tour-header__keywords {
    font-size: 1rem;
    padding: 0;
    list-style: none;
    text-align: center; }
    .tour-header__keywords-item {
      display: inline-block;
      color: #2c2c2c;
      font-family: 'Roboto-bold';
      font-weight: 500;
      margin: 0 0.3125rem; }
  .tour-header__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.625rem; }
    .tour-header__data-text {
      font-family: 'Oswald';
      color: #2c2c2c;
      font-size: 1rem;
      letter-spacing: -0.03125rem;
      line-height: 1.3;
      margin-bottom: 0; }
  .tour-header__actions {
    padding-left: 0.625rem;
    border-left: 0.0625rem solid black; }
  .tour-header__action-btn {
    margin: auto;
    max-width: 17.5rem; }
    .tour-header__action-btn + .tour-header__action-btn {
      margin-top: 0.3125rem; }
  .tour-header figure.tour-header__cover-author-toptraveler {
    background-color: white;
    border-radius: 0.1875rem;
    padding: 0.625rem 0.3125rem 0.3125rem 0.3125rem;
    width: 6.25rem; }
    .tour-header figure.tour-header__cover-author-toptraveler svg {
      position: relative;
      width: 100%;
      height: auto; }

.ModalComments {
  padding: 30px;
  z-index: 2000;
  /* padding-top: 10px; */
  position: relative;
  border-bottom: none;
  overflow: auto;
  background-color: white;
  outline: none;
  width: 100%;
  height: 100%; }
  .ModalComments::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .ModalComments::-webkit-scrollbar-thumb {
    background: #00affe; }
  .ModalComments .buttonClose {
    position: absolute;
    right: 5px;
    top: 5px; }
    .ModalComments .buttonClose .closeIcon {
      background-color: transparent;
      display: inline-block;
      border: none;
      vertical-align: middle;
      line-height: 1; }
      .ModalComments .buttonClose .closeIcon svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: black; }
  .ModalComments .title {
    color: #2c2c2c;
    font-size: 1.75rem;
    font-family: 'Roboto-bold';
    font-weight: 500; }

@media screen and (min-width: 992px) {
  .ModalComments {
    width: 45%; }
  .tour-header {
    background-color: white; }
    .tour-header--staff-pick:before {
      display: none; }
    .tour-header--staff-pick .tour-header__cover-data:before {
      content: '';
      background: url("/assets/images/icono_staff_pick.svg") no-repeat;
      background-size: 4.375rem 5rem;
      position: absolute;
      top: 0.125rem;
      left: 0.625rem;
      height: 100%;
      width: 100%; }
    .tour-header__rrss {
      justify-content: flex-start; }
    .tour-header__cover {
      position: relative;
      overflow: hidden; }
      .tour-header__cover:after {
        display: none; }
      .tour-header__cover-image {
        height: calc(100vh - 39px);
        height: calc(var(--vh, 1vh) * 100 - 39px);
        min-height: 37.5rem;
        object-fit: cover; }
      .tour-header__cover-data {
        padding: 2.8125rem 0.9375rem 1.875rem 0.9375rem;
        background-color: #2c2c2c;
        top: unset;
        bottom: 6.25rem;
        left: 12.5rem;
        width: 17.5rem;
        clip-path: polygon(0 0, 100% 7%, 100% 100%, 0% 100%); }
      .tour-header__cover-title {
        padding: 2.8125rem 0.9375rem 1.25rem 0.9375rem;
        margin-bottom: 1.25rem;
        border-bottom: 0.0625rem dashed white;
        font-size: 1.75rem;
        text-align: left; }
      .tour-header__cover-avatar-wrapper {
        width: 6.25rem;
        height: 6.25rem;
        margin-bottom: 0.9375rem; }
      .tour-header__cover svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1; }
    .tour-header__actions {
      border-left: none; }
    .tour-header__body {
      background-color: white;
      padding: 2.5rem 4vw;
      margin: 0 auto; }
      .tour-header__body-author-name, .tour-header__body-date {
        text-align: left; }
      .tour-header__body-author {
        flex-direction: row;
        margin: 0; }
      .tour-header__body-avatar {
        margin: 0 0.625rem 0 0; }
    .tour-header__title {
      text-align: left;
      color: #2c2c2c;
      font-size: 1.75rem;
      font-family: 'Roboto-bold';
      font-weight: 500;
      margin-top: 1.5625rem; }
    .tour-header__rrss {
      text-align: left;
      border-bottom: none;
      padding: 0;
      margin-bottom: 0; }
    .tour-header__keywords {
      font-size: 1.125rem;
      text-align: left;
      font-weight: bold; }
    .tour-header__data {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .tour-header__data-text {
        color: black;
        font-size: 1.1875rem;
        letter-spacing: 0; }
    .tour-header__select-wrapper {
      position: absolute;
      bottom: -4.0625rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 16.25rem; }
    .tour-header__select-image {
      display: inline-block;
      vertical-align: bottom;
      height: 4.0625rem;
      width: auto;
      margin-left: 0.625rem; }
    .tour-header__date {
      color: #6f6f6f;
      margin-bottom: 0;
      text-align: right; }
    .tour-header figure.tour-header__cover-author-toptraveler {
      width: 7.5rem; }
  div.tour-header__select {
    display: inline-block;
    vertical-align: bottom;
    width: 12.5rem; }
    div.tour-header__select .react-select__control {
      border: 0.125rem solid #2c2c2c;
      border-radius: 0.9375rem; }
      div.tour-header__select .react-select__control--menu-is-open .react-select__dropdown-indicator {
        transform: rotate(0deg); }
    div.tour-header__select .react-select__value-container {
      line-height: 2.1875rem;
      font-size: 1.5rem;
      font-family: 'Oswald';
      text-transform: uppercase; }
    div.tour-header__select .react-select__single-value {
      color: #2c2c2c;
      width: calc(100% - 8px);
      text-align: center; }
    div.tour-header__select .react-select__dropdown-indicator {
      transform: rotate(-90deg); }
      div.tour-header__select .react-select__dropdown-indicator svg {
        fill: #dedede;
        width: 1.875rem;
        height: 1.875rem; }
    div.tour-header__select .react-select__menu {
      left: 0;
      right: 0;
      width: calc(100% - 15px);
      margin: 0 auto; }
    div.tour-header__select .react-select__option {
      text-align: center;
      text-transform: uppercase;
      font-family: 'Oswald';
      font-size: 18px; }
    div.tour-header__select .react-select__indicator-separator {
      display: none; } }

.tour-config-main__rich-text-editor-ReactQuill {
  margin-bottom: 10px; }
  .tour-config-main__rich-text-editor-ReactQuill .ql-editor {
    font-size: 14px;
    min-height: 110px; }
  .tour-config-main__rich-text-editor-ReactQuill .ql-editor.ql-blank::before {
    font-size: 14px; }

.tour-content-poi-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 1.5625rem;
  margin-bottom: 0rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  /* .tour-content-text-box {
    padding-left : rem(10px);
    padding-right: rem(10px);
  } */
  /* &.dropdown.show {

    .dropdown-toggle,
    .nav-dropdown-toggle:hover {
      background-color: $topcolor-black;
    }
  } */ }
  .tour-content-poi-box .seeMoreDescription {
    cursor: pointer;
    text-decoration: underline black; }
  .tour-content-poi-box .imageCampain {
    margin-top: 0px; }
  .tour-content-poi-box .target {
    height: 300px; }
    .tour-content-poi-box .target .contentTourLoading {
      width: 60%;
      padding: 20px;
      box-sizing: border-box; }
      .tour-content-poi-box .target .contentTourLoading .itemTour {
        height: 25px;
        padding-bottom: 5px; }
        .tour-content-poi-box .target .contentTourLoading .itemTour.Tittle {
          width: 40%; }
        .tour-content-poi-box .target .contentTourLoading .itemTour.Description {
          width: 70%; }
    .tour-content-poi-box .target .contentSponsorLoading {
      width: 40%; }
      .tour-content-poi-box .target .contentSponsorLoading .itemSponsor {
        margin-bottom: 5px; }
      .tour-content-poi-box .target .contentSponsorLoading .itemSponsorTittle {
        width: 80%;
        height: 25px; }
      .tour-content-poi-box .target .contentSponsorLoading .itemSponsorImagen {
        width: 70%;
        height: 150px; }
      .tour-content-poi-box .target .contentSponsorLoading .itemSponsorDesc {
        width: 70%;
        height: 25px; }
  .tour-content-poi-box .modalMarkerPosition {
    border: 3px solid #a7dbfc;
    margin-top: 4px; }
  .tour-content-poi-box__order {
    margin-right: 24px;
    display: inline-block;
    background-color: #00affe;
    color: black;
    font-size: 2.125rem;
    font-weight: bold;
    /* border-bottom-right-radius: 75%; */
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
    text-align: center;
    /* padding-right             : 0.625rem; */
    position: relative;
    top: 4px;
    left: 10px;
    /* z-index                   : 100; */ }
  .tour-content-poi-box .tour-content-poi-box__partner-call {
    text-align: center;
    margin-bottom: 0;
    font-family: 'Oswald';
    font-weight: 100;
    text-transform: uppercase; }
    .tour-content-poi-box .tour-content-poi-box__partner-call button {
      display: none;
      opacity: 0; }
  .tour-content-poi-box__top {
    border-bottom: 0.0625rem dotted #2c2c2c;
    padding-bottom: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .tour-content-poi-box__header {
    align-items: flex-end;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .tour-content-poi-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0.0625rem 0;
      text-align: center; }
      .tour-content-poi-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
    .tour-content-poi-box__header .textMP {
      color: #00affe;
      font-weight: 100;
      margin: 0px; }
    .tour-content-poi-box__header .textMP2 {
      display: flex;
      align-items: flex-end; }
      .tour-content-poi-box__header .textMP2 span {
        color: #00affe;
        text-decoration: underline;
        margin-left: 5px; }
    .tour-content-poi-box__header .arrowRight {
      display: flex;
      justify-content: center;
      margin-left: 8px;
      border-radius: 50%;
      border: none;
      width: 30px;
      height: 30px;
      background-color: #00affe; }
      .tour-content-poi-box__header .arrowRight img {
        width: 8px; }
  .tour-content-poi-box__owner {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    width: 100%;
    font-size: 0.6875rem;
    margin-top: 2%;
    color: black; }
    .tour-content-poi-box__owner-name {
      margin-right: 10px;
      display: inline-block;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      text-transform: capitalize;
      color: black;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis; }
      .tour-content-poi-box__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }
  .tour-content-poi-box__image {
    height: 66.3vw;
    margin-bottom: 0;
    object-fit: cover; }
    .tour-content-poi-box__image-author {
      display: inline-block;
      max-width: 75%;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: 0.3125rem 0.625rem 0.3125rem 1.875rem; }
      .tour-content-poi-box__image-author-avatar {
        position: absolute;
        bottom: -0.625rem;
        left: 0.625rem;
        margin-left: -2.5rem;
        margin-bottom: 0;
        height: 3.125rem;
        width: 3.125rem;
        border: 0.125rem solid white;
        background-color: white;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; }
      .tour-content-poi-box__image-author-name {
        font-family: 'Oswald';
        text-transform: uppercase;
        font-size: 0.9375rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0; }
  .tour-content-poi-box__title, .tour-content-poi-box__address {
    margin-bottom: 0;
    color: #2c2c2c; }
  .tour-content-poi-box__title {
    font-weight: 700;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.125rem;
    line-height: 1.5rem;
    max-height: 3rem;
    overflow: hidden; }
  .tour-content-poi-box__address {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    text-transform: capitalize;
    font-size: 1rem;
    margin: 0.5%; }
  .tour-content-poi-box__info {
    list-style: none;
    padding-left: 0;
    text-align: right;
    margin: 15px 0 0; }
    .tour-content-poi-box__info-item {
      display: inline-block;
      vertical-align: middle;
      color: #2c2c2c;
      font-size: 1rem;
      line-height: 1; }
      .tour-content-poi-box__info-item svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        height: 1.125rem;
        width: auto;
        max-width: 1.25rem;
        fill: #6f6f6f;
        margin-right: 0.3125rem; }
      .tour-content-poi-box__info-item + .tour-content-poi-box__info-item {
        margin-left: 0.9375rem; }
  .tour-content-poi-box__description {
    margin: 0.9375rem 0 0 0;
    white-space: pre-line;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100 !important;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }
  .tour-content-poi-box__partner {
    margin: 0;
    cursor: pointer; }
    .tour-content-poi-box__partner-image {
      display: block;
      max-width: 50%;
      margin: 1.875rem auto 0.9375rem auto;
      width: 35%;
      max-width: 250px !important; }
  .tour-content-poi-box__topright {
    padding: 0;
    text-align: right;
    max-width: max-content; }
  .tour-content-poi-box__topleft {
    -webkit-flex-grow: 1; }
  .tour-content-poi-box .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .tour-content-poi-box .buttonLike img {
      width: 80%; }
  .tour-content-poi-box .actionIcon {
    background: transparent;
    width: 40px;
    height: 40px;
    border-width: 0;
    padding: 0;
    margin: 0px 10px 0px 0px;
    text-align: center;
    cursor: pointer; }
    .tour-content-poi-box .actionIcon img {
      width: 80%; }
  .tour-content-poi-box .actionIconRoute {
    height: auto; }
  .tour-content-poi-box .buttonMenu {
    background: transparent;
    width: 40px;
    height: 22px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .tour-content-poi-box .buttonMenu img {
      width: 80%; }
  .tour-content-poi-box .authorPhoto {
    /* font-family     : 'Roboto',sans-serif !important; */
    /* font-weight     : 100; */
    width: auto;
    position: absolute;
    right: 0%;
    bottom: 2%;
    padding-right: 30px;
    background-color: white; }
  .tour-content-poi-box .buttonAuthor {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 98%;
    bottom: -35%;
    background-color: white;
    border-radius: 50%;
    border-style: none; }
  .tour-content-poi-box .contentAuthor {
    position: relative; }
  .tour-content-poi-box .timePoi {
    background-color: #f0f0f0;
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    padding: 15px; }
    .tour-content-poi-box .timePoi h5 {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700; }
      .tour-content-poi-box .timePoi h5 span {
        font-weight: 100; }
  .tour-content-poi-box .carousel .slider-wrapper {
    overflow: visible; }
  .tour-content-poi-box .carousel.carousel-slider {
    padding-bottom: 2.1875rem; }
    .tour-content-poi-box .carousel.carousel-slider .control-arrow {
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      margin: auto 0.3125rem;
      border-radius: 50%;
      opacity: 0.3;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .tour-content-poi-box .carousel.carousel-slider .control-arrow.control-prev:before, .tour-content-poi-box .carousel.carousel-slider .control-arrow.control-next:before {
        width: 0.9375rem;
        height: 0.9375rem;
        border-top: none;
        border-right: none;
        border-bottom: 0.1875rem solid #2c2c2c;
        border-left: 0.1875rem solid #2c2c2c; }
      .tour-content-poi-box .carousel.carousel-slider .control-arrow.control-prev:before {
        margin-left: 0.625rem;
        transform: rotate(50deg) skewY(-15deg); }
      .tour-content-poi-box .carousel.carousel-slider .control-arrow.control-next:before {
        margin-right: 0.625rem;
        transform: rotate(-135deg); }
      .tour-content-poi-box .carousel.carousel-slider .control-arrow:hover {
        opacity: 1; }
  .tour-content-poi-box .carousel .control-dots {
    padding-left: 0; }
    .tour-content-poi-box .carousel .control-dots .dot {
      box-shadow: none;
      background-color: black;
      width: 0.375rem;
      height: 0.375rem;
      margin: 0 0.125rem; }
      .tour-content-poi-box .carousel .control-dots .dot.selected {
        background-color: #2c2c2c; }
  .tour-content-poi-box .tour-content-images-box__img-author {
    display: none; }
  .tour-content-poi-box .tour-content-images-box,
  .tour-content-poi-box .tour-content-map-box,
  .tour-content-poi-box .tour-content-text-box,
  .tour-content-poi-box .tour-content-time-box,
  .tour-content-poi-box .tour-content-video-box {
    padding: 0;
    box-shadow: none; }
    .tour-content-poi-box .tour-content-images-box + *,
    .tour-content-poi-box .tour-content-map-box + *,
    .tour-content-poi-box .tour-content-text-box + *,
    .tour-content-poi-box .tour-content-time-box + *,
    .tour-content-poi-box .tour-content-video-box + * {
      margin-top: 1.25rem; }
    .tour-content-poi-box .tour-content-images-box .allTime,
    .tour-content-poi-box .tour-content-map-box .allTime,
    .tour-content-poi-box .tour-content-text-box .allTime,
    .tour-content-poi-box .tour-content-time-box .allTime,
    .tour-content-poi-box .tour-content-video-box .allTime {
      position: relative;
      width: 100%; }
      .tour-content-poi-box .tour-content-images-box .allTime div,
      .tour-content-poi-box .tour-content-map-box .allTime div,
      .tour-content-poi-box .tour-content-text-box .allTime div,
      .tour-content-poi-box .tour-content-time-box .allTime div,
      .tour-content-poi-box .tour-content-video-box .allTime div {
        max-width: max-content; }
  .tour-content-poi-box .tour-content-images-box__content {
    padding: 0; }
  .tour-content-poi-box .dropdown-menu {
    background-color: #F1F1F1;
    padding: 0.9375rem 0.3125rem;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 1px; }
    .tour-content-poi-box .dropdown-menu .dropdown-item {
      background-color: #F1F1F1;
      /*  border-bottom : rem(1px) dotted $topcolor-black; */
      padding-bottom: 0.625rem;
      min-width: 12.5rem;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      color: #6f6f6f;
      /* &:hover,
      &--active {
        background-color: lightgray;
      } */ }
      .tour-content-poi-box .dropdown-menu .dropdown-item + .dropdown-item {
        margin-top: 0.3125rem; }
    .tour-content-poi-box .dropdown-menu:after, .tour-content-poi-box .dropdown-menu:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
    .tour-content-poi-box .dropdown-menu:before {
      border-bottom: 0.9375rem solid #F1F1F1;
      top: -14px;
      z-index: 2; }
    .tour-content-poi-box .dropdown-menu:after {
      border-bottom: 0.9375rem solid gray;
      top: -15px;
      z-index: 1; }
    .tour-content-poi-box .dropdown-menu.dropdown-menu-right {
      right: -12px; }
      .tour-content-poi-box .dropdown-menu.dropdown-menu-right:before, .tour-content-poi-box .dropdown-menu.dropdown-menu-right:after {
        right: 0.5rem; }
    .tour-content-poi-box .dropdown-menu.dropdown-menu-left {
      left: -0.4375rem; }
      .tour-content-poi-box .dropdown-menu.dropdown-menu-left:before, .tour-content-poi-box .dropdown-menu.dropdown-menu-left:after {
        left: 2.5rem; }
  .tour-content-poi-box .likes {
    padding: 0px !important;
    margin: 0px !important; }
  .tour-content-poi-box .closeMenu {
    display: none;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    padding-right: 3px;
    right: 1px;
    z-index: 2000; }
    .tour-content-poi-box .closeMenu svg {
      fill: gray;
      width: 1.5625rem;
      height: 1.5625rem; }

.ContentModal {
  justify-content: center;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(33, 37, 41, 0.9); }

.ContentModalVideoCampaign {
  justify-content: center;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2001 !important;
  background-color: rgba(33, 37, 41, 0.9); }

.ReactModal__Overlay--after-open {
  z-index: 2000;
  /* background-color: #212529D4 !important;
    display          : flex;
    justify-content  : center;
    align-items      : center; */ }

@media screen and (min-width: 992px) {
  .tour-content-poi-box {
    margin-top: 1.5625rem;
    margin-bottom: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    /*     border-bottom-right-radius: rem(0px); */
    padding: 1.25rem 2.1875rem; }
    .tour-content-poi-box__header {
      align-items: center;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .tour-content-poi-box__header-icon {
        display: inline-block;
        width: 5rem;
        height: 2.5rem;
        background-color: white;
        margin-right: 0.3125rem;
        padding: 0.5rem 0 0 0;
        text-align: center; }
        .tour-content-poi-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; }
    .tour-content-poi-box__owner {
      font-size: 0.875rem; }
      .tour-content-poi-box__owner-name svg {
        height: 1.25rem; }
    .tour-content-poi-box__category {
      font-size: 1rem;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      text-transform: capitalize;
      color: black; }
    .tour-content-poi-box__order {
      margin-right: 24px;
      display: inline-block;
      background-color: #00affe;
      color: black;
      font-size: 2.125rem;
      font-weight: bold;
      /* border-bottom-right-radius: 75%; */
      width: 3.75rem;
      height: 3.75rem;
      line-height: 3.75rem;
      text-align: center;
      /* padding-right             : 0.625rem; */
      position: relative;
      top: 4px;
      left: 10px;
      /* z-index                   : 100; */ }
    .tour-content-poi-box__image {
      height: 37.75rem; }
    .tour-content-poi-box__top {
      border-bottom: 0.0625rem dotted #2c2c2c;
      padding-bottom: 0.3125rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
    .tour-content-poi-box__title {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 2rem;
      max-height: 4rem; }
    .tour-content-poi-box__info {
      padding-bottom: 0.1875rem;
      margin: 0; }
      .tour-content-poi-box__info-item + .tour-content-poi-box__info-item {
        margin-left: 1.25rem; }
    .tour-content-poi-box__partner-call {
      font-family: 'Oswald';
      font-weight: 100;
      text-transform: uppercase;
      text-align: center;
      font-size: 0.875rem;
      color: black;
      margin-bottom: 0.3125rem;
      position: relative; }
      .tour-content-poi-box__partner-call button {
        display: block !important;
        opacity: 1 !important;
        background: initial;
        border: none;
        padding: 0;
        position: absolute;
        left: 50%;
        bottom: -0.875rem;
        transform: rotate(-45deg);
        height: 0.5rem;
        width: 0.5rem; }
        .tour-content-poi-box__partner-call button:before {
          border-bottom: 2px solid #303030;
          border-left: 2px solid #303030;
          content: '';
          display: block;
          height: 100%;
          width: 100%; }
        .tour-content-poi-box__partner-call button + button {
          top: -0.75rem;
          bottom: auto;
          transform: rotate(135deg); }
    .tour-content-poi-box__partner-image {
      width: 75%; }
    .tour-content-poi-box__partner-description {
      word-break: break-word;
      width: 250px !important;
      text-align: center;
      color: black;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100 !important;
      font-size: 0.9375rem;
      line-height: 1.2;
      padding: 0 1.25rem; }
    .tour-content-poi-box .tour-content-images-box {
      margin-top: 1.25rem; }
    .tour-content-poi-box .tour-content-images-box,
    .tour-content-poi-box .tour-content-map-box,
    .tour-content-poi-box .tour-content-video-box {
      padding: 0 0.9375rem;
      box-shadow: none; }
      .tour-content-poi-box .tour-content-images-box + *,
      .tour-content-poi-box .tour-content-map-box + *,
      .tour-content-poi-box .tour-content-video-box + * {
        margin-top: 1.25rem; }
    .tour-content-poi-box .dropdown-menu.dropdown-menu-right {
      right: 0.1875rem; }
      .tour-content-poi-box .dropdown-menu.dropdown-menu-right:before, .tour-content-poi-box .dropdown-menu.dropdown-menu-right:after {
        right: 0.5rem; }
  .sup {
    padding: 0px !important; } }

.campaing_image {
  height: 100% !important; }

@media screen and (min-width: 1200px) {
  .tour-content-poi-box__image {
    height: 41.3125rem; } }

@media screen and (min-width: 992px) {
  .tour-content-poi-box__top {
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .tour-content-poi-box .pin {
    justify-content: flex-start !important; } }

.contentTitlesMap {
  margin-left: 20px !important; }
  .contentTitlesMap span {
    margin-right: 5px; }
  .contentTitlesMap p {
    margin: 0px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700; }
    .contentTitlesMap p span {
      margin: 0px 5px;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100; }

.modalPartner {
  margin: 0px !important; }
  .modalPartner .imagePartnerMobile {
    display: none; }

.ModalMap {
  z-index: 2000;
  /* padding-top: 10px; */
  position: relative;
  border-bottom: none;
  overflow: auto;
  width: 1045px !important;
  /* @media only screen and (max-width: 600px) {
    z-index: 1000;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;

    .mod-movil {
      display: inline;

      img {
        width: inherit;
        padding: 15px;
        padding-bottom: 20px;
      }

    }

    .actionIcon {
      display: none;
    }

    .head-map-modal {
      border-bottom: dotted black;
      padding-right: 0 !important;
      margin-right: 10px !important;
      padding-bottom: 10px !important;

    }

    .modal-mapa-imagenes {
      display: none;
    }

    .info-modal-movil {
      margin: 10px;
      margin-top: 20px;

      h4 {
        margin-right: 5px;
      }

      p {
        margin-bottom: 0px;

      }

    }
  } */
  background-color: white;
  outline: none; }
  .ModalMap::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .ModalMap::-webkit-scrollbar-thumb {
    background: #00affe; }
  .ModalMap .imagePartner {
    width: 100%; }
  .ModalMap .buttonClose {
    position: absolute;
    right: 5px;
    top: 5px; }
  .ModalMap .alturaImagen {
    height: inherit;
    padding-top: 36px !important;
    margin: 0px !important; }
  .ModalMap .twoH .buttonActive {
    border-bottom: 5px solid #abdafd;
    padding-bottom: 32px; }
  .ModalMap .threeH {
    width: auto;
    /* position: absolute;
    top: 2px;
    right: 8px; */ }
  .ModalMap .portada {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .ModalMap .info {
    margin-bottom: 50px;
    width: 90%; }
  .ModalMap .info-modal {
    margin: 10px; }
    .ModalMap .info-modal h4 {
      margin-right: 5px;
      margin-bottom: 2px;
      font-size: 18px; }
    .ModalMap .info-modal p {
      margin-bottom: 0px; }
  .ModalMap .actionIcon {
    background: transparent;
    width: 35px;
    height: 35px;
    border-width: 0;
    padding: 0;
    margin: 0px 10px 0px 0px;
    text-align: center;
    cursor: pointer; }
    .ModalMap .actionIcon img {
      width: 80%; }
  .ModalMap .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .ModalMap .buttonLike img {
      width: 80%; }
  .ModalMap .closeIcon {
    background-color: transparent;
    display: inline-block;
    border: none;
    vertical-align: middle;
    line-height: 1; }
    .ModalMap .closeIcon svg {
      height: 1.75rem;
      width: 1.75rem; }
  .ModalMap .head-map-modal {
    position: fixed;
    z-index: 1000;
    background-color: white;
    width: 1045px !important;
    /* margin-left  : 15px !important;
    margin-right    : 15px !important; */ }
  .ModalMap .modal-mapa-imagenes {
    height: 70%;
    /* .pdg0 {
      padding-left : 0px;
      padding-right: 35px;
    } */
    /*     img {
      width: inherit;
      padding-left: 0px;
      margin-right: 11px;

    } */ }
  .ModalMap .modal-mapa-imagenesPois {
    margin-top: 70px !important;
    height: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
    align-items: flex-start; }
    .ModalMap .modal-mapa-imagenesPois .items {
      cursor: pointer;
      width: 24%;
      height: 220px;
      margin: 4px;
      border: 1px solid #8D9EAE; }
      .ModalMap .modal-mapa-imagenesPois .items img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .ModalMap .MapaImage {
    width: calc(100% - 280px) !important;
    height: auto !important;
    flex-grow: 1;
    padding: 10px;
    /*     width: 70%; */
    height: 100%; }
    .ModalMap .MapaImage .simple-map {
      height: 100% !important; }
    .ModalMap .MapaImage .division2 {
      background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
      padding-bottom: 10px;
      margin: 10px; }
  .ModalMap .widthAternative {
    width: 70% !important;
    height: 100% !important;
    overflow: auto;
    overflow-x: hidden; }
    .ModalMap .widthAternative::-webkit-scrollbar {
      background-color: #2c2c2c;
      width: 0.3125rem; }
    .ModalMap .widthAternative::-webkit-scrollbar-thumb {
      background: #00affe; }
  .ModalMap .contentOneTwo {
    display: flex;
    justify-content: space-between;
    width: auto;
    flex-grow: 1; }
    .ModalMap .contentOneTwo .oneH {
      -webkit-flex-grow: 1; }
  .ModalMap .mapRight {
    width: 280px;
    height: 690px;
    max-width: 280px;
    flex-basis: unset;
    padding-top: 10px !important;
    padding-left: 5px !important;
    padding-bottom: 10px;
    padding-right: 5px !important;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    /*     width: 30%;
    height: 100%; */ }
    .ModalMap .mapRight div {
      text-align: center; }
    .ModalMap .mapRight p {
      /* height: 30%; */
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: start;
      font-size: 15px; }
    .ModalMap .mapRight .partnerImage {
      width: 250px;
      height: 180px; }
    .ModalMap .mapRight .collage {
      width: 250px;
      object-fit: cover;
      height: 400px; }
    .ModalMap .mapRight__top {
      width: 250px; }
    .ModalMap .mapRight__bot {
      margin-bottom: 10px;
      width: 250px;
      /* height    : 100%; */ }

.contentPartner {
  display: flex;
  height: 30%;
  justify-content: center;
  align-items: center; }
  .contentPartner .partnerImageMobile {
    height: 80%; }

@media screen and (max-width: 992px) {
  .ModalMap {
    width: 100%;
    height: 100%; }
    .ModalMap .modal-mapa-imagenesPois {
      justify-content: center;
      margin-right: 10px;
      height: auto;
      align-content: flex-start;
      align-items: flex-start; }
      .ModalMap .modal-mapa-imagenesPois .items {
        width: 23vw;
        height: 23vw;
        margin: 3px; }
    .ModalMap .MapaImage {
      width: 100%; }
    .ModalMap .info {
      width: 100%;
      margin-top: 20px; }
  .tour-content-poi-box .poiBoxDescriptionContent span {
    margin: 0.9375rem 0 0 0;
    white-space: pre-line;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.8125rem;
    font-size: 1rem;
    color: #2c2c2c;
    overflow: visible; }
  .tour-content-poi-box__top {
    margin-bottom: 15px !important; }
  .tour-content-poi-box__partner {
    display: flex;
    margin: 0px;
    padding: 0px; }
    .tour-content-poi-box__partner img {
      margin: 0px; }
  .tour-content-poi-box__partner-description {
    word-break: break-word;
    width: 90%;
    color: black;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100 !important;
    font-size: 0.9375rem;
    line-height: 1.2;
    padding: 0 1.25rem; }
  .tour-content-poi-box__partner-call {
    display: flex;
    align-items: center;
    font-family: 'Oswald';
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    color: black;
    margin-bottom: 0.3125rem;
    position: relative; }
    .tour-content-poi-box__partner-call button {
      display: block !important;
      opacity: 1 !important;
      background: initial;
      border: none;
      padding: 0;
      /*           position: absolute; */
      left: 50%;
      bottom: -0.875rem;
      transform: rotate(45deg);
      height: 0.5rem;
      width: 0.5rem; }
      .tour-content-poi-box__partner-call button:before {
        border-bottom: 2px solid #303030;
        border-left: 2px solid #303030;
        content: '';
        display: block;
        height: 100%;
        width: 100%; }
      .tour-content-poi-box__partner-call button + button {
        top: -0.75rem;
        bottom: auto;
        transform: rotate(225deg); }
  .modalPartner {
    width: 100% !important;
    height: 100% !important; }
    .modalPartner .imagePartner {
      display: none; }
    .modalPartner .imagePartnerMobile {
      display: block; }
      .modalPartner .imagePartnerMobile img {
        width: 100%; }
    .modalPartner .alturaImagen {
      padding: 0px !important;
      margin: 0px !important;
      width: 100%; } }

@media screen and (max-width: 768px) {
  .ModalMap {
    width: 100%;
    height: 100%; }
    .ModalMap .modal-mapa-imagenesPois {
      justify-content: center;
      margin-right: 10px;
      height: auto;
      align-content: flex-start;
      align-items: flex-start;
      margin-top: 80px !important; }
      .ModalMap .modal-mapa-imagenesPois .items {
        width: 30vw;
        height: 30vw;
        margin: 2px; }
    .ModalMap .MapaImage {
      width: 100%; }
    .ModalMap .info {
      width: 100%;
      margin-top: 30px; }
  .tour-content-poi-box {
    /* .dropdown{
        bottom: -24px;
      } */ }
    .tour-content-poi-box .target .contentTourLoading {
      display: none; }
    .tour-content-poi-box .target .contentSponsorLoading {
      width: 90%;
      padding-top: 10px;
      box-sizing: border-box; }
    .tour-content-poi-box .dropdown-menu.dropdown-menu-right {
      right: 0px;
      left: 2rem; }
      .tour-content-poi-box .dropdown-menu.dropdown-menu-right:before, .tour-content-poi-box .dropdown-menu.dropdown-menu-right:after {
        right: 0.5rem; }
    .tour-content-poi-box .test {
      width: 100vw;
      max-width: 100vw; }
    .tour-content-poi-box .closeMenu {
      display: block; }
    .tour-content-poi-box__topleft {
      max-width: 90%; }
    .tour-content-poi-box__topright {
      max-width: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    .tour-content-poi-box__category {
      display: none; }
    .tour-content-poi-box__description {
      margin: 0.9375rem 0 0 0;
      white-space: pre-line;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 400 !important;
      line-height: 1.8125rem;
      font-size: 1rem;
      color: #2c2c2c;
      overflow: visible; }
  .contentTitlesMap {
    display: none; }
  .map-box__header {
    justify-content: flex-end !important; }
  .head-map-modal {
    position: fixed;
    z-index: 1000;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-bottom: 1px dashed; }
    .head-map-modal .twoH {
      display: none !important; } }

@media screen and (min-width: 768px) {
  .ModalMap {
    width: 80%;
    height: 80%; }
    .ModalMap .MapaImage {
      width: 100%; }
    .ModalMap .modal-mapa-imagenes {
      height: 70%; }
  .mapRight {
    display: none; }
  .head-map-modal {
    position: fixed;
    z-index: 1000;
    background-color: white;
    width: 96%;
    padding-top: 10px;
    margin-left: 15px !important;
    /* margin-right : 15px !important; */ } }

@media screen and (min-width: 992px) {
  .ModalMap {
    width: 80%;
    height: 90%;
    border-bottom: 10px solid white;
    /* .twoH{
        margin-right: 30%;
      } */ }
    .ModalMap.modalPartner {
      width: 800px !important;
      height: auto !important;
      border-bottom: none; }
    .ModalMap .info {
      margin-top: 40px; }
    .ModalMap .threeH {
      width: 280px !important;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start; }
    .ModalMap .MapaImage {
      width: 70%; }
    .ModalMap .modal-mapa-imagenes {
      margin-top: 62px !important;
      height: 90%; }
  .mapRight {
    display: block; }
  .head-map-modal {
    padding-top: 10px;
    padding-left: 15px;
    position: fixed;
    z-index: 1;
    background-color: white;
    width: 80%;
    margin: 0 !important;
    padding-right: 15px;
    /* width        : 68%;
      margin-left   : 15px !important;
      margin-right  : 15px !important; */ }
  .contentPartner {
    display: none; } }

@media screen and (min-width: 992px) and (min-height: 800px) {
  .ModalMap {
    width: 80%;
    height: 60%; } }

@media screen and (min-width: 1200px) and (min-height: 800px) {
  .ModalMap {
    width: 65%;
    height: 80%;
    border-bottom: none; }
  .head-map-modal {
    position: fixed;
    z-index: 1000;
    background-color: white;
    width: 65%; } }

@media screen and (max-width: 992px) {
  .ModalMap {
    width: 100% !important;
    height: 100% !important; }
    .ModalMap .head-map-modal {
      width: 96% !important; }
    .ModalMap .MapaImage {
      width: 100% !important;
      height: 100% !important; }
      .ModalMap .MapaImage.infoBoxModal {
        height: auto !important; }
    .ModalMap .mapRight {
      width: 100% !important;
      max-width: 100% !important;
      height: auto; } }

.service-premium {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 1.875rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .service-premium__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .service-premium__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0;
      text-align: center; }
      .service-premium__header-icon svg {
        padding-top: 0.1875rem;
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .service-premium__owner {
    font-size: 0.6875rem;
    margin: 0 0 0 -0.625rem;
    font-weight: bold;
    color: #6f6f6f; }
    .service-premium__owner-name {
      display: inline-block;
      vertical-align: bottom;
      font-family: 'Oswald';
      font-weight: 100;
      text-transform: uppercase;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .service-premium__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }

.service-business {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 1.875rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .service-business__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .service-business__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0;
      text-align: center; }
      .service-business__header-icon svg {
        padding-top: 0.1875rem;
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .service-business__owner {
    font-size: 0.6875rem;
    margin: 0 0 0 -0.625rem;
    font-weight: bold;
    color: #6f6f6f; }
    .service-business__owner-name {
      display: inline-block;
      vertical-align: bottom;
      font-family: 'Oswald';
      font-weight: 100;
      text-transform: uppercase;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .service-business__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }

.poi-file {
  background-color: white;
  position: relative;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .poi-file .buttonLike, .poi-file .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .poi-file .buttonLike img, .poi-file .buttonComment img {
      width: 90%; }
  .poi-file .poi-file__routes p {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
    font-size: 20px;
    box-sizing: border-box; }
  .poi-file .poi-file__routes span {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 20px;
    color: #00affe; }
  .poi-file .poi-file__routes .routesWhite {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .poi-file .poi-file__routes .routesWhite img {
      width: 90%; }
  .poi-file .poi-file__routes .externalBlack {
    background: transparent;
    width: 25px;
    height: 25px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .poi-file .poi-file__routes .externalBlack img {
      width: 90%; }
  .poi-file .likePoi {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    border-left: 0.0625rem dashed #6f6f6f;
    border-right: 0.0625rem dashed #6f6f6f; }
    @media (max-width: 600px) {
      .poi-file .likePoi {
        padding: 0 8px;
        margin: 0 8px 0 10px !important; } }
  .poi-file__wrapper {
    margin: auto;
    max-width: 85.375rem;
    padding: 0.9375rem 0; }
  .poi-file__cover {
    position: relative;
    margin-bottom: 0;
    background-color: #2c2c2c; }
    .poi-file__cover-image {
      width: 100%;
      height: auto;
      max-height: calc(100vh - 50px);
      max-height: calc(var(--vh, 1vh) * 100 - 50px);
      object-fit: contain; }
    .poi-file__cover-author {
      display: inline-block;
      max-width: 78%;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: 0.3125rem 0.625rem 0.3125rem 1.875rem; }
      .poi-file__cover-author > svg {
        height: 2.75rem;
        margin: -0.75rem -0.375rem -0.8125rem 0;
        stroke-width: 0.625rem; }
        .poi-file__cover-author > svg #heart-path {
          fill: none;
          stroke: #2c2c2c;
          stroke-width: 1.625rem; }
        .poi-file__cover-author > svg circle {
          display: none; }
      .poi-file__cover-author-avatar {
        position: absolute;
        bottom: -0.625rem;
        left: 0.625rem;
        margin-left: -2.5rem;
        margin-bottom: 0;
        height: 3.125rem;
        width: 3.125rem;
        border: 0.125rem solid white;
        background-color: white;
        border-radius: 50%;
        object-fit: cover; }
      .poi-file__cover-author-name {
        font-family: 'Oswald';
        text-transform: uppercase;
        font-size: 0.9375rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
        display: inline-block; }
  .poi-file__title, .poi-file__address {
    color: #2c2c2c; }
  .poi-file__title {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0; }
  .poi-file__address {
    font-family: 'Oswald';
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.9375rem; }
  .poi-file__info {
    list-style: none;
    padding-left: 0;
    margin: 0; }
    .poi-file__info-item {
      display: inline-block;
      vertical-align: middle;
      color: #2c2c2c;
      font-size: 1rem;
      line-height: 1; }
      .poi-file__info-item svg {
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        height: 1rem;
        width: auto;
        max-width: 1.25rem;
        fill: #6f6f6f;
        margin-right: 0.1875rem; }
      .poi-file__info-item + .poi-file__info-item {
        margin-left: 0.9375rem; }
  .poi-file__description {
    margin: 0.9375rem 0;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }
  .poi-file__tags {
    padding-left: 0;
    list-style: none;
    margin-top: 0.625rem; }
    .poi-file__tags-item {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 1.2;
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: 0.9375rem;
      color: #2c2c2c;
      margin-right: 0.625rem; }
      .poi-file__tags-item:before {
        content: '#';
        display: inline-block;
        vertical-align: middle; }
  .poi-file__data-title, .poi-file__data-text, .poi-file__data-link {
    font-family: 'Roboto-bold';
    font-weight: 500;
    line-height: 1.2; }
  .poi-file__data-title {
    color: #2c2c2c;
    font-size: 0.9375rem;
    margin-bottom: 0; }
  .poi-file__data-text, .poi-file__data-link {
    display: inline-block;
    color: #6f6f6f;
    font-size: 0.875rem;
    margin-bottom: 0.9375rem;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .poi-file__data-link {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .poi-file__rrss {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media (max-width: 600px) {
      .poi-file__rrss {
        justify-content: center; } }
    .poi-file__rrss p {
      font-family: 'Oswald';
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px; }
    .poi-file__rrss-item {
      display: inline-block; }
    .poi-file__rrss-link {
      display: inline-block;
      cursor: pointer; }
      .poi-file__rrss-link + .poi-file__rrss-link {
        margin-left: 0.625rem; }
      .poi-file__rrss-link:hover circle {
        fill: #00affe; }
  .poi-file__author {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%; }
    .poi-file__author-avatar {
      display: inline-block;
      margin: 0 3.125rem 0.3125rem 0;
      width: 100%;
      height: 100%;
      border: 0.3125rem solid white;
      box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(44, 44, 44, 0.5);
      border-radius: 50%;
      object-fit: cover;
      position: relative; }
      .poi-file__author-avatar-wrapper {
        width: 6.25rem;
        height: 6.25rem; }
    .poi-file__author-name {
      font-family: 'Oswald';
      text-transform: uppercase;
      font-size: 1.125rem;
      margin: 0.625rem 0 0 0; }
  .poi-file__flag-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    right: -3.125rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
    .poi-file__flag-icon svg {
      fill: #6f6f6f;
      width: 2.5rem;
      height: 2.5rem; }
  .poi-file .tour-content-images-box {
    padding: 0; }
    .poi-file .tour-content-images-box__img-author {
      display: none; }
  .poi-file__action-btn {
    margin: auto;
    max-width: 15rem; }

@media screen and (min-width: 992px) {
  .poi-file {
    box-shadow: none;
    padding-bottom: 0; }
    .poi-file__wrapper {
      padding: 1.875rem 0; }
    .poi-file__cover-image {
      max-height: 135vh;
      max-height: calc(var(--vh, 1vh) * 135); }
    .poi-file__cover-author {
      top: 3.125rem;
      bottom: unset;
      padding: 0.625rem 0.625rem 0.625rem 2.1875rem; }
      .poi-file__cover-author > svg {
        height: 3.75rem;
        margin: -0.375rem; }
      .poi-file__cover-author-avatar {
        top: 0;
        bottom: 0;
        left: 0.3125rem;
        height: 4.1875rem;
        width: 4.1875rem;
        border: 0.375rem solid white; }
      .poi-file__cover-author-name {
        max-width: 9.375rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.875rem;
        vertical-align: middle; }
    .poi-file__cover-type {
      display: block;
      text-align: right;
      font-size: 1rem;
      text-transform: none;
      line-height: 1.1;
      font-weight: 100; }
    .poi-file__title {
      font-size: 1.75rem;
      font-weight: 500; }
    .poi-file__data-link {
      max-width: 37.5rem; }
    .poi-file__tags {
      margin-top: 0.625rem; }
      .poi-file__tags-item {
        font-size: 1.25rem; }
    .poi-file__info {
      padding-bottom: 0.1875rem; }
      .poi-file__info-item + .poi-file__info-item {
        margin-left: 1.25rem; }
    .poi-file__rrss {
      margin-bottom: 0; }
    .poi-file__map {
      padding: 0;
      margin: -1.875rem 0;
      height: calc(100% + 60px); }
      .poi-file__map .mapWrapper {
        height: 100%; }
    .poi-file__footer-title, .poi-file__author-name {
      display: inline-block;
      width: 50%;
      vertical-align: middle;
      color: white;
      font-size: 1.125rem;
      margin: 0; }
    .poi-file__footer {
      background-color: #2c2c2c;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem; }
      .poi-file__footer-title {
        font-family: 'Oswald';
        text-transform: uppercase;
        text-align: right;
        padding-right: 3.75rem; }
    .poi-file__author-name {
      padding-left: 3.75rem; }
      .poi-file__author-name svg {
        fill: white;
        height: 1.5625rem;
        width: auto;
        margin-left: 0.1875rem; }
    .poi-file__author-avatar {
      box-shadow: none;
      margin: 0;
      background-color: white;
      border: none; }
      .poi-file__author-avatar-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto; }
  .tour-content-images-box {
    margin-top: 1.25rem; } }

.partner-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 3.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  height: auto; }
  .partner-box__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .partner-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.1875rem 0 0 0;
      text-align: center; }
      .partner-box__header-icon svg {
        height: 75%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .partner-box__image {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px; }
  .partner-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }

.tour-sponsor-box .botonWhite {
  color: white !important; }

@media screen and (min-width: 992px) {
  .partner-box {
    margin-top: 4.6875rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 2.1875rem; }
    .partner-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .partner-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.8125rem;
        margin-right: 0.3125rem;
        padding-top: 0.625rem; }
    .partner-box__image {
      padding-left: 0px;
      padding-right: 0px; } }

.ModalVideo {
  z-index: 2000;
  /* padding-top: 10px; */
  position: relative;
  border-bottom: none;
  overflow: auto;
  width: 1045px !important;
  background-color: transparent;
  outline: none; }
  .ModalVideo::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .ModalVideo::-webkit-scrollbar-thumb {
    background: #00affe; }
  .ModalVideo .imagePartner {
    width: 100%; }
  .ModalVideo .buttonClose {
    position: absolute;
    right: 5px;
    top: 5px; }
  .ModalVideo .alturaImagen {
    height: inherit;
    padding-top: 36px !important;
    margin: 0px !important; }
  .ModalVideo .twoH .buttonActive {
    border-bottom: 5px solid #abdafd;
    padding-bottom: 32px; }
  .ModalVideo .threeH {
    width: auto;
    /* position: absolute;
    top: 2px;
    right: 8px; */ }
  .ModalVideo .info {
    margin-bottom: 50px;
    width: 90%; }
  .ModalVideo .info-modal {
    margin: 10px; }
    .ModalVideo .info-modal h4 {
      margin-right: 5px;
      margin-bottom: 0px; }
    .ModalVideo .info-modal p {
      margin-bottom: 0px; }
  .ModalVideo .actionIcon {
    background: transparent;
    width: 35px;
    height: 35px;
    border-width: 0;
    padding: 0;
    margin: 0px 10px 0px 0px;
    text-align: center;
    cursor: pointer; }
    .ModalVideo .actionIcon img {
      width: 80%; }
  .ModalVideo .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .ModalVideo .buttonLike img {
      width: 80%; }
  .ModalVideo .closeIcon {
    background-color: transparent;
    display: inline-block;
    border: none;
    vertical-align: middle;
    line-height: 1; }
    .ModalVideo .closeIcon svg {
      height: 1.75rem;
      width: 1.75rem;
      fill: white; }
  .ModalVideo .head-map-modal {
    width: 1045px !important;
    /* margin-left: 15px !important;
    margin-right: 15px !important; */ }
  .ModalVideo .modal-mapa-imagenes {
    height: 70%;
    /* .pdg0 {
      padding-left: 0px;
      padding-right: 35px;
    } */
    /*     img {
      width: inherit;
      padding-left: 0px;
      margin-right: 11px;

    } */ }
  .ModalVideo .MapaImage {
    width: auto !important;
    height: auto !important;
    flex-grow: 1;
    padding: 10px;
    /*     width: 70%; */
    height: 100%; }
    .ModalVideo .MapaImage .simple-map {
      height: 100% !important; }
  .ModalVideo .contentOneTwo {
    display: flex;
    justify-content: space-between;
    width: auto;
    flex-grow: 1; }
    .ModalVideo .contentOneTwo .oneH {
      -webkit-flex-grow: 1; }
  .ModalVideo .mapRight {
    width: 280px;
    height: 690px;
    max-width: 280px;
    flex-basis: unset;
    padding-top: 10px !important;
    padding-left: 5px !important;
    padding-bottom: 10px;
    padding-right: 5px !important;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    /*     width: 30%;
    height: 100%; */ }
    .ModalVideo .mapRight div {
      text-align: center; }
    .ModalVideo .mapRight p {
      /* height: 30%; */
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: start;
      font-size: 15px; }
    .ModalVideo .mapRight .partnerImage {
      width: 250px;
      height: 180px; }
    .ModalVideo .mapRight .collage {
      width: 250px;
      object-fit: cover;
      height: 400px; }
    .ModalVideo .mapRight__top {
      width: 250px; }
    .ModalVideo .mapRight__bot {
      margin-bottom: 10px;
      width: 250px;
      /* height: 100%; */ }

.tour-sponsor-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 3.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .tour-sponsor-box .campaing_image {
    height: 100% !important; }
  .tour-sponsor-box__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .tour-sponsor-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.1875rem 0 0 0;
      text-align: center; }
      .tour-sponsor-box__header-icon svg {
        height: 75%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .tour-sponsor-box__slide {
    height: 100vw;
    position: relative; }
    .tour-sponsor-box__slide-image {
      display: block;
      height: auto;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .tour-sponsor-box__p-container p {
    word-wrap: break-word; }
  .tour-sponsor-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }
  .tour-sponsor-box__title {
    font-family: 'Roboto',sans-serif !important;
    font-weight: 700;
    font-size: 1.1875rem; }
  .tour-sponsor-box__text {
    word-break: break-word;
    white-space: pre-line;
    font-family: 'Roboto',sans-serif !important;
    font-size: 1.1875rem; }
  .tour-sponsor-box__link {
    display: block;
    width: 9.375rem;
    cursor: pointer; }
    @media screen and (min-width: 992px) {
      .tour-sponsor-box__link {
        min-width: 0; } }
  .tour-sponsor-box .carousel.carousel-slider .slide {
    background-color: transparent; }
  .tour-sponsor-box .carousel.carousel-slider .control-dots .dot {
    background-color: #2c2c2c; }
  .tour-sponsor-box .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin: auto 0.3125rem;
    border-radius: 50%;
    opacity: 0.3;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .tour-sponsor-box .carousel.carousel-slider .control-arrow.control-prev:before, .tour-sponsor-box .carousel.carousel-slider .control-arrow.control-next:before {
      width: 0.9375rem;
      height: 0.9375rem;
      border-top: none;
      border-right: none;
      border-bottom: 0.1875rem solid #2c2c2c;
      border-left: 0.1875rem solid #2c2c2c; }
    .tour-sponsor-box .carousel.carousel-slider .control-arrow.control-prev:before {
      margin-left: 0.625rem;
      transform: rotate(50deg) skewY(-15deg); }
    .tour-sponsor-box .carousel.carousel-slider .control-arrow.control-next:before {
      margin-right: 0.625rem;
      transform: rotate(-135deg); }
    .tour-sponsor-box .carousel.carousel-slider .control-arrow:hover {
      opacity: 1; }

@media screen and (min-width: 992px) {
  .tour-sponsor-box {
    margin-top: 4.6875rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 2.1875rem; }
    .tour-sponsor-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .tour-sponsor-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.8125rem;
        margin-right: 0.3125rem;
        padding-top: 0.625rem; }
    .tour-sponsor-box__slide {
      height: 40.625rem; } }

.map-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem;
  position: relative;
  margin-top: 2.8125rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .map-box__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .map-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.1875rem 0 0 0;
      text-align: center; }
      .map-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .map-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }

@media screen and (min-width: 992px) {
  .map-box {
    margin-top: 3.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 2.1875rem; }
    .map-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .map-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.8125rem;
        background-color: white;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        margin-right: 0.3125rem;
        text-align: center; }
        .map-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; } }

.author-box {
  background-color: white;
  padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 3.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .author-box .designprofile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    position: relative; }
  .author-box .profileItems {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .author-box .profileItems svg {
      height: 0.9375rem;
      width: 0.9375rem;
      margin-right: 0.3125rem;
      fill: #2c2c2c; }
  .author-box .centerNickName {
    text-align: center;
    padding-top: 15px; }
  .author-box__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .author-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0 0;
      text-align: center; }
      .author-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .author-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }
  .author-box__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .author-box__avatar-wrapper {
      position: relative;
      width: 4.6875rem;
      height: 4.6875rem;
      border-radius: 50%;
      box-shadow: 0 0 0.1875rem rgba(44, 44, 44, 0.3);
      border: 0.3125rem solid white;
      background-color: white;
      overflow: hidden;
      margin: 0 auto; }
  .author-box__top-traveler-wrapper {
    text-align: center;
    margin-top: 0.3125rem; }
    .author-box__top-traveler-wrapper svg {
      width: 7.5rem;
      max-width: 100%; }
  .author-box__nickname {
    color: black;
    margin-bottom: 0.4375rem;
    font-size: 22px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    text-align: center; }
  .author-box__data {
    list-style: none;
    padding-left: 0;
    margin-top: 0.9375rem;
    position: relative;
    max-width: 100%; }
    .author-box__data-item {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #2c2c2c;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .author-box__data-item svg {
        height: 0.9375rem;
        width: 0.9375rem;
        margin-right: 0.3125rem;
        fill: #2c2c2c; }
  .author-box__name {
    font-family: 'Oswald';
    text-transform: uppercase;
    font-size: 1.125rem;
    margin: 0.625rem 0 0 0;
    line-height: 1; }
  .author-box__website {
    display: inline-block;
    max-width: 100%;
    color: #2c2c2c;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1;
    text-decoration: underline;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .author-box__rrss {
    padding: 0;
    list-style: none;
    text-align: center;
    margin-top: 0.9375rem; }
    .author-box__rrss-item {
      display: inline-block;
      margin: 0 0.3125rem;
      line-height: 2.5rem; }
    .author-box__rrss-link {
      display: inline-block;
      width: 2.1875rem;
      height: 2.1875rem;
      border-radius: 50%;
      text-align: center;
      line-height: 2.1875rem; }
      .author-box__rrss-link svg {
        fill: #333033;
        height: 100%;
        width: auto; }
      .author-box__rrss-link:hover svg {
        fill: #00affe; }
  .author-box__description {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }

@media screen and (min-width: 992px) {
  .author-box {
    margin-top: 3.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 2.1875rem; }
    .author-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .author-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.8125rem;
        background-color: white;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        margin-right: 0.3125rem;
        padding: 0.5rem 0 0.1875rem 0;
        text-align: center; }
        .author-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; }
    .author-box__info {
      padding-bottom: 0.9375rem;
      border-bottom: 0.0625rem dotted #2c2c2c; }
    .author-box__avatar-wrapper {
      width: 6.25rem;
      height: 6.25rem; }
    .author-box__avatar-flag {
      position: absolute;
      top: -0.625rem;
      right: 0.3125rem; }
      .author-box__avatar-flag svg {
        fill: #6f6f6f;
        height: 1.875rem;
        width: auto; }
    .author-box__rrss {
      text-align: right;
      margin: 0; }
      .author-box__rrss-item {
        margin: 0 0 0 0.3125rem; }
      .author-box__rrss-link {
        width: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem; }
    .author-box__top-traveler-wrapper {
      text-align: left;
      margin-top: 0.625rem; }
    .author-box__data {
      left: unset;
      margin-top: 0.3125rem; }
      .author-box__data-item {
        display: inline-block;
        width: auto;
        font-size: 0.9375rem; }
        .author-box__data-item + .author-box__data-item {
          margin-left: 0.625rem; } }

.gallery-box {
  background-color: white;
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
  position: relative;
  margin-top: 3.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .gallery-box__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .gallery-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0 0;
      text-align: center; }
      .gallery-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .gallery-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }
  .gallery-box__slide-trigger {
    border: none;
    padding: 0.3125rem;
    background-color: white; }
    .gallery-box__slide-trigger[data-type="default"] {
      cursor: inherit; }
      .gallery-box__slide-trigger[data-type="default"] .gallery-box__slide-image {
        visibility: hidden; }
  .gallery-box__slide-image {
    height: calc(33vw - 12.37px);
    object-fit: cover;
    margin-bottom: 0; }
  .gallery-box .carousel .slide {
    background-color: white; }
  .gallery-box .carousel.carousel-slider {
    padding-bottom: 2.1875rem; }
  .gallery-box .control-dots {
    padding-left: 0; }
    .gallery-box .control-dots .dot {
      box-shadow: none;
      background-color: #6f6f6f;
      width: 0.375rem;
      height: 0.375rem;
      margin: 0 0.125rem; }
      .gallery-box .control-dots .dot.selected {
        background-color: #2c2c2c; }

@media screen and (min-width: 992px) {
  .gallery-box {
    margin-top: 4.6875rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 10rem 0.9375rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4000 1000'%3E%3Cg clip-rule='evenodd'%3E%3Cpath fill-rule='evenodd' fill='%232C2C2C' d='M0 131.8c372.119 13.294 746.652 18.572 1116.35-25.7 306.98-36.761 448.116-65.419 674.56-78.616 186.833-10.889 373.801.143 558.855 26.888C2530.47 80.489 2822.537 99.406 3005 104c370.031 9.316 627.156-12.963 995-54.258v915.601c-378.941-9.219-722.709 12.711-1131.013-22.3-22.38-1.919-465.592-49.518-602.987-45.043-114.231 3.721-291.784 9.61-462 25-139.219 12.587-205 38-396.648 42.343-297.965 6.752-367.667-95.569-601.666-90.818-131.67 2.674-249.091 15.093-378.945 26.337C320.424 910.067 50 954 0 910.931'/%3E%3Cpath fill='none' stroke='red' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='3' d='M2722.275 91.8h2.074'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    box-shadow: none; }
    .gallery-box__header {
      top: 5vw;
      top: 3.4375rem;
      justify-content: center; }
      .gallery-box__header-title {
        color: white;
        text-align: center;
        font-size: 2.25rem;
        margin-bottom: 1.5625rem; }
      .gallery-box__header-subtitle {
        display: block;
        font-size: 1.5rem;
        margin-top: 0.3125rem; }
    .gallery-box__slide-trigger, .gallery-box__slide-image {
      background-color: #2c2c2c; }
    .gallery-box__slide-image {
      height: calc(960px / 4 - 30px); }
    .gallery-box__footer {
      padding: 0 2.5rem; }
    .gallery-box .carousel.carousel-slider {
      padding: 0 2.5rem 0.9375rem 2.5rem; }
      .gallery-box .carousel.carousel-slider .control-arrow:hover {
        background: none; }
      .gallery-box .carousel.carousel-slider .control-arrow:before {
        border-top-width: 1rem;
        border-bottom-width: 1rem; }
      .gallery-box .carousel.carousel-slider .control-arrow.control-prev:before {
        border-right: 1rem solid white; }
      .gallery-box .carousel.carousel-slider .control-arrow.control-next:before {
        border-left: 1rem solid white; }
      .gallery-box .carousel.carousel-slider .control-dots {
        display: none; } }

@media screen and (min-width: 1200px) {
  .gallery-box__slide-image {
    height: calc(1140px / 4 - 53.75px); } }

.intro-box {
  background-color: white;
  border-radius: 0.9375rem;
  font-family: 'Roboto-bold';
  padding-top: 2rem; }
  .intro-box h3 {
    color: #303030; }
  .intro-box .svg-login svg {
    fill: #00affe;
    max-height: 6rem;
    width: auto; }
  .intro-box .form-group {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100; }
    .intro-box .form-group label {
      position: relative; }
    .intro-box .form-group svg {
      fill: #00affe;
      height: 1.3rem;
      bottom: 8px;
      left: 2px;
      position: absolute;
      width: 40px; }
  .intro-box hr {
    border-color: #2c2c2c;
    border-style: dashed; }
  .intro-box .d-inline-block {
    font-size: 1.2rem; }

@media screen and (min-width: 768px) {
  .grey-bg:before {
    background-color: #dedede;
    content: "";
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1; }
  .intro-box {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin: 3rem 0; }
    .intro-box .w75 {
      max-width: 75%; } }

.register-option {
  border: 1px solid #00affe;
  padding-top: 3rem;
  text-align: center; }
  .register-option img {
    float: left; }
  .register-option p {
    color: #6f6f6f;
    line-height: 1.4;
    text-align: left; }
    .register-option p:first-of-type {
      color: #303030;
      margin-bottom: 0; }

.not-allowed {
  opacity: .2;
  pointer-events: none; }

.price span {
  color: #00affe; }

@media screen and (min-width: 768px) {
  .register-option {
    border: 0;
    padding: 0 3rem; }
    .register-option.b-right {
      border-right: 1px dashed #2c2c2c; }
    .register-option p {
      font-size: 1.1rem; }
    .register-option img {
      float: none; }
  .price {
    padding-left: 3.125rem; }
    .price span {
      display: block;
      padding-left: 6.25rem; } }

footer.main-footer {
  background-color: #2c2c2c;
  color: white;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100;
  font-size: .9rem;
  margin-top: 200px;
  position: relative; }
  footer.main-footer a {
    color: #dedede; }
  footer.main-footer svg {
    min-height: 380px;
    position: absolute;
    /* top    : -180px; */
    left: 0;
    bottom: 0px;
    width: 100%; }
  footer.main-footer img {
    display: none; }
  footer.main-footer .row {
    padding-bottom: 50px; }

.font-foot {
  font-family: 'Oswald'; }

.site-map {
  font-size: 1rem; }
  .site-map a {
    font-family: "Roboto-Regular"; }
  .site-map h4 {
    font-size: 1rem;
    font-family: 'Oswald';
    text-transform: uppercase; }
  .site-map ul {
    list-style-type: none;
    padding-left: 0; }
    .site-map ul li {
      padding-left: 1.25rem; }

@media screen and (min-width: 768px) {
  footer.main-footer img {
    display: inline-block;
    width: 65%; }
  .site-map {
    border-left: 1px dashed white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .site-map ul {
      padding-left: 2rem; } }

.modal-image-file {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  margin: 0 auto 0 auto;
  padding: 0 0 1.25rem 0;
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .modal-image-file .comments {
    width: 100%; }
  .modal-image-file .buttonLike,
  .modal-image-file .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .modal-image-file .buttonLike img,
    .modal-image-file .buttonComment img {
      width: 90%; }
  .modal-image-file .likePoi {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    text-align: center;
    border-left: 0.0625rem dashed #6f6f6f;
    border-right: 0.0625rem dashed #6f6f6f; }
    @media (max-width: 600px) {
      .modal-image-file .likePoi {
        padding: 0 8px;
        margin: 0 5px 0 10px !important; } }
  .modal-image-file::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .modal-image-file::-webkit-scrollbar-thumb {
      background: #00affe; }
  .modal-image-file.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .modal-image-file__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(44, 44, 44, 0.9);
    z-index: 1010; }
  .modal-image-file__close-btn {
    width: 55px;
    height: 55px;
    position: fixed;
    background-color: transparent;
    border: none;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 2001; }
    .modal-image-file__close-btn svg {
      fill: white;
      width: 55px;
      height: 55px; }
  .modal-image-file__header {
    margin: 0 0 1.875rem 0;
    background-color: #303030;
    padding: 0.9375rem 0 3.125rem 0;
    position: relative; }
  .modal-image-file__image {
    display: block;
    max-width: 100%;
    max-height: 80vh;
    max-height: calc(var(--vh, 1vh) * 80);
    margin: 0 auto; }
  .modal-image-file__user {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Oswald';
    position: absolute;
    bottom: -2.1875rem;
    right: 0;
    left: 0;
    text-align: center;
    text-transform: uppercase; }
    .modal-image-file__user figure {
      margin: 0 0.625rem; }
  .modal-image-file__description {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }
  .modal-image-file__action-btn {
    margin: auto;
    width: calc(50% - 10px); }
    .modal-image-file__action-btn + .modal-image-file__action-btn {
      margin-top: 0.3125rem; }
  .modal-image-file__content {
    max-width: 85.375rem;
    padding: 2.5rem 0.9375rem; }
    .modal-image-file__content-left section {
      margin-bottom: 15px;
      margin-left: 5px;
      display: flex;
      align-items: center; }
      .modal-image-file__content-left section p {
        font-family: 'Oswald';
        margin-bottom: 10px;
        text-align: center;
        font-size: 14px; }
    .modal-image-file__content-left > a svg {
      height: 2.5rem;
      margin-right: 0.625rem;
      margin-bottom: 1rem; }
    .modal-image-file__content-left .hashtags {
      font-size: 1.25rem;
      font-weight: bold; }
      .modal-image-file__content-left .hashtags span {
        margin-right: 0.625rem; }
    .modal-image-file__content-left .toplinks {
      font-size: 0.8rem; }
      .modal-image-file__content-left .toplinks a {
        color: initial; }
      .modal-image-file__content-left .toplinks svg {
        margin: 0 0.3125rem 0 0.625rem;
        width: 1rem; }
        .modal-image-file__content-left .toplinks svg:first-of-type {
          margin-left: 0; }
    .modal-image-file__content-left button {
      margin: 0 0.3125rem; }
    .modal-image-file__content-right figcaption {
      font-family: 'Oswald';
      text-align: center;
      text-transform: uppercase; }
  .modal-image-file__poi-cover, .modal-image-file__partner-logo {
    display: block;
    width: 100%;
    height: auto; }
  .modal-image-file__poi-cover {
    margin: 0 auto; }
  .modal-image-file__poi-link {
    color: #2c2c2c; }
    .modal-image-file__poi-link:hover {
      text-decoration: none;
      color: #2c2c2c; }
  .modal-image-file__partner-logo {
    width: 180px;
    height: 130px;
    /* max-width: rem(200px);
    max-height: rem(250px); */
    margin: 0.9375rem auto 0 auto; }
  .modal-image-file__poi-title {
    max-height: 15.625rem;
    font-family: 'Roboto-bold';
    font-size: 0.8rem;
    margin: 0 auto; }
    .modal-image-file__poi-title svg {
      margin-right: 0.625rem;
      vertical-align: text-bottom;
      width: 1.25rem; }
  .modal-image-file__rrss-link {
    display: inline-block;
    text-align: center;
    cursor: pointer; }
    .modal-image-file__rrss-link + .modal-image-file__rrss-link {
      margin-left: 0.1875rem; }
    .modal-image-file__rrss-link:hover circle {
      fill: #00affe; }
    .modal-image-file__rrss-link p {
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px; }
  .modal-image-file .modal_image {
    max-height: calc(var(--vh, 1vh) * 80);
    width: auto !important; }
  .modal-image-file .carousel .slide {
    background-color: #303030; }
  .modal-image-file .carousel .control-dots {
    display: none; }

@media screen and (min-width: 992px) {
  .modal-image-file__action-btn {
    width: auto;
    max-width: 17.5rem; } }

.overlayModal {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

.tour-search {
  padding-top: 0.9375rem; }
  .tour-search__title {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Roboto-bold';
    font-size: 1.75rem;
    font-weight: 500;
    color: #2c2c2c;
    white-space: nowrap;
    line-height: 1.5rem;
    margin-top: 1.25rem; }
    .tour-search__title-legend {
      line-height: 1.5rem;
      display: inline-block;
      vertical-align: middle;
      margin-left: 1.25rem;
      font-family: 'Oswald';
      font-size: 1rem;
      text-transform: uppercase; }
      .tour-search__title-legend svg {
        display: inline-block;
        line-height: 1.5rem;
        vertical-align: bottom;
        fill: #ffcb02;
        height: 2rem;
        width: auto;
        margin-right: 0.625rem;
        padding: 5px;
        border-radius: 4px;
        background-color: #40444B; }
  .tour-search__search {
    display: block;
    padding-right: 2.1875rem;
    position: relative;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100; }
    .tour-search__search-icon svg {
      height: 1.25rem;
      width: auto;
      position: absolute;
      right: 0.625rem;
      top: 0.625rem; }
  .tour-search .autocomplete-tag {
    padding-top: 2.5rem;
    position: relative; }
    .tour-search .autocomplete-tag .react-autosuggest__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
  .tour-search .autosuggest-wrapper__input {
    border-radius: 0.625rem;
    height: 2.5rem;
    text-transform: uppercase;
    margin: 0; }
    .tour-search .autosuggest-wrapper__input-field {
      background-color: white; }
  .tour-search__toggle {
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    line-height: 2.375rem; }
    .tour-search__toggle svg {
      width: auto;
      height: 1.75rem; }
    .tour-search__toggle--blue svg {
      fill: #00affe; }
  .tour-search__results {
    margin: 0.9375rem 0; }
  .tour-search__guide {
    animation: showIn 0.3s; }
    .tour-search__guide-title {
      margin-top: 1.5625rem;
      font-family: 'Roboto-bold';
      color: #2c2c2c;
      font-size: 1.25rem; }
    .tour-search__guide-example {
      color: #2c2c2c;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.2; }
      .tour-search__guide-example span {
        display: block;
        color: #6f6f6f;
        font-size: 1rem;
        font-weight: 500; }
    .tour-search__guide--hidden {
      display: none; }

@media screen and (max-width: 991px) {
  .tour-search__results {
    padding: 0;
    overflow-x: hidden; } }

@media screen and (min-width: 992px) {
  .tour-search {
    padding-top: 0; }
    .tour-search__title {
      margin: 0; }
    .tour-search__results {
      border-right: 0.0625rem dashed #2c2c2c; }
    .tour-search__guide--hidden + .tour-search__results {
      border-right: none; } }

@keyframes showIn {
  0% {
    transform: translate(100%, 0); }
  100% {
    transform: translate(0, 0); } }

.validation-input--error input,
.validation-input--error textarea {
  border: 0.0625rem solid #d34c50; }

.simple-map {
  height: 31.25rem; }

.profile-list__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

@media screen and (max-width: 991px) {
  .profile-list__items {
    padding: 0; }
    .profile-list__items .content-card {
      flex-basis: calc(50% - 10px); } }

.flex-responsive-form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1 0 auto;
  padding: 0 10px; }
  .flex-responsive-form-row__label {
    order: 0;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem; }
    .flex-responsive-form-row__label svg {
      height: 2rem;
      width: 2rem;
      min-height: 2rem;
      min-width: 2rem;
      margin: 0 0.3125rem; }
  .flex-responsive-form-row__label-container {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .flex-responsive-form-row__input {
    order: 1;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
    box-sizing: border-box; }
    .flex-responsive-form-row__input--rich-text-editor {
      min-height: 12.5rem;
      min-width: 100%;
      display: flex;
      flex-direction: column; }
      .flex-responsive-form-row__input--rich-text-editor .ql-toolbar {
        flex-grow: 1;
        max-height: 2.8125rem; }
      .flex-responsive-form-row__input--rich-text-editor .ql-container {
        flex-grow: 3;
        min-height: 9.6875rem;
        display: flex;
        flex-direction: column; }
        .flex-responsive-form-row__input--rich-text-editor .ql-container .ql-editor {
          flex-grow: 1; }
  .flex-responsive-form-row__vertical-label-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-grow: 3; }
  .flex-responsive-form-row--flex-end {
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end; }
  .flex-responsive-form-row--flex-start {
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start; }
  .flex-responsive-form-row__btn-save-container {
    max-width: 12.5rem;
    padding: 0 0.625rem; }
  .flex-responsive-form-row > svg {
    height: auto;
    width: 4rem;
    margin-right: 1.25rem; }

@media screen and (min-width: 992px) {
  .flex-responsive-form-row {
    flex-wrap: nowrap;
    padding: 0 20px; }
    .flex-responsive-form-row__label {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: space-between; }
    .flex-responsive-form-row__label-container {
      width: unset; }
    .flex-responsive-form-row__input {
      flex-grow: 3;
      flex-shrink: 3; }
      .flex-responsive-form-row__input--rich-text-editor {
        min-width: 100%; }
    .flex-responsive-form-row__vertical-label-input {
      flex-wrap: nowrap; }
    .flex-responsive-form-row > svg {
      height: auto;
      width: 5rem; } }

.tour-content-edition-modal {
  width: 100%;
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 3.125rem; }
  .tour-content-edition-modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010; }
  .tour-content-edition-modal__header {
    background-color: #2c2c2c;
    margin-bottom: 1.25rem;
    height: 3.125rem;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1; }
    .tour-content-edition-modal__header-actions {
      position: absolute;
      right: 1.25rem;
      top: 0.3125rem;
      bottom: 0;
      margin: auto 0;
      width: auto;
      padding: 0; }
    .tour-content-edition-modal__header-btn {
      padding: 0;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1;
      background-color: #2c2c2c;
      border: 0.125rem solid #00affe;
      vertical-align: middle; }
      .tour-content-edition-modal__header-btn svg {
        height: 60%;
        width: 60%;
        fill: white; }
      .tour-content-edition-modal__header-btn--blue {
        background-color: #00affe; }
      .tour-content-edition-modal__header-btn--highlighted {
        fill: greenyellow; }
      .tour-content-edition-modal__header-btn + .tour-content-edition-modal__header-btn {
        margin-left: 0.625rem; }
      .tour-content-edition-modal__header-btn .promise-button__bounce {
        width: 0.625rem;
        height: 0.625rem; }
  .tour-content-edition-modal__form-wrapper {
    height: 100%;
    padding: 0.9375rem 0 0.625rem 0;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c;
    scrollbar-track-color: #00affe;
    -webkit-overflow-scrolling: touch; }
    .tour-content-edition-modal__form-wrapper::-webkit-scrollbar {
      background-color: #2c2c2c;
      width: 0.3125rem; }
    .tour-content-edition-modal__form-wrapper::-webkit-scrollbar-thumb {
      background: #00affe; }

@media screen and (max-width: 374px) {
  .tour-content-edition-modal__header {
    text-align: left; } }

@media screen and (min-width: 992px) {
  .tour-content-edition-modal {
    width: 56.25rem;
    margin: 6vh auto 0 auto;
    margin-top: calc(var(--vh, 1vh) * 6);
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    padding-top: 4.375rem;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .tour-content-edition-modal.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .tour-content-edition-modal__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(44, 44, 44, 0.9);
      z-index: 1010; }
    .tour-content-edition-modal__header {
      border-bottom: 0.0625rem solid #2c2c2c;
      background-color: transparent;
      text-align: right;
      margin: 0 0.9375rem;
      padding: 0.9375rem 0;
      height: auto;
      position: absolute; }
      .tour-content-edition-modal__header-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.375rem;
        margin: 0 0.625rem 0 0; }
      .tour-content-edition-modal__header-actions {
        position: unset; }
      .tour-content-edition-modal__header-btn {
        border: none; }
        .tour-content-edition-modal__header-btn + .tour-content-edition-modal__header-btn {
          margin-left: 0.3125rem; }
    .tour-content-edition-modal__form-wrapper {
      max-height: 100%;
      padding: 0.9375rem 1.875rem 0.625rem 1.875rem; } }

@media screen and (min-width: 1200px) {
  .tour-content-edition-modal {
    width: 62.5rem; } }

.tour-content-text-form {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .tour-content-text-form::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .tour-content-text-form::-webkit-scrollbar-thumb {
    background: #00affe; }
  .tour-content-text-form__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem;
    margin-bottom: 0.625rem; }
  .tour-content-text-form__figure {
    margin: 0;
    padding-right: 0; }
    .tour-content-text-form__figure > svg {
      height: auto;
      width: 4rem; }
  .tour-content-text-form__text-editor {
    min-height: 9.375rem;
    min-width: 100%; }
    .tour-content-text-form__text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .tour-content-text-form__text-editor .ql-container {
      min-height: 6.25rem; }

@media screen and (min-width: 992px) {
  .text-tour-content-modal__figure {
    padding-right: 0.9375rem; } }

.tour-content-video-form__supported-text {
  display: inline-block;
  vertical-align: middle;
  font-family: 'Roboto-bold';
  font-weight: bold;
  font-size: 1.125rem;
  margin: 0 0.625rem 0 0; }

.tour-content-video-form__supported-type {
  display: inline-block;
  vertical-align: middle; }
  .tour-content-video-form__supported-type svg {
    width: 2.5rem;
    height: auto;
    margin-right: 0.3125rem; }
  .tour-content-video-form__supported-type + .tour-content-video-form__supported-type {
    margin-left: 0.625rem; }

.tour-content-video-form__label {
  text-align: center;
  font-family: 'Roboto-bold';
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.125rem;
  margin-bottom: 0.625rem; }

.tour-content-video-form__text-editor {
  min-height: 9.375rem;
  min-width: 100%; }
  .tour-content-video-form__text-editor .ql-toolbar {
    max-height: 2.8125rem; }
  .tour-content-video-form__text-editor .ql-container {
    min-height: 6.25rem; }

.tour-content-duration-form__label {
  text-align: center;
  font-family: 'Roboto-bold';
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.125rem;
  margin-bottom: 0.625rem; }

.tour-content-tip-form {
  font-family: 'Roboto-bold';
  padding-bottom: 1.25rem; }
  .tour-content-tip-form__title {
    font-weight: bold;
    font-size: 1.375rem;
    margin-bottom: 0.9375rem; }
  .tour-content-tip-form__cover {
    width: calc(50vw - 25px);
    height: 30vw;
    min-height: 10.9375rem;
    object-fit: cover;
    margin-bottom: 1.25rem; }
    .tour-content-tip-form__cover--no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.0625rem dotted #6f6f6f;
      padding: 0.9375rem; }
      .tour-content-tip-form__cover--no-image .tour-content-tip-form__cover-icon {
        margin-bottom: 0.9375rem; }
        .tour-content-tip-form__cover--no-image .tour-content-tip-form__cover-icon svg {
          width: 2.5rem;
          height: auto; }
  .tour-content-tip-form__label {
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem; }
    .tour-content-tip-form__label svg {
      margin-right: 0.625rem;
      fill: #00affe;
      width: 1.875rem; }
  .tour-content-tip-form__photos-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .tour-content-tip-form__photos-item {
    height: 7.8125rem;
    width: 6.25rem;
    display: inline-block;
    margin: 0 0.3125rem 0.3125rem 0;
    padding-top: 1.5625rem; }
    .tour-content-tip-form__photos-item .fileInput {
      padding: 0; }
    .tour-content-tip-form__photos-item .fine-uploader__files-wrapper {
      padding: 0; }
    .tour-content-tip-form__photos-item .fileInput,
    .tour-content-tip-form__photos-item .fine-uploader__file-input,
    .tour-content-tip-form__photos-item .fine-uploader__files-wrapper,
    .tour-content-tip-form__photos-item .responsive-img {
      height: 100%; }
    .tour-content-tip-form__photos-item .thumbnails-wrapper__box {
      display: none; }
  .tour-content-tip-form__photos-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; }
  .tour-content-tip-form__photos-add {
    border: none;
    background-color: #00affe;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
    .tour-content-tip-form__photos-add:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .tour-content-tip-form__photos-add svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white; }
  .tour-content-tip-form__photos-remove {
    position: absolute;
    top: -1.5625rem;
    right: 0.1875rem;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-content-tip-form__photos-remove svg {
      fill: #d34c50;
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-content-tip-form__rich-text-editor {
    min-height: 9.375rem;
    width: 100%; }
    .tour-content-tip-form__rich-text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .tour-content-tip-form__rich-text-editor .ql-container {
      min-height: 6.25rem; }
    .tour-content-tip-form__rich-text-editor strong {
      color: #00affe;
      font-weight: 100; }
    .tour-content-tip-form__rich-text-editor a {
      text-decoration: underline; }
    .tour-content-tip-form__rich-text-editor p {
      margin-bottom: 0; }
  .tour-content-tip-form__suggestions {
    line-height: 1.2;
    font-size: 1.125rem;
    border: 0.0625rem solid #303030;
    position: absolute;
    top: 2.5rem;
    left: 0.9375rem;
    right: 0;
    z-index: 1;
    background-color: white; }
    .tour-content-tip-form__suggestions-item {
      padding: 0.625rem; }
      .tour-content-tip-form__suggestions-item--active {
        background-color: #00affe;
        color: white; }
      .tour-content-tip-form__suggestions-item + .poi-position-modal__suggestions-item {
        border-top: 0.0625rem solid #303030; }
  .tour-content-tip-form__map-title {
    font-size: 1.375rem;
    text-align: center;
    margin-top: 0.9375rem; }
  .tour-content-tip-form__map {
    padding: 0; }
  .tour-content-tip-form__location {
    padding: 0.625rem 0 0.625rem 0.9375rem;
    border-left: 0.0625rem dashed grey; }
    .tour-content-tip-form__location-btn {
      background: none;
      border: none; }
    .tour-content-tip-form__location-img {
      margin: 0 auto;
      height: 4.6875rem;
      width: 4.6875rem; }
    .tour-content-tip-form__location-text {
      text-transform: uppercase;
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: 1.125rem; }
  .tour-content-tip-form__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(255, 255, 255, 0.5); }
    .tour-content-tip-form__no-visible-layer svg {
      width: 1.875rem;
      height: 1.875rem;
      fill: #2c2c2c;
      stroke: #2c2c2c; }

@media screen and (min-width: 992px) {
  .tour-content-tip-form {
    margin-top: 0; }
    .tour-content-tip-form__cover {
      width: 100%;
      height: auto;
      min-height: 10.9375rem;
      object-fit: cover;
      margin-bottom: 1.25rem; }
    .tour-content-tip-form__location {
      border: none;
      padding: 0; }
      .tour-content-tip-form__location-btn {
        margin-left: 0.9375rem; }
      .tour-content-tip-form__location-text {
        display: block;
        text-transform: uppercase;
        margin: 0.3125rem 0 0 0; }
      .tour-content-tip-form__location-img {
        width: 3.4375rem;
        height: 3.4375rem; }
    .tour-content-tip-form__map {
      padding: 0.9375rem; } }

.tour-content-map-form {
  padding-bottom: 1.5625rem; }
  .tour-content-map-form__location {
    padding: 0.625rem 0 0.625rem 0.9375rem;
    border-left: 0.0625rem dashed grey; }
    .tour-content-map-form__location-btn {
      background: none;
      border: none; }
    .tour-content-map-form__location-img {
      margin: 0 auto;
      height: 4.6875rem;
      width: 4.6875rem; }
    .tour-content-map-form__location-text {
      text-transform: uppercase;
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: 1.125rem; }
  .tour-content-map-form__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem;
    margin-bottom: 0.625rem; }
  .tour-content-map-form__text-editor {
    min-height: 9.375rem;
    min-width: 100%; }
    .tour-content-map-form__text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .tour-content-map-form__text-editor .ql-container {
      min-height: 6.25rem; }
  .tour-content-map-form__suggestions {
    line-height: 1.2;
    font-size: 1.125rem;
    border: 0.0625rem solid #303030;
    position: absolute;
    top: 2.5rem;
    left: 0.9375rem;
    right: 0;
    z-index: 1;
    background-color: white; }
    .tour-content-map-form__suggestions-item {
      padding: 0.625rem; }
      .tour-content-map-form__suggestions-item--active {
        background-color: #00affe;
        color: white; }
      .tour-content-map-form__suggestions-item + .poi-position-modal__suggestions-item {
        border-top: 0.0625rem solid #303030; }
  .tour-content-map-form__map-title {
    font-size: 1.375rem;
    text-align: center;
    margin-top: 0.9375rem; }

@media screen and (min-width: 992px) {
  .tour-content-map-form__location {
    border: none;
    padding: 0; }
    .tour-content-map-form__location-btn {
      margin-left: 0.9375rem; }
    .tour-content-map-form__location-text {
      display: block;
      text-transform: uppercase;
      margin: 0.3125rem 0 0 0; }
    .tour-content-map-form__location-img {
      width: 3.4375rem;
      height: 3.4375rem; } }

.tour-content-recommendation-form {
  font-family: 'Roboto-bold';
  padding-bottom: 1.25rem; }
  .tour-content-recommendation-form__title {
    font-weight: bold;
    font-size: 1.375rem;
    margin-bottom: 0.9375rem; }
  .tour-content-recommendation-form__service-name {
    font-size: 1.25rem; }
  .tour-content-recommendation-form__cover {
    width: calc(50vw - 25px);
    height: 30vw;
    min-height: 10.9375rem;
    object-fit: cover;
    margin-bottom: 1.25rem; }
    .tour-content-recommendation-form__cover--no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.0625rem dotted #6f6f6f;
      padding: 0.9375rem; }
      .tour-content-recommendation-form__cover--no-image .tour-content-recommendation-form__cover-img {
        width: 3.75rem;
        height: auto; }
  .tour-content-recommendation-form__tag {
    background-color: #81d4fb;
    padding: 0.3125rem;
    line-height: 1;
    position: absolute;
    bottom: -1.5625rem;
    right: 0; }
  .tour-content-recommendation-form__label {
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem; }
    .tour-content-recommendation-form__label svg {
      margin-right: 0.625rem;
      fill: #00affe;
      width: 1.875rem; }
  .tour-content-recommendation-form__photos-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .tour-content-recommendation-form__photos-item {
    height: 7.8125rem;
    width: 6.25rem;
    display: inline-block;
    margin: 0 0.3125rem 0.3125rem 0;
    padding-top: 1.5625rem;
    z-index: 1010;
    cursor: grab; }
    .tour-content-recommendation-form__photos-item .fileInput {
      padding: 0; }
    .tour-content-recommendation-form__photos-item .fine-uploader__files-wrapper {
      padding: 0; }
    .tour-content-recommendation-form__photos-item .fileInput,
    .tour-content-recommendation-form__photos-item .fine-uploader__file-input,
    .tour-content-recommendation-form__photos-item .fine-uploader__files-wrapper,
    .tour-content-recommendation-form__photos-item .responsive-img {
      height: 100%; }
    .tour-content-recommendation-form__photos-item .thumbnails-wrapper__box {
      display: none; }
  .tour-content-recommendation-form__photos-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; }
  .tour-content-recommendation-form__photos-add {
    border: none;
    background-color: #00affe;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
    .tour-content-recommendation-form__photos-add svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white; }
  .tour-content-recommendation-form__photos-remove {
    position: absolute;
    top: -1.5625rem;
    right: 0.1875rem;
    background-color: transparent;
    border: none;
    padding: 0; }
    .tour-content-recommendation-form__photos-remove svg {
      fill: #d34c50;
      width: 1.5625rem;
      height: 1.5625rem; }
  .tour-content-recommendation-form .custom-checkbox {
    padding-left: 0.9375rem;
    font-size: 1rem;
    max-width: 15.625rem;
    margin-bottom: 1.25rem; }
    .tour-content-recommendation-form .custom-checkbox span {
      position: relative; }
  .tour-content-recommendation-form__rich-text-editor {
    min-height: 9.375rem;
    width: 100%; }
    .tour-content-recommendation-form__rich-text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .tour-content-recommendation-form__rich-text-editor .ql-container {
      min-height: 6.25rem; }

@media screen and (min-width: 992px) {
  .tour-content-recommendation-form {
    margin-top: 0; }
    .tour-content-recommendation-form__cover {
      width: 100%;
      height: auto;
      min-height: 10.9375rem;
      object-fit: cover;
      margin-bottom: 1.25rem; } }

.product-form {
  font-family: 'Roboto-bold';
  padding-bottom: 1.25rem; }
  .product-form__title {
    font-weight: bold;
    font-size: 1.375rem;
    margin-bottom: 0.9375rem; }
  .product-form__service-name {
    font-size: 1.25rem; }
  .product-form__cover {
    width: calc(50vw - 25px);
    height: 30vw;
    min-height: 10.9375rem;
    object-fit: cover;
    margin-bottom: 1.25rem; }
    .product-form__cover--no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.0625rem dotted #6f6f6f;
      padding: 0.9375rem; }
      .product-form__cover--no-image .product-form__cover-icon {
        margin-bottom: 0.9375rem; }
        .product-form__cover--no-image .product-form__cover-icon svg {
          width: 2.5rem;
          height: auto; }
  .product-form__tag {
    background-color: #81d4fb;
    padding: 0.3125rem;
    line-height: 1;
    position: absolute;
    bottom: -1.5625rem;
    right: 0; }
  .product-form__label {
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem; }
    .product-form__label svg {
      margin-right: 0.625rem;
      fill: #00affe;
      width: 1.875rem; }
  .product-form__photos-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .product-form__photos-item {
    height: 7.8125rem;
    width: 6.25rem;
    display: inline-block;
    margin: 0 0.3125rem 0.3125rem 0;
    padding-top: 1.5625rem;
    z-index: 1010;
    cursor: grab !important; }
    .product-form__photos-item .fileInput {
      padding: 0; }
    .product-form__photos-item .fine-uploader__files-wrapper {
      padding: 0; }
    .product-form__photos-item .fileInput,
    .product-form__photos-item .fine-uploader__file-input,
    .product-form__photos-item .fine-uploader__files-wrapper,
    .product-form__photos-item .responsive-img {
      height: 100%; }
    .product-form__photos-item .thumbnails-wrapper__box {
      display: none; }
  .product-form__photos-img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .product-form__photos-add {
    border: none;
    background-color: #00affe;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
    .product-form__photos-add:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .product-form__photos-add svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white; }
  .product-form__photos-remove {
    position: absolute;
    top: -1.5625rem;
    right: 0.1875rem;
    background-color: transparent;
    border: none;
    padding: 0; }
    .product-form__photos-remove svg {
      fill: #d34c50;
      width: 1.5625rem;
      height: 1.5625rem; }
  .product-form__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(255, 255, 255, 0.5); }
    .product-form__no-visible-layer svg {
      width: 1.875rem;
      height: 1.875rem;
      fill: #2c2c2c;
      stroke: #2c2c2c; }
  .product-form .custom-checkbox {
    padding-left: 0.9375rem;
    font-size: 1rem;
    max-width: 15.625rem;
    margin-bottom: 1.25rem; }
    .product-form .custom-checkbox span {
      position: relative; }
  .product-form__rich-text-editor {
    min-height: 9.375rem;
    width: 100%; }
    .product-form__rich-text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .product-form__rich-text-editor .ql-container {
      min-height: 6.25rem;
      font-family: "Oswald" !important; }

@media screen and (min-width: 992px) {
  .product-form {
    margin-top: 0; }
    .product-form__cover {
      width: 100%;
      height: auto;
      min-height: 10.9375rem;
      object-fit: cover;
      margin-bottom: 1.25rem; } }

.tour-content-text-box {
  background-color: white;
  padding: 1.25rem 0.9375rem;
  position: relative;
  margin-top: 1.5625rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  /* &__decorator {
    border-right: rem(7px) $topcolor-blue solid;
  } */ }
  .tour-content-text-box__title {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    color: black;
    font-size: 1.5rem;
    padding-right: 0.625rem;
    margin-bottom: 0; }
  .tour-content-text-box__subtitle {
    border-bottom: 1px dashed black;
    padding-bottom: 15px;
    margin: 0.625rem 0 0 0;
    color: gray !important;
    font-family: 'Roboto-Italic', sans-serif !important;
    font-style: italic;
    font-size: 17px !important;
    font-weight: 400 !important; }
  .tour-content-text-box__subtitle, .tour-content-text-box__main-text {
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible;
    margin-bottom: 0; }
    .tour-content-text-box__subtitle strong, .tour-content-text-box__main-text strong {
      color: #2c2c2c;
      font-weight: bold; }
    .tour-content-text-box__subtitle a, .tour-content-text-box__main-text a {
      text-decoration: underline; }
    .tour-content-text-box__subtitle p, .tour-content-text-box__main-text p {
      margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .tour-content-text-box {
    /* &__subtitle {
      margin-top: 0;
    } */ }
    .tour-content-text-box__decorator {
      max-width: 100%; } }

@media screen and (min-width: 992px) {
  .tour-content-text-box {
    padding: 1.25rem 2.1875rem;
    margin-top: 1.5625rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; }
    .tour-content-text-box__title {
      font-size: 1.625rem; } }

.tour-content-time-box {
  background-color: white;
  padding: 0.625rem 0.9375rem;
  position: relative;
  margin-top: 1.5625rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500;
  border-radius: 0.625rem; }
  .tour-content-time-box__icon {
    display: inline-block;
    line-height: 2.5rem;
    margin-right: 0.625rem; }
    .tour-content-time-box__icon svg {
      height: 1.875rem;
      width: auto; }
  .tour-content-time-box__time {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    display: inline-block;
    line-height: 2.5rem;
    color: #2c2c2c;
    margin-bottom: 0;
    font-size: 1.3125rem; }
    .tour-content-time-box__time span {
      font-weight: 700; }
  .tour-content-time-box__duration {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    display: inline-block;
    line-height: 2.5rem;
    vertical-align: middle;
    color: #2c2c2c;
    margin-bottom: 0;
    max-width: 100%;
    font-size: 1.3125rem;
    /* &--with-arrival {
      margin-left: rem(50px);
    } */ }
    .tour-content-time-box__duration span {
      font-weight: 700; }
  .tour-content-time-box__duration-title, .tour-content-time-box__time-title {
    color: #00affe; }
  .tour-content-time-box .b-white {
    background-color: white;
    position: relative;
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .tour-content-time-box .hour {
    margin-right: 10px; }
  .tour-content-time-box .time {
    margin-left: 10px; }
  .tour-content-time-box .lineTime {
    border: 0.5px dashed black;
    position: absolute;
    right: 0px;
    left: 0px;
    max-width: 100% !important; }
  .tour-content-time-box .allTime {
    position: relative; }

@media screen and (min-width: 767px) {
  .tour-content-time-box {
    /* .b-white{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    } */ }
    .tour-content-time-box__duration {
      text-align: center; }
      .tour-content-time-box__duration--with-arrival {
        margin-left: 0; }
    .tour-content-time-box .hour {
      margin-right: 20px; }
    .tour-content-time-box .time {
      margin-left: 20px; } }

@media screen and (min-width: 992px) {
  .tour-content-time-box {
    padding: 0.625rem 2.1875rem;
    margin-top: 1.5625rem; }
    .tour-content-time-box__duration {
      margin-left: 0; } }

@media screen and (max-width: 630px) {
  .tour-content-time-box {
    border-radius: 0; }
    .tour-content-time-box .lineTime {
      display: none; }
    .tour-content-time-box .b-white {
      box-sizing: border-box;
      flex-direction: column;
      margin-left: 0 !important;
      padding-left: 0 !important; }
    .tour-content-time-box .allTime {
      justify-content: flex-start !important; }
    .tour-content-time-box__duration, .tour-content-time-box__time {
      font-size: 17px; }
    .tour-content-time-box__icon svg {
      height: 20px; }
    .tour-content-time-box img {
      height: 20px; } }

.tour-content-video-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem;
  position: relative;
  margin-top: 0.625rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500; }
  .tour-content-video-box__video-wrapper {
    padding: 0;
    height: 66vw; }
  .tour-content-video-box__text {
    padding: 1.25rem 0.9375rem;
    font-family: 'Roboto', sans-serif !important;
    white-space: pre-line;
    font-weight: 100 !important;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible;
    margin: 0.9375rem 0; }
    .tour-content-video-box__text strong {
      color: #2c2c2c;
      font-weight: 700 !important; }
    .tour-content-video-box__text a {
      text-decoration: underline; }
    .tour-content-video-box__text p {
      margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .tour-content-video-box {
    padding: 0.9375rem 1.875rem;
    border-radius: 0.625rem;
    margin-top: 1.5625rem; }
    .tour-content-video-box__video-wrapper {
      height: 33.75rem; }
    .tour-content-video-box__text {
      padding: 0; } }

@media screen and (min-width: 1200px) {
  .tour-content-video-box__video-wrapper {
    height: 36.6875rem; } }

.tour-content-map-box {
  background-color: white;
  padding: 0.625rem 1.25rem;
  position: relative;
  margin-top: 0.625rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500; }
  .tour-content-map-box__title {
    color: #00affe;
    font-size: 1.5rem;
    font-family: 'Roboto-bold';
    font-weight: 500;
    padding: 0 0.625rem 0.625rem 0.625rem;
    margin: 0.9375rem 0 0.625rem 0;
    border-bottom: 0.0625rem dashed #2c2c2c; }
  .tour-content-map-box__text {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }
    .tour-content-map-box__text strong {
      color: #2c2c2c;
      font-weight: bold; }
    .tour-content-map-box__text a {
      text-decoration: underline; }
    .tour-content-map-box__text p {
      margin-bottom: 0; }
  .tour-content-map-box .simple-map {
    min-height: 18.75rem;
    height: 100%; }
  .tour-content-map-box--squared .simple-map {
    height: calc(60vw - 15px); }

@media screen and (min-width: 576px) {
  .tour-content-map-box {
    padding: 0.9375rem 1.875rem; }
    .tour-content-map-box__title {
      margin: 1.875rem 0 0.625rem 0; } }

@media screen and (min-width: 992px) {
  .tour-content-map-box {
    border-radius: 0.625rem;
    margin-top: 1.5625rem; }
    .tour-content-map-box--squared .simple-map {
      height: 31.25rem; } }

@media screen and (min-width: 1200px) {
  .tour-content-map-box--squared .simple-map {
    height: 37.5rem; } }

.tour-content-images-box {
  background-color: white;
  padding: 0.625rem 0.9375rem;
  margin-top: 1.5625rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .tour-content-images-box__content + .tour-content-images-box__content {
    margin-top: 0.9375rem; }
  .tour-content-images-box__figure {
    position: relative;
    margin-bottom: 0; }
  .tour-content-images-box__img {
    width: 100%;
    max-width: 100%;
    height: auto; }
    .tour-content-images-box__img-author {
      display: inline-block;
      max-width: 75%;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: 0.3125rem 0.625rem 0.3125rem 1.875rem; }
      .tour-content-images-box__img-author-avatar {
        position: absolute;
        bottom: -0.625rem;
        left: 0.625rem;
        margin-left: -2.5rem;
        margin-bottom: 0;
        height: 3.125rem;
        width: 3.125rem;
        border: 0.125rem solid white;
        background-color: white;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; }
      .tour-content-images-box__img-author-name {
        font-family: 'Oswald';
        text-transform: uppercase;
        font-size: 0.9375rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0; }
  .tour-content-images-box__title, .tour-content-images-box__description {
    color: #2c2c2c;
    margin-bottom: 0;
    padding: 0 0.9375rem;
    margin-top: 0.3125rem;
    line-height: 1.8125rem;
    font-size: 1.1875rem; }
  .tour-content-images-box__title {
    margin-top: 15px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700; }
  .tour-content-images-box__description {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    overflow: visible; }

@media screen and (min-width: 992px) {
  .tour-content-images-box {
    padding: 0.9375rem 1.875rem;
    border-radius: 0.625rem;
    margin-top: 1.5625rem; }
    .tour-content-images-box__title, .tour-content-images-box__description {
      padding: 0px; } }

.tour-content-service-box {
  background-color: white;
  padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 2.8125rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .tour-content-service-box__category {
    font-family: 'Oswald';
    text-transform: uppercase; }
  .tour-content-service-box--simple .tour-content-service-box__affiliate {
    padding-top: 1.5625rem;
    border-top: 0.0625rem dashed #2c2c2c; }
  .tour-content-service-box--premium .tour-content-service-box__title {
    font-size: 1.5rem;
    color: #2c2c2c;
    margin-bottom: 0.3125rem; }
  .tour-content-service-box--premium .tour-content-service-box__info-btn {
    display: none; }
  .tour-content-service-box--premium .tour-content-service-box__recommendation {
    margin-top: 0.9375rem; }
    .tour-content-service-box--premium .tour-content-service-box__recommendation:before {
      content: '';
      display: block;
      height: 0.0625rem;
      background-color: #dedede;
      margin: 0 auto 0.9375rem auto; }
  .tour-content-service-box--timeline .tour-content-service-box__recommendation:before {
    display: none; }
  .tour-content-service-box--timeline .tour-content-service-box__title {
    margin-bottom: 0.9375rem; }
  .tour-content-service-box--timeline .tour-content-service-box__recommendation-text {
    margin-bottom: 0.9375rem; }
  .tour-content-service-box__header {
    align-items: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .tour-content-service-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0 0;
      text-align: center; }
      .tour-content-service-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .tour-content-service-box__owner {
    font-size: 0.6875rem;
    margin: 0 0 0 0;
    padding-left: 0;
    color: black; }
    .tour-content-service-box__owner-name {
      display: inline-block;
      vertical-align: bottom;
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      text-transform: capitalize;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .tour-content-service-box__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }
  .tour-content-service-box__title {
    display: inline-block;
    font-size: 1.125rem;
    color: #6f6f6f;
    position: relative;
    padding-right: 2.1875rem;
    font-family: 'Roboto-bold';
    font-weight: 500;
    margin-bottom: 0; }
  .tour-content-service-box__info-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: -0.3125rem;
    padding: 0; }
    .tour-content-service-box__info-btn svg {
      height: 1.5625rem;
      width: 1.5625rem;
      fill: #00affe; }
  .tour-content-service-box__recommendation-text {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible; }
    .tour-content-service-box__recommendation-text strong {
      color: #00affe;
      font-weight: 100; }
    .tour-content-service-box__recommendation-text a {
      text-decoration: underline; }
    .tour-content-service-box__recommendation-text p {
      margin-bottom: 0; }
  .tour-content-service-box__tours-counter {
    color: #6f6f6f;
    margin-bottom: 0; }
    .tour-content-service-box__tours-counter svg {
      display: inline-block;
      vertical-align: text-top;
      width: auto;
      height: 20px;
      margin-left: 5px;
      fill: #6f6f6f; }
  .tour-content-service-box__author-avatar {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    margin: 0 0 0.625rem 0.9375rem;
    background-color: white;
    object-fit: cover; }
    .tour-content-service-box__author-avatar--toptraveler {
      display: block;
      margin: 0 auto; }
  .tour-content-service-box__author-toptraveler {
    display: block;
    width: 5.3125rem;
    margin-top: 0.3125rem; }
  .tour-content-service-box__video-wrapper {
    padding: 0;
    height: 66vw; }
  .tour-content-service-box__affiliate {
    margin-top: 1.5625rem;
    overflow: hidden; }
    .tour-content-service-box__affiliate-title, .tour-content-service-box__affiliate-text {
      font-family: 'Roboto-bold';
      font-size: 1rem;
      line-height: 1.2;
      overflow-wrap: break-word; }
    .tour-content-service-box__affiliate-title {
      color: #2c2c2c;
      font-weight: bold; }
    .tour-content-service-box__affiliate-text {
      color: #6f6f6f;
      margin-bottom: 0;
      font-weight: 500; }
  .tour-content-service-box__address {
    text-transform: uppercase;
    font-family: 'Oswald';
    margin-bottom: 0; }
  .tour-content-service-box__phone {
    text-align: right;
    margin-bottom: 0;
    font-family: 'Roboto-bold';
    font-size: 1.1875rem; }
  .tour-content-service-box__website {
    text-decoration: underline;
    margin-bottom: 0;
    color: #2c2c2c; }
  .tour-content-service-box__slide-image {
    height: 66.3vw;
    object-fit: cover; }
  .tour-content-service-box__rrss {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    .tour-content-service-box__rrss-item {
      display: inline-block; }
      .tour-content-service-box__rrss-item + .tour-content-service-box__rrss-item {
        margin-left: 0.3125rem; }
      .tour-content-service-box__rrss-item svg {
        height: 2.5rem;
        width: auto; }
  .tour-content-service-box__map {
    margin-top: 0.3125rem;
    padding: 0; }
    .tour-content-service-box__map .simple-map {
      width: 100vw;
      max-width: 100%;
      height: 70vw; }

@media screen and (min-width: 992px) {
  .tour-content-service-box {
    margin-top: 3.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 2.1875rem; }
    .tour-content-service-box--simple .tour-content-service-box__affiliate {
      margin-top: 0;
      padding-top: 0;
      border-top: none; }
      .tour-content-service-box--simple .tour-content-service-box__affiliate-title {
        display: none; }
      .tour-content-service-box--simple .tour-content-service-box__affiliate-text {
        margin-bottom: 0; }
    .tour-content-service-box--simple .tour-content-service-box__thumb {
      width: 100%;
      height: auto;
      max-height: 15rem;
      object-fit: cover; }
      .tour-content-service-box--simple .tour-content-service-box__thumb--icon {
        object-fit: contain; }
    .tour-content-service-box--simple .tour-content-service-box__author-avatar {
      height: 3.75rem;
      width: 3.75rem;
      margin-left: 0; }
      .tour-content-service-box--simple .tour-content-service-box__author-avatar--toptraveler {
        margin: 0 auto; }
    .tour-content-service-box--premium .tour-content-service-box__recommendation {
      margin-top: 0; }
      .tour-content-service-box--premium .tour-content-service-box__recommendation:before {
        display: none; }
    .tour-content-service-box--premium .tour-content-service-box__author-avatar {
      height: 5.3125rem;
      width: 5.3125rem;
      margin-left: 0; }
      .tour-content-service-box--premium .tour-content-service-box__author-avatar--toptraveler {
        margin: 0 auto; }
    .tour-content-service-box--premium .tour-content-service-box__affiliate-text {
      margin-bottom: 0;
      padding: 0.9375rem 0 0.9375rem 2.5rem;
      border-left: 0.125rem solid #dedede;
      position: relative;
      margin-left: -0.9375rem; }
      .tour-content-service-box--premium .tour-content-service-box__affiliate-text:before, .tour-content-service-box--premium .tour-content-service-box__affiliate-text:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg); }
      .tour-content-service-box--premium .tour-content-service-box__affiliate-text:before {
        border-color: transparent transparent transparent #dedede;
        top: 0.9375rem;
        left: 0;
        border-width: 0.9375rem 0 0 1.875rem; }
      .tour-content-service-box--premium .tour-content-service-box__affiliate-text:after {
        border-color: transparent transparent transparent #fff;
        top: 1.0625rem;
        left: -0.125rem;
        border-width: 0.75rem 0 0 1.625rem; }
    .tour-content-service-box--timeline .tour-content-service-box__slide-image {
      border-top-left-radius: 0.625rem;
      height: 28.4375rem; }
    .tour-content-service-box--timeline .tour-content-service-box__title {
      font-size: 1.875rem; }
    .tour-content-service-box--timeline.tour-content-service-box--simple {
      padding: 1.25rem 0.9375rem 0 0.9375rem; }
      .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author {
        border-right: none;
        width: 9.375rem; }
        .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author:before, .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author:after {
          display: none; }
        .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author .responsive-img {
          height: 100%; }
        .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author-avatar, .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author-toptraveler {
          position: absolute; }
        .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author-avatar {
          height: 4.375rem;
          width: 4.375rem;
          right: -0.625rem;
          bottom: -1.5625rem;
          padding: 0.625rem;
          background-color: white;
          border-radius: 50%;
          box-sizing: content-box; }
        .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__author-toptraveler {
          bottom: 4.0625rem;
          right: -0.9375rem; }
      .tour-content-service-box--timeline.tour-content-service-box--simple .tour-content-service-box__recommendation-text {
        min-height: 4.375rem;
        padding-left: 0; }
    .tour-content-service-box--timeline.tour-content-service-box--premium {
      padding: 0 0.9375rem; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__title {
        padding-right: 0;
        margin-top: 0.9375rem; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .control-arrow {
        display: none; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .carousel-slider {
        border-top-left-radius: 0.625rem; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__recommendation {
        margin-top: 0.9375rem; }
        .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__recommendation-text {
          padding: 0.9375rem 0 0 6.875rem;
          margin-bottom: 3.125rem;
          max-height: 12.1875rem;
          overflow: hidden; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__recommendation-text {
        min-height: 11rem; }
      .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__author {
        position: absolute;
        left: -5.625rem;
        top: 0; }
        .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__author-avatar {
          height: 7.5rem;
          width: 7.5rem;
          padding: 0.75rem;
          background-color: white;
          border-radius: 50%;
          box-sizing: content-box;
          margin-bottom: 0; }
        .tour-content-service-box--timeline.tour-content-service-box--premium .tour-content-service-box__author-toptraveler {
          width: 7.5rem;
          margin: 0 auto; }
    .tour-content-service-box__decorator {
      position: absolute;
      top: 0;
      left: -2.875rem;
      bottom: 0;
      height: 100%;
      width: auto; }
    .tour-content-service-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .tour-content-service-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.8125rem;
        background-color: white;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        margin-right: 0.3125rem;
        padding: 0.3125rem 0 0.1875rem 0;
        text-align: center; }
        .tour-content-service-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; }
    .tour-content-service-box__owner {
      font-size: 0.875rem; }
      .tour-content-service-box__owner-name svg {
        height: 1.25rem; }
    .tour-content-service-box__category {
      font-size: 0.875rem;
      font-family: 'Oswald';
      color: #6f6f6f;
      text-transform: uppercase; }
    .tour-content-service-box__title {
      font-size: 1.75rem; }
      .tour-content-service-box__title svg {
        top: 0.3125rem; }
    .tour-content-service-box__recommendation-text {
      padding-left: 1.875rem;
      margin-bottom: 0; }
    .tour-content-service-box__phone, .tour-content-service-box__address, .tour-content-service-box__website {
      line-height: 1.2; }
    .tour-content-service-box__phone {
      text-align: left;
      margin-top: 0.625rem; }
    .tour-content-service-box__rrss {
      border-top: 0.0625rem dashed #6f6f6f;
      padding-top: 0.9375rem; }
    .tour-content-service-box__tours-counter {
      color: #6f6f6f;
      margin-top: 0.9375rem;
      font-size: 1.125rem; }
      .tour-content-service-box__tours-counter .btn-link {
        background-color: transparent;
        text-decoration: underline;
        border: none;
        color: #6f6f6f; }
      .tour-content-service-box__tours-counter-icon {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.5rem 0 -0.9375rem; }
        .tour-content-service-box__tours-counter-icon svg {
          height: 1.25rem;
          width: auto; }
    .tour-content-service-box__author {
      border-right: 0.125rem solid #dedede;
      position: relative; }
      .tour-content-service-box__author:before, .tour-content-service-box__author:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg); }
      .tour-content-service-box__author:before {
        border-color: transparent transparent transparent #dedede;
        top: 0.9375rem;
        right: -1.875rem;
        border-width: 0.9375rem 0 0 1.875rem; }
      .tour-content-service-box__author:after {
        border-color: transparent transparent transparent #fff;
        top: 1.0625rem;
        right: -1.625rem;
        border-width: 0.75rem 0 0 1.625rem; }
      .tour-content-service-box__author-toptraveler {
        width: 6.25rem; }
    .tour-content-service-box__slide-image {
      height: 37.75rem; }
    .tour-content-service-box__video-wrapper,
    .tour-content-service-box__map .simple-map {
      height: 21.875rem; }
    .tour-content-service-box__map {
      margin-top: 0;
      padding-left: 0.0625rem; } }

@media screen and (min-width: 1200px) {
  .tour-content-service-box__slide-image {
    height: 41.3125rem; } }

.tour-content-tip-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem 0 0.9375rem;
  position: relative;
  margin-top: 3.125rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .tour-content-tip-box__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .tour-content-tip-box__header-icon {
      width: 3.4375rem;
      height: 1.5625rem;
      background-color: white;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0 0;
      text-align: center; }
      .tour-content-tip-box__header-icon svg {
        height: auto;
        width: 1.5625rem;
        display: inline-block;
        vertical-align: baseline; }
  .tour-content-tip-box__content {
    padding: 0.9375rem 0; }
  .tour-content-tip-box__link:hover svg {
    fill: #3366cc; }
  .tour-content-tip-box__link svg {
    width: 2.5rem;
    height: auto;
    fill: #00affe; }
  .tour-content-tip-box__slide-image {
    height: 66.3vw;
    object-fit: cover; }
  .tour-content-tip-box__title {
    margin-bottom: 0;
    color: #2c2c2c;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-height: 3rem;
    overflow: hidden; }
  .tour-content-tip-box__text {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #2c2c2c;
    overflow: visible;
    margin-top: 0.9375rem; }
    .tour-content-tip-box__text strong {
      color: #00affe;
      font-weight: 100; }
    .tour-content-tip-box__text a {
      text-decoration: underline; }
    .tour-content-tip-box__text p {
      margin-bottom: 0; }
  .tour-content-tip-box .carousel .slider-wrapper {
    overflow: visible; }
  .tour-content-tip-box .carousel .slide {
    background-color: transparent; }
  .tour-content-tip-box .carousel.carousel-slider {
    padding-bottom: 2.1875rem; }
    .tour-content-tip-box .carousel.carousel-slider .control-arrow {
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      margin: auto 0.3125rem;
      border-radius: 50%;
      opacity: 0.3;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center; }
      .tour-content-tip-box .carousel.carousel-slider .control-arrow.control-prev:before, .tour-content-tip-box .carousel.carousel-slider .control-arrow.control-next:before {
        width: 0.9375rem;
        height: 0.9375rem;
        border-top: none;
        border-right: none;
        border-bottom: 0.1875rem solid #2c2c2c;
        border-left: 0.1875rem solid #2c2c2c; }
      .tour-content-tip-box .carousel.carousel-slider .control-arrow.control-prev:before {
        margin-left: 0.625rem;
        transform: rotate(50deg) skewY(-15deg); }
      .tour-content-tip-box .carousel.carousel-slider .control-arrow.control-next:before {
        margin-right: 0.625rem;
        transform: rotate(-135deg); }
      .tour-content-tip-box .carousel.carousel-slider .control-arrow:hover {
        opacity: 1; }
  .tour-content-tip-box .carousel .control-dots {
    padding-left: 0; }
    .tour-content-tip-box .carousel .control-dots .dot {
      box-shadow: none;
      background-color: #6f6f6f;
      width: 0.375rem;
      height: 0.375rem;
      margin: 0 0.125rem; }
      .tour-content-tip-box .carousel .control-dots .dot.selected {
        background-color: #2c2c2c; }
  .tour-content-tip-box .simple-map {
    height: 66.3vw;
    width: 100%; }

@media screen and (min-width: 992px) {
  .tour-content-tip-box {
    margin-top: 5.3125rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 1.875rem 0.625rem 1.875rem; }
    .tour-content-tip-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .tour-content-tip-box__header-icon {
        display: inline-block;
        width: 5rem;
        height: 2.5rem;
        background-color: white;
        margin-right: 0.3125rem;
        padding: 0.5rem 0 0 0;
        text-align: center; }
        .tour-content-tip-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; }
    .tour-content-tip-box__category {
      font-size: 0.875rem;
      font-family: 'Oswald';
      text-transform: uppercase;
      color: #6f6f6f; }
    .tour-content-tip-box__content {
      padding-bottom: 0; }
    .tour-content-tip-box__link svg {
      width: 3.125rem;
      height: auto; }
    .tour-content-tip-box__image {
      height: 37.75rem; }
    .tour-content-tip-box__title {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 2rem;
      max-height: 4rem;
      margin-bottom: 0.625rem; }
    .tour-content-tip-box__text {
      margin: 0; }
    .tour-content-tip-box .simple-map, .tour-content-tip-box__slide-image {
      height: 25rem; } }

@media screen and (min-width: 1200px) {
  .tour-content-tip-box .simple-map, .tour-content-tip-box__slide-image {
    height: 28.125rem; } }

.virtual-shop-box {
  background-color: white;
  padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  margin-top: 2.8125rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3); }
  .virtual-shop-box__category {
    font-size: 0.875rem;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: #6f6f6f; }
  .virtual-shop-box__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: -1.5625rem;
    left: 0;
    right: 0;
    margin: 0; }
    .virtual-shop-box__header-icon {
      width: 3.4375rem;
      height: 1.875rem;
      background-color: white;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      margin: 0 -0.625rem 0 0;
      padding: 0.3125rem 0 0 0;
      text-align: center; }
      .virtual-shop-box__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .virtual-shop-box__title {
    display: inline-block;
    font-size: 1.5rem;
    color: #6f6f6f;
    position: relative;
    padding-right: 2.1875rem;
    font-family: 'Roboto-bold';
    font-weight: 500;
    margin-bottom: 0.3125rem; }
  .virtual-shop-box__text {
    margin-bottom: 0.9375rem;
    font-size: 1.1875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    line-height: 1.25; }
  .virtual-shop-box__product-name, .virtual-shop-box__price {
    font-family: 'Roboto-bold';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.2; }
  .virtual-shop-box__product-name {
    margin-bottom: 0; }
  .virtual-shop-box__price {
    color: #d34c50; }
  .virtual-shop-box__carousel-wrapper, .virtual-shop-box__video-wrapper {
    padding: 0; }
  .virtual-shop-box__carousel-wrapper {
    margin-top: 0.625rem; }
    .virtual-shop-box__carousel-wrapper > div {
      height: 100%; }
    .virtual-shop-box__carousel-wrapper .carousel.carousel-slider {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-bottom: 2.1875rem; }
      .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .slider,
      .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .slider-wrapper {
        overflow: visible;
        height: 100%; }
      .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow {
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        margin: auto 0.3125rem;
        border-radius: 50%;
        opacity: 0.3;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
        .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow.control-prev:before, .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow.control-next:before {
          width: 0.9375rem;
          height: 0.9375rem;
          border-top: none;
          border-right: none;
          border-bottom: 0.1875rem solid #2c2c2c;
          border-left: 0.1875rem solid #2c2c2c; }
        .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow.control-prev:before {
          margin-left: 0.625rem;
          transform: rotate(50deg) skewY(-15deg); }
        .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow.control-next:before {
          margin-right: 0.625rem;
          transform: rotate(-135deg); }
        .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-arrow:hover {
          opacity: 1; }
      .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-dots {
        padding-left: 0; }
        .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-dots .dot {
          box-shadow: none;
          background-color: #6f6f6f;
          width: 0.375rem;
          height: 0.375rem;
          margin: 0 0.125rem; }
          .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .control-dots .dot.selected {
            background-color: #2c2c2c; }
      .virtual-shop-box__carousel-wrapper .carousel.carousel-slider .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white; }
  .virtual-shop-box__image {
    height: 100vw;
    max-height: 25rem;
    max-width: 100%;
    object-fit: cover; }
    .virtual-shop-box__image--no-photo {
      display: block;
      height: 15.625rem;
      margin: 0 auto;
      padding: 1.25rem;
      object-fit: contain; }
    .virtual-shop-box__image-wrapper {
      height: 100%; }
  .virtual-shop-box__video-wrapper {
    height: 55vw; }
  .virtual-shop-box__buy-btn {
    display: block;
    width: 9.375rem;
    min-width: unset;
    margin: 0.9375rem auto; }
  .virtual-shop-box__aside {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow-y: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c;
    scrollbar-track-color: #00affe;
    -webkit-overflow-scrolling: touch; }
    .virtual-shop-box__aside::-webkit-scrollbar {
      background-color: #2c2c2c;
      height: 0.3125rem; }
    .virtual-shop-box__aside::-webkit-scrollbar-thumb {
      background: #00affe; }
    .virtual-shop-box__aside-product {
      display: inline-block;
      background-color: transparent;
      padding: 0 0 0.625rem 0;
      border: none;
      width: 7.5rem;
      text-align: left;
      cursor: pointer; }
      .virtual-shop-box__aside-product + .virtual-shop-box__aside-product {
        margin-left: 0.3125rem; }
      .virtual-shop-box__aside-product:first-of-type {
        margin-left: 0.625rem; }
      .virtual-shop-box__aside-product:last-of-type {
        margin-right: 0.625rem; }
    .virtual-shop-box__aside-image {
      border: 0.0625rem solid #dedede;
      width: 7.5rem;
      height: 7.5rem;
      object-fit: cover; }
      .virtual-shop-box__aside-image--no-photo {
        padding: 1.25rem; }
    .virtual-shop-box__aside-name, .virtual-shop-box__aside-price {
      padding: 0 0.4375rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .virtual-shop-box__aside-name {
      font-family: 'Roboto-bold';
      margin: 0.625rem 0 0.1875rem 0;
      font-size: 0.875rem; }
    .virtual-shop-box__aside-price {
      font-size: 1.125rem; }

@media screen and (min-width: 768px) {
  .virtual-shop-box__buy-btn {
    margin-top: 0; }
  .virtual-shop-box__carousel-wrapper {
    height: 31.25rem;
    padding: 0 0.3125rem 0 0.9375rem;
    margin-top: 0; }
  .virtual-shop-box__image {
    height: 100%;
    max-height: none; }
  .virtual-shop-box__video-wrapper {
    padding: 0 0.9375rem 2.1875rem 0.3125rem;
    height: initial; } }

@media screen and (min-width: 992px) {
  .virtual-shop-box {
    margin-top: 3.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.25rem 1.5625rem 1.25rem 2.1875rem;
    min-height: 37.5rem;
    height: auto; }
    .virtual-shop-box__header {
      align-items: center;
      position: absolute;
      top: -2.5rem;
      left: 0;
      right: 0;
      margin: 0; }
      .virtual-shop-box__header-icon {
        display: inline-block;
        width: 4.6875rem;
        height: 2.5rem;
        background-color: white;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        margin-right: 0.3125rem;
        padding: 0.625rem 0 0.1875rem 0;
        text-align: center; }
        .virtual-shop-box__header-icon svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline; }
    .virtual-shop-box__product-data:before {
      content: '';
      height: 100%;
      width: 0.0625rem;
      border-left: 0.125rem dashed #dedede;
      position: absolute;
      left: 1.5rem;
      top: 0;
      bottom: 0; }
    .virtual-shop-box__title {
      font-size: 1.5rem; }
    .virtual-shop-box .marginTopTitle {
      margin-top: 2.3125rem; }
    .virtual-shop-box__text {
      padding: 0.3125rem 0;
      max-height: 10.625rem;
      margin-bottom: 1.875rem;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #2c2c2c;
      scrollbar-track-color: #00affe;
      -webkit-overflow-scrolling: touch; }
      .virtual-shop-box__text::-webkit-scrollbar {
        background-color: #2c2c2c;
        width: 0.3125rem; }
      .virtual-shop-box__text::-webkit-scrollbar-thumb {
        background: #00affe; }
    .virtual-shop-box__carousel-wrapper {
      height: 100%;
      padding-left: 0; }
      .virtual-shop-box__carousel-wrapper > div {
        height: 100%; }
      .virtual-shop-box__carousel-wrapper .carousel .control-dots {
        margin-bottom: 0; }
    .virtual-shop-box__video-wrapper {
      height: 10rem;
      padding: 0 0.9375rem; }
    .virtual-shop-box__author-avatar, .virtual-shop-box__author-toptraveler {
      width: 6.25rem; }
    .virtual-shop-box__author-avatar {
      height: 6.25rem;
      border-radius: 50%;
      object-fit: cover;
      margin-left: -3.75rem; }
    .virtual-shop-box__author-toptraveler {
      display: block;
      height: auto;
      margin: 0;
      position: absolute;
      top: 0;
      left: 3.75rem; }
    .virtual-shop-box__product-name, .virtual-shop-box__price {
      display: block;
      font-size: 1.625rem; }
    .virtual-shop-box__product-name {
      margin-top: 0.625rem; }
    .virtual-shop-box__aside {
      padding-right: 0.3125rem;
      max-height: 35rem; }
      .virtual-shop-box__aside::-webkit-scrollbar {
        background-color: #2c2c2c;
        width: 0.3125rem; }
      .virtual-shop-box__aside-product {
        display: block;
        padding-bottom: 0; }
        .virtual-shop-box__aside-product + .virtual-shop-box__aside-product {
          margin-left: 0;
          margin-top: 0.3125rem; }
        .virtual-shop-box__aside-product:first-of-type {
          margin-left: 0; }
        .virtual-shop-box__aside-product:last-of-type {
          margin-right: 0; } }

.tour-topbar {
  background-color: #303030;
  position: fixed;
  left: 0;
  top: 3.125rem;
  z-index: 989; }
  .tour-topbar__title {
    text-align: center;
    border-bottom: 0.0625rem dotted white;
    font-family: 'Oswald' !important;
    font-weight: bold;
    font-size: 1.125rem;
    color: white;
    max-width: calc(100% - 15px);
    margin: 0 auto 0.1875rem auto;
    padding-bottom: 0.3125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .tour-topbar__back {
    background: transparent;
    border: none;
    height: 3.125rem;
    width: 3.125rem;
    padding: 0.625rem 0.75rem 0.625rem 0.5rem; }
    .tour-topbar__back svg {
      fill: white;
      width: 100%;
      height: auto; }
  .tour-topbar__filter-btn {
    height: 3.125rem;
    width: 3.125rem;
    background-color: transparent;
    border: none;
    padding: 0.1875rem; }
    .tour-topbar__filter-btn--active, .tour-topbar__filter-btn:hover {
      background-color: #00affe; }
    .tour-topbar__filter-btn svg {
      height: 100%;
      width: auto; }
  .tour-topbar__shop-btn, .tour-topbar__edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    height: 3.125rem;
    width: 3.125rem; }
    .tour-topbar__shop-btn svg, .tour-topbar__edit-btn svg {
      fill: white; }
  .tour-topbar__shop-btn svg {
    height: 2.1875rem;
    width: 2.1875rem;
    fill: white; }
  .tour-topbar__edit-btn svg {
    height: 100%;
    width: 100%;
    fill: #00affe; }
  .tour-topbar__edit-btn:hover svg {
    fill: #81d4fb; }
  .tour-topbar__days-text {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Oswald';
    text-transform: uppercase;
    color: white;
    font-size: 0.875rem;
    line-height: 3.125rem;
    margin-right: 0.3125rem; }
  .tour-topbar__days-select {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 0 1.5rem; }
  .tour-topbar__days-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0; }
    .tour-topbar__days-btn svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: white; }
    .tour-topbar__days-btn--prev {
      left: 0; }
    .tour-topbar__days-btn--next {
      right: 0; }
  .tour-topbar__days-current {
    display: inline-block;
    height: 2.1875rem;
    width: 2.1875rem;
    border-radius: 50%;
    background-color: white;
    font-family: 'Roboto-bold';
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 2.1875rem;
    text-align: center; }
  .tour-topbar__days-total {
    position: absolute;
    top: -0.4375rem;
    right: 1.125rem;
    color: white;
    font-size: 0.6875rem; }
  .tour-topbar__days + .ml-auto {
    margin-left: 0 !important; }
  .tour-topbar__publish {
    background-color: #fd7e14;
    height: 3.125rem;
    align-items: center; }
    .tour-topbar__publish-state {
      display: inline-block;
      color: white;
      text-transform: uppercase;
      font-family: 'Oswald';
      font-weight: 100;
      font-size: 1.125rem;
      vertical-align: middle;
      margin: 0 0.625rem 0 0; }
  .tour-topbar--big + * {
    margin-top: 6.25rem; }
  .tour-topbar--big + .tour-header,
  .tour-topbar--big + .recommendations-tabs-bar {
    margin-top: 3.125rem; }
  .tour-topbar .custom-state-select {
    width: 11.25rem;
    position: absolute;
    right: 0.9375rem;
    left: unset;
    z-index: 10; }
    .tour-topbar .custom-state-select__list {
      display: flex;
      flex-direction: column;
      height: 2.3125rem; }
      .tour-topbar .custom-state-select__list label {
        color: white;
        cursor: pointer;
        line-height: 2.3125rem;
        text-align: center;
        margin: 0; }
    .tour-topbar .custom-state-select input {
      position: absolute;
      visibility: hidden; }
  .tour-topbar__state-btn--edition,
  .tour-topbar .custom-state-select__list-item--edition {
    background-color: #00affe;
    color: white; }
  .tour-topbar__state-btn--published,
  .tour-topbar .custom-state-select__list-item--published {
    background-color: #fd7e14;
    color: white; }
  .tour-topbar__state-btn--approved,
  .tour-topbar .custom-state-select__list-item--approved {
    background-color: #28a745;
    color: white; }
  .tour-topbar__state-btn--hidden,
  .tour-topbar .custom-state-select__list-item--hidden {
    background-color: #6f6f6f;
    color: white; }

@media screen and (min-width: 992px) {
  .tour-topbar {
    top: 0;
    height: 56px; }
    .tour-topbar__back {
      background: transparent;
      border: none;
      height: 3.5rem;
      width: 4.375rem;
      padding: 0.625rem 0.75rem 0.625rem 0.5rem; }
      .tour-topbar__back svg {
        fill: white;
        width: 75%;
        height: auto; }
      .tour-topbar__back:hover {
        background-color: #00affe; }
    .tour-topbar__filter-btn {
      height: 3.5rem;
      line-height: 3.5rem;
      width: auto;
      color: white;
      font-family: "Oswald";
      text-transform: uppercase;
      padding: 0 0.9375rem;
      font-size: 1.125rem;
      border-left-width: 0.0625rem; }
      .tour-topbar__filter-btn + .tour-topbar__filter-btn {
        border-left: 0.0625rem dashed white; }
      .tour-topbar__filter-btn--active, .tour-topbar__filter-btn:hover {
        border-left-color: transparent;
        cursor: pointer; }
        .tour-topbar__filter-btn--active + .tour-topbar__filter-btn, .tour-topbar__filter-btn:hover + .tour-topbar__filter-btn {
          border-left-color: transparent; }
      .tour-topbar__filter-btn:disabled {
        cursor: not-allowed; }
    .tour-topbar__edit-btn, .tour-topbar__shop-btn {
      height: 3.5rem;
      width: 4.375rem; }
    .tour-topbar__days-select {
      padding: 0 0.625rem; }
    .tour-topbar__days-text {
      line-height: 3.5rem;
      font-size: 1.125rem; }
    .tour-topbar__days-btn {
      position: initial;
      border: 0.0625rem solid white;
      border-radius: 50%;
      padding: 0.1875rem; }
      .tour-topbar__days-btn span {
        display: inline-block;
        color: white;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
        line-height: 1.875rem;
        text-align: center; }
      .tour-topbar__days-btn--active span, .tour-topbar__days-btn:hover span {
        background-color: white;
        color: #2c2c2c; }
      .tour-topbar__days-btn + .tour-topbar__days-btn {
        margin-left: 0.625rem; }
    .tour-topbar__days-separator {
      color: white;
      display: inline-block;
      line-height: 1;
      margin: 0 0.25rem;
      font-size: 1.125rem; }
    .tour-topbar--big + * {
      margin-top: 7.5rem; } }

/* modal */
.modal-pages {
  /*   position     : absolute; */
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: none;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 100%;
  height: 100%; }
  .modal-pages__overlay {
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529D4; }
  .modal-pages .contentModal {
    flex-direction: column; }
    .modal-pages .contentModal .nav {
      width: 100%; }
      .modal-pages .contentModal .nav .closeIcon {
        background-color: transparent;
        display: inline-block;
        border: none;
        vertical-align: middle;
        line-height: 1; }
        .modal-pages .contentModal .nav .closeIcon svg {
          height: 2.5rem;
          width: 2.5rem;
          fill: white; }
    .modal-pages .contentModal .contentCenter {
      margin-top: 40px;
      flex-direction: column; }
      .modal-pages .contentModal .contentCenter h3 {
        font-size: 26px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        margin-bottom: 15px; }
      .modal-pages .contentModal .contentCenter p {
        font-size: 19px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 100; }
    .modal-pages .contentModal .contentBot {
      margin-right: 100px; }
      .modal-pages .contentModal .contentBot .acceso,
      .modal-pages .contentModal .contentBot .registrarme {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 3px;
        height: 35px;
        min-width: 150px; }
    .modal-pages .contentModal .contentAlert {
      display: none; }

.menu-tour-content-modal {
  width: 100%;
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 3.125rem; }
  .menu-tour-content-modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010; }
  .menu-tour-content-modal__header {
    background-color: #2c2c2c;
    margin-bottom: 1.25rem;
    height: 3.125rem;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1; }
    .menu-tour-content-modal__header-actions {
      position: absolute;
      right: 1.25rem;
      top: 0.3125rem;
      bottom: 0;
      margin: auto 0;
      width: auto;
      padding: 0; }
    .menu-tour-content-modal__header-btn {
      padding: 0;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1;
      background-color: #2c2c2c;
      border: 0.125rem solid #00affe;
      vertical-align: middle; }
      .menu-tour-content-modal__header-btn svg {
        height: 60%;
        width: 60%;
        fill: white; }
  .menu-tour-content-modal__content {
    padding: 0.9375rem;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c;
    scrollbar-track-color: #00affe;
    -webkit-overflow-scrolling: touch; }
    .menu-tour-content-modal__content::-webkit-scrollbar {
      background-color: #2c2c2c;
      width: 0.3125rem; }
      .menu-tour-content-modal__content::-webkit-scrollbar-thumb {
        background: #00affe; }
  .menu-tour-content-modal__list {
    list-style: none;
    padding: 0 0 1.875rem 0; }
  .menu-tour-content-modal__option-icon svg {
    width: 3.125rem;
    height: auto; }
  .menu-tour-content-modal__option-title, .menu-tour-content-modal__option-btn {
    display: inline-block;
    vertical-align: middle; }
  .menu-tour-content-modal__option-title {
    font-size: 1.25rem;
    font-family: 'Roboto-bold';
    font-weight: 500;
    max-width: calc(100% - 50px);
    margin-bottom: 0;
    color: #2c2c2c; }
  .menu-tour-content-modal__option-description {
    line-height: 1.2;
    color: #2c2c2c;
    margin-top: 0.625rem; }
  .menu-tour-content-modal__option-btn {
    background-color: #00affe;
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 50%;
    border: none;
    padding: 0;
    line-height: 1;
    margin-left: 0.625rem; }
    .menu-tour-content-modal__option-btn svg {
      height: 50%;
      width: auto;
      fill: white; }
    .menu-tour-content-modal__option-btn:disabled {
      background-color: #dedede;
      cursor: not-allowed; }
    .menu-tour-content-modal__option-btn:hover, .menu-tour-content-modal__option-btn:focus {
      background-color: #3366cc; }

@media screen and (min-width: 992px) {
  .menu-tour-content-modal {
    width: 75vw;
    z-index: 1000;
    margin: auto;
    max-height: 35.3125rem;
    min-height: auto;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .menu-tour-content-modal.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .menu-tour-content-modal__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(44, 44, 44, 0.9);
      z-index: 1010; }
    .menu-tour-content-modal__content {
      padding: 1.5625rem; }
    .menu-tour-content-modal__list {
      padding-bottom: 0;
      margin-bottom: 0;
      height: 100%;
      align-items: center; }
    .menu-tour-content-modal__option-title {
      font-size: 1.375rem; }
    .menu-tour-content-modal__option-icon svg {
      width: 4.0625rem;
      height: auto; }
    .menu-tour-content-modal__option-btn {
      background-color: #00affe;
      width: 2.5rem;
      height: 2.5rem; } }

.images-edition {
  /* REFACTOR: fix to input overlays above the images
  disabling the ability to show the image edition page */ }
  .images-edition__header-icon, .images-edition__header-text {
    display: inline-block;
    vertical-align: middle; }
  .images-edition__header-icon {
    margin-right: 0.625rem; }
    .images-edition__header-icon svg {
      width: 2.5rem;
      height: auto;
      fill: #00affe; }
  .images-edition__header-text {
    max-width: calc(100% - 50px);
    margin-bottom: 0;
    font-size: 1.125rem; }
  .images-edition__header-title {
    text-align: center;
    margin: 0.9375rem 0;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    font-size: 1.125rem; }
  .images-edition__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .images-edition__dropzone--no-images {
      justify-content: flex-start; }
    .images-edition__dropzone-img {
      display: none;
      width: 90%;
      max-width: 14.375rem;
      max-height: 9.375rem;
      margin: 0 auto; }
      .images-edition__dropzone-img--drag {
        display: none; }
    .images-edition__dropzone-text {
      margin: 1.25rem 0 0 0;
      font-family: 'Roboto-bold';
      text-align: center;
      font-size: 1.125rem;
      padding: 0 0.625rem;
      line-height: 1.2; }
  .images-edition__gallery {
    margin-bottom: 0.625rem; }
    .images-edition__gallery-btn {
      background-color: transparent;
      border: none;
      padding: 0.3125rem;
      z-index: 1010;
      cursor: grab !important; }
    .images-edition__gallery-img {
      width: 100%;
      height: calc(25vw - 20px);
      object-fit: cover; }
  .images-edition__form {
    /* position: relative; */ }
    .images-edition__form-img {
      height: 9.375rem;
      width: auto;
      max-width: 100%;
      margin-bottom: 1.25rem; }
    .images-edition__form-data {
      margin-top: 4.6875rem; }
    .images-edition__form-label {
      font-size: 1.125rem;
      margin-bottom: 0; }
  .images-edition__back-btn {
    display: none;
    background-color: transparent;
    min-width: auto;
    padding: 0;
    position: absolute;
    top: -4.375rem;
    left: 0; }
    .images-edition__back-btn svg {
      width: 1.25rem;
      height: auto; }
      .images-edition__back-btn svg + svg {
        width: 2.1875rem;
        margin-left: 0.3125rem; }
  .images-edition__cross-icon svg {
    fill: #d34c50;
    width: 3.125rem;
    height: 3.125rem; }
  .images-edition .react-fine-uploader-file-input-container.fine-uploader__file-input {
    margin-top: 3.75rem; }
  .images-edition .fine-uploader__files-wrapper {
    padding: 0;
    width: 100%; }
  .images-edition .fine-uploader__file-input {
    width: 100%; }
  .images-edition .react-fine-uploader-file-input {
    height: 2.5rem !important;
    cursor: pointer; }
  .images-edition .dropzone {
    border: none; }
  .images-edition .thumbnails-wrapper__box {
    display: inline-block;
    width: 25%;
    min-width: 12.5rem; }
    .images-edition .thumbnails-wrapper__box + .thumbnails-wrapper__box {
      margin-right: 0.3125rem; }
  .images-edition .circular-bar {
    width: 6.25rem;
    margin-top: 4.6875rem; }
  .images-edition .position {
    height: 0px;
    width: 1px !important;
    position: initial;
    min-width: 0px; }
  .images-edition .save {
    position: absolute;
    top: 15px;
    right: 60px;
    z-index: 2;
    min-width: auto;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0px; }
    .images-edition .save svg {
      height: 24px;
      width: 24px;
      fill: white; }

@media screen and (min-width: 768px) {
  .images-edition__gallery-img {
    height: calc(20vw - 25px); } }

@media screen and (min-width: 992px) {
  .images-edition__header {
    margin-top: 2.5rem; }
  .images-edition__gallery {
    padding: 0 0.625rem; }
    .images-edition__gallery-img {
      height: 8.75rem; }
  .images-edition__dropzone {
    margin-top: 0;
    min-height: 18.75rem; }
    .images-edition__dropzone--no-images {
      justify-content: center;
      margin-top: 4.6875rem;
      min-height: 25rem; }
    .images-edition__dropzone-img {
      display: block; }
  .images-edition__back-btn {
    top: 0.9375rem;
    left: 0.9375rem; }
    .images-edition__back-btn svg {
      width: 1.5625rem;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0.1875rem; }
      .images-edition__back-btn svg + svg {
        width: 2.5rem;
        margin: 0 0 0 0.3125rem; }
  .images-edition__form-data {
    margin-top: 2.5rem; }
  .react-fine-uploader-file-input {
    height: auto !important;
    top: 0 !important;
    cursor: pointer; } }

@media screen and (min-width: 1200px) {
  .images-edition__gallery-img {
    height: 8.4375rem; } }

@media screen and (max-width: 992px) {
  .save {
    top: 5px !important;
    right: 55px !important; } }

.recommendations-tabs-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white; }
  .recommendations-tabs-bar__btn {
    background: transparent;
    border: none;
    position: relative;
    flex-grow: 1;
    height: 5rem; }
    .recommendations-tabs-bar__btn + .recommendations-tabs-bar__btn::before {
      content: '';
      display: inline-block;
      width: 0.0625rem;
      height: 3.125rem;
      border-right: 0.0625rem #2c2c2c dashed;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
    .recommendations-tabs-bar__btn svg {
      fill: black;
      width: 3.125rem;
      height: 3.125rem; }
    .recommendations-tabs-bar__btn--active svg {
      fill: #00affe; }
    .recommendations-tabs-bar__btn--disabled {
      opacity: .5;
      cursor: not-allowed; }

@media only screen and (min-width: 992px) {
  .recommendations-tabs-bar {
    justify-content: center; }
    .recommendations-tabs-bar__btn {
      max-width: 6.25rem;
      height: 6.25rem; }
      .recommendations-tabs-bar__btn + .recommendations-tabs-bar__btn::before {
        height: 4.0625rem; } }

.recommendation-images-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  padding-bottom: 1.875rem;
  margin-bottom: 2.1875rem; }
  .recommendation-images-box__header {
    align-items: center;
    padding-top: 0.625rem; }
    .recommendation-images-box__header-btn {
      padding: 0;
      background-color: #2c2c2c;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1; }
      .recommendation-images-box__header-btn svg {
        height: 50%;
        width: 50%;
        fill: white; }
    .recommendation-images-box__header:after {
      display: block;
      content: '';
      width: calc(100% - 30px);
      margin: 0 auto;
      border-bottom: 0.0625rem solid #2c2c2c;
      padding-bottom: 0.625rem;
      margin-bottom: 0.46875rem; }
  .recommendation-images-box__content {
    max-height: 80vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c;
    scrollbar-track-color: #00affe;
    -webkit-overflow-scrolling: touch; }
    .recommendation-images-box__content::-webkit-scrollbar {
      background-color: #2c2c2c;
      width: 0.3125rem; }
      .recommendation-images-box__content::-webkit-scrollbar-thumb {
        background: #00affe; }
  .recommendation-images-box__title {
    font-size: 1.375rem;
    text-align: right;
    margin-bottom: 0; }
  .recommendation-images-box__image {
    width: 100%;
    height: auto; }
    .recommendation-images-box__image + .recommendation-images-box__image {
      margin-top: 0.3125rem; }
    .recommendation-images-box__image-wrapper {
      padding: 0; }
  .recommendation-images-box__footer-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -4.0625rem;
    height: 2.5rem;
    width: 8.75rem;
    margin: 0 auto;
    z-index: 1;
    margin: auto; }

@media screen and (min-width: 992px) {
  .recommendation-images-box {
    padding: 0 0.9375rem 2.1875rem 0.9375rem;
    margin: 3.75rem auto 0 auto; } }

.recommendation-info-box {
  background-color: white;
  padding: 0.3125rem 0.9375rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3);
  padding-bottom: 1.875rem;
  margin-bottom: 2.1875rem; }
  .recommendation-info-box__header {
    align-items: center;
    padding-top: 0.625rem; }
    .recommendation-info-box__header:after {
      display: block;
      content: '';
      width: calc(100% - 100px);
      margin: 0 1.875rem 0.46875rem auto;
      border-bottom: 0.0625rem solid #2c2c2c;
      padding-bottom: 0.625rem; }
    .recommendation-info-box__header-title {
      font-size: 1.375rem;
      text-align: right;
      margin-bottom: 0; }
    .recommendation-info-box__header-btn {
      padding: 0;
      background-color: #2c2c2c;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1; }
      .recommendation-info-box__header-btn svg {
        height: 50%;
        width: 50%;
        fill: white; }
  .recommendation-info-box__content {
    padding: 0.9375rem 0 0 0; }
  .recommendation-info-box__title, .recommendation-info-box__address, .recommendation-info-box__website, .recommendation-info-box__phone {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.25; }
  .recommendation-info-box__title {
    font-family: 'Roboto-bold';
    font-size: 1.375rem; }
  .recommendation-info-box__address {
    text-transform: uppercase; }
  .recommendation-info-box__website {
    text-decoration: underline; }
  .recommendation-info-box__phone {
    font-family: 'Roboto-bold';
    font-weight: bold;
    margin-top: 0.625rem; }
  .recommendation-info-box__rrss-list {
    padding-left: 0;
    list-style: none; }
    .recommendation-info-box__rrss-list:before {
      content: '';
      display: block;
      width: 60%;
      min-width: 9.375rem;
      max-width: 25rem;
      border-top: 0.0625rem dashed #2c2c2c;
      margin: 0.625rem 0; }
  .recommendation-info-box__rrss-item {
    display: inline-block; }
    .recommendation-info-box__rrss-item + .recommendation-info-box__rrss-item {
      margin-left: 0.3125rem; }
  .recommendation-info-box__rrss-link {
    display: inline-block; }
    .recommendation-info-box__rrss-link:hover svg, .recommendation-info-box__rrss-link:hover circle {
      fill: #00affe; }
    .recommendation-info-box__rrss-link svg {
      height: 2.8125rem;
      width: auto; }
  .recommendation-info-box__tours-counter {
    color: #6f6f6f; }
    .recommendation-info-box__tours-counter svg {
      fill: #6f6f6f;
      height: 1.25rem;
      width: auto;
      margin-right: 0.4375rem; }
    .recommendation-info-box__tours-counter svg, .recommendation-info-box__tours-counter-text, .recommendation-info-box__tours-counter-value {
      display: inline-block;
      vertical-align: middle; }
    .recommendation-info-box__tours-counter-value {
      text-decoration: underline;
      margin-left: 0.3125rem; }

@media screen and (min-width: 992px) {
  .recommendation-info-box {
    margin: 3.75rem auto 0 auto; }
    .recommendation-info-box__content {
      padding: 0.9375rem 1.875rem 1.875rem 3.75rem; }
      .recommendation-info-box__content .simple-map {
        height: 18.125rem; } }

@media screen and (min-width: 1200px) {
  .recommendation-info-box__content .simple-map {
    height: 20rem; } }

.modal-newsletter {
  padding: 2.5rem 0 0 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: white; }
  .modal-newsletter__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    overflow-y: auto; }
  .modal-newsletter__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 1; }
    .modal-newsletter__close-btn svg {
      width: 2.5rem;
      height: 2.5rem; }
  .modal-newsletter__image {
    text-align: center; }
    .modal-newsletter__image svg {
      width: 90%;
      max-width: 15.625rem;
      height: auto; }
  .modal-newsletter__title {
    text-align: right;
    font-family: 'Roboto-bold';
    font-weight: bold; }

@media screen and (min-width: 992px) {
  .modal-newsletter {
    width: 62rem;
    max-width: 90vw;
    height: 23.125rem;
    min-height: unset;
    overflow-y: auto;
    background-color: #fff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 3.125rem; }
    .modal-newsletter.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .modal-newsletter__overlay {
      background-color: rgba(44, 44, 44, 0.9); }
    .modal-newsletter__image svg {
      width: auto;
      height: 15.625rem; }
    .modal-newsletter__title {
      font-size: 2rem; }
    .modal-newsletter__text {
      font-size: 1.125rem; } }

.modal-duplicated-content {
  display: flex;
  align-items: center;
  max-height: 90vh;
  max-height: calc(var(--vh, 1) * 90);
  background-color: #fff;
  margin: auto;
  padding: 2.8125rem 0;
  position: absolute;
  top: 3.125rem;
  bottom: 3.125rem;
  left: 0.9375rem;
  right: 0.9375rem;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .modal-duplicated-content::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .modal-duplicated-content::-webkit-scrollbar-thumb {
      background: #00affe; }
  .modal-duplicated-content__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    background-color: rgba(44, 44, 44, 0.9); }
  .modal-duplicated-content__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 1; }
    .modal-duplicated-content__close-btn svg {
      width: 1.875rem;
      height: 1.875rem; }
  .modal-duplicated-content__main-image {
    display: block;
    width: 100%;
    height: auto;
    max-height: 15rem;
    margin: 0 auto; }
  .modal-duplicated-content__content-title, .modal-duplicated-content__content-text {
    color: #00affe;
    text-align: center; }
  .modal-duplicated-content__content-title {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: bold;
    font-family: 'Roboto-Regular'; }
  .modal-duplicated-content__content-text {
    font-size: 1.125rem;
    font-weight: bold;
    font-family: 'Roboto-bold'; }
  .modal-duplicated-content .custom-checkbox {
    font-weight: 500; }
  .modal-duplicated-content.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .modal-duplicated-content--fullscreen {
    max-height: unset;
    min-height: 100vh;
    min-height: calc(var(--vh, 1) * 100);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    align-items: unset; }

@media screen and (min-width: 768px) {
  .modal-duplicated-content {
    padding: 1.875rem 2.5rem;
    max-width: 59.375rem;
    width: 90vw;
    height: 40.9375rem;
    overflow-y: auto; }
    .modal-duplicated-content__main-image {
      max-height: unset;
      width: 12.5rem; }
    .modal-duplicated-content__content-title, .modal-duplicated-content__content-text {
      text-align: left; }
    .modal-duplicated-content__content-title {
      font-size: 4.125rem; } }

.modal-publish-confirm {
  display: flex;
  align-items: center;
  max-height: 90vh;
  max-height: calc(var(--vh, 1) * 90);
  background-color: #fff;
  margin: auto;
  padding: 2.8125rem 0;
  position: absolute;
  top: 3.125rem;
  bottom: 3.125rem;
  left: 0.9375rem;
  right: 0.9375rem;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .modal-publish-confirm::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .modal-publish-confirm::-webkit-scrollbar-thumb {
      background: #00affe; }
  .modal-publish-confirm__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    background-color: rgba(44, 44, 44, 0.9); }
  .modal-publish-confirm__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 1; }
    .modal-publish-confirm__close-btn svg {
      width: 1.875rem;
      height: 1.875rem; }
  .modal-publish-confirm__main-image {
    display: block;
    width: 100%;
    height: auto;
    max-height: 15rem;
    margin: 0 auto; }
  .modal-publish-confirm__content-title, .modal-publish-confirm__content-text {
    color: #00affe;
    text-align: center; }
  .modal-publish-confirm__content-title {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: bold;
    font-family: 'Roboto-Regular'; }
  .modal-publish-confirm__content-text {
    font-size: 1.125rem;
    font-weight: bold;
    font-family: 'Roboto-bold'; }
  .modal-publish-confirm.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media screen and (min-width: 768px) {
  .modal-publish-confirm {
    max-width: 31.25rem;
    width: 90vw;
    height: 37.5rem;
    overflow-y: auto; }
    .modal-publish-confirm__main-image {
      width: 12.5rem; } }

@media screen and (min-width: 992px) {
  .modal-publish-confirm {
    max-width: 65.625rem;
    padding: 1.875rem 2.5rem; }
    .modal-publish-confirm__main-image {
      max-height: unset;
      width: 18.75rem;
      margin-right: 1.875rem; }
    .modal-publish-confirm__content-title, .modal-publish-confirm__content-text {
      text-align: left; }
    .modal-publish-confirm__content-title {
      font-size: 4.125rem; } }

.modal-publish-congrats {
  display: flex;
  align-items: center;
  max-height: 90vh;
  max-height: calc(var(--vh, 1) * 90);
  background-color: #fff;
  margin: auto;
  padding: 2.8125rem 0;
  position: absolute;
  top: 3.125rem;
  bottom: 3.125rem;
  left: 0.9375rem;
  right: 0.9375rem;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .modal-publish-congrats::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
    .modal-publish-congrats::-webkit-scrollbar-thumb {
      background: #00affe; }
  .modal-publish-congrats__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    background-color: rgba(44, 44, 44, 0.9); }
  .modal-publish-congrats__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 1; }
    .modal-publish-congrats__close-btn svg {
      width: 1.875rem;
      height: 1.875rem; }
  .modal-publish-congrats__main-image {
    display: block;
    width: 100%;
    height: auto;
    max-height: 15rem;
    margin: 0 auto; }
  .modal-publish-congrats__content-title, .modal-publish-congrats__content-text {
    color: #00affe;
    text-align: center; }
  .modal-publish-congrats__content-title {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: bold;
    font-family: 'Roboto-Regular'; }
  .modal-publish-congrats__content-text {
    font-size: 1.125rem;
    font-weight: bold;
    font-family: 'Roboto-bold'; }
  .modal-publish-congrats .custom-checkbox {
    font-weight: 500; }
  .modal-publish-congrats.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media screen and (min-width: 768px) {
  .modal-publish-congrats {
    width: 90vw;
    height: 31.25rem;
    overflow-y: auto; }
    .modal-publish-congrats__main-image {
      width: 12.5rem; } }

@media screen and (min-width: 992px) {
  .modal-publish-congrats {
    padding: 1.875rem 2.5rem;
    max-width: 65.625rem;
    width: 90vw;
    overflow-y: auto; }
    .modal-publish-congrats__main-image {
      max-height: unset;
      width: 23.125rem; }
    .modal-publish-congrats__content-title, .modal-publish-congrats__content-text {
      text-align: left; }
    .modal-publish-congrats__content-title {
      font-size: 4.125rem; } }

.cookie-fixed-banner {
  background-color: rgba(44, 44, 44, 0.9);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-top: 1px double white;
  font-size: 1rem; }
  .cookie-fixed-banner__wrapper {
    position: relative;
    padding: 1.875rem 0.625rem 1.25rem 0.625rem; }
  .cookie-fixed-banner__text {
    color: white;
    text-align: center;
    margin: 0;
    font-family: 'Roboto-Regular'; }
  .cookie-fixed-banner__close-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 1;
    top: 0.1875rem;
    right: 0.1875rem;
    position: absolute; }
    .cookie-fixed-banner__close-btn svg {
      fill: white;
      width: 1.75rem;
      height: 1.75rem; }

@media screen and (min-width: 992px) {
  .cookie-fixed-banner {
    font-size: 1.125rem; } }

.disabled-place-edition {
  height: 100%; }
  .disabled-place-edition__container {
    padding: 0.9375rem 0;
    min-height: calc(100vh - 50px);
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    max-height: 100%;
    align-content: center; }
  .disabled-place-edition__image {
    display: block;
    width: 90%;
    max-width: 25rem;
    margin: 0 auto; }
  .disabled-place-edition__title {
    color: #0090BC;
    font-weight: bold;
    font-family: 'Roboto-Regular';
    text-align: center;
    font-size: 1.625rem;
    margin-top: 1.875rem; }
  .disabled-place-edition__description {
    font-family: 'Roboto-Regular';
    text-align: center;
    font-size: 1.125rem;
    margin-top: 0.9375rem;
    line-height: 1.375rem; }
  .disabled-place-edition__footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.9375rem 0;
    box-shadow: 0.0625rem 0.0625rem 0.3125rem #2c2c2c;
    background-color: white; }
    .disabled-place-edition__footer .btn {
      width: 11.25rem; }
  .disabled-place-edition--modal {
    min-height: 100%;
    height: auto;
    padding: 0.9375rem;
    margin-bottom: 3.75rem; }
    .disabled-place-edition--modal .disabled-place-edition__container {
      max-height: unset;
      padding-bottom: 4.375rem; }

.modal-log-out {
  /*   position     : absolute; */
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 70%;
  height: 80%; }
  .modal-log-out__overlay {
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529D4; }
  .modal-log-out .contentModal {
    flex-direction: column; }
    .modal-log-out .contentModal .nav {
      width: 100%; }
      .modal-log-out .contentModal .nav .closeIcon {
        background-color: transparent;
        display: inline-block;
        border: none;
        vertical-align: middle;
        line-height: 1; }
        .modal-log-out .contentModal .nav .closeIcon svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: black; }
    .modal-log-out .contentModal .contentCenter {
      margin: 60px;
      flex-direction: column; }
      .modal-log-out .contentModal .contentCenter h3 {
        font-size: 26px;
        font-family: 'Roboto',sans-serif !important;
        font-weight: 700;
        margin-bottom: 15px; }
      .modal-log-out .contentModal .contentCenter p {
        font-size: 19px;
        font-family: 'Roboto',sans-serif !important;
        font-weight: 100; }
    .modal-log-out .contentModal .contentBot {
      margin-right: 100px; }
      .modal-log-out .contentModal .contentBot .acceso,
      .modal-log-out .contentModal .contentBot .registrarme {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 3px;
        height: 35px;
        min-width: 150px; }
    .modal-log-out .contentModal .contentAlert {
      display: none; }

@media screen and (max-width: 768px) {
  .modal-log-out {
    width: 100%;
    height: 100%; }
    .modal-log-out .contentCenter {
      margin: 20px !important; }
    .modal-log-out .contentBot {
      margin: 0px !important; } }

@media screen and (max-width: 350px) {
  .modal-log-out .contentBot {
    flex-direction: column; } }

@media screen and (min-width: 992px) {
  .contentAlert {
    width: 200px;
    position: relative;
    bottom: 85px;
    left: -21px;
    height: 100px;
    display: block !important; } }

@media screen and (min-width: 992px) and (min-height: 800px) {
  .modal-log-out {
    width: 80%;
    height: 40%; } }

@media screen and (min-width: 1200px) and (min-height: 800px) {
  .modal-log-out {
    width: 60%;
    height: 60%; } }

@media screen and (min-width: 1800px) and (min-height: 900px) {
  .modal-log-out {
    width: 50%;
    height: 50%; } }

@media screen and (min-width: 2100px) and (min-height: 900px) {
  .modal-log-out {
    width: 35%;
    height: 40%; } }

.modal-preview-content {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  outline: none;
  padding: 10px 10px 20px 10px;
  width: 40%;
  height: 100%; }
  .modal-preview-content::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .modal-preview-content .enhorabuena_text {
    color: #5a5a5a; }
  .modal-preview-content::-webkit-scrollbar-thumb {
    background: #00affe; }
  .modal-preview-content__overlay {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529D4; }
  .modal-preview-content .contentModal {
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: column; }
    .modal-preview-content .contentModal .nav {
      width: 100%; }
      .modal-preview-content .contentModal .nav .closeIcon {
        background-color: transparent;
        display: inline-block;
        border: none;
        vertical-align: middle;
        line-height: 1; }
        .modal-preview-content .contentModal .nav .closeIcon svg {
          /* height: rem(24px);
          width : rem(24px); */
          width: 40px;
          height: 40px;
          fill: black; }
      .modal-preview-content .contentModal .nav .checkIcon {
        background-color: transparent;
        display: inline-block;
        border: none;
        vertical-align: middle;
        line-height: 1;
        background-color: #00affe;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 10px; }
        .modal-preview-content .contentModal .nav .checkIcon svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: white; }
      .modal-preview-content .contentModal .nav .opacity {
        opacity: 0.5; }
    .modal-preview-content .contentModal .contentCenter {
      flex-direction: column; }
      .modal-preview-content .contentModal .contentCenter h3 {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 1.625rem;
        line-height: 2rem;
        /*         max-height : 4rem; */ }
      .modal-preview-content .contentModal .contentCenter p {
        font-size: 19px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 100; }
    .modal-preview-content .contentModal .contentBot {
      margin-right: 100px; }
      .modal-preview-content .contentModal .contentBot .acceso,
      .modal-preview-content .contentModal .contentBot .registrarme {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 3px;
        height: 35px;
        min-width: 150px; }
  .modal-preview-content .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .modal-preview-content .buttonLike img {
      width: 80%; }
  .modal-preview-content .carousel .slide {
    height: 100%; }
    .modal-preview-content .carousel .slide img {
      height: 100%; }
  .modal-preview-content .carousel .slider-wrapper {
    overflow: visible;
    /* .selected{
      height  : 100%;
      } */ }
  .modal-preview-content .carousel.carousel-slider {
    padding-bottom: 2.1875rem; }
    .modal-preview-content .carousel.carousel-slider .control-arrow {
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      margin: auto 0.3125rem;
      border-radius: 50%;
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      /*         &:hover {
          opacity: 1;
        } */ }
      .modal-preview-content .carousel.carousel-slider .control-arrow.control-prev:before, .modal-preview-content .carousel.carousel-slider .control-arrow.control-next:before {
        width: 0.9375rem;
        height: 0.9375rem;
        border-top: none;
        border-right: none;
        border-bottom: 0.1875rem solid #2c2c2c;
        border-left: 0.1875rem solid #2c2c2c; }
      .modal-preview-content .carousel.carousel-slider .control-arrow.control-prev:before {
        margin-left: 0.625rem;
        transform: rotate(50deg) skewY(-15deg); }
      .modal-preview-content .carousel.carousel-slider .control-arrow.control-next:before {
        margin-right: 0.625rem;
        transform: rotate(-135deg); }
  .modal-preview-content .carousel .control-dots {
    padding-left: 0; }
    .modal-preview-content .carousel .control-dots .dot {
      box-shadow: none;
      background-color: black;
      width: 0.375rem;
      height: 0.375rem;
      margin: 0 0.125rem; }
      .modal-preview-content .carousel .control-dots .dot.selected {
        background-color: #2c2c2c; }
  .modal-preview-content__info {
    list-style: none;
    padding-left: 0;
    text-align: left;
    margin: 15px 0 0; }
    .modal-preview-content__info-item {
      display: inline-block;
      vertical-align: middle;
      color: #2c2c2c;
      font-size: 1rem;
      line-height: 1; }
      .modal-preview-content__info-item svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        height: 1.125rem;
        width: auto;
        max-width: 1.25rem;
        fill: #6f6f6f;
        margin-right: 0.3125rem; }
      .modal-preview-content__info-item + .modal-preview-content__info-item {
        margin-left: 0.9375rem; }
  .modal-preview-content__description {
    width: 60%;
    margin: .9375rem 0 0;
    white-space: pre-line;
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    line-height: 22px;
    font-size: 16px !important;
    color: #2c2c2c;
    overflow: visible; }
    .modal-preview-content__description p {
      margin: 0px;
      white-space: pre-line;
      word-wrap: break-word;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      line-height: 22px;
      font-size: 16px !important;
      color: #2c2c2c;
      overflow: visible; }
  .modal-preview-content__partnerContainer {
    width: 50%; }
  .modal-preview-content__partner {
    /* width: 40%; */
    margin: 0.3125rem 0; }
    .modal-preview-content__partner-image {
      display: block;
      width: 180px;
      height: 130px;
      margin: 0.9375rem auto 0.9375rem auto; }
  .modal-preview-content__header {
    align-items: center;
    top: -2.5rem;
    left: 0;
    right: 0;
    margin: 0; }
    .modal-preview-content__header-icon {
      display: inline-block;
      width: 5rem;
      height: 2.5rem;
      background-color: white;
      margin-right: 0.3125rem;
      padding: 0.5rem 0 0 0;
      text-align: center; }
      .modal-preview-content__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .modal-preview-content__owner {
    font-size: 0.875rem !important; }
    .modal-preview-content__owner-name svg {
      height: 1.25rem; }
  .modal-preview-content__owner {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    width: 100%;
    font-size: 0.6875rem;
    margin-top: 2%;
    color: black; }
    .modal-preview-content__owner-name {
      margin-right: 10px;
      display: inline-block;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      text-transform: capitalize;
      color: black;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis; }
      .modal-preview-content__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }
  .modal-preview-content .botLeft {
    flex-wrap: wrap;
    display: flex;
    width: 50%;
    /* height: fit-content; */ }
    .modal-preview-content .botLeft .father {
      grid-gap: 2px;
      display: grid !important;
      flex-wrap: wrap;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      height: calc(100% - 26px) !important; }
      .modal-preview-content .botLeft .father figure {
        /* width: 100%; */ }
      .modal-preview-content .botLeft .father .closeIcon {
        width: 20px;
        position: absolute;
        right: 5px;
        background-color: white;
        border-radius: 50%;
        border: none;
        height: 20px;
        top: 1px; }
        .modal-preview-content .botLeft .father .closeIcon svg {
          width: 25px;
          fill: #d34c50;
          right: 2px;
          position: relative; }
    .modal-preview-content .botLeft h6 {
      width: 100%;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700; }
    .modal-preview-content .botLeft .previewImages {
      /* margin: 2px; */
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .modal-preview-content .botLeft .sinCargar {
      font-weight: 100 !important;
      font-size: 15px;
      margin: 1.5rem !important; }
      .modal-preview-content .botLeft .sinCargar span {
        color: #00affe;
        text-decoration: underline; }
  .modal-preview-content .botRight {
    width: 50%; }
  .modal-preview-content .MapaImage {
    width: auto !important;
    flex-grow: 1;
    padding: 0px 10px;
    /*     width : 70%; */
    /*     height: 100%; */
    height: 300px; }
    .modal-preview-content .MapaImage .simple-map {
      height: 100% !important; }
  .modal-preview-content .division {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-top: 10px; }
    .modal-preview-content .division .boton {
      margin: 10px 10px 20px 10px; }
    .modal-preview-content .division .dropzone {
      height: 40px;
      border: none; }
      .modal-preview-content .division .dropzone .poi-images-box__dropzone {
        padding-top: 10px;
        min-height: 40px; }
        .modal-preview-content .division .dropzone .poi-images-box__dropzone p {
          font-size: 15px; }
      .modal-preview-content .division .dropzone .fine-uploader__files-wrapper {
        position: fixed;
        z-index: 1;
        background-color: white;
        padding-top: 0px;
        width: 220px;
        justify-content: center;
        height: 150px; }
        .modal-preview-content .division .dropzone .fine-uploader__files-wrapper .thumbnails-wrapper__box {
          position: relative;
          right: 250px;
          z-index: 8000;
          position: relative;
          width: 182px;
          top: -150px; }
        .modal-preview-content .division .dropzone .fine-uploader__files-wrapper .react-fine-uploader-file-input-container {
          display: none !important; }
    .modal-preview-content .division .modal-warning__cross-icon svg {
      padding-top: 5px;
      width: 35px;
      height: 35px; }
    .modal-preview-content .division .modal-warning__footer {
      width: 60%; }
      .modal-preview-content .division .modal-warning__footer p {
        font-size: 15px; }
    .modal-preview-content .division .verificando {
      width: 220px; }
    .modal-preview-content .division h3 {
      color: #00affe; }
  .modal-preview-content .information_text {
    text-align: center;
    font-weight: 700 !important;
    margin-top: 25px; }

/* react-fine-uploader-file-input-container fine-uploader__file-input */
@media screen and (min-width: 992px) {
  .modal-preview-content .carousel .slide img {
    height: 212px !important; } }

@media screen and (min-width: 1200px) {
  .modal-preview-content .carousel .slide img {
    height: 250px !important; } }

@media screen and (min-width: 1400px) {
  .modal-preview-content .carousel .slide img {
    height: 282px !important; } }

@media screen and (min-width: 1600px) {
  .modal-preview-content .carousel .slide img {
    height: 320px !important; } }

@media screen and (min-width: 1800px) {
  .modal-preview-content .carousel .slide img {
    height: 358px !important; } }

@media screen and (min-width: 2100px) and (min-height: 100px) {
  .modal-preview-content .carousel .slide img {
    height: 480px !important; } }

.modal-preview-content-tour {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  outline: none;
  padding: 10px 10px 20px 10px;
  width: 40%;
  height: 100%; }
  .modal-preview-content-tour::-webkit-scrollbar {
    background-color: #2c2c2c;
    width: 0.3125rem; }
  .modal-preview-content-tour .daysTourModal span {
    margin: 0;
    font-size: 15px !important;
    font-family: 'Oswald' !important; }
  .modal-preview-content-tour .contentImgPoi {
    position: relative;
    color: white; }
    .modal-preview-content-tour .contentImgPoi > div {
      position: absolute;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 63%);
      z-index: 2000;
      bottom: 0;
      left: 0;
      right: 0;
      height: 35%; }
    .modal-preview-content-tour .contentImgPoi h1 {
      margin: 0;
      font-weight: bold; }
    .modal-preview-content-tour .contentImgPoi h6 {
      text-transform: capitalize;
      text-align: left;
      margin: 0;
      font-size: 16px; }
    .modal-preview-content-tour .contentImgPoi p {
      text-transform: capitalize;
      text-align: left;
      margin: 0 !important;
      font-size: 12px !important; }
  .modal-preview-content-tour::-webkit-scrollbar-thumb {
    background: #00affe; }
  .modal-preview-content-tour__overlay {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529D4; }
  .modal-preview-content-tour .contentModal {
    flex-direction: column; }
    .modal-preview-content-tour .contentModal .nav {
      width: 100%; }
      .modal-preview-content-tour .contentModal .nav .closeIcon {
        background-color: transparent;
        display: inline-block;
        border: none;
        vertical-align: middle;
        line-height: 1; }
        .modal-preview-content-tour .contentModal .nav .closeIcon svg {
          height: 1.5rem;
          width: 1.5rem;
          fill: black; }
    .modal-preview-content-tour .contentModal .contentCenter {
      flex-direction: column; }
      .modal-preview-content-tour .contentModal .contentCenter h3 {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 700;
        font-size: 1.625rem;
        line-height: 2rem;
        max-height: 4rem; }
      .modal-preview-content-tour .contentModal .contentCenter p {
        font-size: 19px;
        font-family: 'Roboto', sans-serif, sans-serif !important;
        font-weight: 100; }
    .modal-preview-content-tour .contentModal .contentBot {
      margin-right: 100px; }
      .modal-preview-content-tour .contentModal .contentBot .acceso,
      .modal-preview-content-tour .contentModal .contentBot .registrarme {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 3px;
        height: 35px;
        min-width: 150px; }
  .modal-preview-content-tour .likes {
    margin: 15px 0 0 !important; }
  .modal-preview-content-tour .buttonLike {
    z-index: 500;
    background: transparent;
    width: 30px;
    height: 30px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer; }
    .modal-preview-content-tour .buttonLike img {
      width: 80%; }
  .modal-preview-content-tour .carousel .slide {
    height: 100%; }
    .modal-preview-content-tour .carousel .slide img {
      height: 100%; }
  .modal-preview-content-tour .carousel .slider-wrapper {
    overflow: visible;
    /* .selected{
      height: 100%;
      } */ }
  .modal-preview-content-tour .carousel.carousel-slider {
    padding-bottom: 2.1875rem; }
    .modal-preview-content-tour .carousel.carousel-slider .control-arrow {
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      margin: auto 0.3125rem;
      border-radius: 50%;
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      /*         &:hover {
          opacity: 1;
        } */ }
      .modal-preview-content-tour .carousel.carousel-slider .control-arrow.control-prev:before, .modal-preview-content-tour .carousel.carousel-slider .control-arrow.control-next:before {
        width: 0.9375rem;
        height: 0.9375rem;
        border-top: none;
        border-right: none;
        border-bottom: 0.1875rem solid #2c2c2c;
        border-left: 0.1875rem solid #2c2c2c; }
      .modal-preview-content-tour .carousel.carousel-slider .control-arrow.control-prev:before {
        margin-left: 0.625rem;
        transform: rotate(50deg) skewY(-15deg); }
      .modal-preview-content-tour .carousel.carousel-slider .control-arrow.control-next:before {
        margin-right: 0.625rem;
        transform: rotate(-135deg); }
  .modal-preview-content-tour .carousel .control-dots {
    padding-left: 0; }
    .modal-preview-content-tour .carousel .control-dots .dot {
      box-shadow: none;
      background-color: black;
      width: 0.375rem;
      height: 0.375rem;
      margin: 0 0.125rem; }
      .modal-preview-content-tour .carousel .control-dots .dot.selected {
        background-color: #2c2c2c; }
  .modal-preview-content-tour__info {
    list-style: none;
    padding-left: 0;
    text-align: left;
    margin: 15px 0 0; }
    .modal-preview-content-tour__info-item {
      display: inline-block;
      vertical-align: middle;
      color: #2c2c2c;
      font-size: 1rem;
      line-height: 1; }
      .modal-preview-content-tour__info-item svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        height: 1.125rem;
        width: auto;
        max-width: 1.25rem;
        fill: #6f6f6f;
        margin-right: 0.3125rem; }
      .modal-preview-content-tour__info-item + .modal-preview-content-tour__info-item {
        margin-left: 0.9375rem; }
  .modal-preview-content-tour__description {
    white-space: pre-line;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    font-style: italic;
    line-height: 22px;
    font-size: 16px !important;
    color: gray;
    width: 60%;
    margin: 0.9375rem 0 0 0;
    overflow: visible; }
  .modal-preview-content-tour__partner {
    width: 50%;
    margin: 0.3125rem 0; }
    .modal-preview-content-tour__partner img {
      width: 60%; }
    .modal-preview-content-tour__partner-image {
      display: block;
      max-width: 50%;
      margin: 0 auto; }
  .modal-preview-content-tour__header {
    align-items: center;
    top: -2.5rem;
    left: 0;
    right: 0;
    margin: 0; }
    .modal-preview-content-tour__header-icon {
      display: inline-block;
      width: 5rem;
      height: 2.5rem;
      background-color: white;
      margin-right: 0.3125rem;
      padding: 0.5rem 0 0 0;
      text-align: center; }
      .modal-preview-content-tour__header-icon svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline; }
  .modal-preview-content-tour__owner {
    font-size: 0.875rem !important; }
    .modal-preview-content-tour__owner-name svg {
      height: 1.25rem; }
  .modal-preview-content-tour__owner {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    width: 100%;
    font-size: 0.6875rem;
    margin-top: 2%;
    color: black; }
    .modal-preview-content-tour__owner-name {
      margin-right: 10px;
      display: inline-block;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      text-transform: capitalize;
      color: black;
      font-size: 0.875rem;
      margin-left: 0.3125rem;
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis; }
      .modal-preview-content-tour__owner-name svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.1875rem;
        height: 0.875rem;
        width: auto;
        fill: #6f6f6f; }
  .modal-preview-content-tour .botLeft {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    /* height: fit-content; */ }
    .modal-preview-content-tour .botLeft .father {
      grid-gap: 2px;
      display: grid !important;
      flex-wrap: wrap;
      grid-template-columns: repeat(4, 1fr); }
      .modal-preview-content-tour .botLeft .father img {
        width: 100%;
        height: 100px;
        object-fit: cover; }
    .modal-preview-content-tour .botLeft .services {
      /* grid-gap: 2px;
    display: grid !important;
    flex-wrap: wrap;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(3,1fr);
    height: calc(100% - 26px) !important; */ }
      .modal-preview-content-tour .botLeft .services figure {
        /* width: 100%; */
        display: flex; }
        .modal-preview-content-tour .botLeft .services figure img {
          width: 100px;
          height: 50px;
          object-fit: cover; }
        .modal-preview-content-tour .botLeft .services figure p {
          text-transform: capitalize;
          margin-left: 10px;
          white-space: pre-line;
          font-family: 'Roboto', sans-serif !important;
          font-weight: 100;
          line-height: 22px;
          font-size: 16px !important; }
        .modal-preview-content-tour .botLeft .services figure span {
          font-family: 10px;
          color: gray;
          text-transform: uppercase;
          font-family: 'Oswald'; }
    .modal-preview-content-tour .botLeft h6 {
      width: 100%;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700; }
    .modal-preview-content-tour .botLeft .previewImages {
      /* margin: 2px; */
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .modal-preview-content-tour .botRight {
    width: 50%; }
  .modal-preview-content-tour .MapaImage {
    width: auto !important;
    flex-grow: 1;
    padding: 0px 10px;
    /*     width: 70%; */
    /*     height: 100%; */
    height: 35vh; }
    .modal-preview-content-tour .MapaImage .simple-map {
      height: 100% !important; }
  .modal-preview-content-tour .division {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-top: 10px;
    padding-bottom: 10px; }
    .modal-preview-content-tour .division .boton {
      margin: 10px 10px 20px 10px; }
  .modal-preview-content-tour .tour-content-poi-box__topleft {
    margin-bottom: 10px !important; }

@media screen and (min-width: 992px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 212px !important; } }

@media screen and (min-width: 1200px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 250px !important; } }

@media screen and (min-width: 1400px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 282px !important; } }

@media screen and (min-width: 1600px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 320px !important; } }

@media screen and (min-width: 1800px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 358px !important; } }

@media screen and (min-width: 2100px) and (min-height: 100px) {
  .modal-preview-content-tour .carousel .slide img {
    height: 480px !important; } }

/** PAGE COMPONENTS **/
.poi-edition {
  background-color: #dedede; }
  .poi-edition .input-group-text {
    background: transparent;
    border: none; }

@media screen and (min-width: 992px) {
  .poi-edition {
    min-height: calc(100vh - 70px);
    min-height: calc((var(--vh, 1vh) * 100) - 70px);
    padding-bottom: 3.75rem; } }

.poi-info-page {
  padding-bottom: 9.375rem;
  background-color: white;
  color: #303030; }
  .poi-info-page__main-deco {
    display: none; }
  .poi-info-page__location {
    padding: 0.625rem 0 0.625rem 1.5625rem;
    border-left: 0.0625rem dashed grey; }
    .poi-info-page__location-btn {
      background: none;
      border: none; }
    .poi-info-page__location-img {
      margin: 0 auto;
      height: 4.6875rem;
      width: 4.6875rem; }
    .poi-info-page__location-text {
      text-transform: uppercase;
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: 1.125rem; }
  .poi-info-page__section-title {
    font-family: 'Roboto-bold';
    font-weight: 500;
    font-size: 1.375rem;
    margin: 0.9375rem 0 0 0; }
  .poi-info-page__range-img {
    width: auto; }
    .poi-info-page__range-img--left {
      height: 1.875rem; }
    .poi-info-page__range-img--right {
      height: 4.375rem;
      margin-top: -2.1875rem; }
  .poi-info-page__range-text {
    text-transform: uppercase;
    margin-top: 0.9375rem; }
    .poi-info-page__range-text--left {
      float: left; }
    .poi-info-page__range-text--right {
      float: right;
      margin-right: -3.125rem; }
  .poi-info-page__footer {
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 0.9375rem 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    z-index: 1; }

@media screen and (min-width: 992px) {
  .poi-info-page {
    padding: 2.5rem 1.5625rem 1.875rem 6.25rem;
    border-top-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 4.6875rem; }
    .poi-info-page__main {
      border-left: 0.1875rem solid #81d4fb;
      margin: 0 0 5rem -1.4375rem;
      padding: 3.125rem 0 4.375rem 1.875rem;
      position: relative; }
      .poi-info-page__main:before, .poi-info-page__main:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: 0.1875rem;
        background-color: #81d4fb; }
      .poi-info-page__main:before {
        top: 0;
        width: 6.875rem; }
      .poi-info-page__main:after {
        bottom: 0;
        width: 12.5rem; }
      .poi-info-page__main-deco {
        display: inline-block;
        position: absolute;
        z-index: 1; }
        .poi-info-page__main-deco--top {
          width: auto;
          height: 4.375rem;
          top: -3.75rem;
          left: 6.875rem; }
        .poi-info-page__main-deco--bottom {
          bottom: 0;
          left: 11.75rem;
          width: auto;
          height: 2.5rem; }
    .poi-info-page__location {
      border: none;
      padding: 0; }
      .poi-info-page__location-btn {
        margin-left: 0.9375rem; }
      .poi-info-page__location-text {
        display: block;
        text-transform: uppercase;
        margin: 0.3125rem 0 0 0; }
      .poi-info-page__location-img {
        width: 3.4375rem;
        height: 3.4375rem; }
    .poi-info-page__section-title {
      font-size: 1.375rem;
      margin: 0 -0.9375rem 0.9375rem -0.9375rem; }
    .poi-info-page .fine-uploader-dropzone-container {
      border-radius: 0.9375rem;
      border: 0.0625rem dashed #2c2c2c;
      height: auto; }
      .poi-info-page .fine-uploader-dropzone-container.react-fine-uploader-dropzone-active {
        border: 0.0625rem solid #00affe;
        background-color: rgba(0, 175, 254, 0.1);
        opacity: 0.75; } }

@media screen and (max-width: 768px) {
  .poi-info-page .instagramMobile {
    margin-bottom: 100px !important; } }

.poi-images-page {
  padding: 0.9375rem 0.9375rem 1.875rem 0.9375rem;
  background-color: white; }
  .poi-images-page__footer {
    background-color: white;
    padding: 1.25rem 0.9375rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2); }
    .poi-images-page__footer-text {
      text-align: center;
      margin: 0.625rem 0 0 0; }
      .poi-images-page__footer-text svg {
        height: 0.9375rem;
        width: auto;
        margin-left: 0.3125rem; }
    .poi-images-page__footer .fine-uploader__file-input {
      display: block !important; }
    .poi-images-page__footer .fine-uploader__files-wrapper {
      padding: 0px; }
    .poi-images-page__footer .progressPercentageBarWrapper {
      height: 2.375rem;
      background-color: rgba(0, 175, 254, 0.6); }
    .poi-images-page__footer .progressPercentageBarProgress {
      display: block;
      height: 100%;
      background-color: #00affe;
      color: white;
      padding: 0 0.625rem;
      line-height: 2.375rem;
      font-weight: bold;
      font-family: 'Roboto-bold'; }
    .poi-images-page__footer .progressPercentageBarProgress,
    .poi-images-page__footer .progressPercentageBarWrapper {
      border-radius: 0.625rem; }

@media screen and (max-width: 991px) {
  .poi-images-page {
    padding-bottom: 8.75rem; } }

@media screen and (min-width: 992px) {
  .poi-images-page {
    padding: 1.875rem 3.75rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    margin-bottom: 2.5rem; }
    .poi-images-page__total-images {
      text-align: right;
      margin: 0.625rem 0 0 0;
      font-weight: bold; }
      .poi-images-page__total-images span {
        font-weight: 100;
        margin: 0 0.625rem;
        font-size: 1.125rem; }
    .poi-images-page__footer {
      display: none; }
    .poi-images-page__desktop-footer {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      justify-content: center;
      background-color: #fff;
      padding: 0.9375rem 0;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); } }

.home-page__header {
  display: block;
  position: relative; }
  .home-page__header svg {
    display: none;
    min-height: 12.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1; }

.home-page__cover {
  margin-bottom: 0; }
  .home-page__cover--loading:before {
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #dedede;
    background-image: url("/assets/images/spinner.svg");
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 1.875rem;
    z-index: 1; }
  .home-page__cover--loading .home-page__cover-image {
    visibility: hidden; }
  .home-page__cover-image {
    width: 100%;
    height: auto; }
  .home-page__cover-user {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2.8125rem;
    text-align: center;
    z-index: 1; }
    .home-page__cover-user-name, .home-page__cover-user-avatar-wrapper {
      display: inline-block; }
    .home-page__cover-user-name {
      border-radius: 0 1.25rem 1.25rem 0;
      left: 0; }
    .home-page__cover-user-avatar {
      object-fit: cover;
      width: 5.625rem;
      height: 5.625rem;
      border: 0.3125rem solid white;
      border-radius: 50%;
      box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.15); }
      .home-page__cover-user-avatar-wrapper {
        position: relative; }
  .home-page__cover-user-name, .home-page__cover-staff-pick {
    font-family: 'Oswald';
    font-size: 0.9rem;
    padding: 0.375rem 0.3125rem;
    position: relative;
    top: 0.9375rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6.25rem; }
  .home-page__cover-staff-pick {
    border-radius: 1.25rem 0 0 1.25rem;
    right: 0; }
    .home-page__cover-staff-pick svg {
      fill: #ffcb02;
      height: 1.25rem;
      margin: -0.3125rem 0.3125rem 0 0;
      width: auto; }
  .home-page__cover-toptraveler {
    display: block;
    width: 6.25rem; }

.home-page__diagonal-top {
  clip-path: polygon(0 0, 100% 10%, 100% 100%, -2% 102%);
  margin-top: -3.125rem; }
  .home-page__diagonal-top .laptop-container {
    box-sizing: border-box; }
    .home-page__diagonal-top .laptop-container .laptop-img {
      margin-left: 200px; }

.home-page .grey-video {
  background-color: #2c2c2c; }
  .home-page .grey-video h3 {
    color: white;
    font-size: 1.2rem; }
    @media screen and (min-width: 992px) {
      .home-page .grey-video h3 {
        font-size: 1.75rem; } }

.home-page__grey-bg--desktop {
  background-color: white; }

.home-page__grey-bg--mobile {
  background-color: #dedede;
  position: relative; }
  .home-page__grey-bg--mobile:after {
    content: '';
    background-color: inherit;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
  .home-page__grey-bg--mobile .f-quicksand {
    font-size: 1.2rem; }

.home-page .alert-primary {
  margin-bottom: 0; }
  .home-page .alert-primary svg {
    fill: white;
    margin-right: 0.625rem;
    width: 1.25rem; }
    .home-page .alert-primary svg path + path {
      fill: #81d4fb; }

.home-page__premium {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100;
  font-size: 0.9rem;
  margin-left: 0.9375rem; }
  .home-page__premium svg {
    fill: #ffcb02;
    height: 1.25rem;
    margin: -0.3125rem 0.3125rem 0 0;
    width: auto; }

.home-page__sponsor {
  text-align: center; }
  .home-page__sponsor img {
    border-radius: 0.625rem 0;
    width: 70%; }

.home-page__main-title {
  font-family: 'Roboto-bold';
  font-size: 1.5rem; }

.home-page__main-description {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  padding: 1.5625rem; }

.home-page__comment {
  border: 0.4375rem solid;
  border-radius: 3.125rem;
  font-family: 'Roboto-bold';
  margin-bottom: 2rem;
  padding: 2rem 2rem 5rem 2rem;
  position: relative; }
  .home-page__comment .btn {
    box-shadow: 0 0 0 0.5rem #81d4fb;
    min-width: 11.25rem;
    position: absolute;
    bottom: -1.25rem;
    right: 4.5rem; }
  .home-page__comment .form-group {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100; }
    .home-page__comment .form-group label {
      position: relative; }
    .home-page__comment .form-group input {
      background-color: rgba(255, 255, 255, 0.5);
      min-width: 10.625rem; }
      .home-page__comment .form-group input:focus {
        background-color: rgba(255, 255, 255, 0.8); }
    .home-page__comment .form-group svg {
      fill: #00affe;
      height: 1.3rem;
      bottom: 0.5rem;
      left: 0.125rem;
      position: absolute;
      width: 2.5rem; }

.home-page__comment-quote {
  background: #81d4fb;
  padding: 0.9375rem;
  position: absolute;
  top: -2.5rem;
  left: -1.875rem;
  width: 5.625rem; }

.home-page__comment-title {
  font-size: 2.25rem;
  text-align: center; }

.home-page__comment-text {
  font-size: 1rem;
  line-height: 1; }

.home-page__comment-text-white {
  color: white;
  display: block;
  font-size: 1.2rem;
  text-align: right; }

.home-page__img-w-text--wrapper {
  position: relative;
  bottom: 6.25rem;
  left: 5%;
  width: 90%; }

.home-page__img-w-text--title {
  color: white;
  font-family: 'Roboto-bold';
  font-size: 1.5rem;
  text-align: center; }
  .home-page__img-w-text--title-white {
    color: #303030;
    font-family: 'Roboto-bold';
    font-size: 1.5rem;
    text-align: left; }

.home-page__img-w-text--bg-text-white {
  background-color: white;
  padding: 0.625rem 1.25rem; }

.home-page__img-w-text--desc {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100; }

.home-page__highlighted-list {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow-y: hidden;
  padding: 1.5625rem 0.9375rem 10px 0;
  margin-bottom: 0.625rem;
  max-width: none;
  max-height: none;
  background-color: white;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c;
  scrollbar-track-color: #00affe;
  -webkit-overflow-scrolling: touch; }
  .home-page__highlighted-list::-webkit-scrollbar {
    background-color: #2c2c2c;
    height: 0.3125rem; }
  .home-page__highlighted-list::-webkit-scrollbar-thumb {
    background: #00affe; }
  .home-page__highlighted-list .content-card {
    box-shadow: none;
    margin: 0;
    min-width: 15.625rem; }

.home-page .subsidized {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FEFEFE;
  flex-direction: column;
  padding-top: 15px; }
  .home-page .subsidized img {
    width: 70%;
    height: auto; }
  .home-page .subsidized p {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 18px; }

@media screen and (min-width: 768px) {
  .home-page__sponsor img {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .home-page__header svg {
    display: block; }
  .home-page__cover {
    position: relative; }
    .home-page__cover-image {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      object-fit: cover; }
    .home-page__cover-user {
      justify-content: flex-start;
      margin-top: 0;
      padding-left: 3.125rem; }
      .home-page__cover-user-name {
        left: -0.3125rem; }
    .home-page__cover-user-name, .home-page__cover-staff-pick {
      background-color: white;
      font-size: 1rem;
      padding: 0.375rem 1.875rem;
      top: 0.1875rem;
      max-width: 12.5rem; }
    .home-page__cover-staff-pick {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      right: -0.3125rem; }
      .home-page__cover-staff-pick svg {
        margin-right: 1.25rem; }
    .home-page__cover-toptraveler {
      margin: 0 -0.625rem -1.875rem; }
  .home-page__grey-bg--mobile .f-quicksand {
    font-size: 1.75rem; }
  .home-page__grey-bg--desktop {
    background-color: #dedede;
    position: relative; }
    .home-page__grey-bg--desktop:after {
      content: '';
      background-color: inherit;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
  .home-page__main-title {
    font-size: 2rem;
    text-align: right;
    margin-top: 2.1875rem; }
  .home-page__img-w-text {
    position: relative; }
    .home-page__img-w-text--wrapper {
      color: white;
      max-width: 28.75rem;
      position: absolute;
      bottom: 3.75rem;
      right: 3.75rem;
      left: auto; }
  .home-page__img-w-text--desc {
    padding-left: 9.375rem; }
  .home-page__img-w-text--title {
    font-size: 2rem;
    text-align: left; }
    .home-page__img-w-text--title-white {
      color: #81d4fb;
      font-size: 2rem; }
  .home-page__img-w-text--bg-text-white {
    background-color: transparent;
    padding: 0; }
  .home-page__main-description {
    border-left: 1px dashed #303030;
    border-right: 1px dashed #303030;
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem; }
  .home-page__comment-text {
    font-size: 1.3rem;
    line-height: 1.4; }
  .home-page__comment-text-white {
    font-size: 1.6rem; }
  .home-page__sponsor {
    text-align: left; }
  .home-page__highlighted-list {
    background-color: transparent; }
  .home-page .content-card + .content-card {
    margin-left: 0.625rem; } }

.form-control-ReactQuill {
  background-color: #C2DBE7;
  margin: 5px 0px; }

.profile-page {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100;
  font-size: 1.1rem; }
  .profile-page .pointer {
    cursor: pointer; }
  .profile-page .loadingItems {
    width: 200px; }
  .profile-page__top {
    position: relative; }
    .profile-page__top--public {
      margin-top: 3.75rem; }
  .profile-page__top-traveler-wrapper {
    text-align: center;
    margin-top: 4.0625rem; }
    .profile-page__top-traveler-wrapper svg {
      width: 7.5rem; }
  .profile-page__header {
    text-align: center;
    z-index: 0;
    padding-top: 20px;
    position: relative;
    background-color: #81d4fb; }
    .profile-page__header svg {
      display: none; }
  .profile-page__main-image {
    margin-top: 100px;
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    right: 0;
    background-color: #81d4fb; }
    .profile-page__main-image ::after {
      content: '';
      width: 100%;
      height: 80px;
      background: red; }
  .profile-page__avatar {
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border: 0.3125rem solid white;
    margin: auto;
    width: 7.5rem;
    height: 7.5rem;
    background-color: white;
    object-fit: cover; }
    .profile-page__avatar-wrapper {
      position: relative;
      top: -3.75rem;
      left: 0;
      right: 0;
      z-index: 1; }
  .profile-page__user {
    margin-top: 3.75rem; }
    .profile-page__user--toptraveler {
      margin-top: 0; }
    .profile-page__user-name {
      font-family: 'Roboto-bold';
      font-size: 1.5rem;
      text-align: center; }
    .profile-page__user-nickname {
      font-family: 'Roboto-bold';
      color: #00affe;
      font-size: 1.125rem;
      margin-top: 0.625rem;
      text-align: center; }
      .profile-page__user-nickname-form {
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        background-color: #00affe;
        color: white;
        margin: 0.3125rem 0 0 0 !important;
        font-size: 1.1875rem; }
    .profile-page__user-logo svg {
      height: 3.125rem;
      position: absolute;
      top: 0;
      left: calc(50% - 125px);
      width: 5.625rem; }
      .profile-page__user-logo svg .text {
        transform: translate(-100px, -100px) scale(0.8); }
  .profile-page__angel-btn {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    background-color: #81d4fb;
    border-radius: 50%;
    border: none;
    position: absolute;
    right: calc(50% - 100px);
    top: 0.3125rem;
    line-height: 0; }
    .profile-page__angel-btn svg {
      height: 48%;
      fill: white;
      width: auto; }
    .profile-page__angel-btn:hover, .profile-page__angel-btn:active {
      background-color: #00affe; }
  .profile-page__edit-btn {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    background-color: #81d4fb;
    border-radius: 50%;
    border: none;
    position: absolute;
    right: calc(50% - 100px);
    top: 0.3125rem;
    line-height: 0; }
    .profile-page__edit-btn svg {
      height: 60%;
      fill: white;
      width: auto; }
    .profile-page__edit-btn:hover, .profile-page__edit-btn:active {
      background-color: #00affe; }
  .profile-page__website {
    text-align: center; }
    .profile-page__website-link {
      color: inherit;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      font-size: 1.125rem;
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .profile-page__social-list {
    margin-bottom: 0.3125rem;
    text-align: center; }
  .profile-page__social-link svg {
    height: 2.5rem;
    margin-right: 0.625rem; }
    .profile-page__social-link svg:hover path {
      fill: #00affe; }
  .profile-page__description {
    border-bottom: 1px dashed #303030;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: 1.8125rem;
    font-size: 1.1875rem;
    color: #303030;
    overflow: visible;
    padding-bottom: 1.25rem; }
  .profile-page__form-side .input-group-text {
    border: none;
    background-color: white;
    padding: 0 0.3125rem; }
    .profile-page__form-side .input-group-text svg {
      height: 1.5625rem;
      width: 1.5625rem; }
      .profile-page__form-side .input-group-text svg path {
        fill: #00affe; }
  .profile-page__actions {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4.375rem;
    padding: 0 0.9375rem; }
    .profile-page__actions .btn + .btn {
      margin-left: 0.3125rem; }
    .profile-page__actions div.fine-uploader__files-wrapper {
      padding: 0;
      margin-right: 0.3125rem; }
    .profile-page__actions + .profile-page__user .profile-page__user-name {
      margin-top: 0.625rem; }
    .profile-page__actions .progressPercentageBarWrapper {
      height: 2.375rem;
      width: 7.5rem;
      background-color: rgba(0, 175, 254, 0.6); }
    .profile-page__actions .progressPercentageBarProgress {
      display: inline-block;
      height: 100%;
      background-color: #00affe;
      color: white;
      padding: 0 0.625rem;
      line-height: 2.375rem;
      font-weight: bold;
      font-family: 'Roboto-bold'; }
    .profile-page__actions .progressPercentageBarProgress,
    .profile-page__actions .progressPercentageBarWrapper {
      border-radius: 0.625rem; }
  .profile-page__tabs-button {
    background-color: transparent;
    border: 0;
    padding: 0.625rem; }
    .profile-page__tabs-button svg {
      height: 4.375rem;
      margin: 0; }
    .profile-page__tabs-button--active {
      background-color: #81d4fb; }
  .profile-page__tabs-text {
    display: block;
    margin: 0.625rem 0 0 0;
    font-size: 0.85rem;
    font-family: 'Oswald'; }
  .profile-page__affiliate-plans span {
    font-size: 20px;
    font-weight: 700;
    margin: 0px 10px; }
  .profile-page__affiliate-plans p {
    margin: 0px 10px; }
  .profile-page__affiliate-plans .affiliate-plans-input {
    display: flex; }
  .profile-page__donation-box span {
    font-size: 20px;
    font-weight: 700;
    margin: 0px 10px; }
  .profile-page__donation-box p {
    margin: 0px 10px; }
  .profile-page__donation-box .custom-checkbox {
    color: dimgray;
    font-size: 1.1875rem;
    padding-left: 2.5rem; }
    .profile-page__donation-box .custom-checkbox i {
      width: 25px;
      height: 25px; }
    .profile-page__donation-box .custom-checkbox i:after {
      top: -2px;
      left: 0;
      width: 25px;
      height: 25px; }

.modalOptions {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: white;
  overflow: auto;
  outline: none;
  padding: 10px 10px 20px 10px;
  width: 60%;
  height: 100%; }
  .modalOptions__contentModal {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529d4; }
  .modalOptions .navbarLeft {
    position: fixed;
    flex-direction: column;
    right: 60vw;
    background-color: transparent; }
    .modalOptions .navbarLeft__btn {
      width: 3.75rem;
      height: 3.75rem;
      padding: 0.4375rem;
      background-color: white;
      border: none;
      line-height: 0; }
      .modalOptions .navbarLeft__btn svg {
        width: 100%;
        height: auto;
        fill: black; }
      .modalOptions .navbarLeft__btn:hover, .modalOptions .navbarLeft__btn:active {
        background-color: #00affe; }
      .modalOptions .navbarLeft__btn--active {
        background-color: #00affe; }
        .modalOptions .navbarLeft__btn--active svg {
          fill: white; }
  .modalOptions .content .cursor {
    cursor: pointer; }
  .modalOptions .content .añadir_azul {
    margin-top: 10px; }
  .modalOptions .content .textBlue {
    color: #00affe; }
  .modalOptions .content .imageSponsor {
    width: 150px;
    height: 140px;
    object-fit: cover; }
  .modalOptions .content .withOutImage {
    min-width: 150px;
    width: 150px;
    height: 140px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #81d4fb; }
    .modalOptions .content .withOutImage svg {
      width: 30px;
      fill: white; }
  .modalOptions .content .transparent {
    opacity: 0.5; }
  .modalOptions .content .division2 {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
    margin: 10px; }
  .modalOptions .content .division3 {
    border-bottom: 1px solid black;
    padding-bottom: 10px; }
  .modalOptions .content .division4 {
    border-right: 0.0625rem dashed #6f6f6f; }
    .modalOptions .content .division4 span {
      font-size: 20px; }
  .modalOptions .content .checkIcon {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #00affe;
    display: inline-block;
    border: none;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 1;
    margin-right: 5px; }
    .modalOptions .content .checkIcon svg {
      width: 60%;
      height: 60%;
      fill: white; }
  .modalOptions .content .closeIcon {
    height: 2.5rem;
    width: 2.5rem;
    background-color: black;
    display: inline-block;
    border: none;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 1; }
    .modalOptions .content .closeIcon svg {
      width: 60%;
      height: 60%;
      fill: white; }
  .modalOptions .content .fixedObject {
    z-index: 1;
    background-color: white;
    position: fixed;
    top: 0px;
    height: 60px;
    width: 58% !important; }
  .modalOptions .content .bar {
    display: flex;
    padding: 5px;
    background-color: #E9EBEF;
    margin-top: 52px; }
    .modalOptions .content .bar .barContent {
      position: fixed;
      flex-direction: column;
      left: 55.5rem;
      background-color: transparent; }
      .modalOptions .content .bar .barContent__btn {
        width: 80px;
        height: 80px;
        padding: 0.4375rem;
        background-color: white;
        border: none;
        line-height: 0; }
        .modalOptions .content .bar .barContent__btn svg {
          width: 60%;
          height: auto;
          fill: black; }
        .modalOptions .content .bar .barContent__btn:hover, .modalOptions .content .bar .barContent__btn:active {
          background-color: #00affe; }
        .modalOptions .content .bar .barContent__btn--active {
          background-color: #00affe; }
          .modalOptions .content .bar .barContent__btn--active svg {
            fill: white; }
          .modalOptions .content .bar .barContent__btn--active p {
            color: white; }
        .modalOptions .content .bar .barContent__btn p {
          margin: 20px 0px; }
  .modalOptions .content .left__category-item {
    list-style: none;
    background-color: white; }
    .modalOptions .content .left__category-item + .filters-modal__category-item {
      margin-top: 0.1875rem; }
    .modalOptions .content .left__category-item .custom-checkbox {
      font-size: 1rem;
      line-height: 1.5625rem;
      padding-left: 2.5rem; }
      .modalOptions .content .left__category-item .custom-checkbox i {
        border: 0.0625rem solid #2c2c2c;
        border-radius: 0; }
      .modalOptions .content .left__category-item .custom-checkbox i,
      .modalOptions .content .left__category-item .custom-checkbox i:after {
        width: 1.5625rem;
        height: 1.5625rem; }
  .modalOptions .content .left__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.125rem;
    margin-bottom: 0.625rem; }
  .modalOptions .content .left__text-editor {
    min-height: 9.375rem;
    min-width: 100%; }
    .modalOptions .content .left__text-editor .ql-toolbar {
      max-height: 2.8125rem; }
    .modalOptions .content .left__text-editor .ql-container {
      min-height: 6.25rem; }
  .modalOptions .content .left .dropzone {
    border: none;
    display: flex;
    align-items: center; }
  .modalOptions .content .left .poi-images-box__dropzone {
    min-height: 10rem;
    min-width: 160px; }
  .modalOptions .content .left .fine-uploader__upload-input {
    width: 50px; }
  .modalOptions .content .left .tour-content-video-form__supported-text {
    font-size: 13px !important; }
  .modalOptions .content .left .tour-content-video-form__supported-type svg {
    width: 1.5rem !important; }
  .modalOptions .content .divisorLateral {
    margin-top: 130px;
    height: 500px;
    width: 1px;
    border-left: 1px solid; }
  .modalOptions .content .right .logotipo {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .modalOptions .content .right .logotipo .cuadrado {
      width: 250px;
      height: 230px;
      border: 1px solid black; }
      .modalOptions .content .right .logotipo .cuadrado img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .modalOptions .content .right .logotipo .cuadrado .dropzone {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
      .modalOptions .content .right .logotipo .cuadrado .poi-images-box__dropzone {
        min-width: 150px;
        min-height: 175px; }
      .modalOptions .content .right .logotipo .cuadrado .fine-uploader__upload-input {
        display: block !important;
        width: 50px; }
    .modalOptions .content .right .logotipo .rectangulo {
      border: 1px solid black; }
      .modalOptions .content .right .logotipo .rectangulo.banner {
        width: 250px;
        height: 400px; }
        .modalOptions .content .right .logotipo .rectangulo.banner img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .modalOptions .content .right .logotipo .rectangulo .dropzone {
        border: none;
        /* display    : flex; */
        align-items: center; }
      .modalOptions .content .right .logotipo .rectangulo .poi-images-box__dropzone {
        min-width: 200px;
        min-height: 175px; }
      .modalOptions .content .right .logotipo .rectangulo .fine-uploader__upload-input {
        display: block !important;
        width: 50px; }

@media screen and (min-width: 768px) {
  .profile-page__grey-bg--desktop {
    background-color: #dedede;
    position: relative; }
    .profile-page__grey-bg--desktop:after {
      content: "";
      background-color: inherit;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
  .profile-page__top--public {
    margin-top: 0; }
  .profile-page__top-traveler-wrapper {
    margin-top: -1.25rem; }
  .profile-page__social-list {
    text-align: left; }
  .profile-page__header {
    margin-bottom: -4.375rem;
    z-index: 0;
    height: auto;
    padding-top: 0;
    overflow: hidden;
    position: relative; }
    .profile-page__header svg {
      display: block;
      min-height: 12.5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1; }
  .profile-page__main-image {
    position: relative;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%; }
  .profile-page__user {
    margin-top: 0;
    border-right: 1px dashed #303030;
    text-align: right;
    min-height: 6.25rem; }
    .profile-page__user-name {
      position: relative;
      display: inline-block;
      padding-left: 3.125rem;
      line-height: 2.5rem;
      margin-top: 0;
      text-align: right;
      font-size: 1.75rem; }
    .profile-page__user-nickname {
      margin-top: 0;
      text-align: right;
      font-size: 1.25rem; }
    .profile-page__user-logo {
      position: relative; }
      .profile-page__user-logo svg {
        width: auto;
        height: 3.75rem;
        left: unset;
        right: 0; }
        .profile-page__user-logo svg .text {
          transform: none; }
  .profile-page__avatar {
    border: 0.1875rem solid black; }
    .profile-page__avatar-wrapper {
      top: -5.625rem; }
  .profile-page__angel-btn {
    margin-right: 0.625rem;
    left: -52px;
    bottom: 0;
    top: 0; }
    .profile-page__angel-btn svg {
      position: relative; }
  .profile-page__edit-btn {
    margin-right: 0.625rem;
    left: 0;
    bottom: 0;
    top: 0; }
    .profile-page__edit-btn svg {
      position: relative; }
  .profile-page__website {
    text-align: left;
    min-height: 1.625rem; }
    .profile-page__website--private {
      margin-left: 3.125rem; }
  .profile-page__description {
    font-family: 'Roboto', sans-serif;
    border: 0;
    padding-bottom: 0;
    text-align: center; }
  .profile-page__actions {
    margin: 0 0 0.9375rem 0; }
    .profile-page__actions + .profile-page__user .profile-page__user-name {
      margin-top: 0; }
  .profile-page__tabs-button svg {
    height: 6.25rem; }
  .profile-page__tabs-text {
    font-size: 1.1rem;
    text-transform: uppercase; } }

@media screen and (max-width: 992px) {
  .modalOptions {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background: white;
    overflow: auto;
    outline: none;
    padding: 10px 10px 20px 10px;
    width: 100%;
    height: 100%; }
    .modalOptions__contentModal {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #212529d4; }
    .modalOptions .navbarLeft {
      display: none !important;
      position: fixed;
      flex-direction: column;
      right: 60vw;
      background-color: transparent; }
      .modalOptions .navbarLeft__btn {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.4375rem;
        background-color: white;
        border: none;
        line-height: 0; }
        .modalOptions .navbarLeft__btn svg {
          width: 100%;
          height: auto;
          fill: black; }
        .modalOptions .navbarLeft__btn:hover, .modalOptions .navbarLeft__btn:active {
          background-color: #00affe; }
        .modalOptions .navbarLeft__btn--active {
          background-color: #00affe; }
          .modalOptions .navbarLeft__btn--active svg {
            fill: white; }
    .modalOptions .content .cursor {
      cursor: pointer; }
    .modalOptions .content .añadir_azul {
      margin-top: 10px; }
    .modalOptions .content .textBlue {
      color: #00affe; }
    .modalOptions .content .imageSponsor {
      width: 150px;
      height: 140px;
      object-fit: cover; }
    .modalOptions .content .division2 {
      background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
      padding-bottom: 10px;
      margin: 10px; }
    .modalOptions .content .division3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px; }
    .modalOptions .content .fixedObject {
      width: 98% !important; }
    .modalOptions .content .division4 {
      border-right: 0.0625rem dashed #6f6f6f; }
    .modalOptions .content .checkIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #00affe;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1;
      margin-right: 5px; }
      .modalOptions .content .checkIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .modalOptions .content .closeIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: black;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1; }
      .modalOptions .content .closeIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .modalOptions .content .bar {
      display: flex;
      padding: 5px;
      background-color: #E9EBEF; }
      .modalOptions .content .bar .barContent {
        position: fixed;
        flex-direction: column;
        left: 55.5rem;
        background-color: transparent; }
        .modalOptions .content .bar .barContent__btn {
          width: 80px;
          height: 80px;
          padding: 0.4375rem;
          background-color: white;
          border: none;
          line-height: 0; }
          .modalOptions .content .bar .barContent__btn svg {
            width: 60%;
            height: auto;
            fill: black; }
          .modalOptions .content .bar .barContent__btn:hover, .modalOptions .content .bar .barContent__btn:active {
            background-color: #00affe; }
          .modalOptions .content .bar .barContent__btn--active {
            background-color: #00affe; }
            .modalOptions .content .bar .barContent__btn--active svg {
              fill: white; }
            .modalOptions .content .bar .barContent__btn--active p {
              color: white; }
          .modalOptions .content .bar .barContent__btn p {
            margin: 20px 0px; }
    .modalOptions .content .left__category-item {
      list-style: none;
      background-color: white; }
      .modalOptions .content .left__category-item + .filters-modal__category-item {
        margin-top: 0.1875rem; }
      .modalOptions .content .left__category-item .custom-checkbox {
        font-size: 1rem;
        line-height: 1.5625rem;
        padding-left: 2.5rem; }
        .modalOptions .content .left__category-item .custom-checkbox i {
          border: 0.0625rem solid #2c2c2c;
          border-radius: 0; }
        .modalOptions .content .left__category-item .custom-checkbox i,
        .modalOptions .content .left__category-item .custom-checkbox i:after {
          width: 1.5625rem;
          height: 1.5625rem; }
    .modalOptions .content .left__label {
      text-align: center;
      font-family: 'Roboto-bold';
      font-weight: bold;
      line-height: 1.2;
      font-size: 1.125rem;
      margin-bottom: 0.625rem; }
    .modalOptions .content .left__text-editor {
      min-height: 9.375rem;
      min-width: 100%; }
      .modalOptions .content .left__text-editor .ql-toolbar {
        max-height: 2.8125rem; }
      .modalOptions .content .left__text-editor .ql-container {
        min-height: 6.25rem; }
    .modalOptions .content .left .dropzone {
      width: 80px;
      min-width: 80px;
      border: none;
      display: flex;
      align-items: center; }
    .modalOptions .content .left .poi-images-box__dropzone {
      min-height: 10rem;
      min-width: 160px;
      display: none; }
    .modalOptions .content .left .fine-uploader__upload-input {
      width: 50px; }
    .modalOptions .content .left .tour-content-video-form__supported-text {
      font-size: 13px !important; }
    .modalOptions .content .left .tour-content-video-form__supported-type svg {
      width: 1.5rem !important; }
    .modalOptions .content .divisorLateral {
      margin-top: 130px;
      height: 500px;
      width: 1px;
      border-left: 1px solid; }
    .modalOptions .content .right .logotipo {
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      .modalOptions .content .right .logotipo .cuadrado {
        width: 250px;
        height: 180px;
        border: 1px solid black; }
        .modalOptions .content .right .logotipo .cuadrado img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .modalOptions .content .right .logotipo .cuadrado .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .modalOptions .content .right .logotipo .cuadrado .poi-images-box__dropzone {
          min-width: 215px;
          min-height: 175px;
          display: none; }
        .modalOptions .content .right .logotipo .cuadrado .fine-uploader__upload-input {
          display: block !important;
          width: 50px; }
      .modalOptions .content .right .logotipo .rectangulo {
        border: 1px solid black; }
        .modalOptions .content .right .logotipo .rectangulo .dropzone {
          border: none;
          display: flex;
          align-items: center;
          min-width: 100px; }
        .modalOptions .content .right .logotipo .rectangulo .poi-images-box__dropzone {
          min-width: 118px;
          min-height: 175px;
          display: none; }
        .modalOptions .content .right .logotipo .rectangulo .fine-uploader__upload-input {
          display: block !important;
          width: 50px; } }

@media screen and (max-width: 768px) {
  .modalOptions {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background: white;
    overflow: auto;
    outline: none;
    padding: 10px 10px 20px 10px;
    width: 100%;
    height: 100%; }
    .modalOptions__contentModal {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #212529d4; }
    .modalOptions .navbarLeft {
      display: none !important;
      position: fixed;
      flex-direction: column;
      right: 60vw;
      background-color: transparent; }
      .modalOptions .navbarLeft__btn {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.4375rem;
        background-color: white;
        border: none;
        line-height: 0; }
        .modalOptions .navbarLeft__btn svg {
          width: 100%;
          height: auto;
          fill: black; }
        .modalOptions .navbarLeft__btn:hover, .modalOptions .navbarLeft__btn:active {
          background-color: #00affe; }
        .modalOptions .navbarLeft__btn--active {
          background-color: #00affe; }
          .modalOptions .navbarLeft__btn--active svg {
            fill: white; }
    .modalOptions .content .contentCenter {
      flex-direction: column-reverse; }
      .modalOptions .content .contentCenter .campaign {
        max-width: 100%; }
        .modalOptions .content .contentCenter .campaign h4 {
          margin-left: 0px; }
        .modalOptions .content .contentCenter .campaign h6 {
          padding-left: 0px !important; }
      .modalOptions .content .contentCenter .sponsored-destinations {
        max-width: 100%;
        margin-top: 15px; }
    .modalOptions .content .addCampaign {
      flex-direction: column; }
      .modalOptions .content .addCampaign .left {
        max-width: 100%; }
      .modalOptions .content .addCampaign .divisorLateral {
        height: 1px;
        width: 100%;
        border-bottom: 1px solid; }
      .modalOptions .content .addCampaign .right {
        max-width: 100%; }
    .modalOptions .content .seeCampaign {
      flex-direction: column; }
      .modalOptions .content .seeCampaign .left {
        max-width: 100%; }
      .modalOptions .content .seeCampaign .divisorLateral {
        height: 1px;
        width: 100%;
        border-bottom: 1px solid; }
      .modalOptions .content .seeCampaign .right {
        max-width: 100%; }
    .modalOptions .content .cursor {
      cursor: pointer; }
    .modalOptions .content .añadir_azul {
      margin-top: 10px; }
    .modalOptions .content .textBlue {
      color: #00affe; }
    .modalOptions .content .imageSponsor {
      width: 150px;
      height: 140px;
      object-fit: cover; }
    .modalOptions .content .division2 {
      background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
      padding-bottom: 10px;
      margin: 10px; }
    .modalOptions .content .division3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px; }
    .modalOptions .content .fixedObject {
      width: 97% !important; }
    .modalOptions .content .division4 {
      border-right: 0.0625rem dashed #6f6f6f; }
    .modalOptions .content .checkIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #00affe;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1;
      margin-right: 5px; }
      .modalOptions .content .checkIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .modalOptions .content .closeIcon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: black;
      display: inline-block;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1; }
      .modalOptions .content .closeIcon svg {
        width: 60%;
        height: 60%;
        fill: white; }
    .modalOptions .content .bar {
      display: flex;
      padding: 5px;
      background-color: #E9EBEF; }
      .modalOptions .content .bar .barContent {
        position: fixed;
        flex-direction: column;
        left: 55.5rem;
        background-color: transparent; }
        .modalOptions .content .bar .barContent__btn {
          width: 80px;
          height: 80px;
          padding: 0.4375rem;
          background-color: white;
          border: none;
          line-height: 0; }
          .modalOptions .content .bar .barContent__btn svg {
            width: 60%;
            height: auto;
            fill: black; }
          .modalOptions .content .bar .barContent__btn:hover, .modalOptions .content .bar .barContent__btn:active {
            background-color: #00affe; }
          .modalOptions .content .bar .barContent__btn--active {
            background-color: #00affe; }
            .modalOptions .content .bar .barContent__btn--active svg {
              fill: white; }
            .modalOptions .content .bar .barContent__btn--active p {
              color: white; }
          .modalOptions .content .bar .barContent__btn p {
            margin: 20px 0px; }
    .modalOptions .content .left__category-item {
      list-style: none;
      background-color: white; }
      .modalOptions .content .left__category-item + .filters-modal__category-item {
        margin-top: 0.1875rem; }
      .modalOptions .content .left__category-item .custom-checkbox {
        font-size: 1rem;
        line-height: 1.5625rem;
        padding-left: 2.5rem; }
        .modalOptions .content .left__category-item .custom-checkbox i {
          border: 0.0625rem solid #2c2c2c;
          border-radius: 0; }
        .modalOptions .content .left__category-item .custom-checkbox i,
        .modalOptions .content .left__category-item .custom-checkbox i:after {
          width: 1.5625rem;
          height: 1.5625rem; }
    .modalOptions .content .left__label {
      text-align: center;
      font-family: 'Roboto-bold';
      font-weight: bold;
      line-height: 1.2;
      font-size: 1.125rem;
      margin-bottom: 0.625rem; }
    .modalOptions .content .left__text-editor {
      min-height: 9.375rem;
      min-width: 100%; }
      .modalOptions .content .left__text-editor .ql-toolbar {
        max-height: 2.8125rem; }
      .modalOptions .content .left__text-editor .ql-container {
        min-height: 6.25rem; }
    .modalOptions .content .left .dropzone {
      border: none;
      display: flex;
      align-items: center; }
    .modalOptions .content .left .poi-images-box__dropzone {
      min-height: 10rem;
      min-width: 160px;
      display: none; }
    .modalOptions .content .left .fine-uploader__upload-input {
      width: 50px; }
    .modalOptions .content .left .tour-content-video-form__supported-text {
      font-size: 13px !important; }
    .modalOptions .content .left .tour-content-video-form__supported-type svg {
      width: 1.5rem !important; }
    .modalOptions .content .divisorLateral {
      margin-top: 130px;
      height: 500px;
      width: 1px;
      border-left: 1px solid; }
    .modalOptions .content .right .logotipo {
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      .modalOptions .content .right .logotipo .cuadrado {
        width: 250px;
        height: 180px;
        border: 1px solid black; }
        .modalOptions .content .right .logotipo .cuadrado img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .modalOptions .content .right .logotipo .cuadrado .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .modalOptions .content .right .logotipo .cuadrado .poi-images-box__dropzone {
          min-width: 215px;
          min-height: 175px;
          display: none; }
        .modalOptions .content .right .logotipo .cuadrado .fine-uploader__upload-input {
          display: block !important;
          width: 50px; }
      .modalOptions .content .right .logotipo .rectangulo {
        border: 1px solid black; }
        .modalOptions .content .right .logotipo .rectangulo .dropzone {
          border: none;
          display: flex;
          align-items: center; }
        .modalOptions .content .right .logotipo .rectangulo .poi-images-box__dropzone {
          min-width: 118px;
          min-height: 175px;
          display: none; }
        .modalOptions .content .right .logotipo .rectangulo .fine-uploader__upload-input {
          display: block !important;
          width: 50px; } }

.textRedCampaign {
  color: red; }

.contentText {
  word-wrap: break-word; }

.boldTextCampaing p {
  font-family: 'Roboto-bold', sans-serif !important;
  font-weight: bold; }

.galleryCampaign figure {
  margin: 0;
  padding: 0;
  width: 100px;
  height: 100px;
  position: relative; }
  .galleryCampaign figure .deleteImgGalleryCampaign {
    width: 20px;
    position: absolute;
    right: 4px;
    background-color: white;
    border-radius: 50%;
    border: none;
    height: 20px;
    top: 2px; }
    .galleryCampaign figure .deleteImgGalleryCampaign svg {
      width: 25px;
      fill: red;
      right: 2px;
      position: relative; }
  .galleryCampaign figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageC {
  width: 100px;
  height: 100px;
  object-fit: cover; }

.tour-content-tip-form__photos-item {
  z-index: 3000; }

.deleteImgGalleryCampaign {
  width: 20px;
  position: absolute;
  right: 4px;
  background-color: white;
  border-radius: 50%;
  border: none;
  height: 20px;
  top: 2px; }
  .deleteImgGalleryCampaign svg {
    width: 25px;
    fill: red;
    right: 2px;
    position: relative; }

.dragg {
  position: relative; }

.register-page__input-extra-info {
  display: block;
  font-size: 80%;
  background-color: rgba(129, 212, 251, 0.05); }

.discover-page__top-nav {
  align-items: center;
  background-color: #303030;
  display: flex;
  height: 4.375rem;
  margin-top: 0;
  padding: 0 0.3125rem 0 0.9375rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1002; }

.discover-page__top-btn {
  padding: 0.3125rem;
  text-align: center;
  border: none;
  background-color: transparent; }
  .discover-page__top-btn:hover, .discover-page__top-btn--active {
    background-color: #81d4fb; }
  .discover-page__top-btn svg {
    height: 3.125rem; }

.discover-page__back-btn {
  background: initial;
  border: none;
  margin-right: auto;
  padding: 0;
  transform: rotate(45deg);
  height: 2rem;
  width: 2rem; }
  .discover-page__back-btn:before {
    border-bottom: 0.1875rem solid #f4f4f4;
    border-left: 0.1875rem solid #f4f4f4;
    content: '';
    display: block;
    height: 100%;
    width: 100%; }

.discover-page__header {
  display: none;
  overflow: hidden;
  text-align: center; }
  .discover-page__header:before {
    content: '';
    background-color: #f4f4f4;
    height: 100%;
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -1; }
  .discover-page__header-title {
    color: white;
    display: inline-block;
    font-family: 'Roboto-bold'; }
  .discover-page__header-subtitle {
    color: initial;
    display: block;
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    text-align: right; }
  .discover-page__header-description {
    font-size: 1.1rem;
    font-family: 'Roboto-Regular'; }
  .discover-page__header-form {
    font-family: 'Roboto-Regular' !important;
    font-weight: 100;
    justify-content: center; }
    .discover-page__header-form label {
      margin-right: 0.625rem;
      position: relative; }
      .discover-page__header-form label svg {
        fill: #00affe;
        height: 1.3rem;
        bottom: 8px;
        left: 2px;
        position: absolute;
        width: 40px; }
      .discover-page__header-form label input {
        background-color: rgba(255, 255, 255, 0.5);
        min-width: 250px; }
        .discover-page__header-form label input:focus {
          background-color: rgba(255, 255, 255, 0.8); }
    .discover-page__header-form button {
      background-color: white;
      border: 0;
      border-radius: 50%;
      padding: 0;
      height: 2.375rem;
      width: 2.375rem; }
      .discover-page__header-form button svg {
        position: relative;
        top: -1px;
        width: 1rem; }
      .discover-page__header-form button:hover {
        background-color: #f4f4f4; }

.discover-page__header-content, .discover-page__tabs-buttons {
  z-index: 1; }

.discover-page__tabs {
  position: relative; }
  .discover-page__tabs-selector {
    display: block;
    background: none;
    border: none;
    text-align: center;
    padding: 1.25rem 1.5625rem 5rem 1.5625rem; }
    .discover-page__tabs-selector--active, .discover-page__tabs-selector:hover:not(:disabled) {
      background-color: #f4f4f4; }
    .discover-page__tabs-selector:hover:not(:disabled) p {
      text-decoration: underline; }
    .discover-page__tabs-selector:disabled {
      color: black;
      cursor: not-allowed;
      opacity: 0.5; }
    .discover-page__tabs-selector p {
      font-family: 'Oswald';
      margin-top: 1rem;
      text-transform: uppercase; }
    .discover-page__tabs-selector svg {
      width: 6.25rem;
      height: 6.25rem; }
  .discover-page__tabs:after {
    content: 'No disponible en la versión beta';
    display: inline-block;
    height: 1.5rem;
    width: 14.6875rem;
    background-color: #81d4fb;
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin: auto;
    position: absolute;
    right: -22.8125rem;
    left: 0;
    top: -4.375rem;
    bottom: 0;
    margin: auto;
    z-index: 1; }

.discover-page__content {
  background-color: #f4f4f4;
  margin-top: 70px; }

.discover-page .location-search {
  min-height: calc(100vh - 50px);
  min-height: calc((var(--vh, 1vh) * 100) - 50px); }

.discover-page .search-map .simple-map {
  height: calc(100vh - 163px);
  height: calc((var(--vh, 1vh) * 100) - 163px); }

@media screen and (min-width: 992px) {
  .discover-page__top-nav {
    display: none; }
  .discover-page__header {
    display: block; }
  .discover-page .search-map .simple-map {
    height: calc(100vh - 80px);
    height: calc((var(--vh, 1vh) * 100) - 80px); }
  .discover-page__tabs #blue-curve {
    position: absolute;
    bottom: -28vw;
    left: 0.9375rem;
    right: 0.9375rem;
    z-index: 0; }
    .discover-page__tabs #blue-curve path {
      fill: #81d4fb; } }

@media screen and (min-width: 1200px) {
  .discover-page__tabs #blue-curve {
    bottom: -29vw; } }

@media screen and (min-width: 1600px) {
  .discover-page__tabs #blue-curve {
    bottom: -31vw; } }

.poi-page {
  padding-bottom: 1.5625rem;
  background-color: #dedede; }

@media screen and (min-width: 1200px) {
  .poi-page {
    padding-bottom: 4.6875rem; } }

@media screen and (min-width: 1200px) {
  .poi-page .container {
    max-width: 65.3125rem; } }

.not-found-page {
  height: calc(100vh - 50px);
  height: calc(var(--vh, 1vh) * 100 - 50px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.9375rem; }
  .not-found-page__main-image {
    width: 100%;
    height: auto; }
    .not-found-page__main-image-wrapper {
      width: 100%;
      max-width: 31.25rem;
      margin: 0 auto; }
  .not-found-page__wire-image {
    display: none;
    margin-bottom: 0; }
  .not-found-page__title, .not-found-page__description {
    text-align: center;
    margin-bottom: 0; }
  .not-found-page__title {
    font-family: 'Roboto-Regular';
    font-weight: bold;
    color: #00affe;
    font-size: 4rem;
    text-align: center; }
  .not-found-page__description {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1.125rem; }
    .not-found-page__description--primary {
      color: #00affe; }
    .not-found-page__description--grey {
      color: #6f6f6f; }

@media screen and (min-width: 768px) {
  .not-found-page__title {
    font-size: 5.625rem; }
  .not-found-page__description {
    font-size: 1.375rem; }
  .not-found-page__wire-image {
    display: block;
    width: 100%;
    max-width: 30vw;
    height: auto; }
    .not-found-page__wire-image--left {
      margin-left: auto; }
    .not-found-page__wire-image--right {
      margin-right: auto; } }

@media screen and (min-width: 992px) {
  .not-found-page {
    height: calc(100vh - 70px);
    height: calc(var(--vh, 1vh) * 100 - 70px); } }

.error-page {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.9375rem; }
  .error-page__main-image {
    width: 100%;
    height: auto; }
    .error-page__main-image-wrapper {
      width: 100%;
      max-width: 31.25rem;
      margin: 0 auto; }
  .error-page__wire-image {
    display: none;
    margin-bottom: 0; }
  .error-page__title, .error-page__description {
    margin-bottom: 0; }
  .error-page__title {
    font-family: 'Roboto-Regular';
    font-weight: bold;
    color: #00affe;
    font-size: 4rem;
    text-align: center; }
  .error-page__description {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: 1.125rem; }
    .error-page__description--primary {
      color: #00affe; }
    .error-page__description--grey {
      color: #6f6f6f; }
  .error-page__link {
    display: inline-block;
    margin-top: 1.5625rem;
    font-size: 1rem;
    color: #2c2c2c; }

@media screen and (min-width: 768px) {
  .error-page__title {
    font-size: 5.625rem; }
  .error-page__description {
    font-size: 1.375rem; }
  .error-page__wire-image {
    display: block;
    width: 100%;
    max-width: 30vw;
    min-width: 9.375rem;
    height: auto; }
    .error-page__wire-image--left {
      margin-left: auto; }
    .error-page__wire-image--right {
      margin-right: auto; } }

@media screen and (min-width: 992px) {
  .error-page {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100); } }

.faqs-page {
  min-height: calc(100vh - 50px);
  min-height: calc(var('--vh', 1) * 100 - 50px); }
  .faqs-page__header-top {
    padding: 1.875rem 0.9375rem;
    background-color: #81d4fb;
    text-align: center; }
  .faqs-page__header-bottom {
    padding: 1.875rem 0; }
  .faqs-page__header-title {
    display: inline-block;
    color: white;
    font-family: 'Roboto-bold';
    font-weight: 100;
    font-size: 1.625rem;
    text-align: center;
    max-width: calc(100% - 80px);
    position: relative;
    margin-bottom: 0; }
  .faqs-page__header-back-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    left: -2.8125rem;
    top: -0.625rem;
    bottom: 0;
    margin: auto; }
    .faqs-page__header-back-btn svg {
      fill: white;
      height: 1.4375rem;
      width: auto; }
  .faqs-page__topic-tabs-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0; }
  .faqs-page__topic-tabs-item {
    flex: 0 0 33%;
    max-width: 33.33333%;
    text-align: center; }
  .faqs-page__topic-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.375rem;
    height: 4.375rem;
    margin: 0 auto; }
    .faqs-page__topic-icon svg {
      width: 100%;
      height: auto; }
  .faqs-page__topic-link {
    color: #2c2c2c;
    font-size: 0.875rem; }
    .faqs-page__topic-link:hover {
      text-decoration: none; }
  .faqs-page__questions {
    list-style: none;
    padding-left: 0; }
    .faqs-page__questions-header {
      text-align: center;
      padding: 1.25rem 0; }
      .faqs-page__questions-header .faqs-page__topic-icon {
        width: 5.625rem;
        height: 5.625rem; }
    .faqs-page__questions-title {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
      font-size: 0.875rem;
      margin: 0.625rem 0 0 0; }
    .faqs-page__questions-item {
      padding: 0 0.9375rem; }
      .faqs-page__questions-item + .faqs-page__questions-item {
        margin-top: 0.9375rem; }
    .faqs-page__questions-btn {
      text-align: left;
      background-color: transparent;
      border: none;
      font-family: 'Roboto-bold';
      font-size: 1.125rem;
      line-height: 1.3;
      font-weight: bold; }
  .faqs-page__question {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
    .faqs-page__question-title, .faqs-page__question-subtitle, .faqs-page__question-text, .faqs-page__question-list-item {
      font-family: 'Roboto-bold';
      font-weight: 500;
      font-size: 1.125rem; }
    .faqs-page__question-title {
      font-weight: bold;
      font-size: 1.625rem;
      margin-bottom: 1.25rem; }
    .faqs-page__question-text, .faqs-page__question-list-item {
      font-size: 0.875rem;
      line-height: 1.25; }
    .faqs-page__question-list {
      padding-left: 1.25rem; }
    .faqs-page__question-lightbox-btn {
      background-color: transparent;
      padding: 0;
      border: none;
      margin: 1.25rem 0;
      text-align: left;
      font-family: 'Roboto-bold'; }
    .faqs-page__question-img {
      max-width: 100%;
      height: auto; }

@media screen and (min-width: 992px) {
  .faqs-page {
    min-height: calc(100vh - 70px);
    min-height: calc(var('--vh', 1) * 100 - 70px);
    background-color: #dedede; }
    .faqs-page__header {
      padding: 1.875rem 0.9375rem 2.5rem 0.9375rem;
      background-color: #81d4fb; }
      .faqs-page__header-top {
        text-align: left;
        padding: 0; }
      .faqs-page__header-bottom {
        padding-bottom: 0; }
      .faqs-page__header-title {
        font-size: 2rem; }
    .faqs-page__content {
      padding-top: 2.5rem;
      margin-top: -2.8125rem;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      background-color: white;
      box-shadow: 0 0.125rem 0.3125rem rgba(111, 111, 111, 0.6);
      margin-bottom: 0.9375rem;
      min-height: calc(100vh - 220px);
      min-height: calc(var('--vh', 1) * 100 - 220px); }
    .faqs-page__topic-tabs-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0; }
    .faqs-page__topic-tabs-item {
      flex: 1 0 auto;
      max-width: unset;
      width: auto;
      text-align: center; }
    .faqs-page__topic-link {
      display: block;
      font-family: 'Roboto-bold';
      font-weight: bold;
      font-size: 1.125rem;
      padding: 0.3125rem 0.625rem 0.625rem 0.625rem;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem; }
      .faqs-page__topic-link--active {
        background-color: white; }
    .faqs-page__questions {
      margin-bottom: 0; }
      .faqs-page__questions-item {
        max-width: 14.0625rem;
        padding: 0; }
        .faqs-page__questions-item + .faqs-page__questions-item {
          margin-top: 0.9375rem; }
        .faqs-page__questions-item--active .faqs-page__questions-btn {
          background-color: #00affe;
          color: white; }
      .faqs-page__questions-btn {
        display: block;
        width: 100%;
        font-size: 1rem;
        text-align: left;
        line-height: 1.25;
        font-weight: 500;
        padding: 0.4375rem 1.25rem;
        border-radius: 0.3125rem; }
    .faqs-page__question {
      padding-top: 0; } }

.poi-info-page-preview {
  padding-bottom: 9.375rem;
  background-color: white;
  color: #303030; }
  .poi-info-page-preview__main-deco {
    display: none; }
  .poi-info-page-preview__main .allFlagsTitle {
    margin-left: 76px; }
  .poi-info-page-preview__main .allFlagsDescription {
    margin-left: 145px; }
  .poi-info-page-preview__location {
    padding: 0.625rem 0 0.625rem 1.5625rem;
    border-left: 0.0625rem dashed grey; }
    .poi-info-page-preview__location-btn {
      background: none;
      border: none; }
    .poi-info-page-preview__location-img {
      margin: 0 auto;
      height: 4.6875rem;
      width: 4.6875rem; }
    .poi-info-page-preview__location-text {
      /* text-transform: uppercase; */
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: 1.125rem; }
  .poi-info-page-preview__section-title {
    font-family: 'QuickSand';
    font-weight: 500;
    font-size: 1.375rem;
    margin: 0.9375rem 0 0 0; }
  .poi-info-page-preview__range-img {
    width: auto; }
    .poi-info-page-preview__range-img--left {
      height: 1.875rem; }
    .poi-info-page-preview__range-img--right {
      height: 4.375rem;
      margin-top: -2.1875rem; }
  .poi-info-page-preview__range-text {
    text-transform: uppercase;
    margin-top: 0.9375rem; }
    .poi-info-page-preview__range-text--left {
      float: left; }
    .poi-info-page-preview__range-text--right {
      float: right;
      margin-right: -3.125rem; }
  .poi-info-page-preview__footer {
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 0.9375rem 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .poi-info-page-preview .flag {
    width: 50px;
    background: none;
    border: none;
    height: 50px;
    padding: 0px;
    margin: 0px 5px; }
  .poi-info-page-preview .select {
    border-bottom: 5px solid #21B5FD; }
  .poi-info-page-preview .camp {
    border: none;
    background-color: rgba(129, 212, 251, 0.3);
    margin: 0px 10px; }
  .poi-info-page-preview .special {
    background-color: #F2ECD6; }
  .poi-info-page-preview .label {
    width: 180px; }
  .poi-info-page-preview .label2 {
    width: 325px; }
  .poi-info-page-preview .selectpartner {
    background-color: #21B5FD !important; }

.navTop {
  padding: 10px;
  background-color: #efefef;
  align-items: center; }
  .navTop h5 {
    margin: 0px;
    font-size: 18px; }
  .navTop__title {
    width: 60%;
    text-align: end;
    color: #00affe;
    font-weight: 700; }
  .navTop__state {
    width: 40%;
    justify-content: flex-end;
    align-items: center; }
    .navTop__state div {
      background-color: #BDDCB0;
      padding: 15px 40px 15px 40px;
      margin: 0px 10px 0px; }
  .navTop .blueCheck {
    margin-left: 30px;
    background-color: #00affe; }

@media screen and (min-width: 992px) {
  .poi-info-page-preview {
    padding: 2.5rem 1.5625rem 1.875rem 6.25rem;
    /*     border-top-left-radius: rem(15px);
    border-bottom-right-radius: rem(15px);
    box-shadow: 0 0 3px rgba(black, 0.2); */
    margin-bottom: 4.6875rem; }
    .poi-info-page-preview__main {
      /* border-left: rem(3px) solid $topcolor-light-blue; */
      margin: 0 0 0rem -1.4375rem;
      padding: 0rem 0 0rem 1.875rem;
      position: relative;
      /* &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: rem(3px);
        background-color: $topcolor-light-blue;
      } */ }
      .poi-info-page-preview__main:before {
        top: 0;
        width: 6.875rem; }
      .poi-info-page-preview__main:after {
        bottom: 0;
        width: 12.5rem; }
      .poi-info-page-preview__main-deco {
        display: inline-block;
        position: absolute;
        z-index: 1; }
        .poi-info-page-preview__main-deco--top {
          width: auto;
          height: 4.375rem;
          top: -3.75rem;
          left: 6.875rem; }
        .poi-info-page-preview__main-deco--bottom {
          bottom: 0;
          left: 11.75rem;
          width: auto;
          height: 2.5rem; }
    .poi-info-page-preview__location {
      border: none;
      padding: 0; }
      .poi-info-page-preview__location-btn {
        margin-left: 0.9375rem; }
      .poi-info-page-preview__location-text {
        display: block;
        /* text-transform: uppercase; */
        margin: 0.3125rem 0 0 0; }
      .poi-info-page-preview__location-img {
        width: 3.4375rem;
        height: 3.4375rem; }
    .poi-info-page-preview__section-title {
      font-size: 1.375rem;
      margin: 0 -0.9375rem 0.9375rem -0.9375rem; }
    .poi-info-page-preview .fine-uploader-dropzone-container {
      border-radius: 0.9375rem;
      border: 0.0625rem dashed #2c2c2c;
      height: auto; }
      .poi-info-page-preview .fine-uploader-dropzone-container.react-fine-uploader-dropzone-active {
        border: 0.0625rem solid #00affe;
        background-color: rgba(0, 175, 254, 0.1);
        opacity: 0.75; } }

@media screen and (max-width: 768px) {
  .poi-info-page-preview .instagramMobile {
    margin-bottom: 100px !important; } }

.sponsor-place .botonabajo,
.payForm .botonabajo {
  margin: 30px 0;
  min-width: 200px; }

.sponsor-place .pagarButtonSponsor,
.payForm .pagarButtonSponsor {
  min-width: 200px; }

.sponsor-place .mobile,
.payForm .mobile {
  display: none; }

.sponsor-place .tour,
.payForm .tour {
  border: 1px solid red !important; }

.sponsor-place .div1,
.payForm .div1 {
  width: 100%;
  height: 180px;
  background-color: #78caf9; }
  .sponsor-place .div1 .title1,
  .payForm .div1 .title1 {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.5rem;
    font-family: 'Roboto-bold';
    color: white;
    margin-bottom: 5px; }
  .sponsor-place .div1 .title2,
  .payForm .div1 .title2 {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1rem;
    font-family: 'Roboto-Regular'; }

.sponsor-place .div2,
.payForm .div2 {
  width: 100%;
  padding: 30px 20px 40px 20px;
  background-color: #f5f5f5;
  position: relative;
  font-family: 'Roboto-Regular' !important; }
  .sponsor-place .div2 .ItemDiv2,
  .payForm .div2 .ItemDiv2 {
    width: 335px;
    margin-left: 15px;
    margin-right: 15px; }
    .sponsor-place .div2 .ItemDiv2 .msg2,
    .payForm .div2 .ItemDiv2 .msg2 {
      text-align: center;
      margin: 0px 10px 0px 10px; }
      .sponsor-place .div2 .ItemDiv2 .msg2 p,
      .payForm .div2 .ItemDiv2 .msg2 p {
        line-height: 1.3;
        font-size: 14px;
        margin-top: 10px; }
  .sponsor-place .div2 .I1,
  .payForm .div2 .I1 {
    font-family: 'Oswald'; }
    .sponsor-place .div2 .I1 .divPatrocinar,
    .payForm .div2 .I1 .divPatrocinar {
      position: relative; }
      .sponsor-place .div2 .I1 .divPatrocinar span,
      .payForm .div2 .I1 .divPatrocinar span {
        position: absolute;
        top: -25px; }
      .sponsor-place .div2 .I1 .divPatrocinar .Img2,
      .payForm .div2 .I1 .divPatrocinar .Img2 {
        width: 100%;
        object-fit: cover;
        height: 220px;
        object-position: center;
        position: absolute; }
      .sponsor-place .div2 .I1 .divPatrocinar .img-link1,
      .payForm .div2 .I1 .divPatrocinar .img-link1 {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer; }
  .sponsor-place .div2 .I2 .title2,
  .payForm .div2 .I2 .title2 {
    text-align: center;
    width: 90%;
    font-family: 'Roboto-bold';
    border-bottom: 1px dashed #333333; }
  .sponsor-place .div2 .I2 .itemList,
  .payForm .div2 .I2 .itemList {
    display: block;
    margin: 0px 0px 0px 0px;
    margin-top: 10px;
    font-family: 'Oswald';
    text-transform: uppercase; }
    .sponsor-place .div2 .I2 .itemList svg,
    .payForm .div2 .I2 .itemList svg {
      width: 20px !important;
      height: 20px !important; }
  .sponsor-place .div2 .I2 .icono2,
  .payForm .div2 .I2 .icono2 {
    height: auto; }
  .sponsor-place .div2 .I2 .img-link2,
  .payForm .div2 .I2 .img-link2 {
    cursor: pointer; }
  .sponsor-place .div2 .I3,
  .payForm .div2 .I3 {
    background-color: #7bcffe;
    position: relative; }
    .sponsor-place .div2 .I3 .Title2-3,
    .payForm .div2 .I3 .Title2-3 {
      font-family: 'Roboto-bold';
      height: 60px; }
    .sponsor-place .div2 .I3 .bloque-2-I3 .SubTitle2-3,
    .payForm .div2 .I3 .bloque-2-I3 .SubTitle2-3 {
      font-family: 'Roboto-bold';
      height: 50px;
      color: white;
      font-size: 70px; }
    .sponsor-place .div2 .I3 .bloque-2-I3 .Anuales,
    .payForm .div2 .I3 .bloque-2-I3 .Anuales {
      width: 81%;
      text-align: right;
      font-family: 'Oswald'; }
    .sponsor-place .div2 .I3 .bloque-2-I3 .PrecioProm,
    .payForm .div2 .I3 .bloque-2-I3 .PrecioProm {
      font-size: 13px; }
    .sponsor-place .div2 .I3 .DirectAutor,
    .payForm .div2 .I3 .DirectAutor {
      margin-top: 10px;
      margin-bottom: 45px;
      font-weight: bold !important;
      font-size: 24px; }
    .sponsor-place .div2 .I3 .NombrePersona,
    .payForm .div2 .I3 .NombrePersona {
      width: fit-content;
      position: absolute;
      bottom: 0px;
      left: 150px;
      font-family: 'Oswald'; }
    .sponsor-place .div2 .I3 .img-foto,
    .payForm .div2 .I3 .img-foto {
      border-radius: 100%;
      position: absolute;
      height: 60px;
      width: 60px;
      object-fit: cover;
      bottom: -30px;
      left: 80px;
      background-color: white; }
  .sponsor-place .div2 .botonMitad,
  .payForm .div2 .botonMitad {
    position: absolute;
    bottom: -17px; }

.sponsor-place .div3,
.payForm .div3 {
  width: 100%;
  position: relative;
  margin: 50px 0 0; }
  .sponsor-place .div3 h3,
  .payForm .div3 h3 {
    font-family: 'Roboto-bold'; }

.sponsor-place .div4,
.payForm .div4 {
  width: 100%;
  height: 400px; }
  .sponsor-place .div4 .ItemDiv4,
  .payForm .div4 .ItemDiv4 {
    width: 340px;
    border: 1.5px solid #7bcffe;
    margin: 3px;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: column; }
    .sponsor-place .div4 .ItemDiv4 .title2,
    .payForm .div4 .ItemDiv4 .title2 {
      margin-top: 15px;
      padding-left: 5%;
      padding-right: 5%;
      font-size: 1rem;
      font-family: 'Roboto-Regular'; }
    .sponsor-place .div4 .ItemDiv4 .title22,
    .payForm .div4 .ItemDiv4 .title22 {
      margin-top: 15px;
      padding-left: 5%;
      padding-right: 5%;
      font-size: 1rem;
      font-family: 'Oswald'; }
    .sponsor-place .div4 .ItemDiv4 .Titulo,
    .payForm .div4 .ItemDiv4 .Titulo {
      text-align: center;
      width: 90%;
      font-family: 'Roboto-bold';
      border-bottom: 1px dashed #333333;
      margin: 0 auto;
      padding-bottom: 5px;
      margin-top: 45px; }
    .sponsor-place .div4 .ItemDiv4 .divbotones,
    .payForm .div4 .ItemDiv4 .divbotones {
      margin-top: 70px;
      height: 150px; }
      .sponsor-place .div4 .ItemDiv4 .divbotones .botones1,
      .payForm .div4 .ItemDiv4 .divbotones .botones1 {
        height: 80px; }
      .sponsor-place .div4 .ItemDiv4 .divbotones .botones,
      .payForm .div4 .ItemDiv4 .divbotones .botones {
        width: 70px;
        margin: 10px; }

.sponsor-place .div5,
.payForm .div5 {
  width: 100%; }
  .sponsor-place .div5 .div6,
  .payForm .div5 .div6 {
    width: 55%; }
    .sponsor-place .div5 .div6 p,
    .payForm .div5 .div6 p {
      margin-bottom: 0px;
      font-family: 'Roboto-bold';
      font-size: 1rem; }
    .sponsor-place .div5 .div6 .title2,
    .payForm .div5 .div6 .title2 {
      font-size: 1rem;
      font-family: 'Roboto-Regular'; }
  .sponsor-place .div5 p,
  .payForm .div5 p {
    margin-top: 10px;
    font-family: 'Roboto-bold';
    font-size: 1.5rem; }

.sponsor-place .div7,
.payForm .div7 {
  width: 100%; }
  .sponsor-place .div7 .div6,
  .payForm .div7 .div6 {
    width: 34%; }
    .sponsor-place .div7 .div6 p,
    .payForm .div7 .div6 p {
      margin-top: 20px;
      font-family: 'Roboto-bold';
      font-size: 1.5rem;
      color: #1fb6ff; }

.sponsor-place .botonabajo,
.payForm .botonabajo {
  margin: 30px; }

@media screen and (max-width: 991px) {
  .sponsor-place .div1,
  .payForm .div1 {
    width: 100%;
    height: auto;
    padding: 30px 0;
    box-sizing: unset;
    background-color: #78caf9; }
    .sponsor-place .div1 .Title2,
    .payForm .div1 .Title2 {
      text-align: left !important; }
    .sponsor-place .div1 h3,
    .payForm .div1 h3 {
      font-size: 18px; }
    .sponsor-place .div1 h6,
    .payForm .div1 h6 {
      font-size: 16px; }
  .sponsor-place .div2,
  .payForm .div2 {
    padding-bottom: 170px; }
    .sponsor-place .div2 .I1 .divPatrocinar,
    .payForm .div2 .I1 .divPatrocinar {
      margin-top: 30px; }
    .sponsor-place .div2 .I2,
    .payForm .div2 .I2 {
      padding: 250px 0 0px;
      box-sizing: unset; }
      .sponsor-place .div2 .I2 .msg2,
      .payForm .div2 .I2 .msg2 {
        text-align: justify;
        box-sizing: unset; }
      .sponsor-place .div2 .I2 .itemList,
      .payForm .div2 .I2 .itemList {
        font-size: 15px;
        font-weight: 600; }
        .sponsor-place .div2 .I2 .itemList svg,
        .payForm .div2 .I2 .itemList svg {
          width: 30px !important;
          height: 30px !important; }
    .sponsor-place .div2 .I3,
    .payForm .div2 .I3 {
      margin: 40px 0 0;
      width: 100%;
      height: 420px; }
      .sponsor-place .div2 .I3 .img-foto,
      .payForm .div2 .I3 .img-foto {
        bottom: -30px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2; }
      .sponsor-place .div2 .I3 .NombrePersona,
      .payForm .div2 .I3 .NombrePersona {
        position: absolute;
        bottom: -55px;
        left: 0;
        right: 0;
        margin: auto;
        font-weight: bold;
        text-transform: capitalize; }
      .sponsor-place .div2 .I3 .BackGround-Blue,
      .payForm .div2 .I3 .BackGround-Blue {
        background-color: #7bcffe;
        z-index: 2; }
      .sponsor-place .div2 .I3 .Title2-3,
      .payForm .div2 .I3 .Title2-3 {
        margin-bottom: 15px; }
        .sponsor-place .div2 .I3 .Title2-3 h3,
        .payForm .div2 .I3 .Title2-3 h3 {
          font-size: 1.4rem; }
      .sponsor-place .div2 .I3 .bloque-2-I3,
      .payForm .div2 .I3 .bloque-2-I3 {
        margin-bottom: 20px; }
        .sponsor-place .div2 .I3 .bloque-2-I3 .SubTitle2-3,
        .payForm .div2 .I3 .bloque-2-I3 .SubTitle2-3 {
          margin-top: 10px; }
        .sponsor-place .div2 .I3 .bloque-2-I3 .Anuales,
        .payForm .div2 .I3 .bloque-2-I3 .Anuales {
          width: 96%;
          font-weight: bold; }
        .sponsor-place .div2 .I3 .bloque-2-I3 .PrecioProm,
        .payForm .div2 .I3 .bloque-2-I3 .PrecioProm {
          text-align: end;
          width: 172px;
          display: flex;
          flex-direction: column; }
      .sponsor-place .div2 .I3 .DirectAutor,
      .payForm .div2 .I3 .DirectAutor {
        position: relative; }
        .sponsor-place .div2 .I3 .DirectAutor .Eur50,
        .payForm .div2 .I3 .DirectAutor .Eur50 {
          font-size: 70px; }
        .sponsor-place .div2 .I3 .DirectAutor .DirectAutor2,
        .payForm .div2 .I3 .DirectAutor .DirectAutor2 {
          position: absolute;
          text-align: center;
          bottom: -31px;
          width: 132px;
          font-size: 18px;
          text-align: end; }
      .sponsor-place .div2 .I3 .LineVertical,
      .payForm .div2 .I3 .LineVertical {
        position: absolute;
        height: 100%;
        border-left: 1.6px dashed white;
        z-index: 1; }
    .sponsor-place .div2 .botonMitad,
    .payForm .div2 .botonMitad {
      width: 200px;
      bottom: 25px; }
  .sponsor-place .div33,
  .payForm .div33 {
    width: 100%;
    position: relative;
    border-bottom: 1px dashed #7bcffe;
    height: 30px;
    margin-bottom: 30px; }
    .sponsor-place .div33 .titulomobile,
    .payForm .div33 .titulomobile {
      position: absolute;
      bottom: -18px;
      background: white; }
  .sponsor-place .divVentajas,
  .payForm .divVentajas {
    margin-bottom: 0px; }
  .sponsor-place .flip1,
  .payForm .flip1 {
    padding: 10px 5px;
    text-align: left;
    color: #7bcffe;
    font-family: 'Roboto-bold';
    border-bottom: 1px solid #7bcffe;
    padding: 0px;
    margin: 20px;
    height: 25px; }
    .sponsor-place .flip1 .imgdown,
    .payForm .flip1 .imgdown {
      background: red; }
  .sponsor-place .panel,
  .payForm .panel {
    margin: 20px;
    display: none; }
    .sponsor-place .panel .title2,
    .payForm .panel .title2 {
      margin: 0px;
      font-size: 0.8rem;
      font-family: 'Roboto-Regular'; }
    .sponsor-place .panel .divbotones .botones,
    .payForm .panel .divbotones .botones {
      height: 50px;
      width: 90px;
      margin: 10px; }
    .sponsor-place .panel .title22,
    .payForm .panel .title22 {
      margin-top: 10px;
      margin-bottom: 0px;
      padding-left: 5%;
      padding-right: 5%;
      font-size: 1rem;
      font-family: 'Oswald'; }
  .sponsor-place .div7,
  .payForm .div7 {
    width: 100%; }
    .sponsor-place .div7 .div66 p,
    .payForm .div7 .div66 p {
      margin-top: 20px;
      font-family: 'Roboto-bold';
      font-size: 1.3rem;
      color: #1fb6ff; }
  .sponsor-place .div55,
  .payForm .div55 {
    width: 100%; }
    .sponsor-place .div55 .div66,
    .payForm .div55 .div66 {
      width: 100%;
      margin: 20px; }
      .sponsor-place .div55 .div66 p,
      .payForm .div55 .div66 p {
        margin-bottom: 0px;
        font-family: 'Roboto-bold';
        font-size: 1rem; }
      .sponsor-place .div55 .div66 .title2,
      .payForm .div55 .div66 .title2 {
        font-size: 1rem;
        font-family: 'Roboto-Regular'; }
    .sponsor-place .div55 p,
    .payForm .div55 p {
      margin-top: 10px;
      font-family: 'Roboto-bold';
      font-size: 1.5rem; }
  .sponsor-place .botonabajo2,
  .payForm .botonabajo2 {
    margin: 10px;
    width: 50%; }
  .sponsor-place .img2,
  .payForm .img2 {
    fill: white; } }

.tinyButtons {
  min-width: 40px !important;
  width: 40px !important;
  margin: auto 2px; }

.transparentTitle {
  color: transparent; }

.modalImagesSponsor {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: white;
  overflow: auto;
  outline: none;
  padding: 10px 20px 20px 20px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .modalImagesSponsor {
      align-items: center; } }
  .modalImagesSponsor__contentModal {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529d4; }
  .modalImagesSponsor img {
    width: auto;
    height: 800px; }
    @media (max-width: 991px) {
      .modalImagesSponsor img {
        width: 95%;
        height: auto; } }
  .modalImagesSponsor .closeIcon {
    background-color: transparent;
    display: inline-block;
    border: none;
    vertical-align: middle;
    line-height: 1; }
    .modalImagesSponsor .closeIcon svg {
      width: 30px;
      height: 30px;
      fill: black; }

.modalPay {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: white;
  overflow: auto;
  outline: none;
  padding: 10px 10px 20px 10px;
  width: 50%;
  height: 100%; }
  .modalPay__contentModal {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #212529d4; }
  .modalPay .content .infoTours h3 {
    font-weight: 700; }
  .modalPay .content .infoTours .p {
    width: 20%; }
  .modalPay .content .infoTours img.portada {
    height: 100%;
    object-fit: cover; }
  .modalPay .content .infoTours h5 {
    font-weight: 700;
    margin: 0; }
  .modalPay .content .infoTours h4 {
    font-weight: 700; }
  .modalPay .content .infoTours .previewImages {
    width: 100%; }
  .modalPay .content .infoTours .img-link2 {
    cursor: pointer; }
  .modalPay .content .infoPay h3, .modalPay .content .infoPay h4 {
    font-weight: 700; }
  .modalPay .content .division {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-top: 10px; }
  .modalPay .content .division2 {
    background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
    margin: 10px; }
  .modalPay .content .closeIcon {
    background-color: transparent;
    display: inline-block;
    border: none;
    vertical-align: middle;
    line-height: 1; }
    .modalPay .content .closeIcon svg {
      width: 40px;
      height: 40px;
      fill: black; }
  .modalPay .content .contentPay {
    padding: 30px; }
    .modalPay .content .contentPay .top .circle {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0px 1px 3px #888888;
      overflow: hidden; }
      .modalPay .content .contentPay .top .circle img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .modalPay .content .contentPay .top .textBlue {
      color: #1fb6ff; }
    .modalPay .content .contentPay .center .payService {
      background-color: #68cdff;
      padding: 20px;
      margin-top: 18px; }
      .modalPay .content .contentPay .center .payService h1 {
        font-size: 80px;
        font-weight: 700;
        color: white; }
      .modalPay .content .contentPay .center .payService h3 {
        font-weight: 700;
        color: black;
        text-align: center; }
    .modalPay .content .contentPay .center .notice {
      display: flex;
      text-align: center;
      color: #7c959c; }
    .modalPay .content .contentPay .center .mastercard {
      width: 40px;
      height: 26px;
      padding: 2px;
      border: 1px solid lightgray;
      margin-left: 30px; }
    .modalPay .content .contentPay .center .visa {
      width: 40px;
      height: 26px;
      padding-right: 2px;
      border: 1px solid lightgray;
      margin-left: 30px; }
    .modalPay .content .contentPay .center .amex {
      width: 45px;
      margin-left: 28px; }
    .modalPay .content .contentPay .center .inputCard {
      background: url(/assets/images/cardgray.svg) no-repeat scroll 7px 7px;
      padding-left: 60px;
      background-size: 45px;
      border: 1px solid lightgray; }
    .modalPay .content .contentPay .center .form-control {
      background-color: white !important;
      border: 1px solid lightgray; }
  .modalPay .content .paymentConfirmed .textBlue {
    color: #1fb6ff; }
  .modalPay .content .paymentConfirmed .grafismo {
    width: 250px; }

@media screen and (max-width: 1480px) {
  .modalPay {
    width: 60%; } }

@media screen and (max-width: 992px) {
  .modalPay {
    width: 90%;
    height: 90%;
    border-radius: 20px; }
    .modalPay__contentModal {
      justify-content: center; }
    .modalPay .closeIcon svg {
      width: 35px !important;
      height: 35px !important;
      fill: #68cdff !important; }
    .modalPay .infoPay h3 {
      text-align: center; }
    .modalPay .contentPay .buttonPay {
      width: 200px; } }

@media screen and (max-width: 768px) {
  .modalPay .textBlue {
    font-size: 20px;
    font-weight: 700; }
  .modalPay .center {
    flex-direction: column; }
    .modalPay .center .col-6 {
      max-width: 100% !important; }
    .modalPay .center .payService {
      background-color: white !important;
      padding: 0px !important; }
      .modalPay .center .payService h3 {
        width: 100%;
        font-weight: 100 !important;
        font-size: 16px;
        text-align: left !important; }
      .modalPay .center .payService h1 {
        width: 100%;
        background-color: #68cdff;
        text-align: center; }
    .modalPay .center .sub {
      font-weight: 700; }
    .modalPay .center .textPay {
      font-size: 20px;
      font-weight: 700; }
  .modalPay .contentPay {
    padding: 10px 0px !important;
    margin: 20px; }
    .modalPay .contentPay .top {
      justify-content: center; }
  .paymentConfirmed__content {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 25px; }
  .paymentConfirmed__buttons {
    flex-direction: column;
    align-items: center; }
    .paymentConfirmed__buttons button {
      width: 205px;
      margin: 10px 0px !important; }
  .botonabajo2 {
    margin: 10px;
    width: 50%; }
  .img2 {
    fill: red; } }

input,
.StripeElement {
  height: 50px;
  padding: 16px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 2px;
  font-size: 1.1875rem;
  font-family: 'Oswald' !important;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.__PrivateStripeElement-input {
  font-size: 18px;
  text-transform: uppercase; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }
