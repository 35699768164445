//All those classes are dependant on the "autosuggest" npm package https://github.com/moroshko/react-autosuggest#installation
.autosuggest-wrapper {
  &__input {
    position: relative;
    margin-top: rem(15px);
    &-icon {
      fill: red;
      position: absolute;
      top: rem(12px);
      left: rem(10px);
      width: 1.5rem;
      svg path {
        fill: red;
      }
    }
  }
}
.react-autosuggest {
  background-color: $topcolor-blue;
  color: white;
  &__container {
    display: inline-block;
    width: 100%;
  }
  &__suggestions-container {
    position: relative;
    &--open {
      background-color: white;
      border: 1px solid $topcolor-blue;
      margin-top: -1px;
    }
  }
  &__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: rem(-1px);
    right: rem(-1px);
    z-index: 100;
    border: rem(1px) solid $topcolor-black;
  }
  &__suggestion {
    background-color: $topcolor-grey;
    color: $topcolor-light-black;
    cursor: pointer;
    &--highlighted {
      background-color: $topcolor-blue;
      color: white;
    }
  }
}

.suggestion-list-item {
  padding: rem(10px);
}
// .suggestion-input
// .suggestion-list-section-title {
//   background-color: #f1f1f1;
//   border-top: 1px solid black;
//   font-size: 0.75rem;
//   font-family: 'Roboto-bold';
//   font-weight: bold;
//   padding: rem(3px) rem(4px);
// }
