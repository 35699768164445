.tour-content-tip-form {
  font-family: 'Roboto-bold';
  padding-bottom: rem(20px);
  &__title {
    font-weight: bold;
    font-size: rem(22px);
    margin-bottom: rem(15px);
  }
  &__cover {
    width: calc(50vw - 25px);
    height: 30vw;
    min-height: rem(175px);
    object-fit: cover;
    margin-bottom: rem(20px);
    &--no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: rem(1px) dotted $topcolor-dark-grey;
      padding: rem(15px);
      .tour-content-tip-form__cover-icon {
        margin-bottom: rem(15px);
        svg {
          width: rem(40px);
          height: auto;
        }
      }
    }
  }

  &__label {
    font-weight: bold;
    line-height: 1.2;
    font-size: rem(18px);
    svg {
      margin-right: rem(10px);
      fill: $topcolor-blue;
      width: rem(30px);
    }
  }

  &__photos {
    &-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    &-item {
      height: rem(125px);
      width: rem(100px);
      display: inline-block;
      margin: rem(0 5px 5px 0);
      padding-top: rem(25px);
      .fileInput {
        padding: 0;
      }
      .fine-uploader__files-wrapper {
        padding: 0;
      }
      .fileInput,
      .fine-uploader__file-input,
      .fine-uploader__files-wrapper,
      .responsive-img {
        height: 100%;
      }
      .thumbnails-wrapper__box {
        display: none;
      }
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
    &-add {
      border: none;
      background-color: $topcolor-blue;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      svg {
        width: 40%;
        height: 40%;
        margin: 0 auto;
        fill: white;
      }
    }
    &-remove {
      position: absolute;
      top: rem(-25px);
      right: rem(3px);
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        fill: $topcolor-red;
        width: rem(25px);
        height: rem(25px);
      }
    }
  }
  &__rich-text-editor {
    min-height: rem(150px);
    width: 100%;
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-container {
      min-height: rem(100px);
    }
    strong {
      color: $topcolor-blue;
      font-weight: 100;
    }
    a {
      text-decoration: underline;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__suggestions {
    line-height: 1.2;
    font-size: rem(18px);
    border: rem(1px) solid $topcolor-light-black;
    position: absolute;
    top: rem(40px);
    left: rem(15px);
    right: 0;
    z-index: 1;
    background-color: white;
    &-item {
      padding: rem(10px);
      &--active {
        background-color: rgba($topcolor-blue, 1);
        color: white;
      }
      & + .poi-position-modal__suggestions-item {
        border-top: rem(1px) solid $topcolor-light-black;
      }
    }
  }
  &__map-title {
    font-size: rem(22px);
    text-align: center;
    margin-top: rem(15px);
  }
  &__map {
    padding: 0;
  }
  &__location {
    padding: rem(10px) 0 rem(10px) rem(15px);
    border-left: rem(1px) dashed grey;
    &-btn {
      background: none;
      border: none;
    }
    &-img {
      margin: 0 auto;
      height: rem(75px);
      width: rem(75px);
    }
    &-text {
      text-transform: uppercase;
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: rem(18px);
    }
  }
  &__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(white, 0.5);
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: $topcolor-black;
      stroke: $topcolor-black;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-tip-form {
    margin-top: 0;
    &__cover {
      width: 100%;
      height: auto;
      min-height: rem(175px);
      object-fit: cover;
      margin-bottom: rem(20px);
    }
    &__location {
      border: none;
      padding: 0;
      &-btn {
        margin-left: rem(15px);
      }
      &-text {
        display: block;
        text-transform: uppercase;
        margin: rem(5px) 0 0 0;
      }
      &-img {
        width: rem(55px);
        height: rem(55px);
      }
    }
    &__map {
      padding: rem(15px);
    }
  }
}
