.discover-page {
  &__top-nav {
    align-items: center;
    background-color: $topcolor-light-black;
    display: flex;
    height: rem(70px);
    margin-top: 0;
    padding: 0 rem(5px) 0 rem(15px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1002;
  }
  &__top-btn {
    padding: rem(5px);
    text-align: center;
    border: none;
    background-color: transparent;
    &:hover,
    &--active {
      background-color: $topcolor-light-blue;
    }
    svg {
      height: rem(50px);
    }
  }
  &__back-btn {
    background: initial;
    border: none;
    margin-right: auto;
    padding: 0;
    transform: rotate(45deg);
    height: rem(32px);
    width: rem(32px);
    &:before {
      border-bottom: 0.1875rem solid $topcolor-light-grey;
      border-left: 0.1875rem solid $topcolor-light-grey;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  &__header {
    display: none;
    overflow: hidden;
    text-align: center;
    &:before {
      content: '';
      background-color: $topcolor-light-grey;
      height: 100%;
      display: block;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: -1;
    }
    &-title {
      color: white;
      display: inline-block;
      font-family: 'Roboto-bold';
    }
    &-subtitle {
      color: initial;
      display: block;
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      text-align: right;
    }
    &-description {
      font-size: 1.1rem;
  
        font-family: 'Roboto-Regular';
    }
    &-form {
      font-family:'Roboto-Regular' !important ;
      font-weight: 100;
      justify-content: center;
      label {
        margin-right: rem(10px);
        position: relative;
        svg {
          fill: $topcolor-blue;
          height: 1.3rem;
          bottom: 8px;
          left: 2px;
          position: absolute;
          width: 40px;
        }
        input {
          background-color: rgba(255, 255, 255, 0.5);
          min-width: 250px;
          &:focus {
            background-color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      button {
        background-color: white;
        border: 0;
        border-radius: 50%;
        padding: 0;
        height: rem(38px);
        width: rem(38px);
        svg {
          position: relative;
          top: -1px;
          width: 1rem;
        }
        &:hover {
          background-color: $topcolor-light-grey;
        }
      }
    }
  }
  &__header-content,
  &__tabs-buttons {
    z-index: 1;
  }
  &__tabs {
    position: relative;
    &-selector {
      display: block;
      background: none;
      border: none;
      text-align: center;
      padding: rem(20px 25px 80px 25px);
      &--active,
      &:hover:not(:disabled) {
        background-color: $topcolor-light-grey;
      }
      &:hover:not(:disabled) {
        p {
          text-decoration: underline;
        }
      }
      &:disabled {
        color: black;
        cursor: not-allowed;
        opacity: 0.5;
      }
      p {
        font-family: 'Oswald';
        margin-top: rem(16px);
        text-transform: uppercase;
      }
      svg {
        width: rem(100px);
        height: rem(100px);
      }
    }
    &:after {
      content: 'No disponible en la versión beta';
      display: inline-block;
      height: rem(24px);
      width: rem(235px);
      background-color: $topcolor-light-blue;
      color: white;
      text-align: center;
      text-transform: uppercase;
      margin: auto;
      position: absolute;
      right: rem(-365px);
      left: 0;
      top: rem(-70px);
      bottom: 0;
      margin: auto;
      z-index: 1;
    }
  }
  &__content {
    background-color: $topcolor-light-grey;
    margin-top: 70px;
  }
  .location-search {
    min-height: calc(100vh - 50px);
    min-height: calc((var(--vh, 1vh) * 100) - 50px);
  }
  .search-map {
    & .simple-map {
      height: calc(100vh - 163px);
      height: calc((var(--vh, 1vh) * 100) - 163px);
    }
  }
}

@media screen and (min-width: 992px) {
  .discover-page {
    &__top-nav {
      display: none;
    }
    &__header {
      display: block;
    }
    .search-map {

      & .simple-map {
        height: calc(100vh - 80px);
        height: calc((var(--vh, 1vh) * 100) - 80px);
      }
    }
    &__tabs {
      #blue-curve {
        position: absolute;
        bottom: -28vw;
        left: rem(15px);
        right: rem(15px);
        z-index: 0;
        path {
          fill: $topcolor-light-blue;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .discover-page {
    &__tabs {
      #blue-curve {
        bottom: -29vw;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .discover-page {
    &__tabs {
      #blue-curve {
        bottom: -31vw;
      }
    }
  }
}
