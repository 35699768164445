.poi-edition {
  background-color: $topcolor-grey;

  .input-group-text {
    background: transparent;
    border: none;
  }
}

@media screen and (min-width: 992px) {
  .poi-edition {
    min-height: calc(100vh - 70px);
    min-height: calc((var(--vh, 1vh) * 100) - 70px);
    padding-bottom: rem(60px);
  }
}
