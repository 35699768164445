.modal-warning {
  max-width: 90vw;
  height: rem(450px);
  max-height: 95vh;
  max-height: calc(var(--vh, 1vh) * 95);
  width: 100%;
  background-color: #fff;
  margin: auto;
  padding: rem(140px) 0 rem(20px) 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
    &-thumb {
      background: $topcolor-blue;
    }
  }

  &__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    z-index: 1;
    svg {
      width: rem(30px);
      height: rem(30px);
    }
  }

  &__main-image {
    position: absolute;
    width: 75%;
    max-width: rem(275px);
    top: rem(-40px);
    left: rem(15px);
    z-index: 1001;
  }

  &__content {
    max-height: calc(100vh - 100px);
    max-height: calc(var(--vh, 1vh) * 100 - 100px);
    overflow-y: auto;
    &-title,
    &-text {
      padding-left: 25%;
    }
    &-title {
      font-size: rem(18px);
      margin: 0 0 rem(10px) auto;
      text-align: right;
      font-weight: bold;
    }
    &-text {
      text-align: justify;
      font-size: rem(16px);
      line-height: 1.25;
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($topcolor-black, 0.9);
    z-index: 1010;
  }

  &__cross-icon {
    svg {
      fill: $topcolor-red;
      width: rem(50px);
      height: rem(50px);
    }
  }
}

@media screen and (min-width: 992px) {
  .modal-warning {
    padding: rem(10px) 0 0 rem(90px);
    max-width: rem(850px);
    height: rem(710px);
    overflow-y: auto;
    &.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    &__main-image {
      top: rem(10px);
      width: auto;
      max-width: 40%;
      margin-left: rem(70px);
    }
    &__content {
      padding: rem(100px) rem(45px) rem(30px) rem(30px);
      max-height: unset;
      overflow-y: unset;
      &-title {
        text-align: left;
        font-size: rem(28px);
      }
      &-title,
      &-text {
        padding-left: 0;
      }
    }
    &__footer {
      margin-left: rem(-100px);
      font-size: rem(18px);
    }
    &__highlight {
      text-align: right;
      padding-right: rem(15px);
      margin-bottom: rem(5px);
      span {
        background-color: $topcolor-red;
        padding: rem(5px);
        color: white;
        font-size: rem(20px);
      }
    }
  }
}
