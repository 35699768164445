.tour-header {
  position: relative;
  &--staff-pick {
    &:before {
      content: '';
      background: url('/assets/images/icono_staff_pick.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: rem(2px);
      left: rem(10px);
      height: rem(70px);
      width: rem(80px);
      z-index: 2;
    }
  }
  .description_route {
    margin-bottom: rem(15px);
    font-size: rem(19px);
    font-family: 'Roboto',sans-serif !important;
    font-weight: 100;
  }
  .buttonLike, .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    img {
      width: 90%;
    }
  }
  .likeTour {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: rem(1px) dashed $topcolor-dark-grey;
    border-right: rem(1px) dashed $topcolor-dark-grey;
    @media (max-width: 600px) {
      padding: 0 8px;
      margin: 0 0 0 10px !important;
    }
  }
  &__cover {
    background-color: white;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($topcolor-black, 0.3);
      z-index: -1;
    }
    &-data {
      position: absolute;
      top: 10vh;
      top: calc(var(--vh, 1vh) * 10);
      left: 0;
      right: 0;
    }
    &-image {
      width: 100%;
      height: calc(100vh - 100px);
      height: calc(var(--vh, 1vh) * 100 - 100px);
      min-height: rem(250px);
      object-fit: cover;
    }
    &-title {
      text-align: center;
      font-family: 'Roboto-Regular';
      font-size: rem(26px);
      color: white;
      padding: 0 rem(10px);
      line-height: 1;
      z-index: 2;
    }
    &-author {
      display: block;
      margin: 0 auto;
      margin-top: rem(20px);
      text-align: center;
      background: transparent;
      border: none;
      &-name {
        margin-top: rem(10px);
        font-family: 'Oswald';
        color: white;
        text-transform: uppercase;
        font-size: rem(16px);
      }
    }
    &-avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-wrapper {
        margin: 0 auto;
        width: rem(75px);
        height: rem(75px);
        border-radius: 50%;
        overflow: hidden;
        background: white center no-repeat;
        border: rem(3px) solid white;
        &:before {
          border-radius: 50%;
        }
      }
    }
  }
  &__date {
    color: white;
    font-family: 'Oswald';
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    background-color: $topcolor-grey;
    padding-bottom: rem(50px);
    max-width: rem(1366px);
    &-author-name,
    &-date {
      text-transform: uppercase;
      font-size: rem(18px);
      margin-bottom: 0;
      text-align: center;
    }
    &-author {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: rem(15px) 0;
      // &-name {

      // }
    }
    &-avatar {
      width: rem(75px);
      height: rem(75px);
      border-radius: 50%;
      background: white center no-repeat;
      background-size: cover;
      margin-bottom: rem(10px);
    }
  }
  &__rrss {
    padding: rem(25px) 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: center;
    border-bottom: rem(1px) dotted $topcolor-dark-grey;
    margin-bottom: rem(25px);
    p {
      margin-bottom: 10px;
      font-family: 'Oswald';
      text-align: center;
      font-size: 14px;
    }
    &-item {
      display: inline-block;
      text-align: center;
      & + .tour-header__rrss-item {
        margin-left: rem(10px);
      }
    }
    &-link {
      display: inline-block;
      cursor: pointer;
      &:hover {
        circle {
          fill: $topcolor-blue;
        }
      }
    }
  }
  &__title {
    text-align: center;
  }
  &__keywords {
    font-size: rem(16px);
    padding: 0;
    list-style: none;
    text-align: center;
    &-item {
      display: inline-block;
      color: $topcolor-black;
      font-family: 'Roboto-bold';
      font-weight: 500;
      margin: 0 rem(5px);
    }
  }
  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(10px);
    &-text {
      font-family:'Oswald';
      color: $topcolor-black;
      font-size: rem(16px);
      letter-spacing: rem(-0.5px);
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
  &__actions {
    padding-left: rem(10px);
    border-left: rem(1px) solid black;
  }
  &__action-btn {
    margin: auto;
    max-width: rem(280px);
    & + .tour-header__action-btn {
      margin-top: rem(5px);
    }
  }
  figure.tour-header__cover-author {
    &-toptraveler {
      background-color: white;
      border-radius: rem(3px);
      padding: rem(10px 5px 5px 5px);
      width: rem(100px);
      svg {
        position: relative;
        width: 100%;
        height: auto;
      }
    }
  }
}
.ModalComments {
  padding: 30px;
  z-index: 2000;
  /* padding-top: 10px; */
    position: relative;
    border-bottom: none;
    overflow: auto;
    background-color: white;
    outline: none;
    width: 100%;
    height: 100%;
    &::-webkit-scrollbar {
      background-color: #2c2c2c;
    width: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #00affe;
  }
  .buttonClose{
    position: absolute;
    right: 5px;
    top: 5px; 
    .closeIcon {
      background-color: transparent;
      display: inline-block;
      border: none;
      vertical-align: middle;
      line-height: 1;
  
      svg {
  
        height: rem(24px);
        width: rem(24px);
        fill: black;
      }
    }
  }
  .title{
    color: #2c2c2c;
    font-size: 1.75rem;
    font-family: 'Roboto-bold';
    font-weight: 500;
  }
}

@media screen and (min-width: 992px) {
  .ModalComments {
    width: 45%;
  }
  .tour-header {
    background-color: white;
    
    &--staff-pick {
      &:before {
        display: none;
      }
      .tour-header__cover-data {
        &:before {
          content: '';
          background: url('/assets/images/icono_staff_pick.svg') no-repeat;
          background-size: rem(70px) rem(80px);
          position: absolute;
          top: rem(2px);
          left: rem(10px);
          height: 100%;
          width: 100%;
        }
      }
      
    }
    &__rrss {
      justify-content: flex-start;
    }
    &__cover {
      position: relative;
      overflow: hidden;
      &:after {
        display: none;
      }
      &-image {
        height: calc(100vh - 39px);
        height: calc(var(--vh, 1vh) * 100 - 39px);
        min-height: rem(600px);
        object-fit: cover;
      }
      &-data {
        padding: rem(45px) rem(15px) rem(30px) rem(15px);
        background-color: $topcolor-black;
        top: unset;
        bottom: rem(100px);
        left: rem(200px);
        width: rem(280px);
        clip-path: polygon(0 0, 100% 7%, 100% 100%, 0% 100%);
      }
      &-title {
        padding: rem(45px) rem(15px) rem(20px) rem(15px);
        margin-bottom: rem(20px);
        border-bottom: rem(1px) dashed white;
        font-size: rem(28px);
        text-align: left;
      }
      &-avatar {
        &-wrapper {
          width: rem(100px);
          height: rem(100px);
          margin-bottom: rem(15px);
        }
      }
      svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
    &__actions {
      border-left: none;
    }
    &__body {
      background-color: white;
      padding: rem(40px) 4vw;
      margin: 0 auto;

      &-author-name,
      &-date {
        text-align: left;
      }
      &-author {
        flex-direction: row;
        margin: 0;
      }
      &-avatar {
        margin: 0 rem(10px) 0 0;
      }
    }
    &__title {
      text-align: left;
      color: $topcolor-black;
      font-size: rem(28px);
      font-family: 'Roboto-bold';
      font-weight: 500;
      margin-top: rem(25px);
    }
    &__rrss {
      text-align: left;
      border-bottom: none;
      padding: 0;
      margin-bottom: 0;
    }
    &__keywords {
      font-size: rem(18px);
      text-align: left;
      font-weight: bold;
    }
    &__data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-text {
        color: black;
        font-size: rem(19px);
        letter-spacing: 0;
      }
    }
    &__select-wrapper {
      position: absolute;
      bottom: rem(-65px);
      left: 0;
      right: 0;
      margin: auto;
      width: rem(260px);
    }
    &__select-image {
      display: inline-block;
      vertical-align: bottom;
      height: rem(65px);
      width: auto;
      margin-left: rem(10px);
    }
    &__date {
      color: $topcolor-dark-grey;
      margin-bottom: 0;
      text-align: right;
    }
    figure.tour-header__cover-author {
      &-toptraveler {
        width: rem(120px);
      }
    }
  }
  
  div.tour-header__select {
    display: inline-block;
    vertical-align: bottom;
    width: rem(200px);
    .react-select {
      &__control {
        border: rem(2px) solid $topcolor-black;
        border-radius: rem(15px);
        &--menu-is-open {
          .react-select__dropdown-indicator {
            transform: rotate(0deg);
          }
        }
      }
      &__value-container {
        line-height: rem(35px);
        font-size: rem(24px);
        font-family: 'Oswald';
        text-transform: uppercase;
      }
      &__single-value {
        color: $topcolor-black;
        width: calc(100% - 8px);
        text-align: center;
      }
      &__dropdown-indicator {
        transform: rotate(-90deg);
        svg {
          fill: $topcolor-grey;
          width: rem(30px);
          height: rem(30px);
        }
      }
      &__menu {
        left: 0;
        right: 0;
        width: calc(100% - 15px);
        margin: 0 auto;
      }
      &__option {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
      }
    }

    .react-select__indicator-separator {
      display: none;
    }
  }
}
