.tour-content-service-box {
  background-color: white;
  padding: rem(15px) rem(15px) rem(15px) rem(15px);
  position: relative;
  margin-top: rem(45px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);

  &__category {
    font-family: 'Oswald';
    text-transform: uppercase;  
  }

  &--simple {
    .tour-content-service-box {
      &__affiliate {
        padding-top: rem(25px);
        border-top: rem(1px) dashed $topcolor-black;
      }
    }
  }
  &--premium {
    & .tour-content-service-box {
      &__title {
        font-size: rem(24px);
        color: $topcolor-black;
        margin-bottom: rem(5px);
      }
      &__info-btn {
        display: none;
      }
      &__recommendation {
        margin-top: rem(15px);
        &:before {
          content: '';
          display: block;
          height: rem(1px);
          background-color: $topcolor-grey;
          margin: 0 auto rem(15px) auto;
        }
      }
    }
  }
  &--timeline {
    .tour-content-service-box {
      &__recommendation {
        &:before {
          display: none;
        }
      }
      &__title {
        margin-bottom: rem(15px);
      }
      &__recommendation {
        &-text {
          margin-bottom: rem(15px);
        }
      }
    }
  }
  &__header {
    align-items: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(5px) 0 0 0;
      text-align: center;
      svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__owner {
    font-size: rem(11px);
    margin: 0 0 0 0;
    padding-left: 0;
    color: black;
    &-name {
      display: inline-block;
      vertical-align: bottom;
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      text-transform: capitalize;
      font-size: rem(14px);
      margin-left: rem(5px);
      max-width: calc(100vw - 190px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      svg {
        display: inline-block;
        vertical-align: baseline;
        margin-left: rem(3px);
        height: rem(14px);
        width: auto;
        fill: $topcolor-dark-grey;
      }
    }
  }
  &__title {
    display: inline-block;
    font-size: rem(18px);
    color: $topcolor-dark-grey;
    position: relative;
    padding-right: rem(35px);
    font-family: 'Roboto-bold';
    font-weight: 500;
    margin-bottom: 0;
  }
  &__info-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: rem(-5px);
    padding: 0;
    svg {
      height: rem(25px);
      width: rem(25px);
      fill: $topcolor-blue;
    }
  }
  &__recommendation {
    &-text {
      font-family:'Roboto', sans-serif !important ;
      font-weight: 100;
      white-space: pre-line;
      line-height: rem(29px);
      font-size: rem(19px);
      color: $topcolor-black;
      overflow: visible;
      strong {
        color: $topcolor-blue;
        font-weight: 100;
      }
      a {
        text-decoration: underline;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  &__tours-counter {
    color: $topcolor-dark-grey;
    margin-bottom: 0;
    svg {
      display: inline-block;
      vertical-align: text-top;
      width: auto;
      height: 20px;
      margin-left: 5px;
      fill: $topcolor-dark-grey;
    }
  }
  &__author {
    &-avatar {
      width: rem(50px);
      height: rem(50px);
      border-radius: 50%;
      margin: 0 0 rem(10px) rem(15px);
      background-color: white;
      object-fit: cover;
      &--toptraveler {
        display: block;
        margin: 0 auto;
      }
    }
    &-toptraveler {
      display: block;
      width: rem(85px);
      margin-top: rem(5px);
    }
  }
  &__video-wrapper {
    padding: 0;
    height: 66vw;
  }
  &__affiliate {
    margin-top: rem(25px);
    overflow: hidden;
    &-title,
    &-text {
      font-family: 'Roboto-bold';
      font-size: rem(16px);
      line-height: 1.2;
      overflow-wrap: break-word;
    }
    &-title {
      color: $topcolor-black;
      font-weight: bold;
    }
    &-text {
      color: $topcolor-dark-grey;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  &__address {
    text-transform: uppercase;
    font-family: 'Oswald';
    margin-bottom: 0;
  }
  &__phone {
    text-align: right;
    margin-bottom: 0;
    font-family: 'Roboto-bold';
    font-size: rem(19px);
  }
  &__website {
    text-decoration: underline;
    margin-bottom: 0;
    color: $topcolor-black;
  }
  &__slide-image {
    height: 66.3vw;
    object-fit: cover;
  }
  &__rrss {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    &-item {
      display: inline-block;
      & + .tour-content-service-box__rrss-item {
        margin-left: rem(5px);
      }
      svg {
        height: rem(40px);
        width: auto;
      }
    }
  }
  &__map {
    margin-top: rem(5px);
    padding: 0;
    .simple-map {
      width: 100vw;
      max-width: 100%;
      height: 70vw;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-service-box {
    margin-top: rem(60px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(35px);

    &--simple {
      & .tour-content-service-box {
        &__affiliate {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
          &-title {
            display: none;
          }
          &-text {
            margin-bottom: 0;
          }
        }
        &__thumb {
          width: 100%;
          height: auto;
          max-height: rem(240px);
          object-fit: cover;
          &--icon {
            object-fit: contain;
          }
        }
        &__author {
          &-avatar {
            height: rem(60px);
            width: rem(60px);
            margin-left: 0;
            &--toptraveler {
              margin: 0 auto;
            }
          }
        }
      }
    }
    &--premium {
      & .tour-content-service-box {
        &__recommendation {
          margin-top: 0;
          &:before {
            display: none;
          }
        }
        &__author {
          &-avatar {
            height: rem(85px);
            width: rem(85px);
            margin-left: 0;
            &--toptraveler {
              margin: 0 auto;
            }
          }
        }
        &__affiliate-text {
          margin-bottom: 0;
          padding: rem(15px) 0 rem(15px) rem(40px);
          border-left: rem(2px) solid $topcolor-grey;
          position: relative;
          margin-left: rem(-15px);
          &:before,
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
          }
          &:before {
            border-color: transparent transparent transparent $topcolor-grey;
            top: rem(15px);
            left: 0;
            border-width: rem(15px 0 0 30px);
          }
          &:after {
            border-color: transparent transparent transparent #fff;
            top: rem(17px);
            left: rem(-2px);
            border-width: rem(12px 0 0 26px);
          }
        }
      }
    }

    &--timeline {
      .tour-content-service-box__slide-image {
        border-top-left-radius: rem(10px);
        height: rem(455px);
      }
      .tour-content-service-box__title {
        font-size: rem(30px);
      }
      &.tour-content-service-box--simple {
        padding: rem(20px) rem(15px) 0 rem(15px);
        .tour-content-service-box__author {
          border-right: none;
          width: rem(150px);
          &:before,
          &:after {
            display: none;
          }
          .responsive-img {
            height: 100%;
          }
          &-avatar,
          &-toptraveler {
            position: absolute;
          }
          &-avatar {
            height: rem(70px);
            width: rem(70px);
            right: rem(-10px);
            bottom: rem(-25px);
            padding: rem(10px);
            background-color: white;
            border-radius: 50%;
            box-sizing: content-box;
          }
          &-toptraveler {
            bottom: rem(65px);
            right: rem(-15px);
          }
        }
        .tour-content-service-box__recommendation {
          &-text {
            min-height: rem(70px);
            padding-left: 0;
          }
        }
      }
      &.tour-content-service-box--premium {
        padding: 0 rem(15px);
        .tour-content-service-box__title {
          padding-right: 0;
          margin-top: rem(15px);
        }
        .control-arrow {
          display: none;
        }
        .carousel-slider {
          border-top-left-radius: rem(10px);
        }
        .tour-content-service-box__recommendation {
          margin-top: rem(15px);
          &-text {
            padding: 0.9375rem 0 0 6.875rem;
            margin-bottom: rem(50px);
            max-height: rem(195px);
            overflow: hidden;
          }
        }
        .tour-content-service-box {
          &__recommendation {
            &-text {
              min-height: rem(176px);
            }
          }
          &__author {
            position: absolute;
            left: rem(-90px);
            top: 0;
            &-avatar {
              height: rem(120px);
              width: rem(120px);
              padding: rem(12px);
              background-color: white;
              border-radius: 50%;
              box-sizing: content-box;
              margin-bottom: 0;
            }
            &-toptraveler {
              width: rem(120px);
              margin: 0 auto;
            }
          }
        }
      }
    }

    &__decorator {
      position: absolute;
      top: 0;
      left: rem(-46px);
      bottom: 0;
      height: 100%;
      width: auto;
    }

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;
      &-icon {
        display: inline-block;
        width: rem(75px);
        height: rem(45px);
        background-color: white;
        border-top-left-radius: rem(5px);
        border-top-right-radius: rem(5px);
        margin-right: rem(5px);
        padding: rem(5px) 0 rem(3px) 0;
        text-align: center;
        svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline;
        }
      }
    }
    &__owner {
      font-size: rem(14px);
      // margin-left: rem(5px);
      &-name {
        svg {
          height: rem(20px);
        }
      }
    }
    &__category {
      font-size: rem(14px);
      font-family: 'Oswald';
      color: $topcolor-dark-grey;
      text-transform: uppercase;
    }
    &__title {
      font-size: rem(28px);
      svg {
        top: rem(5px);
      }
    }
    &__recommendation {
      &-text {
        padding-left: rem(30px);
        margin-bottom: 0;
      }
    }
    &__phone,
    &__address,
    &__website {
      line-height: 1.2;
    }
    &__phone {
      text-align: left;
      margin-top: rem(10px);
    }
    &__rrss {
      border-top: rem(1px) dashed $topcolor-dark-grey;
      padding-top: rem(15px);
    }
    &__tours-counter {
      color: $topcolor-dark-grey;
      margin-top: rem(15px);
      font-size: rem(18px);
      .btn-link {
        background-color: transparent;
        text-decoration: underline;
        border: none;
        color: $topcolor-dark-grey;
      }
      &-icon {
        display: inline-block;
        vertical-align: middle;
        margin: 0 rem(8px) 0 rem(-15px);
        svg {
          height: rem(20px);
          width: auto;
        }
      }
    }
    &__author {
      border-right: rem(2px) solid $topcolor-grey;
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
      }
      &:before {
        border-color: transparent transparent transparent $topcolor-grey;
        top: rem(15px);
        right: rem(-30px);
        border-width: rem(15px 0 0 30px);
      }
      &:after {
        border-color: transparent transparent transparent #fff;
        top: rem(17px);
        right: rem(-26px);
        border-width: rem(12px 0 0 26px);
      }
      &-toptraveler {
        width: rem(100px);
      }
    }
    &__slide-image {
      height: rem(604px);
    }
    &__video-wrapper,
    &__map .simple-map {
      height: rem(350px);
    }
    &__map {
      margin-top: 0;
      padding-left: rem(1px);
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-service-box {
    &__slide-image {
      height: rem(661px);
    }
  }
}
