.author-box {

  .designprofile {
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;
    align-content  : center;
    position       : relative;
  }

  .profileItems {
    width          : 25%;
    // margin-left : rem(10px);
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    align-content  : center;

    svg {
      height      : rem(15px);
      width       : rem(15px);
      margin-right: rem(5px);
      fill        : $topcolor-black;
    }
  }

  .centerNickName{
    text-align: center;
    padding-top: 15px;
  }

  background-color: white;
  padding         : rem(15px) rem(15px) rem(15px) rem(15px);
  position        : relative;
  margin-top      : rem(60px);
  box-shadow      : rem(1px) rem(1px) rem(2px) rgba(black, 0.3);

  &__header {
    align-items: flex-end;
    position   : absolute;
    top        : rem(-25px);
    left       : 0;
    right      : 0;
    margin     : 0;

    &-icon {
      width                  : rem(55px);
      height                 : rem(25px);
      background-color       : white;
      border-top-left-radius : rem(5px);
      border-top-right-radius: rem(5px);
      margin                 : 0 rem(-10px) 0 0;
      padding                : rem(5px) 0 0 0;
      text-align             : center;

      svg {
        height        : 100%;
        width         : auto;
        display       : inline-block;
        vertical-align: baseline;
      }
    }
  }

  &__category {
    font-size     : rem(14px);
    font-family   : 'Oswald';
    text-transform: uppercase;
    color         : $topcolor-dark-grey;
  }

  &__avatar {
    width     : 100%;
    height    : 100%;
    object-fit: cover;

    &-wrapper {
      position        : relative;
      width           : rem(75px);
      height          : rem(75px);
      border-radius   : 50%;
      box-shadow      : 0 0 rem(3px) rgba($topcolor-black, 0.3);
      border          : rem(5px) solid white;
      background-color: white;
      overflow        : hidden;
      margin          : 0 auto;
    }
  }

  &__top-traveler-wrapper {
    text-align: center;
    margin-top: rem(5px);

    svg {
      width    : rem(120px);
      max-width: 100%;
    }
  }

  &__nickname {
    color        : black;
    margin-bottom: rem(7px);
    font-size    : 22px;
    max-width    : 100%;
    text-overflow: ellipsis;
    overflow     : hidden;
    position     : relative;
    text-align   : center;
  }

  &__data {
    list-style  : none;
    padding-left: 0;
    margin-top  : rem(15px);
    position    : relative;
    max-width   : 100%;

    &-item {
      font-weight  : 500;
      font-size    : rem(12px);
      line-height  : rem(20px);
      color        : $topcolor-black;
      width        : 100%;
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : nowrap;

      svg {
        height      : rem(15px);
        width       : rem(15px);
        margin-right: rem(5px);
        fill        : $topcolor-black;
      }
    }
  }

  &__name {
    font-family   : 'Oswald';
    text-transform: uppercase;
    font-size     : rem(18px);
    margin        : rem(10px) 0 0 0;
    line-height   : 1;
  }

  &__website {
    display        : inline-block;
    max-width      : 100%;
    color          : $topcolor-black;
    font-weight    : bold;
    font-size      : rem(14px);
    line-height    : 1;
    text-decoration: underline;
    white-space    : nowrap;
    text-overflow  : ellipsis;
    overflow       : hidden;
  }

  &__rrss {
    padding   : 0;
    list-style: none;
    text-align: center;
    margin-top: rem(15px);

    &-item {
      display    : inline-block;
      margin     : 0 rem(5px);
      line-height: rem(40px);
    }

    &-link {
      display      : inline-block;
      width        : rem(35px);
      height       : rem(35px);
      border-radius: 50%;
      text-align   : center;
      line-height  : rem(35px);

      svg {
        fill  : #333033;
        height: 100%;
        width : auto;
      }

      &:hover {
        svg {
          fill: $topcolor-blue;
        }
      }
    }
  }

  &__description {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size  : rem(19px);
    color      : $topcolor-black;
    overflow   : visible;
  }
}

@media screen and (min-width: 992px) {
  .author-box {
    margin-top                : rem(60px);
    border-top-left-radius    : rem(10px);
    border-bottom-right-radius: rem(10px);
    padding                   : rem(20px) rem(35px);

    &__header {
      align-items: center;
      position   : absolute;
      top        : rem(-40px);
      left       : 0;
      right      : 0;
      margin     : 0;

      &-icon {
        display                : inline-block;
        width                  : rem(75px);
        height                 : rem(45px);
        background-color       : white;
        border-top-left-radius : rem(5px);
        border-top-right-radius: rem(5px);
        margin-right           : rem(5px);
        padding                : rem(8px) 0 rem(3px) 0;
        text-align             : center;

        svg {
          height        : 100%;
          width         : auto;
          display       : inline-block;
          vertical-align: baseline;
        }
      }
    }

    &__info {
      padding-bottom: rem(15px);
      border-bottom : rem(1px) dotted $topcolor-black;
    }

    &__avatar-wrapper {
      width : rem(100px);
      height: rem(100px);
    }

    &__avatar-flag {
      position: absolute;
      top     : rem(-10px);
      right   : rem(5px);

      svg {
        fill  : $topcolor-dark-grey;
        height: rem(30px);
        width : auto;
      }
    }

    &__rrss {
      text-align: right;
      margin    : 0;

      &-item {
        margin: 0 0 0 rem(5px);
      }

      &-link {
        width      : rem(50px);
        height     : rem(50px);
        line-height: rem(50px);
      }
    }

    &__top-traveler-wrapper {
      text-align: left;
      margin-top: rem(10px);
    }

    &__data {
      left      : unset;
      margin-top: rem(5px);

      &-item {
        display  : inline-block;
        width    : auto;
        font-size: rem(15px);

        &+.author-box__data-item {
          margin-left: rem(10px);
        }
      }
    }
  }
}