.poi-image {
  padding: 0 rem(5px);
  margin-top: rem(15px);
  &__header {
    padding: rem(5px) rem(20px);
    border: rem(1px) dashed $topcolor-grey;
    background-color: white;
  }
  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 70vw;
    height: 45vw;
    margin-bottom: 0;
  }
  &__button {
    background-color: white;
    border: none;
    width: rem(40px);
    height: rem(40px);
    border-radius: 50%;
    padding: 0;
    line-height: 1;
    box-shadow: 0 0 rem(2px) rgba(black, 0.3);
    svg {
      width: rem(25px);
      height: auto;
      fill: $topcolor-dark-grey;
    }
    &--delete {
      svg {
        fill: $topcolor-red;
      }
    }
    &--hightlight {
      svg {
        fill: $yellow;
      }
    }
  }
  &__body {
    padding: 0;
    
  }
  &__input {
    border: rem(1px) dashed $topcolor-light-grey;
    margin-top: rem(5px);
    background-color: white;
    &:focus,
    &:active {
      border: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .poi-image {
    &__header {
      border: none;
      padding: 0 rem(15px);
    }
    &__image {
      width: rem(300px);
      height: rem(177px);  
    }
    &__body {
      margin-left: rem(15px);
    }
    &__input {
      margin-top: 0;
      resize: none;
      &:nth-child(2n) {
        background-color: $topcolor-light-grey;
      }
      & + .poi-image__input {
        margin-top: rem(5px);
      }
    }
  }
}
