.intro-box {
  background-color: white;
  border-radius: rem(15px);
  font-family: 'Roboto-bold';
  padding-top: 2rem;

  h3 {
    color: $topcolor-light-black;
  }

  .svg-login svg {
    fill: $topcolor-blue;
    max-height: 6rem;
    width: auto;
  }

  .form-group {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;

    label {
      position: relative;
    }

    svg {
      fill: $topcolor-blue;
      height: 1.3rem;
      bottom: 8px;
      left: 2px;
      position: absolute;
      width: 40px;
    }
  }

  hr {
    border-color: $topcolor-black;
    border-style: dashed;
  }

  .d-inline-block {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .grey-bg:before {
    background-color: $topcolor-grey;
    content: "";
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .intro-box {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin: 3rem 0;

    .w75 {
      max-width: 75%;
    }
  }
}