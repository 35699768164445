.tour-sponsor-box {
  background-color: white;
  padding: rem(5px) rem(15px) rem(15px) rem(15px);
  position: relative;
  margin-top: rem(60px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  .campaing_image {
    height: 100% !important;
  }
  &__header {
    align-items: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(3px) 0 0 0;
      text-align: center;
      svg {
        height: 75%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__slide {
    height: 100vw;
    position: relative;
    &-image {
      display: block;
      height: auto;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &__p-container {
    p {
      word-wrap: break-word;
    }
  }
  &__category {
    font-size: rem(14px);
    font-family: 'Oswald';
    text-transform: uppercase;
    color: $topcolor-dark-grey;
  }
  &__title {
    font-family: 'Roboto',sans-serif !important;
    font-weight: 700;
    font-size: rem(19px);
  }
  &__text {
    word-break: break-word;
    white-space: pre-line;
    font-family: 'Roboto',sans-serif !important;
    font-size: rem(19px);
  }
  &__link {
    display: block;
    // margin: 0 auto;
    width: rem(150px);
    cursor: pointer;
    @media screen and (min-width: 992px) {
    min-width: 0 }
  }
  .carousel.carousel-slider {
    .slide {
      background-color: transparent;
    }
    .control-dots .dot {
      background-color: $topcolor-black;
    }
    .control-arrow {
      padding: 0;
      width: rem(40px);
      height: rem(40px);
      margin: auto rem(5px);
      border-radius: 50%;
      opacity: 0.3;
      background-color: rgba(white, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.control-prev:before,
      &.control-next:before {
        width: rem(15px);
        height: rem(15px);

        border-top: none;
        border-right: none;
        border-bottom: rem(3px) solid $topcolor-black;
        border-left: rem(3px) solid $topcolor-black;
      }
      &.control-prev:before {
        margin-left: rem(10px);
        transform: rotate(50deg) skewY(-15deg);
      }
      &.control-next:before {
        margin-right: rem(10px);
        transform: rotate(-135deg);
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-sponsor-box {
    margin-top: rem(75px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(35px);

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;
      &-icon {
        display: inline-block;
        width: rem(75px);
        height: rem(45px);
        margin-right: rem(5px);
        padding-top: rem(10px);
      }
    }
    &__slide {
      height: rem(650px);
    }
  }
}
