.tour-content-map-box {
  background-color: white;
  padding: rem(10px) rem(20px);
  position: relative;
  margin-top: rem(10px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500;

  // border-radius: rem(10px);
  &__title {
    color: $topcolor-blue;
    font-size: rem(24px);
    font-family: 'Roboto-bold';
    font-weight: 500;
    padding: 0 rem(10px) rem(10px) rem(10px);
    margin: rem(15px 0 10px 0);
    border-bottom: rem(1px) dashed $topcolor-black;
  }

  &__text {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;

    strong {
      color: #2c2c2c;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }

    p {
      margin-bottom: 0;
    }
  }

  .simple-map {
    min-height: rem(300px);
    height: 100%;
  }

  &--squared {
    .simple-map {
      height: calc(60vw - 15px);
    }
  }
}

@media screen and (min-width: 576px) {
  .tour-content-map-box {
    padding: rem(15px) rem(30px);

    &__title {
      margin: rem(30px 0 10px 0);
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-map-box {
    border-radius: rem(10px);
    margin-top: rem(25px);

    &--squared {
      .simple-map {
        height: rem(500px);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-map-box {
    &--squared {
      .simple-map {
        height: rem(600px);
      }
    }
  }
}