.faqs-page {
  min-height: calc(100vh - 50px);
  min-height: calc(var('--vh', 1) * 100 - 50px);
  &__header {
    &-top {
      padding: rem(30px) rem(15px);
      background-color: $topcolor-light-blue;
      text-align: center;
    }
    &-bottom {
      padding: rem(30px 0);
    }
    &-title {
      display: inline-block;
      color: white;
      font-family: 'Roboto-bold';
      font-weight: 100;
      font-size: rem(26px);
      text-align: center;
      max-width: calc(100% - 80px);
      position: relative;
      margin-bottom: 0;
    }
    &-back-btn {
      background-color: transparent;
      border: 0;
      padding: 0;
      position: absolute;
      left: rem(-45px);
      top: rem(-10px);
      bottom: 0;
      margin: auto;
      svg {
        fill: white;
        height: rem(23px);
        width: auto;
      }
    }
  }

  &__topic {
    &-tabs {
      &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
      }
      &-item {
        flex: 0 0 33%;
        max-width: 33.33333%;
        text-align: center;
      }
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(70px);
      height: rem(70px);
      margin: 0 auto;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &-link {
      color: $topcolor-black;
      font-size: rem(14px);
      &:hover {
        text-decoration: none;
      }
      // &--active {

      // }
    }
  }

  &__questions {
    list-style: none;
    padding-left: 0;
    &-header {
      text-align: center;
      padding: rem(20px 0);
      .faqs-page__topic-icon {
        width: rem(90px);
        height: rem(90px);;
      }
    }
    &-title {
      font-family:'Roboto', sans-serif !important ;
      font-weight: 100;
      font-size: rem(14px);
      margin: rem(10px 0 0 0);
    }
    &-item {
      padding: 0 rem(15px);
      & + .faqs-page__questions-item {
        margin-top: rem(15px);
      }
    }
    &-btn {
      text-align: left;
      background-color: transparent;
      border: none;
      font-family: 'Roboto-bold';
      font-size: rem(18px);
      line-height: 1.3;
      font-weight: bold;
    }
  }
  &__question {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    &-title,
    &-subtitle,
    &-text,
    &-list-item {
      font-family: 'Roboto-bold';
      font-weight: 500;
      font-size: rem(18px);
    }
    &-title {
      font-weight: bold;
      font-size: rem(26px);
      margin-bottom: rem(20px);
    }

    &-text,
    &-list-item {
      font-size: rem(14px);
      line-height: 1.25;
    }
    &-list {
      padding-left: rem(20px);
    }
    &-lightbox-btn {
      background-color: transparent;
      padding: 0;
      border: none;
      margin: rem(20px) 0;
      text-align: left;
      font-family: 'Roboto-bold';
    }
    &-img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 992px) {
  .faqs-page {
    min-height: calc(100vh - 70px);
    min-height: calc(var('--vh', 1) * 100 - 70px);
    background-color: $topcolor-grey;
    &__header {
      padding: rem(30px 15px 40px 15px);
      background-color: $topcolor-light-blue;
      &-top {
        text-align: left;
        padding: 0;
      }
      &-bottom {
        padding-bottom: 0;
      }
      &-title {
        font-size: rem(32px);
      }
    }
    &__content {
      padding-top: rem(40px);
      margin-top: rem(-45px);
      border-top-left-radius: rem(10px);
      border-top-right-radius: rem(10px);
      background-color: white;
      box-shadow: 0 rem(2px) rem(5px) rgba($topcolor-dark-grey, 0.6);
      margin-bottom: rem(15px);
      min-height: calc(100vh - 220px);
      min-height: calc(var('--vh', 1) * 100 - 220px);
    }
    &__topic {
      &-tabs {
        &-list {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;
        }
        &-item {
          flex: 1 0 auto;
          max-width: unset;
          width: auto;
          text-align: center;
        }
      }
      &-link {
        display: block;
        font-family: 'Roboto-bold';
        font-weight: bold;
        font-size: rem(18px);
        padding: rem(5px 10px 10px 10px);
        border-top-left-radius: rem(10px);
        border-top-right-radius: rem(10px);
        &--active {
          background-color: white;
        }
      }
    }
    &__questions {
      margin-bottom: 0;
      &-item {
        max-width: rem(225px);
        padding: 0;
        & + .faqs-page__questions-item {
          margin-top: rem(15px);
        }
        &--active {
          .faqs-page__questions-btn {
            background-color: $topcolor-blue;
            color: white;
          }
        }
      }
      &-btn {
        display: block;
        width: 100%;
        font-size: rem(16px);
        text-align: left;
        line-height: 1.25;
        font-weight: 500;
        padding: rem(7px 20px);
        border-radius: rem(5px);
      }
    }
    &__question {
      padding-top: rem(0);
    }
  }
}