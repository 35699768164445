.ql-editor {
  // font-family:'Roboto', sans-serif !important ;
  font-weight: 100;
  // white-space: pre-line; This line generates a bug on firefox
  white-space: pre-wrap;
  line-height: rem(29px);
  font-size: rem(19px);
  color: $topcolor-black;
  overflow: visible;
  margin-bottom: 0;
  strong {
    color: $topcolor-blue;
    font-weight: 100;
  }
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
}