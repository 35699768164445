.search-filters {
  margin-top: rem(50px);
  padding-top: rem(5px);
  background-color: $topcolor-light-grey;
  align-items: center;

  &__day {
    .day-select {
      line-height: 1;
      border: none;

      &__indicators {
        display: none;
      }

      &__control {

        &--is-focused,
        &--menu-is-open {
          outline: none;
          border: none;
        }
      }

      &__value-container {
        background-color: $topcolor-black;
        position: relative;
        padding: rem(18px) 0 rem(1px) 0;
        width: rem(55px);
        height: rem(55px);
      }

      &__single-value {
        color: white;
        font-weight: bold;
        font-family: 'Roboto-bold';
        font-size: rem(32px);
        max-width: none;
        text-align: center;
        width: 100%;
        margin: 0;
        transform: none;
        top: unset;
        bottom: rem(5px);
      }

      &__menu {
        width: rem(120px);
      }

      &__option {
        display: block;
        font-size: rem(18px);
        padding: rem(10px) rem(10px);
        font-family:'Roboto', sans-serif !important ;
        font-weight: 100;
        text-align: center;
      }
    }

    &:before {
      content: 'dia';
      font-weight: bold;
      font-family: 'Roboto-bold';
      color: white;
      display: block;
      position: absolute;
      text-align: left;
      padding: rem(5px) rem(10px) 0 rem(5px);
      top: rem(1px);
      left: rem(5px);
      right: 0;
      font-size: rem(12px);
      line-height: 1;
      z-index: 1;
    }
  }

  &__search {
    display: block;
    padding-right: rem(35px);
    position: relative;
    margin-bottom: 0;
    // font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    z-index: 10;
    max-width: calc(100% - 20px);

    &-input {
      background-color: white;
      border-radius: rem(10px);
      height: rem(40px);
      text-transform: uppercase;
    }

    &-icon {
      right: rem(10px);
    }
  }

  &__search-icon,
  &__tip {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: rem(20px);

    svg {
      height: 100%;
      width: auto;
      vertical-align: unset;
    }
  }

  &__tip {
    right: rem(5px);

    svg {
      fill: $topcolor-blue;
    }
  }

  &__type {
    padding: rem(6.5px);
    position: relative;

    svg {
      width: rem(40px);
      height: rem(40px);
    }

    &--active {
      background-color: $topcolor-blue;

      &:after {
        content: '';
        display: block;
        width: rem(15px);
        border-top: rem(10px) solid $topcolor-blue;
        border-left: rem(10px) solid transparent;
        border-right: rem(10px) solid transparent;
        position: absolute;
        bottom: rem(-10px);
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  &__footer {
    width: 100%;
    background-color: $topcolor-grey;
    margin-top: rem(10px);
    padding: rem(10px) rem(15px);
  }

  &__view {
    display: block;
    width: 100%;
    background-color: white;
    border-radius: rem(5px);
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(16px);

    &:hover,
    &:focus,
    &--active {
      background-color: $topcolor-blue;
      color: white;
      border-color: transparent;
    }
  }

  &__toggle {
    background-color: transparent;
    border: none;
    padding: 0;

    svg {
      width: rem(20px);
      height: auto;
    }

    &+.search-filters__toggle {
      margin-left: rem(10px);
    }
  }

  & .__react_component_tooltip {
    z-index: 1010;
  }
}

@media screen and (max-width: 991px) {
  .search-filters {
    &__type {
      border-radius: 0;
    }
  }
}

@media screen and (min-width: 375px) {
  .search-filters {
    &__toggle {
      svg {
        width: rem(30px);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .search-filters {
    margin-top: rem(10px);
    margin-bottom: rem(40px);
    align-items: flex-end;
    background-color: transparent;

    &__active-list {
      list-style: none;
      display: inline-block;
      padding-left: 0;
      margin: 0 0 0 rem(25px);

      &-item {
        display: inline-block;
        text-transform: uppercase;
        background-color: white;
        padding: rem(10px) rem(30px);
        font-family: 'Oswald';

        &+.search-filters__active-list-item {
          margin-left: rem(10px);
        }
      }
    }

    &__toggle,
    &__active-list-item,
    &__origin {
      display: inline-block;
      vertical-align: middle;

    }

    &__type {
      background-color: $topcolor-blue;
      color: white;

      &--active {
        background-color: $topcolor-black;
        color: white;

        &:after {
          display: none;
        }
      }
    }

    &__toggle {
      svg {
        width: rem(40px);
      }
    }

    &__map-toggle {
      display: block;
      font-family: 'Oswald';
      text-transform: uppercase;
      background-color: #fff;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      svg {
        height: rem(16px);
        width: auto;
        margin-right: rem(5px);
      }
    }

    &__search {
      padding-right: 0;

      &-input {
        margin-top: rem(5px);
        // font-family: 'Roboto-Regular';
      }
    }

    &__origin {
      .custom-checkbox {
        margin-bottom: 0;
        margin-left: 0.625rem;
        background-color: white;
        padding: 0 rem(20px) 0 rem(50px);
        font-size: rem(18px);
        line-height: rem(44px);

        i {
          margin-left: rem(10px);
        }
      }

      &-value {
        display: inline-block;
        text-transform: uppercase;
        font-family: 'Oswald';
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .search-filters {
    margin-left: rem(30px);
  }
}