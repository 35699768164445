.tour-content-text-form {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
  }
  &::-webkit-scrollbar-thumb {
    background: $topcolor-blue;
  }
  &__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: rem(18px);
    margin-bottom: rem(10px);
  }
  &__figure {
    margin: 0;
    padding-right: 0;
    & > svg {
      height: auto;
      width: rem(64px);
    }
  }
  &__text-editor {
    min-height: rem(150px);
    min-width: 100%;
    // modifies the quill library rich text editor library styles...
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-container {
      min-height: rem(100px);
    }
  }
}

@media screen and (min-width: 992px) {
  .text-tour-content-modal {
    &__figure {
      padding-right: rem(15px);
    }
  }
}
