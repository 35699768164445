.tour-content-recommendation-form {
  font-family: 'Roboto-bold';
  padding-bottom: rem(20px);
  &__title {
    font-weight: bold;
    font-size: rem(22px);
    margin-bottom: rem(15px);
  }
  &__service-name {
    font-size: rem(20px);
  }
  &__cover {
    width: calc(50vw - 25px);
    height: 30vw;
    min-height: rem(175px);
    object-fit: cover;
    margin-bottom: rem(20px);
    &--no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: rem(1px) dotted $topcolor-dark-grey;
      padding: rem(15px);
      .tour-content-recommendation-form__cover-img {
        width: rem(60px);
        height: auto;
      }
    }
  }
  &__tag {
    background-color: $topcolor-light-blue;
    padding: rem(5px);
    line-height: 1;
    position: absolute;
    bottom: rem(-25px);
    right: 0;
  }

  &__label {
    font-weight: bold;
    line-height: 1.2;
    font-size: rem(18px);
    svg {
      margin-right: rem(10px);
      fill: $topcolor-blue;
      width: rem(30px);
    }
  }

  &__photos {
    &-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    &-item {
      height: rem(125px);
      width: rem(100px);
      display: inline-block;
      margin: rem(0 5px 5px 0);
      padding-top: rem(25px);
      z-index: 1010;
      cursor: grab;
      .fileInput {
        padding: 0;
      }
      .fine-uploader__files-wrapper {
        padding: 0;
      }
      .fileInput,
      .fine-uploader__file-input,
      .fine-uploader__files-wrapper,
      .responsive-img {
        height: 100%;
      }
      .thumbnails-wrapper__box {
        display: none;
      }
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
    &-add {
      border: none;
      background-color: $topcolor-blue;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      svg {
        width: 40%;
        height: 40%;
        margin: 0 auto;
        fill: white;
      }
    }
    &-remove {
      position: absolute;
      top: rem(-25px);
      right: rem(3px);
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        fill: $topcolor-red;
        width: rem(25px);
        height: rem(25px);
      }
    }
  }
  
  .custom-checkbox {
    padding-left: rem(15px);
    font-size: rem(16px);
    max-width: rem(250px);
    margin-bottom: rem(20px);
    span {
      position: relative;
    }
  }
  &__rich-text-editor {
    min-height: rem(150px);
    width: 100%;
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-container {
      min-height: rem(100px);
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-recommendation-form {
    margin-top: 0;
    &__cover {
      width: 100%;
      height: auto;
      min-height: rem(175px);
      object-fit: cover;
      margin-bottom: rem(20px);
    }
  }
}
