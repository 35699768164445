.tour-content-video-box {
  background-color: white;
  padding: rem(5px) rem(15px);
  position: relative;
  margin-top: rem(10px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  font-family: 'Roboto-bold';
  font-weight: 500;
  &__video-wrapper {
    padding: 0;
    height: 66vw;
  }
  &__text {
    padding: rem(20px) rem(15px);
    font-family:'Roboto', sans-serif !important ;
    white-space: pre-line;
    font-weight: 100 !important;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;
    margin: rem(15px) 0;
    strong {
      color: $topcolor-black;
      font-weight: 700 !important;
    }
    a {
      text-decoration: underline;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-video-box {
    padding: rem(15px 30px);
    border-radius: rem(10px);
    margin-top: rem(25px);
    &__video-wrapper {
      height: rem(540px);
    }
    &__text {
      padding: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-video-box {
    &__video-wrapper {
      height: rem(587px);
      
    }
  }
}