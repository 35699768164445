.home-page {
  &__header {
    display : block;
    position: relative;

    svg {
      display   : none;
      min-height: rem(200px);
      position  : absolute;
      bottom    : 0;
      left      : 0;
      width     : 100%;
      z-index   : 1;
    }
  }

  &__cover {
    margin-bottom: 0;

    &--loading {
      &:before {
        content            : '';
        display            : block;
        position           : absolute;
        background-repeat  : no-repeat;
        background-position: center;
        background-color   : rgba($topcolor-grey, 1);
        background-image   : url('/assets/images/spinner.svg');
        top                : 0;
        bottom             : 0;
        left               : 0;
        right              : 0;
        background-size    : rem(30px);
        z-index            : 1;
      }

      .home-page__cover-image {
        visibility: hidden;
      }
    }

    &-image {
      width : 100%;
      height: auto;
    }

    &-user {
      display        : flex;
      align-items    : center;
      justify-content: center;
      margin-top     : rem(-45px);
      text-align     : center;
      z-index        : 1;

      &-name,
      &-avatar-wrapper {
        display: inline-block;
      }

      &-name {
        border-radius: 0 rem(20px) rem(20px) 0;
        left         : 0;
      }

      &-avatar {
        object-fit   : cover;
        width        : rem(90px);
        height       : rem(90px);
        border       : rem(5px) solid white;
        border-radius: 50%;
        box-shadow   : 0 rem(2px) rem(2px) rgba(0, 0, 0, 0.15);

        &-wrapper {
          position: relative;
        }
      }
    }

    &-user-name,
    &-staff-pick {
      font-family   : 'Oswald';
      font-size     : 0.9rem;
      padding       : rem(6px) rem(5px);
      position      : relative;
      top           : rem(15px);
      text-transform: uppercase;
      white-space   : nowrap;
      text-overflow : ellipsis;
      overflow      : hidden;
      max-width     : rem(100px);
    }

    &-staff-pick {
      border-radius: rem(20px) 0 0 rem(20px);
      right        : 0;

      svg {
        fill  : $topcolor-yellow;
        height: rem(20px);
        margin: rem(-5px) rem(5px) 0 0;
        width : auto;
      }
    }

    &-toptraveler {
      display: block;
      width  : rem(100px);
    }
  }

  &__diagonal-top {
    clip-path : polygon(0 0, 100% 10%, 100% 100%, -2% 102%);
    margin-top: rem(-50px);

    .laptop-container {
      box-sizing: border-box;

      .laptop-img {
        margin-left: 200px;
      }
    }
  }

  .grey-video {
    h3 {
      color    : white;
      font-size: 1.2rem;

      @media screen and (min-width: 992px) {
        font-size: 1.75rem;
      }
    }

    background-color:#2c2c2c;
  }

  &__grey-bg--desktop {
    background-color: white;
  }

  &__grey-bg--mobile {
    background-color: $topcolor-grey;
    position        : relative;

    &:after {
      content         : '';
      background-color: inherit;
      position        : fixed;
      width           : 100%;
      height          : 100%;
      top             : 0;
      left            : 0;
      z-index         : -1;
    }

    .f-quicksand {
      font-size: 1.2rem;
    }

  }

  .alert-primary {
    margin-bottom: 0;

    svg {
      fill        : white;
      margin-right: rem(10px);
      width       : rem(20px);

      path+path {
        fill: $topcolor-light-blue;
      }
    }
  }

  &__premium {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    font-size  : 0.9rem;
    margin-left: rem(15px);

    svg {
      fill  : $topcolor-yellow;
      height: rem(20px);
      margin: rem(-5px) rem(5px) 0 0;
      width : auto;
    }
  }

  &__sponsor {
    text-align: center;

    img {
      border-radius: rem(10px) 0;
      width        : 70%;
    }
  }

  &__main-title {
    font-family: 'Roboto-bold';
    font-size  : 1.5rem;
  }

  &__main-description {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    padding    : rem(25px);
  }

  &__comment {
    border       : rem(7px) solid;
    border-radius: rem(50px);
    font-family  : 'Roboto-bold';
    margin-bottom: 2rem;
    padding      : 2rem 2rem 5rem 2rem;
    position     : relative;

    .btn {
      box-shadow: 0 0 0 rem(8px) $topcolor-light-blue;
      min-width : rem(180px);
      position  : absolute;
      bottom    : rem(-20px);
      right     : 4.5rem;
    }

    .form-group {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;

      label {
        position: relative;
      }

      input {
        background-color: rgba(255, 255, 255, 0.5);
        min-width       : rem(170px);

        &:focus {
          background-color: rgba(255, 255, 255, 0.8);
        }
      }

      svg {
        fill    : $topcolor-blue;
        height  : 1.3rem;
        bottom  : rem(8px);
        left    : rem(2px);
        position: absolute;
        width   : rem(40px);
      }
    }
  }

  &__comment-quote {
    background: $topcolor-light-blue;
    padding   : rem(15px);
    position  : absolute;
    top       : rem(-40px);
    left      : rem(-30px);
    width     : rem(90px);
  }

  &__comment-title {
    font-size : 2.25rem;
    text-align: center;
  }

  &__comment-text {
    font-size  : 1rem;
    line-height: 1;
  }

  &__comment-text-white {
    color     : white;
    display   : block;
    font-size : 1.2rem;
    text-align: right;
  }

  &__img-w-text--wrapper {
    position: relative;
    bottom  : rem(100px);
    left    : 5%;
    width   : 90%;
  }

  &__img-w-text--title {
    color      : white;
    font-family: 'Roboto-bold';
    font-size  : 1.5rem;
    text-align : center;

    &-white {
      color      : $topcolor-light-black;
      font-family: 'Roboto-bold';
      font-size  : 1.5rem;
      text-align : left;
    }
  }

  &__img-w-text--bg-text-white {
    background-color: white;
    padding         : rem(10px) rem(20px);
  }

  &__img-w-text--desc {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
  }

  &__highlighted-list {
    display                   : flex;
    align-items               : flex-start;
    flex-grow                 : 1;
    flex-wrap                 : nowrap;
    overflow-y                : hidden;
    padding                   : rem(25px) rem(15px) 10px 0;
    margin-bottom             : rem(10px);
    max-width                 : none;
    max-height                : none;
    background-color          : white;
    scrollbar-width           : thin;
    scrollbar-color           : $topcolor-black;
    scrollbar-track-color     : $topcolor-blue;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      height          : rem(5px);
    }

    &::-webkit-scrollbar-thumb {
      background: $topcolor-blue;
    }

    .content-card {
      box-shadow: none;
      margin    : 0;
      min-width : rem(250px);
    }
  }

  .subsidized {
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: #FEFEFE;
    flex-direction  : column;
    padding-top     : 15px;

    img {
      width : 70%;
      height: auto;
    }

    p {
      width     : 100%;
      text-align: center;
      margin-top: 15px;
      font-size : 18px;
    }
  }
}

@media screen and (min-width: 768px) {
  .home-page {
    &__sponsor {
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .home-page {
    &__header {
      svg {
        display: block;
      }
    }

    &__cover {
      position: relative;

      &-image {
        height    : 100vh;
        height    : calc(var(--vh, 1vh) * 100);
        object-fit: cover;
      }

      &-user {
        justify-content: flex-start;
        margin-top     : 0;
        padding-left   : rem(50px);

        &-name {
          left: rem(-5px);
        }
      }

      &-user-name,
      &-staff-pick {
        background-color: white;
        font-size       : 1rem;
        padding         : rem(6px) rem(30px);
        top             : rem(3px);
        max-width       : rem(200px);
      }

      &-staff-pick {
        padding-left : rem(15px);
        padding-right: rem(15px);
        right        : rem(-5px);

        svg {
          margin-right: rem(20px);
        }
      }

      &-toptraveler {
        margin: 0 rem(-10px) rem(-30px);
      }
    }

    &__grey-bg--mobile .f-quicksand {
      font-size: 1.75rem;
    }

    &__grey-bg--desktop {
      background-color: $topcolor-grey;
      position        : relative;

      &:after {
        content         : '';
        background-color: inherit;
        position        : fixed;
        width           : 100%;
        height          : 100%;
        top             : 0;
        left            : 0;
        z-index         : -1;
      }
    }

    &__main-title {
      font-size : 2rem;
      text-align: right;
      margin-top: rem(35px);
    }

    &__img-w-text {
      position: relative;

      &--wrapper {
        color    : white;
        max-width: rem(460px);
        position : absolute;
        bottom   : rem(60px);
        right    : rem(60px);
        left     : auto;
      }
    }

    &__img-w-text--desc {
      padding-left: rem(150px);
    }

    &__img-w-text--title {
      font-size : 2rem;
      text-align: left;

      &-white {
        color    : $topcolor-light-blue;
        font-size: 2rem;
      }
    }

    &__img-w-text--bg-text-white {
      background-color: transparent;
      padding         : 0;
    }

    &__main-description {
      border-left   : 1px dashed $topcolor-light-black;
      border-right  : 1px dashed $topcolor-light-black;
      padding-top   : rem(35px);
      padding-bottom: rem(35px);
    }

    &__comment-text {
      font-size  : 1.3rem;
      line-height: 1.4;
    }

    &__comment-text-white {
      font-size: 1.6rem;
    }

    &__sponsor {
      text-align: left;
    }

    &__highlighted-list {
      background-color: transparent;
    }

    .content-card {
      &+.content-card {
        margin-left: rem(10px);
      }
    }
  }
}