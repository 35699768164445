.tour-config-sponsor {
/*   padding-bottom: 100px; */
  &__label {
    font-family: 'Roboto-bold';
    font-size: rem(18px);
    margin-bottom: rem(7px);
    &--min {
      min-width: rem(150px);
    }
  }
  &__creation-date {
    margin-bottom: 0;
    font-size: rem(18px);
    font-family: 'Roboto-bold';
  }

  .react-datepicker__close-icon::after {
    display: inline-block;
    background-color: $topcolor-red;
    padding: 0;
    font-size: rem(28px);
    line-height: rem(20px);
    height: rem(20px);
    width: rem(20px);
  }
  .fine-uploader__files-wrapper {
    padding: 0;
    text-align: center;
  }
  &__logo {
    margin: 0 auto rem(15px) auto;
    width: 30vw;
    max-width: rem(200px);
    &-img {
      width: 100%;
      height: auto;
    }
  }
  &__photos {
    &-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    &-item {
      height: rem(125px);
      width: rem(100px);
      display: inline-block;
      margin: rem(0 5px 5px 0);
      padding-top: rem(25px);
      cursor: grab;
      z-index: 1010;
      .fileInput {
        padding: 0;
      }
      .fine-uploader__files-wrapper {
        padding: 0;
      }
      .fileInput,
      .fine-uploader__file-input,
      .fine-uploader__files-wrapper,
      .responsive-img {
        height: 100%;
      }
      .thumbnails-wrapper__box {
        display: none;
      }
      &:last-of-type {
        cursor: pointer;
      }
    }
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
    &-add {
      border: none;
      background-color: $topcolor-blue;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      svg {
        width: 40%;
        height: 40%;
        margin: 0 auto;
        fill: white;
      }
    }
    &-remove {
      position: absolute;
      top: rem(-25px);
      right: rem(3px);
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        fill: $topcolor-red;
        width: rem(25px);
        height: rem(25px);
      }
    }
  }
  .footer{
    background-color: white;
    display: flex;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .tour-config-sponsor{
    .footer{
      margin-bottom: 50px;
      border-bottom: 1px solid lightgrey;
    }
  }
}
@media screen and (max-width: 768px) {
  .tour-config-sponsor{
    .footer{
      .contentF{
        width: 100%;
        justify-content: center;
        button{ 
          width: auto;
        }
        div{
          flex: auto;
          width: auto;
          max-width: max-content;
        }
      }
    }
  }
}
