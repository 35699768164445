.images-edition {
  &__header {
    &-icon,
    &-text {
      display: inline-block;
      vertical-align: middle;
    }
    &-icon {
      svg {
        width: rem(40px);
        height: auto;
        fill: $topcolor-blue;
      }
      margin-right: rem(10px);
    }
    &-text {
      max-width: calc(100% - 50px);
      margin-bottom: 0;
      font-size: rem(18px);
    }
    &-title {
      text-align: center;
      margin: rem(15px) 0;
      font-family:'Roboto', sans-serif !important ;
      font-weight: 100;
      font-size: rem(18px);
    }
  }
  &__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &--no-images {
      justify-content: flex-start;
    }
    &-img {
      display: none;
      width: 90%;
      max-width: rem(230px);
      max-height: rem(150px);
      margin: 0 auto;
      &--drag {
        display: none;
      }
    }
    &-text {
      margin: rem(20px) 0 0 0;
      font-family: 'Roboto-bold';
      text-align: center;
      font-size: rem(18px);
      padding: 0 rem(10px);
      line-height: 1.2;
    }
  }
  &__gallery {
    margin-bottom: rem(10px);
    &-btn {
      background-color: transparent;
      border: none;
      padding: rem(5px);
      z-index: 1010;
      cursor: grab !important;
    }
    &-img {
      width: 100%;
      height: calc(25vw - 20px);
      object-fit: cover;
    }
  }

  &__form {
    /* position: relative; */
    &-img {
      height: rem(150px);
      width: auto;
      max-width: 100%;
      margin-bottom: rem(20px);
    }
    &-data {
      margin-top: rem(75px);
    }
    &-label {
      font-size: rem(18px);
      margin-bottom: 0;
    }
  }

  &__back-btn {
    display: none;
    background-color: transparent;
    min-width: auto;
    padding: 0;
    position: absolute;
    top: rem(-70px);
    left: 0;
    svg {
      width: rem(20px);
      height: auto;
      & + svg {
        width: rem(35px);
        margin-left: rem(5px);
      }
    }
  }
  &__cross-icon {
    svg {
      fill: $topcolor-red;
      width: rem(50px);
      height: rem(50px);
    }
  }
  .react-fine-uploader-file-input-container.fine-uploader__file-input {
    margin-top: rem(60px);
  }
  .fine-uploader__files-wrapper {
    padding: 0;
    width: 100%;
  }
  .fine-uploader__file-input {
    width: 100%;
    // height: rem(1px);
  }
  /* REFACTOR: fix to input overlays above the images
  disabling the ability to show the image edition page */
  .react-fine-uploader-file-input {
    height: rem(40px) !important;
    cursor: pointer;
  }
  .dropzone {
    border: none;
  }
  .thumbnails-wrapper__box {
    display: inline-block;
    width: 25%;
    min-width: rem(200px);
    & + .thumbnails-wrapper__box {
      margin-right: rem(5px);
    }
  }
  .circular-bar {
    width: rem(100px);
    margin-top: rem(75px);
  }
  .position{
    height: 0px;
    width: 1px !important;
    position: initial;
    min-width: 0px;
  }
  .save{
    position: absolute;
    top: 15px;
    right: 60px;
    z-index: 2;
    min-width: auto;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0px;
    svg{
      height: 24px;
    width: 24px;
    fill: white;
    }
  }
}

@media screen and (min-width: 768px) {
  .images-edition {
    &__gallery {
      &-img {
        height: calc(20vw - 25px);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .images-edition {
    &__header {
      margin-top: rem(40px);
    }
    &__gallery {
      padding: 0 rem(10px);
      &-img {
        height: rem(140px);
      }
    }
    &__dropzone {
      margin-top: 0;
      min-height: rem(300px);
      &--no-images {
        justify-content: center;
        margin-top: rem(75px);
        min-height: rem(400px);
      }
      &-img {
        display: block;
      }
    }
    &__back-btn {
      top: rem(15px);
      left: rem(15px);
      svg {
        width: rem(25px);
        height: auto;
        display: inline-block;
        vertical-align: middle;
        margin-top: rem(3px);
        & + svg {
          width: rem(40px);
          margin: 0 0 0 rem(5px);
        }
      }
    }
    &__form {
      &-data {
        margin-top: rem(40px);
      }
    }
  }
  // .fine-uploader__file-input {
  //   height: auto;
  // }
  .react-fine-uploader-file-input {
    height: auto !important;
    top: 0 !important;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .images-edition {
    &__gallery {
      &-img {
        height: rem(135px);
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .save{
    top: 5px !important;
    right: 55px !important;
  }
}
