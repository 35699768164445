$POPUP_HEIGHT: rem(150px);

.poi-map-popup {
  width              : 100%;
  background-color   : #fff;
  transition-duration: 0.5s;
  position           : relative;
  transform          : translate(-100vw, calc(100vh - 150px));
  transform          : translate(-100vw, calc((var(--vh, 1vh) * 100) - 150px));
  height             : $POPUP_HEIGHT;

  
    .imagePreview {
      width     : 8.75rem;
      height    : 100%;
      object-fit: cover;
    }
  

  &--added {
    background-color: $topcolor-black;

    .poi-map-popup {
      &__title,
      &__author {
        color: white;

        svg {
          fill: white;
        }
      }

      &__add-btn {
        background-color: white;
        cursor          : inherit;

        svg {
          fill: $topcolor-blue;
        }
      }
    }
  }

  &.ReactModal__Content--after-open {
    transform: translate(0, calc(100vh - 150px));
    transform: translate(0, calc((var(--vh, 1vh) * 100) - 150px));
  }

  &__overlay {
    position        : fixed;
    left            : 0;
    top             : 0;
    right           : 0;
    bottom          : 0;
    background-color: rgba($topcolor-black, 0.9);
    z-index         : 1010;
  }

  &__content {
    padding: rem(5px);
    display: flex;
  }

  &__content,
  &__show-btn,
  &__image,
  &__image-wrapper {
    height: 100%;
  }

  &__show-btn {
    background-color: transparent;
    padding         : 0;
    border          : none;
    flex            : 0 0 auto;
  }

  &__image {
    width     : 100%;
    object-fit: cover;

    &-wrapper {
      width           : $POPUP_HEIGHT - rem(10px);
      background-color: white;
    }
  }

  &__info {
    flex           : 1 1 100%;
    padding        : 0 rem(10px);
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
  }

  &__title {
    width         : 100%;
    text-align    : center;
    font-family   : 'Roboto-bold';
    font-weight   : bold;
    padding-bottom: rem(5px);
    border-bottom : rem(1px) dotted $topcolor-dark-grey;
    margin-bottom : rem(10px);
    font-size     : rem(18px);
    line-height   : 1.1;
  }

  &__author {
    font-family   : 'Oswald';
    text-transform: uppercase;
    color         : $topcolor-dark-grey;
    margin-bottom : 0;
    font-size     : rem(18px);

    svg {
      display     : inline-block;
      height      : rem(22px);
      width       : auto;
      fill        : $topcolor-dark-grey;
      margin-right: rem(7px);
    }
  }

  &__actions {
    align-self: center;
  }

  &__add-btn {
    background-color: $topcolor-blue;
    border          : none;
    width           : rem(30px);
    height          : rem(30px);
    border-radius   : 50%;
    padding         : 0;
    line-height     : 1;

    svg {
      width : 55%;
      height: auto;
      fill  : white;
    }
  }
}