.circular-img {
  border: rem(5px) solid white;
  border-radius: 50%;
  box-shadow: 0 rem(2px) rem(2px) rgba(0, 0, 0, 0.15);
  width: rem(90px);
  height: rem(90px);
  object-fit: cover;
}
.img-staff-pick:after {
  background: url('/assets/images/icono_staff_pick.svg') no-repeat 20px 0;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .circular-img {
    box-shadow: none;
  }
}
