.burguer-menu {
  padding-right: rem(50px);
  top: 0;
  &__button {
    position: relative;
    width: rem(40px);
    height: rem(40px);
    margin-left: rem(10px);
  }
  &__bars {
    background-color: white;
    height: rem(3px) !important;
    &:first-of-type {
      top: 10% !important;
    }
    &:last-of-type {
      top: 70% !important;
    }
  }
  .bm-cross {
    background-color: white;
    cursor: pointer;
    z-index: 9;
    &-button {
      right: 0 !important;
      top: 0 !important;
      height: rem(50px) !important;
      width: rem(50px) !important;
      > span { 
        right: rem(25px) !important; 
        span {
          height: rem(40px) !important;
        }
      }
      button {
        background-color: $topcolor-blue !important;
        width: rem(50px) !important;
        height: rem(50px) !important;
      }
    }
  }
  .bm-menu {
    background-color: white;
    padding: rem(15px 0);
  }
  &__link {
    background-color: transparent;
    border: 0;
    border-top: 1px dotted $topcolor-dark-grey;
    color:  $topcolor-black;
    display: block;
    font-family: 'Oswald';
    font-size: rem(18px);
    padding: rem(15px) rem(10px) rem(15px) rem(10px);
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    &:focus {
      outline: none;
    }
    &:hover {
      color: $topcolor-blue;
      text-decoration: none;
      svg {
        fill: $topcolor-blue;
      }
    }
    svg {
      height: rem(25px);
      margin-right: rem(20px);
      width: auto;
      &#youtube-icon path {
        fill: $topcolor-red;
      }
      &#info-color-icon > path:first-of-type {
        fill: none;
        stroke: $topcolor-black;
        stroke-width: rem(25px);
      }
      &#info-color-icon > path:nth-child(2) {
        fill: $topcolor-black;
      }
      &#add-blue {
        stroke: none;
      }
      &#angel-icon {
        height: rem(25px);
      }
    }
    &--active {
      background-color: $topcolor-blue;
      color: white;
      svg {
        fill: white;
      }
    }
  }
  &__crea-items {
    display: flex;
    list-style-type: none;
    margin: rem(15px) 0 0;
    padding: 0;
    &-btn { 
      background: transparent;
      border: 0;
      padding:0;
      text-align: center; 
      width: 33%; 
    }
    svg {
      height: rem(60px);
      margin: 0 rem(5px);
    }
    &-text {
      display: block;
      font-family: 'Roboto-bold';
      font-size: rem(11px);
      text-decoration: underline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__user {
    padding-bottom: rem(5px);
    &:focus {
      outline: none;
    }
    &-name {
      font-family: 'Oswald';
      font-size: rem(15px);
      margin-top: rem(10px);
      text-transform: uppercase;
    }
    &-avatar {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: rem(90px);
      margin: 0 auto;
    }
  }
}

.bm-overlay {
  top: 0;
  left: 0;
}

@media screen and (min-width: 992px) {
  .burguer-menu {
    display: none;
    &__button {
      display: none;
    }
  }
}
