.tour-config-nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  &__btn {
    background: transparent;
    border: none;
    position: relative;
    flex-grow: 1;
    height: rem(80px);
    svg {
      fill: black;
      width: rem(40px);
      height: rem(40px);
    }
    &--active {
      svg {
        fill: $topcolor-blue;
      }
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    + .tour-config-nav-menu__btn {
      &::before {
        content: '';
        display: inline-block;
        width: rem(1px);
        height: rem(50px);
        border-right: rem(1px) $topcolor-black dashed;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-config-nav-menu {
    position: fixed;
    flex-direction: column;
    left: rem(-64px);
    top: rem(10px);
    background-color: transparent;
    &__btn {
      width: rem(60px);
      height: rem(60px);
      padding: rem(7px);
      background-color: white;
      svg {
        width: rem(50px);
        height: rem(50px);
      }
      &--active {
        background-color: $topcolor-blue;
        svg {
          fill: white;
        }
      }
      & + .tour-config-nav-menu__btn {
        margin-top: rem(3px);
        &::before {
          display: none;
        }
      }
    }
    svg {
      width: 100%;
      height: auto;
    }
  }
}
