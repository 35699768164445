.poi-edition-header {
  &__title {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(22px);
    padding-bottom: rem(5px);
    margin-bottom: 0;
  }

  &__description {
    line-height: 1.25;
    margin-bottom: 0;
  }

  &__content {
    &--desktop {
      display: none;
    }
    &--mobile {
      padding-top: rem(15px);
      padding-bottom: rem(15px);
      background-color: white;
    }
  }
}

@media screen and (min-width: 992px) {
  .poi-edition-header {
    background-image: url($DARK_HEADER_BG);
    background-size: 100%;
    background-position: 0 -2vw;
    background-repeat: no-repeat;
    height: 23vw;
    margin-bottom: rem(60px);
    &__content {
      padding-top: 5%;
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
      }
    }
    &__title {
      color: $topcolor-light-blue;
      font-size: rem(28px);
      text-align: center;
      padding-top: 0;
    }
    &__description {
      text-align: center;
      color: white;
    }
  }
}

@media screen and (min-width: 1201px) {
  .poi-edition-header {
    background-position: 0 -7vw;
    height: 18vw;
  }
}
