$HEIGHT_XS: rem(50px);

.tour-edition-header {
  background-color: $topcolor-light-black;
  position: fixed;
  top: rem(50px);
  left: 0;
  right: 0;
  z-index: 1000;
  &__back {
    background: transparent;
    border: none;
    height: $HEIGHT_XS;
    svg {
      fill: white;
      width: rem(25px);
      height: rem(25px);
    }
  }
  &__title {
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    line-height: $HEIGHT_XS;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(18px);
    max-width: calc(100% - 40px);
  }
  &__keywords {
    .autocomplete-tag {
      max-width: 90% !important;
      &-placeholder {
        width: 100% !important;
        display: inline;
        font-weight: 100 !important;
        font-family: 'Roboto-Regular';
      }
    }
    .form-control {
      background: $topcolor-light-grey;
    }
  }
}

$side-padding: rem(100px);

@media screen and (min-width: 992px) {
  .tour-edition-header {
    position: relative;
    margin-bottom: rem(60px);
    padding-top: rem(50px);
    z-index: 10;
    top: 0;
    max-width: unset;
    &:after {
      content: '';
      display: block;
      background-image: url($DARK_HEADER_BG);
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      height: 25vw;
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      top: rem(100px);
    }
    &__tutorial {
      display: none;
      position: absolute;
      top: rem(60px);
      left: rem(15px);
      text-align: center;
      &-btn {
        background-color: transparent;
        border: none;
      }

      &-img {
        width: rem(183px);
        height: auto;
      }
      &-text {
        margin: rem(10px) 0 0 0;
        text-align: center;
        width: rem(220px);
        color: $topcolor-light-grey;
      }
    }
    &__content {
      min-height: rem(35px);
    }

    &__top {
      text-align: center;
    }
    &__title {
      display: inline-block;
      position: relative;
      font-family: 'Roboto-bold';
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: rem(34px);
      line-height: rem(75px);
      padding: rem(5px) rem(70px) rem(5px) 0;
      color: $topcolor-light-blue;
      margin-bottom: 0;
      max-width: 100%;
    }
    &__preview {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: transparent;
      border: none;
      svg {
        width: rem(50px);
        height: rem(50px);
      }
    }

    &__keywords {
      text-align: center;
      color: white;
      margin-bottom: 0;
      font-weight: bold;
      font-size: rem(18px);
      .autocomplete-tag {
        width: 80%;
        display: inline-block;
        &-placeholder {
          width: 100% !important;
          display: inline;
          font-weight: 100 !important;
          font-family: 'Roboto-Regular';
        }
        &__tag {
          color: white;
          + .autocomplete-tag__tag {
            margin-left: rem(7px);
          }
        }
      }
      &-btn {
        background-color: transparent;
        // color: white;
        border-radius: 50%;
        width: rem(30px);
        height: rem(30px);
        padding: 0;
        border: none;
        // line-height: 30px;
        text-align: center;
        // font-size: rem(20px);
        margin-left: rem(20px);
      }
      &:after {
        content: '';
        display: inline-block;
        width: 80%;
        margin: rem(25px) auto rem(10px) auto;
        border: 1px dashed white;
      }
    }
    &__config {
      background-color: transparent;
      border: none;
      color: white;
      font-size: rem(18px);
      span,
      svg {
        display: inline-block;
        vertical-align: middle;
      }
      svg {
        fill: white;
        width: rem(20px);
        height: auto;
        margin-left: rem(7px);
      }
    }
    &__state {
      color: white;
      font-size: rem(16px);
      margin-bottom: 0;
      &-btn {
        width: rem(98px);
        margin-left: rem(10px);
        padding: rem(5px) rem(15px);
        border: none;
      }
      &-description {
        text-align: left;
        margin: 0;
        color: $topcolor-grey;
        font-size: rem(16px);
      }
    }
  }
  .custom-state-select {
    width: rem(98px);
    position: absolute;
    left: rem(63px);
    z-index: 10;
    &__list {
      display: flex;
      flex-direction: column;
      height: rem(37px);
      label {
        color: white;
        cursor: pointer;
        line-height: rem(37px);
        text-align: center;
        margin: 0;
      }
    }
    //hides radio button in the list, only shows the labels
    input {
      position: absolute;
      visibility: hidden;
    }
  }
  .rejected {
    background-color: $topcolor-red;
  }
}

.tour-edition-header__state-btn,
.custom-state-select__list-item {
  &--edition {
    background-color: $topcolor-blue;
    color: white;
  }
  &--published {
    background-color: $topcolor-orange;
    color: white;
  }
  &--approved {
    background-color: #28a745;
    color: white;
  }
  &--hidden {
    background-color: $topcolor-dark-grey;
    color: white;
  }
}

@media screen and (min-width: 1200px) {
  .tour-edition-header {
    position: relative;
    margin-bottom: rem(60px);
    padding-top: rem(50px);
    z-index: 10;

    &:after {
      top: rem(75px);
    }
  }
}

@media screen and (min-width: 1560px) {
  .tour-edition-header {
    &:after {
      top: rem(25px);
    }
  }
}
