.tour-content-map-form {
  padding-bottom: rem(25px);
  &__location {
    padding: rem(10px) 0 rem(10px) rem(15px);
    border-left: rem(1px) dashed grey;
    &-btn {
      background: none;
      border: none;
    }
    &-img {
      margin: 0 auto;
      height: rem(75px);
      width: rem(75px);
    }
    &-text {
      text-transform: uppercase;
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: rem(18px);
    }
  }

  &__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: rem(18px);
    margin-bottom: rem(10px);
  }

  &__text-editor {
    min-height: rem(150px);
    min-width: 100%;
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-container {
      min-height: rem(100px);
    }
  }

  &__suggestions {
    line-height: 1.2;
    font-size: rem(18px);
    border: rem(1px) solid $topcolor-light-black;
    position: absolute;
    top: rem(40px);
    left: rem(15px);
    right: 0;
    z-index: 1;
    background-color: white;
    &-item {
      padding: rem(10px);
      &--active {
        background-color: rgba($topcolor-blue, 1);
        color: white;
      }
      & + .poi-position-modal__suggestions-item {
        border-top: rem(1px) solid $topcolor-light-black;
      }
    }
  }

  &__map-title {
    font-size: rem(22px);
    text-align: center;
    margin-top: rem(15px);
  }
}


@media screen and (min-width: 992px) {
  .tour-content-map-form {
    &__location {
      border: none;
      padding: 0;
      &-btn {
        margin-left: rem(15px);
      }
      &-text {
        display: block;
        text-transform: uppercase;
        margin: rem(5px) 0 0 0;
      }
      &-img {
        width: rem(55px);
        height: rem(55px);
      }
    }
  }
}