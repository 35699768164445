.gallery-box {
  background-color: white;
  padding: rem(15px) rem(15px) 0 rem(15px);
  position: relative;
  margin-top: rem(60px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;
    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(5px);
      border-top-right-radius: rem(5px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(5px) 0 0 0;
      text-align: center;
      svg {
        height: 100%;
        width: auto;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  &__category {
    font-size: rem(14px);
    font-family: 'Oswald';
    text-transform: uppercase;
    color: $topcolor-dark-grey;
  }
  &__slide {
    &-trigger {
      border: none;
      padding: rem(5px);
      background-color: white;
      &[data-type="default"] {
        cursor: inherit;
        .gallery-box__slide-image {
          visibility: hidden;
        }
      }
    }
    &-image {
      height: calc(33vw - 12.37px);
      object-fit: cover;
      margin-bottom: 0;
    }
  }
  .carousel {
    .slide {
      background-color: white;
    }
    &.carousel-slider {
      padding-bottom: rem(35px);
    }
  }
  .control-dots {
    padding-left: 0;
    .dot {
      box-shadow: none;
      background-color: $topcolor-dark-grey;
      width: rem(6px);
      height: rem(6px);
      margin: 0 rem(2px);
      &.selected {
        background-color: $topcolor-black;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .gallery-box {
    margin-top: rem(75px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    // padding: 15vw rem(15px);
    padding: rem(160px 15px);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4000 1000'%3E%3Cg clip-rule='evenodd'%3E%3Cpath fill-rule='evenodd' fill='%232C2C2C' d='M0 131.8c372.119 13.294 746.652 18.572 1116.35-25.7 306.98-36.761 448.116-65.419 674.56-78.616 186.833-10.889 373.801.143 558.855 26.888C2530.47 80.489 2822.537 99.406 3005 104c370.031 9.316 627.156-12.963 995-54.258v915.601c-378.941-9.219-722.709 12.711-1131.013-22.3-22.38-1.919-465.592-49.518-602.987-45.043-114.231 3.721-291.784 9.61-462 25-139.219 12.587-205 38-396.648 42.343-297.965 6.752-367.667-95.569-601.666-90.818-131.67 2.674-249.091 15.093-378.945 26.337C320.424 910.067 50 954 0 910.931'/%3E%3Cpath fill='none' stroke='red' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='3' d='M2722.275 91.8h2.074'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    box-shadow: none;

    &__header {
      top: 5vw;
      top: rem(55px);
      justify-content: center;
      &-title {
        color: white;
        text-align: center;
        font-size: rem(36px);
        margin-bottom: rem(25px);
      }
      &-subtitle {
        display: block;
        font-size: rem(24px);
        margin-top: rem(5px);
      }
    }
    &__slide {
      &-trigger,
      &-image {
        background-color: $topcolor-black;
      }
      &-image {
        height: calc(960px / 4 - 30px);
      }
    }
    &__footer {
      padding: 0 rem(40px);
    }
    .carousel.carousel-slider {
      padding: 0 rem(40px) rem(15px) rem(40px);
      .control-arrow {
        
        &:hover {
          background: none;
        }
        &:before {
          border-top-width: rem(16px);
          border-bottom-width: rem(16px);
        }
        &.control-prev {
          &:before {
            border-right: rem(16px) solid white;
          }
        }
        &.control-next {
          &:before {
            border-left: rem(16px) solid white;
          }
        }
      }
      .control-dots {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .gallery-box {
    &__slide {
      &-image {
        height: calc(1140px / 4 - 53.75px);
      }
    }
  }
}
