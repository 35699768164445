.poi-images-box {
  &__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem(400px);
    &-img {
      display: block;
      width: 90%;
      max-width: rem(230px);
      max-height: rem(150px);
      margin: 0 auto;
      &--drag {
        display: none;
      }
    }
    &-text {
      margin: rem(20px) 0 0 0;
      font-family: 'Roboto-bold';
      text-align: center;
      font-size: rem(18px);
      padding: 0 rem(10px);
      line-height: 1.2;
    }
  }

  &__images {
    padding: rem(30px) rem(30px) rem(100px) rem(30px);
  }
  &__image-wrap {
    margin-bottom: 0;
    &--default {
      background-color: $topcolor-blue;
      height: rem(120px);
      position: relative;
      svg {
        fill: white;
        width: rem(30px);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    & + .poi-images-box__image-wrap {
      margin-top: rem(5px);
    }
  }
  &__image {
    width: 100%;
    height: auto;
  }
}

.fine-uploader-dropzone-container.dropzone {
  padding-bottom: rem(10px);
}
.react-fine-uploader-dropzone-active {
  .poi-images-box__dropzone {
    &-img {
      &--nodrag {
        display: none;
      }
      &--drag {
        display: block;
      }
    }
    &-text {
      display: none;
    }
  }
}
