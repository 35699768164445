.tour-content-text-box {
  background-color: white;
  padding: rem(20px) rem(15px);
  position: relative;
  margin-top: rem(25px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__title {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 700;
    color: black;
    font-size: rem(24px);
    padding-right: rem(10px);
    margin-bottom: 0;
  }
  /* &__decorator {
    border-right: rem(7px) $topcolor-blue solid;
  } */
  &__subtitle {
    border-bottom: 1px dashed black;
    padding-bottom: 15px;
    margin: rem(10px) 0 0 0;
    color: gray !important;
    font-family: 'Roboto-Italic', sans-serif !important;
    font-style: italic;
    font-size: 17px !important;
    font-weight: 400 !important;
  }
  &__subtitle,
  &__main-text {
    word-wrap: break-word;
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;
    margin-bottom: 0;
    strong {
      color: #2c2c2c;
      font-weight: bold;
    }
    a {
      text-decoration: underline;
    }
    p {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: 768px) {
  .tour-content-text-box {
    /* &__subtitle {
      margin-top: 0;
    } */
    &__decorator{
      max-width: 100%;
    }
  }
}

  @media screen and (min-width: 992px) {
  .tour-content-text-box {
    padding: rem(20px) rem(35px);
    margin-top: rem(25px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    &__title {
      font-size: rem(26px);
    }
  }
}
