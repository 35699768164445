.results-card-list {
  display: flex;
/*   align-items: flex-start;
  flex-grow: 1;
  max-height: calc(100vh - 80px);
  max-height: calc(var(--vh, 1vh) * 100 - 80px); */
  overflow-y: auto;
  padding: rem(10px) rem(15px) 0 0;
  flex-wrap: wrap;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
    &-thumb {
      background: $topcolor-blue;
    }
  }
  //Coreccion de espacio
  /* grid-gap: 2px;
    display: grid !important;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, 260px);
    grid-template-rows: repeat(auto-fill, 340px);
    grid-template-rows: repeat(auto-fill, 280px); */
}