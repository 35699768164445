.form-control-ReactQuill {
  background-color: #C2DBE7;
  margin          : 5px 0px;
}

.profile-page {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100;
  font-size  : 1.1rem;

  .pointer {
    cursor: pointer;
  }

  .loadingItems {
    width: 200px;
  }

  &__top {
    position: relative;

    &--public {
      margin-top: rem(60px);
    }
  }

  &__top-traveler-wrapper {
    text-align: center;
    margin-top: rem(65px);

    svg {
      width: rem(120px);
    }
  }

  &__header {
    text-align      : center;
    z-index         : 0;
    // height     : 40px;
    padding-top     : 20px;
    position        : relative;
    background-color: #81d4fb;

    svg {
      display: none;
    }
  }

  &__main-image {
    margin-top      : 100px;
    width           : 100%;
    position        : static;
    top             : 0;
    left            : 0;
    right           : 0;
    background-color: #81d4fb;

    ::after {
      content   : '';
      width     : 100%;
      height    : 80px;
      background: red;
    }
  }

  &__avatar {
    display         : inline-block;
    border-radius   : 50%;
    box-shadow      : 0 2px 2px rgba(0, 0, 0, .15);
    border          : rem(5px) solid white;
    margin          : auto;
    width           : rem(120px);
    height          : rem(120px);
    background-color: white;
    object-fit      : cover;

    &-wrapper {
      position: relative;
      top     : rem(-60px);
      left    : 0;
      right   : 0;
      z-index : 1;
    }
  }

  &__user {
    margin-top: rem(60px);

    &--toptraveler {
      margin-top: 0;
    }

    &-name {
      font-family  : 'Roboto-bold';
      font-size    : rem(24px);
      // margin-top: rem(70px);
      text-align   : center;
    }

    &-nickname {
      font-family: 'Roboto-bold';
      color      : $topcolor-blue;
      font-size  : rem(18px);
      margin-top : rem(10px);
      text-align : center;

      &-form {
        height          : calc(1.5em + 0.75rem + 2px);
        padding         : 0.375rem 0.75rem;
        background-color: $topcolor-blue;
        color           : white;
        margin          : rem(5px 0 0 0) !important;
        font-size       : rem(19px);
      }
    }

    &-logo {
      svg {
        height  : rem(50px);
        position: absolute;
        top     : 0;
        left    : calc(50% - 125px);
        width   : rem(90px);

        .text {
          transform: translate(-100px, -100px) scale(0.8);
        }
      }
    }
  }

  &__angel-btn {
    height          : rem(40px);
    width           : rem(40px);
    padding         : 0;
    background-color: $topcolor-light-blue;
    border-radius   : 50%;
    border          : none;
    position        : absolute;
    right           : calc(50% - 100px);
    top             : rem(5px);
    line-height     : 0;

    svg {
      height: 48%;
      fill  : white;
      width : auto;
    }

    &:hover,
    &:active {
      background-color: $topcolor-blue;
    }
  }

  &__edit-btn {
    height          : rem(40px);
    width           : rem(40px);
    padding         : 0;
    background-color: $topcolor-light-blue;
    border-radius   : 50%;
    border          : none;
    position        : absolute;
    right           : calc(50% - 100px);
    top             : rem(5px);
    line-height     : 0;

    svg {
      height: 60%;
      fill  : white;
      width : auto;
    }

    &:hover,
    &:active {
      background-color: $topcolor-blue;
    }
  }


  &__website {
    text-align: center;

    &-link {
      color        : inherit;
      font-family  : 'Roboto', sans-serif !important;
      font-weight  : 100;
      font-size    : rem(18px);
      display      : inline-block;
      max-width    : 100%;
      white-space  : nowrap;
      text-overflow: ellipsis;
      overflow     : hidden;
    }
  }

  &__social-list {
    margin-bottom: rem(5px);
    text-align   : center;
  }

  &__social-link {
    svg {
      height      : rem(40px);
      margin-right: rem(10px);

      &:hover path {
        fill: $topcolor-blue;
      }
    }
  }

  &__description {
    border-bottom : 1px dashed $topcolor-light-black;
    font-family   : 'Roboto', sans-serif !important;
    font-weight   : 100;
    white-space   : pre-line;
    line-height   : rem(29px);
    font-size     : rem(19px);
    color         : $topcolor-light-black;
    overflow      : visible;
    padding-bottom: rem(20px);
  }

  &__form-side {
    .input-group-text {
      border          : none;
      background-color: white;
      padding         : 0 rem(5px);

      svg {
        height: rem(25px);
        width : rem(25px);

        path {
          fill: $topcolor-blue;
        }
      }
    }
  }

  &__actions {
    display        : flex;
    width          : 100%;
    justify-content: center;
    margin-top     : rem(70px);
    padding        : 0 rem(15px);

    .btn {
      &+.btn {
        margin-left: rem(5px);
      }
    }

    div.fine-uploader__files-wrapper {
      padding     : 0;
      margin-right: rem(5px);
    }

    &+.profile-page__user {
      .profile-page__user-name {
        margin-top: rem(10px);
      }
    }

    .progressPercentageBarWrapper {
      height          : rem(38px);
      width           : rem(120px);
      background-color: rgba($topcolor-blue, 0.6);
    }

    .progressPercentageBarProgress {
      display         : inline-block;
      height          : 100%;
      background-color: $topcolor-blue;
      color           : white;
      padding         : 0 rem(10px);
      line-height     : rem(38px);
      font-weight     : bold;
      font-family     : 'Roboto-bold';
    }

    .progressPercentageBarProgress,
    .progressPercentageBarWrapper {
      border-radius: $btn-border-radius;
    }
  }

  &__tabs {
    &-button {
      background-color: transparent;
      border          : 0;
      padding         : rem(10px);

      svg {
        height: rem(70px);
        margin: 0;
      }

      &--active {
        background-color: $topcolor-light-blue;
      }
    }

    &-text {
      display    : block;
      margin     : rem(10px) 0 0 0;
      font-size  : 0.85rem;
      font-family: 'Oswald';
    }
  }

  &__affiliate-plans {
    span {
      font-size  : 20px;
      font-weight: 700;
      margin     : 0px 10px;
    }

    p {
      margin: 0px 10px;
    }

    .affiliate-plans-input {
      display: flex;
    }
  }

  &__donation-box {
    span {
      font-size  : 20px;
      font-weight: 700;
      margin     : 0px 10px;
    }

    p {
      margin: 0px 10px;
    }

    .custom-checkbox {
      color       : dimgray;
      font-size   : 1.1875rem;
      padding-left: 2.5rem;

      i {
        width : 25px;
        height: 25px;
      }

      i:after {
        top   : -2px;
        left  : 0;
        width : 25px;
        height: 25px;
      }
    }
  }

}

//modal opciones del perfil
.modalOptions {
  top       : 40px;
  left      : 40px;
  right     : 40px;
  bottom    : 40px;
  background: white;
  overflow  : auto;
  outline   : none;
  padding   : 10px 10px 20px 10px;
  width     : 60%;
  height    : 100%;

  &__contentModal {
    justify-content : flex-end;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529d4;
  }

  .navbarLeft {
    position        : fixed;
    flex-direction  : column;
    right           : 60vw;
    background-color: transparent;

    &__btn {
      width           : 3.75rem;
      height          : 3.75rem;
      padding         : 0.4375rem;
      background-color: white;
      border          : none;
      line-height     : 0;

      svg {
        width : 100%;
        height: auto;
        fill  : black;
      }

      &:hover,
      &:active {
        background-color: #00affe;
      }

      &--active {
        background-color: #00affe;

        svg {
          fill: white;
        }
      }
    }
  }

  .content {
    .cursor {
      cursor: pointer;
    }

    .añadir_azul {
      margin-top: 10px;
    }

    .textBlue {
      color: #00affe;
    }

    .imageSponsor {
      width     : 150px;
      height    : 140px;
      object-fit: cover;
    }

    .withOutImage {
      min-width       : 150px;
      width           : 150px;
      height          : 140px;
      object-fit      : cover;
      display         : flex;
      justify-content : center;
      align-items     : center;
      background-color: #81d4fb;

      svg {
        width: 30px;
        fill : white;
      }
    }

    .transparent {
      opacity: 0.5;
    }

    .division2 {
      background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size    : 6px 1px;
      background-repeat  : repeat-x;
      padding-bottom     : 10px;
      margin             : 10px;
    }

    .division3 {
      border-bottom : 1px solid black;
      padding-bottom: 10px;
    }

    .division4 {
      border-right: 0.0625rem dashed #6f6f6f;

      span {
        font-size: 20px;
      }
    }

    .checkIcon {
      height          : 2.5rem;
      width           : 2.5rem;
      background-color: #00affe;
      display         : inline-block;
      border          : none;
      border-radius   : 50%;
      vertical-align  : middle;
      line-height     : 1;
      margin-right    : 5px;

      svg {
        width : 60%;
        height: 60%;
        fill  : white;
      }
    }

    .closeIcon {
      height          : 2.5rem;
      width           : 2.5rem;
      background-color: black;
      display         : inline-block;
      border          : none;
      border-radius   : 50%;
      vertical-align  : middle;
      line-height     : 1;

      svg {
        width : 60%;
        height: 60%;
        fill  : white;
      }
    }

    .fixedObject {
      z-index         : 1;
      background-color: white;
      position        : fixed;
      top             : 0px;
      height          : 60px;
      width           : 58% !important;
    }

    .bar {
      display         : flex;
      padding         : 5px;
      background-color: #E9EBEF;
      margin-top      : 52px;

      .barContent {
        position        : fixed;
        flex-direction  : column;
        left            : 55.5rem;
        background-color: transparent;

        &__btn {
          width           : 80px;
          height          : 80px;
          padding         : 0.4375rem;
          background-color: white;
          border          : none;
          line-height     : 0;

          svg {
            width : 60%;
            height: auto;
            fill  : black;
          }

          &:hover,
          &:active {
            background-color: #00affe;
          }

          &--active {
            background-color: #00affe;

            svg {
              fill: white;
            }

            p {
              color: white;
            }
          }

          p {
            margin: 20px 0px;
          }
        }
      }
    }

    .left {
      &__category-item {
        list-style      : none;
        background-color: white;

        &+.filters-modal__category-item {
          margin-top: rem(3px);
        }

        .custom-checkbox {
          font-size   : rem(16px);
          line-height : rem(25px);
          padding-left: rem(40px);

          i {
            border       : rem(1px) solid $topcolor-black;
            border-radius: 0;
          }

          i,
          i:after {
            width : rem(25px);
            height: rem(25px);
          }
        }
      }

      &__label {
        text-align   : center;
        font-family  : 'Roboto-bold';
        font-weight  : bold;
        line-height  : 1.2;
        font-size    : rem(18px);
        margin-bottom: rem(10px);
      }

      &__text-editor {
        min-height: rem(150px);
        min-width : 100%;

        // modifies the quill library rich text editor library styles...
        & .ql-toolbar {
          max-height: rem(45px);
        }

        & .ql-container {
          min-height: rem(100px);
        }
      }

      .dropzone {
        border     : none;
        display    : flex;
        align-items: center;
      }

      .poi-images-box__dropzone {
        min-height: 10rem;
        min-width : 160px;
      }

      .fine-uploader__upload-input {
        width: 50px;
      }

      .tour-content-video-form__supported-text {
        font-size: 13px !important;
      }

      .tour-content-video-form__supported-type {
        svg {
          width: 1.5rem !important;
        }
      }
    }

    .divisorLateral {
      margin-top : 130px;
      height     : 500px;
      width      : 1px;
      border-left: 1px solid;
    }

    .right {
      .logotipo {
        display        : flex;
        justify-content: center;
        align-items    : flex-start;

        .cuadrado {
          width : 250px;
          height: 230px;
          border: 1px solid black;

          img {
            width     : 100%;
            height    : 100%;
            object-fit: cover;
          }

          .dropzone {
            border         : none;
            display        : flex;
            align-items    : center;
            justify-content: center;
            flex-direction : column;
          }

          .poi-images-box__dropzone {
            min-width : 150px;
            min-height: 175px;
          }

          .fine-uploader__upload-input {
            display: block !important;
            width  : 50px;
          }
        }

        .rectangulo {
          &.banner {
            width : 250px;
            height: 400px;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: cover;
            }
          }

          border: 1px solid black;

          .dropzone {
            border        : none;
            /* display    : flex; */
            align-items   : center;
          }

          .poi-images-box__dropzone {
            min-width : 200px;
            min-height: 175px;
          }

          .fine-uploader__upload-input {
            display: block !important;
            width  : 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .profile-page {
    &__grey-bg--desktop {
      background-color: $topcolor-grey;
      position        : relative;

      &:after {
        content         : "";
        background-color: inherit;
        position        : fixed;
        width           : 100%;
        height          : 100%;
        top             : 0;
        left            : 0;
        z-index         : -1;
      }
    }

    &__top {
      &--public {
        margin-top: 0;
      }
    }

    &__top-traveler-wrapper {
      margin-top: rem(-20px);
    }

    &__social-list {
      text-align: left;
    }

    &__header {
      margin-bottom: rem(-70px);
      z-index      : 0;
      height       : auto;
      padding-top  : 0;
      overflow     : hidden;
      position     : relative;

      svg {
        display   : block;
        min-height: rem(200px);
        position  : absolute;
        bottom    : 0;
        left      : 0;
        width     : 100%;
        z-index   : 1;
      }
    }

    &__main-image {
      position: relative;
      left    : 0;
      width   : 100%;
      top     : 0;
      height  : 100%;
    }

    &__user {
      margin-top  : 0;
      border-right: 1px dashed $topcolor-light-black;
      text-align  : right;
      min-height  : rem(100px);

      &-name {
        position    : relative;
        display     : inline-block;
        padding-left: rem(50px);
        line-height : rem(40px);
        margin-top  : 0;
        text-align  : right;
        font-size   : rem(28px);
      }

      &-nickname {
        margin-top: 0;
        text-align: right;
        font-size : rem(20px);
      }

      &-logo {
        position: relative;

        svg {
          width : auto;
          height: rem(60px);
          left  : unset;
          right : 0;

          .text {
            transform: none;
          }
        }
      }
    }

    &__avatar {
      border: rem(3px) solid black;

      &-wrapper {
        top: rem(-90px);
      }
    }

    &__angel-btn {
      margin-right: rem(10px);
      left        : -52px;
      bottom      : 0;
      top         : 0;

      svg {
        position: relative;
      }
    }

    &__edit-btn {
      margin-right: rem(10px);
      left        : 0;
      bottom      : 0;
      top         : 0;

      svg {
        position: relative;
      }
    }

    &__website {
      text-align: left;
      min-height: rem(26px);

      &--private {
        margin-left: rem(50px);
      }
    }

    &__description {
      font-family   : 'Roboto', sans-serif;
      border        : 0;
      padding-bottom: 0;
      text-align    : center;
    }

    &__actions {
      margin: 0 0 rem(15px) 0;

      &+.profile-page__user {
        .profile-page__user-name {
          margin-top: 0;
        }
      }
    }

    &__tabs {
      &-button {
        svg {
          height: rem(100px);
        }
      }

      &-text {
        font-size     : 1.1rem;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .modalOptions {
    top       : 40px;
    left      : 40px;
    right     : 40px;
    bottom    : 40px;
    background: white;
    overflow  : auto;
    outline   : none;
    padding   : 10px 10px 20px 10px;
    width     : 100%;
    height    : 100%;

    &__contentModal {
      justify-content : flex-end;
      display         : flex;
      align-items     : center;
      position        : fixed;
      top             : 0px;
      left            : 0px;
      right           : 0px;
      bottom          : 0px;
      background-color: #212529d4;
    }

    .navbarLeft {
      display         : none !important;
      position        : fixed;
      flex-direction  : column;
      right           : 60vw;
      background-color: transparent;

      &__btn {
        width           : 3.75rem;
        height          : 3.75rem;
        padding         : 0.4375rem;
        background-color: white;
        border          : none;
        line-height     : 0;

        svg {
          width : 100%;
          height: auto;
          fill  : black;
        }

        &:hover,
        &:active {
          background-color: #00affe;
        }

        &--active {
          background-color: #00affe;

          svg {
            fill: white;
          }
        }
      }
    }

    .content {
      .cursor {
        cursor: pointer;
      }

      .añadir_azul {
        margin-top: 10px;
      }

      .textBlue {
        color: #00affe;
      }

      .imageSponsor {
        width     : 150px;
        height    : 140px;
        object-fit: cover;
      }

      .division2 {
        background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size    : 6px 1px;
        background-repeat  : repeat-x;
        padding-bottom     : 10px;
        margin             : 10px;
      }

      .division3 {
        border-bottom : 1px solid black;
        padding-bottom: 10px;
      }

      .fixedObject {
        width: 98% !important;
      }

      .division4 {
        border-right: 0.0625rem dashed #6f6f6f;
      }

      .checkIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: #00affe;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;
        margin-right    : 5px;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .closeIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: black;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .bar {
        display         : flex;
        padding         : 5px;
        background-color: #E9EBEF;

        .barContent {
          position        : fixed;
          flex-direction  : column;
          left            : 55.5rem;
          background-color: transparent;

          &__btn {
            width           : 80px;
            height          : 80px;
            padding         : 0.4375rem;
            background-color: white;
            border          : none;
            line-height     : 0;

            svg {
              width : 60%;
              height: auto;
              fill  : black;
            }

            &:hover,
            &:active {
              background-color: #00affe;
            }

            &--active {
              background-color: #00affe;

              svg {
                fill: white;
              }

              p {
                color: white;
              }
            }

            p {
              margin: 20px 0px;
            }
          }
        }
      }

      .left {
        &__category-item {
          list-style      : none;
          background-color: white;

          &+.filters-modal__category-item {
            margin-top: rem(3px);
          }

          .custom-checkbox {
            font-size   : rem(16px);
            line-height : rem(25px);
            padding-left: rem(40px);

            i {
              border       : rem(1px) solid $topcolor-black;
              border-radius: 0;
            }

            i,
            i:after {
              width : rem(25px);
              height: rem(25px);
            }
          }
        }

        &__label {
          text-align   : center;
          font-family  : 'Roboto-bold';
          font-weight  : bold;
          line-height  : 1.2;
          font-size    : rem(18px);
          margin-bottom: rem(10px);
        }

        &__text-editor {
          min-height: rem(150px);
          min-width : 100%;

          // modifies the quill library rich text editor library styles...
          & .ql-toolbar {
            max-height: rem(45px);
          }

          & .ql-container {
            min-height: rem(100px);
          }
        }

        .dropzone {
          width      : 80px;
          min-width  : 80px;
          border     : none;
          display    : flex;
          align-items: center;
        }

        .poi-images-box__dropzone {
          min-height: 10rem;
          min-width : 160px;
          display   : none;
        }

        .fine-uploader__upload-input {
          width: 50px;
        }

        .tour-content-video-form__supported-text {
          font-size: 13px !important;
        }

        .tour-content-video-form__supported-type {
          svg {
            width: 1.5rem !important;
          }
        }
      }

      .divisorLateral {
        margin-top : 130px;
        height     : 500px;
        width      : 1px;
        border-left: 1px solid;
      }

      .right {
        .logotipo {
          display        : flex;
          justify-content: center;
          align-items    : flex-start;

          .cuadrado {
            width : 250px;
            height: 180px;
            border: 1px solid black;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: cover;
            }

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 215px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }

          .rectangulo {
            // width : 150px;
            // height: 200px;
            border: 1px solid black;

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
              min-width  : 100px;
            }

            .poi-images-box__dropzone {
              min-width : 118px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modalOptions {
    top       : 40px;
    left      : 40px;
    right     : 40px;
    bottom    : 40px;
    background: white;
    overflow  : auto;
    outline   : none;
    padding   : 10px 10px 20px 10px;
    width     : 100%;
    height    : 100%;

    &__contentModal {
      justify-content : flex-end;
      display         : flex;
      align-items     : center;
      position        : fixed;
      top             : 0px;
      left            : 0px;
      right           : 0px;
      bottom          : 0px;
      background-color: #212529d4;
    }

    .navbarLeft {
      display         : none !important;
      position        : fixed;
      flex-direction  : column;
      right           : 60vw;
      background-color: transparent;

      &__btn {
        width           : 3.75rem;
        height          : 3.75rem;
        padding         : 0.4375rem;
        background-color: white;
        border          : none;
        line-height     : 0;

        svg {
          width : 100%;
          height: auto;
          fill  : black;
        }

        &:hover,
        &:active {
          background-color: #00affe;
        }

        &--active {
          background-color: #00affe;

          svg {
            fill: white;
          }
        }
      }
    }

    .content {
      .contentCenter {
        flex-direction: column-reverse;

        .campaign {
          max-width: 100%;

          h4 {
            margin-left: 0px;
          }

          h6 {
            padding-left: 0px !important;
          }
        }

        .sponsored-destinations {
          max-width : 100%;
          margin-top: 15px;
        }
      }

      .addCampaign {
        flex-direction: column;

        .left {
          max-width: 100%;
        }

        .divisorLateral {
          height       : 1px;
          width        : 100%;
          border-bottom: 1px solid;
        }

        .right {
          max-width: 100%;
        }

      }

      .seeCampaign {
        flex-direction: column;



        .left {
          max-width: 100%;
        }

        .divisorLateral {
          height       : 1px;
          width        : 100%;
          border-bottom: 1px solid;
        }

        .right {
          max-width: 100%;
        }

      }

      .cursor {
        cursor: pointer;
      }

      .añadir_azul {
        margin-top: 10px;
      }

      .textBlue {
        color: #00affe;
      }

      .imageSponsor {
        width     : 150px;
        height    : 140px;
        object-fit: cover;
      }

      .division2 {
        background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size    : 6px 1px;
        background-repeat  : repeat-x;
        padding-bottom     : 10px;
        margin             : 10px;
      }

      .division3 {
        border-bottom : 1px solid black;
        padding-bottom: 10px;
      }

      .fixedObject {
        width: 97% !important;
      }

      .division4 {
        border-right: 0.0625rem dashed #6f6f6f;
      }

      .checkIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: #00affe;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;
        margin-right    : 5px;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .closeIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: black;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .bar {
        display         : flex;
        padding         : 5px;
        background-color: #E9EBEF;

        .barContent {
          position        : fixed;
          flex-direction  : column;
          left            : 55.5rem;
          background-color: transparent;

          &__btn {
            width           : 80px;
            height          : 80px;
            padding         : 0.4375rem;
            background-color: white;
            border          : none;
            line-height     : 0;

            svg {
              width : 60%;
              height: auto;
              fill  : black;
            }

            &:hover,
            &:active {
              background-color: #00affe;
            }

            &--active {
              background-color: #00affe;

              svg {
                fill: white;
              }

              p {
                color: white;
              }
            }

            p {
              margin: 20px 0px;
            }
          }
        }
      }

      .left {
        &__category-item {
          list-style      : none;
          background-color: white;

          &+.filters-modal__category-item {
            margin-top: rem(3px);
          }

          .custom-checkbox {
            font-size   : rem(16px);
            line-height : rem(25px);
            padding-left: rem(40px);

            i {
              border       : rem(1px) solid $topcolor-black;
              border-radius: 0;
            }

            i,
            i:after {
              width : rem(25px);
              height: rem(25px);
            }
          }
        }

        &__label {
          text-align   : center;
          font-family  : 'Roboto-bold';
          font-weight  : bold;
          line-height  : 1.2;
          font-size    : rem(18px);
          margin-bottom: rem(10px);
        }

        &__text-editor {
          min-height: rem(150px);
          min-width : 100%;

          // modifies the quill library rich text editor library styles...
          & .ql-toolbar {
            max-height: rem(45px);
          }

          & .ql-container {
            min-height: rem(100px);
          }
        }

        .dropzone {
          border     : none;
          display    : flex;
          align-items: center;
        }

        .poi-images-box__dropzone {
          min-height: 10rem;
          min-width : 160px;
          display   : none;
        }

        .fine-uploader__upload-input {
          width: 50px;
        }

        .tour-content-video-form__supported-text {
          font-size: 13px !important;
        }

        .tour-content-video-form__supported-type {
          svg {
            width: 1.5rem !important;
          }
        }
      }

      .divisorLateral {
        margin-top : 130px;
        height     : 500px;
        width      : 1px;
        border-left: 1px solid;
      }

      .right {
        .logotipo {
          display        : flex;
          justify-content: center;
          align-items    : flex-start;

          .cuadrado {
            width : 250px;
            height: 180px;
            border: 1px solid black;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: cover;
            }

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 215px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }

          .rectangulo {
            // width : 150px;
            // height: 200px;
            border: 1px solid black;


            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 118px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }
        }
      }
    }
  }
}

.textRedCampaign {
  color: red;
}

.contentText {
  word-wrap: break-word;
}

.boldTextCampaing {
  p {
    font-family: 'Roboto-bold', sans-serif !important;
    font-weight: bold;
  }
}

.galleryCampaign {
  figure {
    margin  : 0;
    padding : 0;
    width   : 100px;
    height  : 100px;
    position: relative;

    .deleteImgGalleryCampaign {
      width           : 20px;
      position        : absolute;
      right           : 4px;
      background-color: white;
      border-radius   : 50%;
      border          : none;
      height          : 20px;
      top             : 2px;

      svg {
        width   : 25px;
        fill    : red;
        // fill: #d34c50;
        right   : 2px;
        position: relative;
      }
    }

    img {
      width     : 100%;
      height    : 100%;
      object-fit: cover;
    }
  }
}

.imageC {
  width     : 100px;
  height    : 100px;
  object-fit: cover;
}

.tour-content-tip-form__photos-item {
  z-index: 3000;
}

.deleteImgGalleryCampaign {
  width           : 20px;
  position        : absolute;
  right           : 4px;
  background-color: white;
  border-radius   : 50%;
  border          : none;
  height          : 20px;
  top             : 2px;

  svg {
    width   : 25px;
    fill    : red;
    right   : 2px;
    position: relative;
  }
}

.dragg {
  position: relative;
}