.poi-position-modal {
  max-width: 90vw;
  height: calc(100vh - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px);
  width: 100%;
  background-color: #fff;
  margin: rem(20px) auto 0 auto;
  padding: rem(20px) 0 rem(10px) 0;
  position: relative;
  z-index: 1000;
  border-top-left-radius: rem(15px);
  border-bottom-right-radius: rem(15px);

  &.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  &__title {
    color: $topcolor-black;
    font-weight: bold;
    margin-bottom: rem(15px);
    text-align: center;
  }

  &__input,
  &__suggestions {
    font-family: 'Oswald';
    text-transform: uppercase;
  }
  &__input {
    position: relative;
  }

  &__suggestions {
    line-height: 1.2;
    font-size: rem(18px);
    border: rem(1px) solid $topcolor-light-black;
    position: absolute;
    top: rem(40px);
    left: rem(15px);
    right: 0;
    z-index: 2;
    background-color: white;
    &-item {
      padding: rem(10px);
      &--active {
        background-color: rgba($topcolor-blue, 1);
        color: white;
      }
      & + .poi-position-modal__suggestions-item {
        border-top: rem(1px) solid $topcolor-light-black;
      }
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($topcolor-black, 0.9);
    z-index: 1010;
  }

  &__map {
    padding: 0;
    .mapWrapper { // this is required
      height: calc(100vh - 260px);
      height: calc((var(--vh, 1vh) * 100) - 260px);
      max-height: 100%;
    }
  }
}

@media screen and (min-width: 992px) {
  .poi-position-modal {
    padding: rem(30px) 0 rem(30px) 0;
    max-width: rem(850px);

    &__map {
      .mapWrapper { // this is required
        height: calc(100vh - 300px);
        height: calc((var(--vh, 1vh) * 100) - 300px);
        max-height: 100%;
      }
    }
  }
}

