.day-content {
  margin: 12.5vw auto 0 auto;
  &__content {
    background-color: #b9b9b9;
    &--no-bg {
      background-color: transparent;
    }
  }
  &__captions {
    margin: 0 0 rem(60px) 0;
    &-item {
      &-text,
      &-icon {
        display: inline-block;
        vertical-align: middle;
      }
      &-icon {
        width: rem(30px);
        height: rem(30px);
        border-radius: 50%;
        background-color: $topcolor-blue;
        margin-right: rem(10px);
        text-align: center;
        svg {
          width: 90%;
          height: auto;
          fill: white;
        }
      }
      &-text {
        font-size: rem(18px);
        font-family: 'Roboto-Regular';
      }
      &--blue,
      &--green {
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: rem(100px);
          height: rem(6px);
          margin-right: rem(10px);
        }
      }
      &--blue {
        &:before {
          background-color: $topcolor-blue;
        }
      }
      &--green {
        &:before {
          background-color: $topcolor-green;
        }
      }
    }
  }
  &__days {
    text-align: center;
    list-style: none;
    margin-top: rem(-50px);
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: $topcolor-black;
    scrollbar-track-color: $topcolor-blue;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      height: rem(5px);
    }
    &::-webkit-scrollbar-thumb {
      background: $topcolor-blue;
    }
    &-item {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: rem(10px);
      &--active {
        .day-content__days-btn {
          width: rem(200px);
          background-color: $topcolor-light-black;
          color: white;
        }
      }

      &--action {
        .day-content__days-btn {
          background-color: $topcolor-light-black;
          color: white;
          width: rem(40px);
          height: rem(40px);
          border-radius: 50%;
          line-height: 1;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          
          &--red {
            background-color: $topcolor-red;
          }

          svg {
            fill: white;
            width: 50%;
            height: auto;
          }
          &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }

      & + .day-content__days-item {
        margin-left: rem(5px);
      }
    }

    &-btn {
      font-family: 'Roboto-bold';
      font-weight: bold;
      color: $topcolor-black;
      width: rem(100px);
      height: rem(40px);
      line-height: 1;
      text-align: center;
      background-color: white;
      border: rem(1px) solid $topcolor-light-black;
      border-radius: rem(10px);
    }

    & .promise-button__bounce {
      width: rem(10px);
      height: rem(10px);
    }
  }

  &__slider {
    overflow: hidden;
    display: flex;
    justify-content: center;
    max-height: rem(180px);
    &-wrapper {
      padding: rem(30px) rem(55px) rem(20px) rem(55px);
      max-width: calc(100% - 250px);
    }
  }

  &__select-wrapper {
    position: absolute;
    bottom: 15px;
    &-menu {
      top: 90%;
    }
  }

  &__list {
    // top: 20px; // this hides the scroll bar, required to allow javascript .scrollBy function
    // padding-bottom: rem(180px); // this hides the scroll bar, required to allow javascript .scrollBy function
    padding: rem(30px) 0 rem(180px) 0; // this hides the scroll bar, required to allow javascript .scrollBy function
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    white-space: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    position: relative;
    &.ongoingDrag {
      .day-content__list-item:before {
        display: none;
      }
    }
    &-item {
      display: inline-block;
      z-index: 10;
      position: relative;
      &--green {
        .day-content__list-item-btn {
          border: rem(4px) solid $topcolor-green;
        }
      }
      &--blue {
        .day-content__list-item-btn {
          border: rem(4px) solid $topcolor-blue;
        }
      }
      & + .day-content__list-item {
        margin-left: rem(10px);
        &:before {
          content: '';
          display: block;
          width: rem(30px);
          height: rem(1px);
          border-bottom: rem(2px) dashed $topcolor-black;
          position: absolute;
          top: 0;
          bottom: 0;
          left: rem(-10px);
          margin: auto;
          z-index: -1;
        }
      }
      &-btn {
        background-color: white;
        padding: rem(10px);
        border-radius: rem(15px);
        border: none;
        &--no-box {
          .day-content__list-item {
            &-img {
              width: auto;
            }
          }
        }
      }
      &-img {
        width: rem(120px);
        height: rem(120px);
        object-fit: cover;
        &--icon {
          object-fit: contain;
          padding: 20%;
        }
      }
      &-balloon {
        width: rem(30px);
        height: rem(30px);
        line-height: rem(30px);
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: rem(5px);
        bottom: rem(5px);
        background-color: $topcolor-blue;
        color: white;
        font-family: 'Roboto-bold';
        font-weight: bold;
        z-index: 2;
        &--icon {
          line-height: unset;
          svg {
            line-height: 1;
            fill: white;
            width: 90%;
            height: auto;
          }
        }
      }
    }
  }
  &__hide-show-btn {
    position: absolute;
    top: rem(-30px);
    left: rem(10px);
    padding: 0;
    background-color: transparent;
    border: none;
    svg {
      width: rem(20px);
      height: auto;
    }
  }
  &__remove-btn {
    position: absolute;
    top: rem(-30px);
    right: rem(10px);
    padding: 0;
    background-color: transparent;
    border: none;
    svg {
      fill: $topcolor-red;
      width: rem(20px);
      height: auto;
    }
  }
  &__back,
  &__forward {
    background-color: transparent;
    border: none;
    border-left: rem(5px) solid $topcolor-black;
    border-bottom: rem(5px) solid $topcolor-black;
    transform: rotate(45deg);
    width: rem(40px);
    height: rem(40px);
    position: absolute;
    bottom: rem(85px);
    margin: auto;
  }

  &__back {
    left: rem(15px);
  }

  &__forward {
    right: rem(15px);
    transform: rotate(-135deg);
  }

  &__action {
    background-color: $topcolor-blue;
    color: white;
    width: rem(100px);
    height: rem(100px);
    border-radius: 50%;
    border: rem(6px) solid white;
    padding: rem(10px);
    margin: rem(10px) 0 rem(55px) 0;
    font-size: rem(60px);
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    svg {
      display: block;
      fill: white;
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
    &--small-icon {
      svg {
        height: 75%;
        width: 75%;
      }
    }
    & + .day-content__action {
      margin-left: rem(15px);
    }
  }
  &__no-visible-layer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(white, 0.5);
    border-radius: rem(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: $topcolor-black;
      stroke: $topcolor-black;
    }
  }
  &__loading-spinner {
    display: block;
    text-align: center;
    &-img {
      width: rem(30px);
      height: auto;
      margin-bottom: rem(10px);
    }
  }
}