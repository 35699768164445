.modal-image-file {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  margin: 0 auto 0 auto;
  padding: 0 0 rem(20px) 0;
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;

  .comments{
    width: 100%;
    
    // .fb-comments{
    //   width: 90% !important;
    //   iframe{
    //     width: 100% !important;
    //   }
    // }
  }
  
  .buttonLike,
  .buttonComment {
    background: transparent;
    width: 50px;
    height: 50px;
    border-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    img {
      width: 90%;
    }
  }

  .likePoi {
    padding: 0 15px;
    margin: 0 15px 0 25px !important;
    flex-direction: column;
    text-align: center;
    border-left: rem(1px) dashed $topcolor-dark-grey;
    border-right: rem(1px) dashed $topcolor-dark-grey;

    @media (max-width: 600px) {
      padding: 0 8px;
      margin: 0 5px 0 10px !important;
    }
  }

  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);

    &-thumb {
      background: $topcolor-blue;
    }
  }

  &.ReactModal__Content--after-open {
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($topcolor-black, 0.9);
    z-index: 1010;
  }

  &__close-btn {
    width: 55px;
    height: 55px;
    position: fixed;
    background-color: transparent;
    border: none;
    right: rem(5px);
    top: rem(5px);
    z-index: 2001;

    svg {
      fill: white;
      width: 55px;
      height: 55px;
    }
  }

  &__header {
    margin: 0 0 rem(30px) 0;
    background-color: $topcolor-light-black;
    padding: rem(15px) 0 rem(50px) 0;
    position: relative;
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 80vh;
    max-height: calc(var(--vh, 1vh) * 80);
    margin: 0 auto;
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Oswald';
    position: absolute;
    bottom: rem(-35px);
    right: 0;
    left: 0;
    text-align: center;
    text-transform: uppercase;

    figure {
      margin: 0 rem(10px);
    }
  }

  &__description {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;
  }

  &__action-btn {
    margin: auto;
    width: calc(50% - 10px);

    &+.modal-image-file__action-btn {
      margin-top: rem(5px);
    }
  }

  &__content {
    max-width: rem(1366px);
    padding: rem(40px) rem(15px);

    &-left {
      section {
        margin-bottom: 15px;
        margin-left: 5px;
        display: flex;
        align-items: center;

        p {
          font-family: 'Oswald';
          margin-bottom: 10px;
          text-align: center;
          font-size: 14px;
        }
      }

      >a svg {
        height: rem(40px);
        margin-right: rem(10px);
        margin-bottom: 1rem;
      }

      .hashtags {
        font-size: 1.25rem;
        font-weight: bold;

        span {
          margin-right: rem(10px);
        }
      }

      .toplinks {
        font-size: 0.8rem;

        a {
          color: initial;
        }

        svg {
          margin: 0 rem(5px) 0 rem(10px);
          width: rem(16px);

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      button {
        margin: 0 rem(5px);
      }
    }

    &-right {
      figcaption {
        font-family: 'Oswald';
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  &__poi-cover,
  &__partner-logo {
    display: block;
    width: 100%;
    height: auto;
  }

  &__poi-cover {
    margin: 0 auto;
  }

  &__poi-link {
    color: $topcolor-black;

    &:hover {
      text-decoration: none;
      color: $topcolor-black;
    }
  }

  &__partner-logo {
    width: 180px;
    height: 130px;
    /* max-width: rem(200px);
    max-height: rem(250px); */
    margin: rem(15px) auto 0 auto;
  }

  &__poi {
    &-title {
      max-height: rem(250px);
      font-family: 'Roboto-bold';
      font-size: 0.8rem;
      margin: 0 auto;

      svg {
        margin-right: rem(10px);
        vertical-align: text-bottom;
        width: rem(20px);
      }
    }
  }

  &__rrss-link {
    display: inline-block;
    text-align: center;
    // margin-bottom: rem(15px);
    cursor: pointer;

    &+.modal-image-file__rrss-link {
      margin-left: rem(3px);
    }

    &:hover {
      circle {
        fill: $topcolor-blue;
      }
    }

    p {
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px;
    }
  }
  .modal_image{
    max-height: calc(var(--vh, 1vh) * 80);
    width: auto !important;
  }
  .carousel{
    .slide{
     background-color: #303030;
    }
    .control-dots{
      display: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .modal-image-file {
    &__action-btn {
      width: auto;
      max-width: rem(280px);
    }
  }
}

.overlayModal {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }