.tour-topbar {
  background-color: $topcolor-light-black;
  position        : fixed;
  left            : 0;
  top             : rem(50px);
  z-index         : 989;

  &__title {
    text-align    : center;
    border-bottom : rem(1px) dotted white;
    font-family   : 'Oswald' !important;
    font-weight   : bold;
    font-size     : rem(18px);
    color         : white;
    max-width     : calc(100% - 15px);
    margin        : 0 auto rem(3px) auto;
    padding-bottom: rem(5px);
    text-overflow : ellipsis;
    white-space   : nowrap;
    overflow      : hidden;
  }

  &__back {
    background: transparent;
    border    : none;
    height    : rem(50px);
    width     : rem(50px);
    padding   : rem(10px) rem(12px) rem(10px) rem(8px);

    svg {
      fill  : white;
      width : 100%;
      height: auto;
    }
  }

  &__filter-btn {
    height          : rem(50px);
    width           : rem(50px);
    background-color: transparent;
    border          : none;
    padding         : rem(3px);

    &--active,
    &:hover {
      background-color: $topcolor-blue;
    }

    svg {
      height: 100%;
      width : auto;
    }
  }

  &__shop-btn,
  &__edit-btn {
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: transparent;
    border          : none;
    padding         : 0;
    height          : rem(50px);
    width           : rem(50px);

    svg {
      fill: white;
    }
  }

  &__shop-btn {
    svg {
      height: rem(35px);
      width : rem(35px);
      fill  : white;
    }
  }

  &__edit-btn {
    svg {
      height: 100%;
      width : 100%;
      fill  : $topcolor-blue;
    }

    &:hover {
      svg {
        fill: $topcolor-light-blue;
      }
    }
  }

  &__days {
    &-text {
      display       : inline-block;
      vertical-align: middle;
      font-family   : 'Oswald';
      text-transform: uppercase;
      color         : white;
      font-size     : rem(14px);
      line-height   : rem(50px);
      margin-right  : rem(5px);
    }

    &-select {
      display       : inline-block;
      vertical-align: middle;
      position      : relative;
      padding       : 0 rem(24px);
    }

    &-btn {
      background-color: transparent;
      border          : none;
      position        : absolute;
      top             : 0;
      bottom          : 0;
      margin          : auto;
      padding         : 0;

      svg {
        width : rem(20px);
        height: rem(20px);
        fill  : white;
      }

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    &-current {
      display         : inline-block;
      height          : rem(35px);
      width           : rem(35px);
      border-radius   : 50%;
      background-color: white;
      font-family     : 'Roboto-bold';
      font-size       : rem(28px);
      font-weight     : bold;
      line-height     : rem(35px);
      text-align      : center;
    }

    &-total {
      position : absolute;
      top      : rem(-7px);
      right    : rem(18px);
      color    : white;
      font-size: rem(11px);
    }

    &+.ml-auto {
      margin-left: 0 !important;
    }
  }

  &__publish {
    background-color: $topcolor-orange;
    height          : rem(50px);
    align-items     : center;

    &-state {
      display       : inline-block;
      color         : white;
      text-transform: uppercase;
      font-family   : 'Oswald';
      font-weight   : 100;
      font-size     : rem(18px);
      vertical-align: middle;
      margin        : 0 rem(10px) 0 0;
    }
  }

  &--big {
    &+* {
      margin-top: rem(100px);
    }

    &+.tour-header,
    &+.recommendations-tabs-bar {
      margin-top: rem(50px);
    }
  }

  .custom-state-select {
    width   : rem(180px);
    position: absolute;
    right   : rem(15px);
    left    : unset;
    z-index : 10;

    &__list {
      display       : flex;
      flex-direction: column;
      height        : rem(37px);

      label {
        color      : white;
        cursor     : pointer;
        line-height: rem(37px);
        text-align : center;
        margin     : 0;
      }
    }

    //hides radio button in the list, only shows the labels
    input {
      position  : absolute;
      visibility: hidden;
    }
  }

  &__state-btn,
  .custom-state-select__list-item {
    &--edition {
      background-color: $topcolor-blue;
      color           : white;
    }

    &--published {
      background-color: $topcolor-orange;
      color           : white;
    }

    &--approved {
      background-color: #28a745;
      color           : white;
    }

    &--hidden {
      background-color: $topcolor-dark-grey;
      color           : white;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-topbar {
    top   : 0;
    height: 56px;

    &__back {
      background: transparent;
      border    : none;
      height    : rem(56px);
      width     : rem(70px);
      padding   : rem(10px) rem(12px) rem(10px) rem(8px);

      svg {
        fill  : white;
        width : 75%;
        height: auto;
      }

      &:hover {
        background-color: $topcolor-blue;
      }
    }

    &__filter-btn {
      height           : rem(56px);
      line-height      : rem(56px);
      width            : auto;
      color            : white;
      font-family      : "Oswald";
      text-transform   : uppercase;
      padding          : 0 rem(15px);
      font-size        : rem(18px);
      border-left-width: rem(1px);

      &+.tour-topbar__filter-btn {
        border-left: rem(1px) dashed white;
      }

      &--active,
      &:hover {
        border-left-color: transparent;
        cursor           : pointer;

        &+.tour-topbar__filter-btn {
          border-left-color: transparent;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    &__edit-btn,
    &__shop-btn {
      height: rem(56px);
      width : rem(70px);
    }

    &__days {
      &-select {
        padding: 0 rem(10px);
      }

      &-text {
        line-height: rem(56px);
        font-size  : rem(18px);
      }

      &-btn {
        position     : initial;
        border       : rem(1px) solid white;
        border-radius: 50%;
        padding      : rem(3px);

        span {
          display      : inline-block;
          color        : white;
          border-radius: 50%;
          width        : rem(30px);
          height       : rem(30px);
          line-height  : rem(30px);
          text-align   : center;
        }

        &--active,
        &:hover {
          span {
            background-color: white;
            color           : $topcolor-black;
          }
        }

        &+.tour-topbar__days-btn {
          margin-left: rem(10px);
        }
      }

      &-separator {
        color      : white;
        display    : inline-block;
        line-height: 1;
        margin     : rem(0 4px);
        font-size  : rem(18px);
      }
    }

    &--big {
      &+* {
        margin-top: rem(120px);
      }
    }
  }
}

/* modal */
.modal-pages {
  /*   position     : absolute; */
  top          : 40px;
  left         : 40px;
  right        : 40px;
  bottom       : 40px;
  border       : 1px solid rgb(204, 204, 204);
  background   : none;
  overflow     : auto;
  border-radius: 4px;
  outline      : none;
  padding      : 20px;
  width        : 100%;
  height       : 100%;

  &__overlay {
    justify-content : center;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529D4
  }

  .contentModal {
    flex-direction: column;

    .nav {
      width: 100%;

      .closeIcon {
        background-color: transparent;
        display         : inline-block;
        border          : none;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          height: rem(40px);
          width : rem(40px);
          fill  : white;
        }
      }
    }

    .contentCenter {
      margin-top    : 40px;
      flex-direction: column;

      h3 {
        font-size    : 26px;
        font-family  : 'Roboto', sans-serif !important;
        font-weight  : 700;
        margin-bottom: 15px;
      }

      p {
        font-size  : 19px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 100;

      }
    }

    .contentBot {
      margin-right: 100px;

      .acceso,
      .registrarme {
        align-items    : center;
        display        : flex;
        justify-content: center;
        margin         : 3px;
        height         : 35px;
        min-width      : 150px;
      }
    }

    .contentAlert {
      display: none;
    }
  }

}