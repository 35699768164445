@media screen and (max-width: 991px) {
  .location-search {
    background-color: $topcolor-light-grey;
    min-height      : calc(100vh - 100px);
    min-height      : calc((var(--vh, 1vh) * 100) - 100px);
  }
}

@media screen and (min-width: 992px) {
  .location-search {
    &__wrapper {
      display   : flex;
      position  : relative;
      min-height: calc(100vh - 80px);
      height    : calc((var(--vh, 1vh) * 100) - 80px);
    }

    .results-card-list {
      justify-content: center;
      margin-right   : 10px;
      height         : auto;
      align-content  : flex-start;
      align-items    : flex-start;
    }
  }
}