.disabled-place-edition {
  height: 100%;
  &__container {
    padding: rem(15px 0);
    min-height: calc(100vh - 50px);
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    max-height: 100%;
    align-content: center;
  }
  &__image {
    display: block;
    width: 90%;
    max-width: rem(400px);
    margin: 0 auto;
  }
  &__title {
    color: #0090BC;
    font-weight: bold;
    font-family: 'Roboto-Regular';
    text-align: center;
    font-size: rem(26px);
    margin-top: rem(30px);
  }
  &__description {
    font-family: 'Roboto-Regular';
    text-align: center;
    font-size: rem(18px);
    margin-top: rem(15px);
    line-height: rem(22px);
  }
  &__footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: rem(15px 0);
    box-shadow: rem(1px) rem(1px) rem(5px) $topcolor-black;
    background-color: white;
    .btn {
      width: rem(180px);
    }
  }
  &--modal {
    min-height: 100%;
    height: auto;
    padding: rem(15px);
    margin-bottom: rem(60px);
    .disabled-place-edition__container {
      max-height: unset;
      padding-bottom: rem(70px);
    }
  }
}