.poi-images-page {
  padding: rem(15px) rem(15px) rem(30px) rem(15px);
  background-color: white;

  &__footer {
    background-color: white;
    padding: rem(20px) rem(15px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 rem(5px) rgba(black, 0.2);
    &-text {
      text-align: center;
      margin: rem(10px) 0 0 0;
      svg {
        height: rem(15px);
        width: auto;
        margin-left: rem(5px);
      }
    }
    .fine-uploader__file-input {
      display: block !important;
    }
    .fine-uploader__files-wrapper {
      padding: 0px;
    }
    .progressPercentageBarWrapper {
      height: rem(38px);
      background-color: rgba($topcolor-blue, 0.6);
    }
    .progressPercentageBarProgress {
      display: block;
      height: 100%;
      background-color: $topcolor-blue;
      color: white;
      padding: 0 rem(10px);
      line-height: rem(38px);
      font-weight: bold;
      font-family: 'Roboto-bold';
    }
    .progressPercentageBarProgress,
    .progressPercentageBarWrapper {
      border-radius: $btn-border-radius;
    }
  }
}

@media screen and (max-width: 991px) {
  .poi-images-page {
    padding-bottom: rem(140px);
  }
}

@media screen and (min-width: 992px) {
  .poi-images-page {
    padding: rem(30px) rem(60px);
    box-shadow: 0 0 3px rgba(black, 0.2);
    border-top-left-radius: rem(15px);
    border-bottom-right-radius: rem(15px);
    margin-bottom: rem(40px);
    &__total-images {
      text-align: right;
      margin: rem(10px) 0 0 0;
      font-weight: bold;
      span {
        font-weight: 100;
        margin: 0 rem(10px);
        font-size: rem(18px);
      }
    }
    &__footer {
      display: none;
    }
    &__desktop-footer {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      justify-content: center;
      background-color: #fff;
      padding: rem(15px) 0;
      box-shadow: 0 0 3px rgba(black, 0.2);
    }
  }
}
