.flex-responsive-form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1 0 auto;
  padding: 0 10px;
  &__label {
    order: 0;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    font-family:'Roboto', sans-serif !important ;
      font-weight: 100;
    font-weight: 500;
    line-height: 1.2;
    font-size: rem(20px);
    & svg {
      height: rem(32px);
      width: rem(32px);
      min-height: rem(32px);
      min-width: rem(32px);
      margin: 0 rem(5px);
    }
  }
  &__label-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__input {
    order: 1;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
    box-sizing: border-box;
    // width: calc(100% - 20px);
    &--rich-text-editor {
      min-height: rem(200px);
      min-width: 100%;
      display: flex;
      flex-direction: column;
      // modifies the quill library rich text editor library styles...
      & .ql-toolbar {
        flex-grow: 1;
        max-height: rem(45px);
      }
      & .ql-container {
        flex-grow: 3;
        min-height: rem(155px);
        display: flex;
        flex-direction: column;
        & .ql-editor {
          flex-grow: 1;
        }
      }
    }
  }
  &__vertical-label-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-grow: 3;
  }
  &--flex-end {
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }
  &--flex-start {
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  &__btn-save-container {
    max-width: rem(200px);
    padding: 0 rem(10px);
  }
  // don't apply the svg rules to rich text editor svgs with this selector
  & > svg {
    height: auto;
    width: rem(64px);
    margin-right: rem(20px);
  }
}

// @media screen and (min-width: 375px) {
//   .flex-responsive-form-row {
//     &__label {
//     }
//     &__input {
//     }
//   }
// }

// @media screen and (min-width: 576px) {
//   .flex-responsive-form-row {
//     &__label {
//     }
//     &__input {
//     }
//   }
// }

@media screen and (min-width: 992px) {
  .flex-responsive-form-row {
    flex-wrap: nowrap;
    padding: 0 20px;
    &__label {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: space-between;
    }

    &__label-container {
      width: unset;
    }
    &__input {
      flex-grow: 3;
      flex-shrink: 3;
      // width: calc(100% - 40px);

      &--rich-text-editor {
        min-width: 100%;
      }
    }
    &__vertical-label-input {
      flex-wrap: nowrap;
    }
    & > svg {
      height: auto;
      width: rem(80px);
    }
  }
}
// @media screen and (min-width: 1200px) {
//   .flex-responsive-form-row {
//     &__label {
//     }
//     &__input {
//     }
//   }
// }
