label.col-form-label {
  padding-bottom: rem(3px);
}

.form-control {
  border-color: transparent;
  font-family: 'Oswald';
  line-height: rem(29px);
  font-size: rem(19px);
  color: $topcolor-black;
}

.btn {
  font-family: 'Roboto-bold';
  font-weight: 500;
}

.alert-primary {
    background-color: $topcolor-light-blue;
    color: white;
    font-family: 'Roboto', sans-serif !important;
}

@media screen and (min-width: 992px) {
  .btn {
    min-width: rem(180px);
  }
}
