.poi-page {
  padding-bottom: rem(25px);
  background-color: $topcolor-grey;
}

@media screen and (min-width: 1200px) {
  .poi-page {
    padding-bottom: rem(75px);
  }
}
@media screen and (min-width: 1200px) {
  .poi-page {
    .container {
      max-width: rem(1045px);
    }
  }
}