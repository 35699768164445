.recommendation-info-box {
  background-color: white;
  padding: rem(5px) rem(15px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  padding-bottom: rem(30px);
  margin-bottom: rem(35px);
  &__header {
    align-items: center;
    padding-top: rem(10px);
    &:after {
      display: block;
      content: '';
      width: calc(100% - 100px);
      margin: 0 rem(30px) rem(7.5px) auto;
      border-bottom: rem(1px) solid $topcolor-black;
      padding-bottom: rem(10px);
    }
    &-title {
      font-size: rem(22px);
      text-align: right;
      margin-bottom: 0;
    }
    &-btn {
      padding: 0;
      background-color: $topcolor-black;
      border: none;
      border-radius: 50%;
      height: rem(40px);
      width: rem(40px);
      line-height: 1;
      
      svg {
        height: 50%;
        width: 50%;
        fill: white;
      }
    }
  }
  
  &__content {
    padding: rem(15px 0 0 0)
  }
  &__title,
  &__address,
  &__website,
  &__phone {
    margin-bottom: 0;
    font-size: rem(18px);
    line-height: 1.25;
  }
  &__title {
    font-family: 'Roboto-bold';
    font-size: rem(22px);
  }
  &__address {
    text-transform: uppercase;
  }
  &__website {
    text-decoration: underline;
  }
  &__phone {
    font-family: 'Roboto-bold';
    font-weight: bold;
    margin-top: rem(10px);
  }
  &__rrss {
    &-list {
      padding-left: 0;
      list-style: none;
      &:before {
        content: '';
        display: block;
        width: 60%;
        min-width: rem(150px);
        max-width: rem(400px);
        border-top: rem(1px) dashed $topcolor-black;
        margin: rem(10px) 0;
      }
    }
    &-item {
      display: inline-block;
      & + .recommendation-info-box__rrss-item {
        margin-left: rem(5px);
      }
    }
    &-link {
      display: inline-block;
      &:hover {
        svg, circle {
          fill: $topcolor-blue;
        }
      }
      svg {
        height: rem(45px);
        width: auto;
      }
    }
  }
  &__tours-counter {
    color: $topcolor-dark-grey;
    svg {
      fill: $topcolor-dark-grey;
      height: rem(20px);
      width: auto;
      margin-right: rem(7px);
    }
    svg,
    &-text,
    &-value {
      display: inline-block;
      vertical-align: middle;
    }
    &-text {

    }
    &-value {
      text-decoration: underline;
      margin-left: rem(5px);
    }
  }
  &__footer {
    &-btn {

    }
  }
}

@media screen and (min-width: 992px) {
  .recommendation-info-box {
    margin: rem(60px) auto 0 auto;
    &__content {
      padding: rem(15px 30px 30px 60px);
      .simple-map {
        height: rem(290px);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .recommendation-info-box {
    &__content {
      .simple-map {
        height: rem(320px);
      }
    }
  }
}