.autocomplete-tag {
  z-index: 2;
  &__selected-tags {

  }
  &__tag {
    display: inline-block;
    color: $topcolor-blue;
    font-family: 'Roboto-bold';
    font-weight: bold;
    padding: 0 0 0 rem(20px);
    font-size: rem(18px);
    margin: rem(5px);
    transition: color 0.25s ease-in, background-color 0.25s ease-in, transform 0.3s ease-out;
    position: relative;
    &:hover {
      .autocomplete-tag__delete-btn {
        display: block;
      }
    }
    &:active {
      transform: scale(0.95);
    }
  }

  &__delete-btn {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    background-color: transparent;
    border: none;
    line-height: 1;
    svg {
      fill: $topcolor-red;
      width: rem(20px);
      height: rem(20px);
    }
  }

  // .react-autosuggest__container {
  //   width: auto;
  //   min-width: rem(200px);
  //   position: relative;
  //   &--open {
  //     position: absolute;
  //   }
  //   .react-autosuggest__suggestions-container {
  //     &--open {
  //       position: relative;
  //       z-index: 1;
  //       min-width: rem(200px);
  //     }
  //   }
  // }
}

@media screen and (max-width: 991px) {
  .autocomplete-tag__delete-btn {
    display: block;
  }
}
