.sticky-wrapper {
  position: relative;
  &--stuck {
    position: fixed;
    z-index: 10;
  }
  &--stuck-top {
    top: 0;
  }
  &--stuck-right {
    right: 0;
  }
  &--stuck-bottom {
    bottom: 0;
  }
  &--stuck-left {
    left: 0;
  }
}
