.tour-config-main__rich-text-editor-ReactQuill {
  margin-bottom: 10px;

  .ql-editor {
    font-size : 14px;
    min-height: 110px;

  }

  .ql-editor.ql-blank::before {
    font-size: 14px;
  }
}

.tour-content-poi-box {

  .seeMoreDescription {
    // font-weight: bold;
    cursor         : pointer;
    text-decoration: underline black;
  }

  .imageCampain {
    margin-top: 0px;
  }

  //design of target loading
  .target {
    height: 300px;

    .contentTourLoading {
      width     : 60%;
      padding   : 20px;
      box-sizing: border-box;

      .itemTour {
        height        : 25px;
        padding-bottom: 5px;

        &.Tittle {
          width: 40%;
        }

        &.Description {
          width: 70%;
        }
      }
    }

    .contentSponsorLoading {
      width: 40%;

      .itemSponsor {
        margin-bottom: 5px;
      }

      .itemSponsorTittle {
        width : 80%;
        height: 25px;
      }

      .itemSponsorImagen {
        width : 70%;
        height: 150px;
      }

      .itemSponsorDesc {
        width : 70%;
        height: 25px;
      }
    }

  }

  background-color: white;
  padding         : rem(5px) rem(15px) rem(15px) rem(15px);
  position        : relative;
  margin-top      : rem(25px);
  margin-bottom   : rem(0px);
  box-shadow      : rem(1px) rem(1px) rem(2px) rgba(black, 0.3);

  .modalMarkerPosition {
    border    : 3px solid rgb(167, 219, 252);
    margin-top: 4px;
  }

  &__order {
    margin-right                 : 24px;
    display                      : inline-block;
    background-color             : #00affe;
    color                        : black;
    font-size                    : 2.125rem;
    font-weight                  : bold;
    /* border-bottom-right-radius: 75%; */
    width                        : 3.75rem;
    height                       : 3.75rem;
    line-height                  : 3.75rem;
    text-align                   : center;
    /* padding-right             : 0.625rem; */
    position                     : relative;
    top                          : 4px;
    left                         : 10px;
    /* z-index                   : 100; */
  }

  .tour-content-poi-box__partner-call {
    text-align    : center;
    margin-bottom : 0;
    font-family   : 'Oswald';
    font-weight   : 100;
    text-transform: uppercase;

    button {
      display: none;
      opacity: 0;
    }

  }

  &__top {
    border-bottom  : rem(1px) dotted $topcolor-black;
    padding-bottom : rem(5px);
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
  }

  &__header {
    align-items: flex-end;
    top        : rem(-25px);
    left       : 0;
    right      : 0;
    margin     : 0;

    &-icon {
      width                  : rem(55px);
      height                 : rem(25px);
      background-color       : white;
      border-top-left-radius : rem(10px);
      border-top-right-radius: rem(10px);
      margin                 : 0 rem(-10px) 0 0;
      padding                : rem(5px) 0 rem(1px) 0;
      text-align             : center;

      svg {
        height        : 100%;
        width         : auto;
        display       : inline-block;
        vertical-align: baseline;
      }
    }

    .textMP {
      color      : #00affe;
      font-weight: 100;
      margin     : 0px;
    }

    .textMP2 {
      display    : flex;
      align-items: flex-end;

      span {
        color          : #00affe;
        text-decoration: underline;
        margin-left    : 5px;
      }
    }

    .arrowRight {
      display         : flex;
      justify-content : center;
      margin-left     : 8px;
      border-radius   : 50%;
      border          : none;
      width           : 30px;
      height          : 30px;
      background-color: #00affe;

      img {
        width: 8px;
      }
    }
  }

  &__owner {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    width      : 100%;
    font-size  : rem(11px);
    margin-top : 2%;
    color      : black;

    &-name {
      margin-right  : 10px;
      display       : inline-block;
      font-family   : 'Roboto', sans-serif !important;
      font-weight   : 100;
      text-transform: capitalize;
      color         : black;
      font-size     : rem(14px);
      margin-left   : rem(5px);
      max-width     : calc(100vw - 190px);
      white-space   : nowrap;
      text-overflow : ellipsis;

      svg {
        display       : inline-block;
        vertical-align: baseline;
        margin-left   : rem(3px);
        height        : rem(14px);
        width         : auto;
        fill          : $topcolor-dark-grey;
      }
    }
  }

  &__image {
    height       : 66.3vw;
    margin-bottom: 0;
    object-fit   : cover;

    // position: relative;
    &-author {
      display         : inline-block;
      max-width       : 75%;
      position        : absolute;
      bottom          : 0;
      right           : 0;
      background-color: white;
      padding         : rem(5px) rem(10px) rem(5px) rem(30px);

      &-avatar {
        position           : absolute;
        bottom             : rem(-10px);
        left               : rem(10px);
        margin-left        : rem(-40px);
        margin-bottom      : 0;
        height             : rem(50px);
        width              : rem(50px);
        border             : rem(2px) solid white;
        background-color   : white;
        border-radius      : 50%;
        background-repeat  : no-repeat;
        background-position: center;
        background-size    : cover;
      }

      &-name {
        font-family   : 'Oswald';
        text-transform: uppercase;
        font-size     : rem(15px);
        white-space   : nowrap;
        text-overflow : ellipsis;
        overflow      : hidden;
        margin-bottom : 0;
      }
    }
  }

  &__title,
  &__address {
    margin-bottom: 0;
    color        : $topcolor-black;
  }

  &__title {
    font-weight: 700;
    font-family: 'Roboto', sans-serif !important;
    font-size  : rem(18px);
    line-height: rem(24px);
    max-height : rem(48px);
    overflow   : hidden;
  }

  &__address {
    font-family   : 'Roboto', sans-serif !important;
    font-weight   : 100;
    text-transform: capitalize;
    font-size     : rem(16px);
    margin        : 0.5%;
  }

  &__info {
    list-style  : none;
    padding-left: 0;
    text-align  : right;
    margin      : 15px 0 0;

    &-item {
      display       : inline-block;
      vertical-align: middle;
      color         : $topcolor-black;
      font-size     : rem(16px);
      line-height   : 1;

      svg {
        display       : inline-block;
        vertical-align: middle;
        line-height   : 1;
        height        : rem(18px);
        width         : auto;
        max-width     : rem(20px);
        fill          : $topcolor-dark-grey;
        margin-right  : rem(5px);
      }

      &+.tour-content-poi-box__info-item {
        margin-left: rem(15px);

      }
    }
  }

  &__description {
    margin     : rem(15px) 0 0 0;
    white-space: pre-line;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100 !important;
    line-height: rem(29px);
    font-size  : rem(19px);
    color      : $topcolor-black;
    overflow   : visible;
  }

  &__partner {
    margin: 0;
    cursor: pointer;

    &-image {
      display  : block;
      max-width: 50%;
      margin   : rem(30px) auto rem(15px) auto;
      width    : 35%;
      max-width: 250px !important;
    }
  }

  &__topright {
    padding   : 0;
    text-align: right;
    max-width : max-content;
  }

  &__topleft {
    -webkit-flex-grow: 1;
  }


  .buttonLike {
    z-index     : 500;
    background  : transparent;
    width       : 30px;
    height      : 30px;
    border-width: 0;
    padding     : 0;
    margin      : 0;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }

  .actionIcon {
    background  : transparent;
    width       : 40px;
    height      : 40px;
    border-width: 0;
    padding     : 0;
    margin      : 0px 10px 0px 0px;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }


  .actionIconRoute {
    height: auto;
  }

  .buttonMenu {
    background  : transparent;
    width       : 40px;
    height      : 22px;
    border-width: 0;
    padding     : 0;
    margin      : 0;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }

  .authorPhoto {
    /* font-family     : 'Roboto',sans-serif !important; */
    /* font-weight     : 100; */
    width           : auto;
    position        : absolute;
    right           : 0%;
    bottom          : 2%;
    padding-right   : 30px;
    background-color: white;
  }

  .buttonAuthor {
    position        : absolute;
    width           : 60px;
    height          : 60px;
    right           : 98%;
    bottom          : -35%;
    background-color: white;
    border-radius   : 50%;
    border-style    : none;
  }

  .contentAuthor {
    position: relative;
  }


  .timePoi {
    background-color: rgb(240, 240, 240);
    height          : auto;
    position        : absolute;
    right           : 0%;
    left            : 0%;
    padding         : 15px;

    h5 {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700;

      span {
        font-weight: 100;
      }
    }
  }

  .carousel {
    .slider-wrapper {
      overflow: visible;
    }

    &.carousel-slider {
      padding-bottom: rem(35px);

      .control-arrow {
        padding         : 0;
        width           : rem(40px);
        height          : rem(40px);
        margin          : auto rem(5px);
        border-radius   : 50%;
        opacity         : 0.3;
        background-color: rgba(white, 0.5);
        display         : flex;
        justify-content : center;
        align-items     : center;
        cursor          : pointer;

        &.control-prev:before,
        &.control-next:before {
          width : rem(15px);
          height: rem(15px);

          border-top   : none;
          border-right : none;
          border-bottom: rem(3px) solid $topcolor-black;
          border-left  : rem(3px) solid $topcolor-black;
        }

        &.control-prev:before {
          margin-left: rem(10px);
          transform  : rotate(50deg) skewY(-15deg);
        }

        &.control-next:before {
          margin-right: rem(10px);
          transform   : rotate(-135deg);
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .control-dots {
      padding-left: 0;

      .dot {
        box-shadow      : none;
        background-color: black;
        width           : rem(6px);
        height          : rem(6px);
        margin          : 0 rem(2px);

        &.selected {
          background-color: $topcolor-black;
        }
      }
    }
  }

  .tour-content-images-box {
    &__img-author {
      display: none;
    }
  }


  .tour-content-images-box,
  .tour-content-map-box,
  .tour-content-text-box,
  .tour-content-time-box,
  .tour-content-video-box {
    padding   : 0;
    box-shadow: none;

    +* {
      margin-top: rem(20px);
    }

    .allTime {
      position: relative;
      width   : 100%;

      div {
        max-width: max-content;
      }
    }
  }

  /* .tour-content-text-box {
    padding-left : rem(10px);
    padding-right: rem(10px);
  } */

  .tour-content-images-box__content {
    padding: 0;
  }


  .dropdown-menu {
    background-color: #F1F1F1;
    padding         : rem(15px) rem(5px);
    margin-top      : 10px;
    border          : 1px solid gray;
    border-radius   : 1px;

    .dropdown-item {
      background-color  : #F1F1F1;
      /*  border-bottom : rem(1px) dotted $topcolor-black; */
      padding-bottom    : rem(10px);
      min-width         : rem(200px);
      font-family       : 'Roboto', sans-serif !important;
      font-weight       : 100;
      color             : #6f6f6f;

      &+.dropdown-item {
        margin-top: rem(5px);
      }

      /* &:hover,
      &--active {
        background-color: lightgray;
      } */
    }

    &:after,
    &:before {
      content : '';
      display : block;
      position: absolute;

      width       : 10px;
      border-right: 8px solid transparent;
      border-left : 8px solid transparent;
    }

    &:before {
      border-bottom: rem(15px) solid #F1F1F1;
      ;
      top    : -14px;
      z-index: 2;
    }

    &:after {
      border-bottom: rem(15px) solid gray;
      top          : -15px;
      z-index      : 1;
    }

    &.dropdown-menu-right {
      right: -12px;

      &:before,
      &:after {
        right: rem(8px);
      }
    }

    &.dropdown-menu-left {
      left: rem(-7px);

      &:before,
      &:after {
        left: rem(40px);
      }
    }
  }

  /* &.dropdown.show {

    .dropdown-toggle,
    .nav-dropdown-toggle:hover {
      background-color: $topcolor-black;
    }
  } */
  .likes {
    padding: 0px !important;
    margin : 0px !important;
  }

  .closeMenu {
    display         : none;
    background-color: transparent;
    border          : none;
    position        : absolute;
    top             : 5px;
    padding-right   : 3px;
    right           : 1px;
    z-index         : 2000;

    svg {
      fill  : gray;
      width : rem(25px);
      height: rem(25px);
    }
  }




}

.ContentModal {
  justify-content : center;
  display         : flex;
  align-items     : center;
  position        : fixed;
  top             : 0px;
  left            : 0px;
  right           : 0px;
  bottom          : 0px;
  background-color: rgba(33, 37, 41, 0.9)
}

.ContentModalVideoCampaign {
  justify-content : center;
  display         : flex;
  align-items     : center;
  position        : fixed;
  top             : 0px;
  left            : 0px;
  right           : 0px;
  bottom          : 0px;
  z-index         : 2001 !important;
  background-color: rgba(33, 37, 41, 0.9)
}

.ReactModal__Overlay--after-open {
  z-index            : 2000;
  /* background-color: #212529D4 !important;
    display          : flex;
    justify-content  : center;
    align-items      : center; */
}

@media screen and (min-width: 992px) {
  .tour-content-poi-box {

    margin-top                       : rem(25px);
    margin-bottom                    : rem(0);
    border-top-left-radius           : rem(10px);
    border-top-right-radius          : rem(10px);
    /*     border-bottom-right-radius: rem(0px); */
    padding                          : rem(20px) rem(35px);

    &__header {
      align-items: center;
      top        : rem(-40px);
      left       : 0;
      right      : 0;
      margin     : 0;

      &-icon {
        display         : inline-block;
        width           : rem(80px);
        height          : rem(40px);
        background-color: white;
        margin-right    : rem(5px);
        padding         : rem(8px) 0 0 0;
        text-align      : center;

        svg {
          height        : 100%;
          width         : auto;
          display       : inline-block;
          vertical-align: baseline;
        }
      }
    }

    &__owner {
      font-size: rem(14px);

      &-name {
        svg {
          height: rem(20px);
        }
      }
    }

    &__category {
      font-size     : 1rem;
      font-family   : 'Roboto', sans-serif !important;
      font-weight   : 100;
      text-transform: capitalize;
      color         : black;
    }

    &__order {
      margin-right                 : 24px;
      display                      : inline-block;
      background-color             : #00affe;
      color                        : black;
      font-size                    : 2.125rem;
      font-weight                  : bold;
      /* border-bottom-right-radius: 75%; */
      width                        : 3.75rem;
      height                       : 3.75rem;
      line-height                  : 3.75rem;
      text-align                   : center;
      /* padding-right             : 0.625rem; */
      position                     : relative;
      top                          : 4px;
      left                         : 10px;
      /* z-index                   : 100; */
    }

    &__image {
      height: rem(604px);
    }

    &__top {
      border-bottom  : rem(1px) dotted $topcolor-black;
      padding-bottom : rem(5px);
      display        : flex;
      flex-direction : column;
      align-items    : center;
      justify-content: space-between;
    }

    &__title {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700;
      font-size  : rem(26px);
      line-height: rem(32px);
      max-height : rem(64px);
    }

    &__info {
      padding-bottom: rem(3px);
      margin        : 0;

      &-item {
        &+.tour-content-poi-box__info-item {
          margin-left: rem(20px);
        }
      }
    }

    &__partner-call {
      font-family   : 'Oswald';
      font-weight   : 100;
      text-transform: uppercase;
      text-align    : center;
      font-size     : rem(14px);
      color         : black;
      margin-bottom : rem(5px);
      position      : relative;

      button {
        display   : block !important;
        opacity   : 1 !important;
        background: initial;
        border    : none;
        padding   : 0;
        position  : absolute;
        left      : 50%;
        bottom    : rem(-14px);
        transform : rotate(-45deg);
        height    : rem(8px);
        width     : rem(8px);

        &:before {
          border-bottom: 2px solid $topcolor-light-black;
          border-left  : 2px solid $topcolor-light-black;
          content      : '';
          display      : block;
          height       : 100%;
          width        : 100%;
        }

        +button {
          top      : rem(-12px);
          bottom   : auto;
          transform: rotate(135deg);
        }
      }
    }

    &__partner-image {
      width: 75%;
    }

    &__partner-description {
      // width: 90%;
      word-break : break-word;
      width      : 250px !important;
      text-align : center;
      color      : black;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100 !important;
      font-size  : rem(15px);
      line-height: 1.2;
      padding    : 0 rem(20px);
    }

    .tour-content-images-box {
      margin-top: rem(20px);
    }

    .tour-content-images-box,
    .tour-content-map-box,
    .tour-content-video-box {
      padding   : 0 rem(15px);
      box-shadow: none;

      +* {
        margin-top: rem(20px);
      }
    }

    .dropdown-menu {
      &.dropdown-menu-right {
        right: rem(3px);

        &:before,
        &:after {
          right: rem(8px);
        }
      }
    }
  }

  .sup {
    padding: 0px !important;
  }
}

.campaing_image {
  height: 100% !important;
}

@media screen and (min-width: 1200px) {
  .tour-content-poi-box {
    &__image {
      height: rem(661px);
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-poi-box {
    &__top {
      flex-direction    : row;
      // justify-content: flex-start !important;
      justify-content   : center;
      align-items       : center;
    }

    .pin {
      justify-content: flex-start !important;
    }

    .likes {
      // display: none;
    }
  }

}

.contentTitlesMap {
  margin-left: 20px !important;

  span {
    margin-right: 5px;
  }

  p {
    margin     : 0px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;

    span {
      margin     : 0px 5px;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100;
    }
  }
}

.modalPartner {
  margin: 0px !important;

  .imagePartnerMobile {
    display: none;
  }
}

.ModalMap {
  z-index       : 2000;
  /* padding-top: 10px; */
  position      : relative;
  border-bottom : none;
  overflow      : auto;
  width         : 1045px !important;

  &::-webkit-scrollbar {
    background-color: #2c2c2c;
    width           : 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #00affe;
  }

  .imagePartner {
    width: 100%;
  }

  .buttonClose {
    position: absolute;
    right   : 5px;
    top     : 5px;
  }

  .alturaImagen {
    height     : inherit;
    padding-top: 36px !important;
    margin     : 0px !important;
  }

  .twoH {
    .buttonActive {
      border-bottom : 5px solid rgb(171, 218, 253);
      padding-bottom: 32px;
    }
  }

  .threeH {
    width: auto;

    /* position: absolute;
    top: 2px;
    right: 8px; */
  }

  .portada {
    height    : 100%;
    width     : 100%;
    object-fit: cover;
  }

  .info {
    margin-bottom: 50px;
    width        : 90%;
  }

  .info-modal {
    margin: 10px;

    h4 {
      margin-right : 5px;
      margin-bottom: 2px;
      font-size    : 18px;
    }

    p {
      margin-bottom: 0px;

    }

  }

  /* @media only screen and (max-width: 600px) {
    z-index: 1000;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;

    .mod-movil {
      display: inline;

      img {
        width: inherit;
        padding: 15px;
        padding-bottom: 20px;
      }

    }

    .actionIcon {
      display: none;
    }

    .head-map-modal {
      border-bottom: dotted black;
      padding-right: 0 !important;
      margin-right: 10px !important;
      padding-bottom: 10px !important;

    }

    .modal-mapa-imagenes {
      display: none;
    }

    .info-modal-movil {
      margin: 10px;
      margin-top: 20px;

      h4 {
        margin-right: 5px;
      }

      p {
        margin-bottom: 0px;

      }

    }
  } */
  background-color: white;
  outline         : none;

  .actionIcon {
    background  : transparent;
    width       : 35px;
    height      : 35px;
    border-width: 0;
    padding     : 0;
    margin      : 0px 10px 0px 0px;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }

  .buttonLike {
    z-index     : 500;
    background  : transparent;
    width       : 30px;
    height      : 30px;
    border-width: 0;
    padding     : 0;
    margin      : 0;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }

  .closeIcon {
    background-color: transparent;
    display         : inline-block;
    border          : none;
    vertical-align  : middle;
    line-height     : 1;

    svg {
      height : rem(28px);
      width  : rem(28px);
      // fill: white;
    }
  }

  .head-map-modal {
    position        : fixed;
    z-index         : 1000;
    background-color: white;
    width           : 1045px !important;
    /* margin-left  : 15px !important;
    margin-right    : 15px !important; */

  }

  .modal-mapa-imagenes {
    height         : 70%;
    /* .pdg0 {
      padding-left : 0px;
      padding-right: 35px;
    } */

    /*     img {
      width: inherit;
      padding-left: 0px;
      margin-right: 11px;

    } */
  }

  .modal-mapa-imagenesPois {
    margin-top     : 70px !important;
    height         : 70%;
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
    justify-content: end;
    align-items    : flex-start;

    .items {
      cursor: pointer;
      width : 24%;
      height: 220px;
      margin: 4px;
      border: 1px solid #8D9EAE;

      img {
        width     : 100%;
        height    : 100%;
        object-fit: cover;
      }
    }
  }


  .MapaImage {
    width       : calc(100% - 280px) !important;
    height      : auto !important;
    flex-grow   : 1;
    padding     : 10px;
    /*     width: 70%; */
    height      : 100%;

    .simple-map {
      height: 100% !important;
    }

    .division2 {
      background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size    : 6px 1px;
      background-repeat  : repeat-x;
      padding-bottom     : 10px;
      margin             : 10px;
    }
  }

  .widthAternative {
    width     : 70% !important;
    height    : 100% !important;
    overflow  : auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background-color: #2c2c2c;
      width           : 0.3125rem;

    }

    &::-webkit-scrollbar-thumb {
      background: #00affe;
    }
  }

  .contentOneTwo {
    display        : flex;
    justify-content: space-between;
    width          : auto;
    flex-grow      : 1;

    .oneH {
      -webkit-flex-grow: 1;
    }
  }

  .mapRight {
    width          : 280px;
    height         : 690px;
    max-width      : 280px;
    flex-basis     : unset;
    padding-top    : 10px !important;
    padding-left   : 5px !important;
    padding-bottom : 10px;
    padding-right  : 5px !important;
    align-items    : center;
    justify-content: flex-start;
    display        : flex;
    flex-direction : column;

    /*     width: 30%;
    height: 100%; */
    div {
      text-align: center;
    }

    p {
      /* height: 30%; */
      margin-top   : 8px;
      margin-bottom: 8px;
      text-align   : start;
      font-size    : 15px;
    }

    .partnerImage {
      width : 250px;
      height: 180px;
    }

    .collage {
      width     : 250px;
      object-fit: cover;
      height    : 400px;
    }

    &__top {
      width: 250px;
    }

    &__bot {
      margin-bottom: 10px;
      width        : 250px;
      /* height    : 100%; */
    }
  }
}

.contentPartner {
  display        : flex;
  height         : 30%;
  justify-content: center;
  align-items    : center;

  .partnerImageMobile {
    height: 80%;
  }
}

@media screen and (max-width: 992px) {
  .ModalMap {
    width : 100%;
    height: 100%;

    .modal-mapa-imagenesPois {
      justify-content: center;
      margin-right   : 10px;
      height         : auto;
      align-content  : flex-start;
      align-items    : flex-start;

      .items {
        width : 23vw;
        height: 23vw;
        margin: 3px;
      }
    }

    .MapaImage {
      width: 100%;
    }

    .info {
      width     : 100%;
      margin-top: 20px;
    }
  }


  .tour-content-poi-box {
    .poiBoxDescriptionContent {
      span {
        margin     : 0.9375rem 0 0 0;
        white-space: pre-line;
        font-family: 'Roboto',
          sans-serif !important;
        font-weight: 400 !important;
        line-height: 1.8125rem;
        font-size  : 1rem;
        color      : #2c2c2c;
        overflow   : visible;
      }
    }

    &__top {
      margin-bottom: 15px !important;
    }

    &__partner {
      display: flex;
      margin : 0px;
      padding: 0px;

      img {
        margin: 0px;
      }
    }



    &__partner-description {
      word-break : break-word;
      width      : 90%;
      color      : black;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 100 !important;
      font-size  : rem(15px);
      line-height: 1.2;
      padding    : 0 rem(20px);
    }

    &__partner-call {
      display       : flex;
      align-items   : center;
      font-family   : 'Oswald';
      font-weight   : 100;
      text-transform: uppercase;
      text-align    : center;
      font-size     : rem(14px);
      color         : black;
      margin-bottom : rem(5px);
      position      : relative;

      button {
        display              : block !important;
        opacity              : 1 !important;
        background           : initial;
        border               : none;
        padding              : 0;
        /*           position: absolute; */
        left                 : 50%;
        bottom               : rem(-14px);
        transform            : rotate(45deg);
        height               : rem(8px);
        width                : rem(8px);

        &:before {
          border-bottom: 2px solid $topcolor-light-black;
          border-left  : 2px solid $topcolor-light-black;
          content      : '';
          display      : block;
          height       : 100%;
          width        : 100%;
        }

        +button {
          top      : rem(-12px);
          bottom   : auto;
          transform: rotate(225deg);
        }
      }
    }
  }

  .modalPartner {
    width : 100% !important;
    height: 100% !important;

    .imagePartner {
      display: none;
    }

    .imagePartnerMobile {
      display: block;

      img {
        width: 100%;
      }
    }

    .alturaImagen {
      padding: 0px !important;
      margin : 0px !important;
      width  : 100%;
    }
  }
}


@media screen and (max-width: 768px) {
  .ModalMap {
    width : 100%;
    height: 100%;

    .modal-mapa-imagenesPois {
      justify-content: center;
      margin-right   : 10px;
      height         : auto;
      align-content  : flex-start;
      align-items    : flex-start;
      margin-top     : 80px !important;

      .items {
        width : 30vw;
        height: 30vw;
        margin: 2px;
      }
    }

    .MapaImage {
      width: 100%;
    }

    .info {
      width     : 100%;
      margin-top: 30px;
    }
  }

  .tour-content-poi-box {



    /* .dropdown{
        bottom: -24px;
      } */
    //design responsive target loading
    .target {
      .contentTourLoading {
        display: none;
      }

      .contentSponsorLoading {
        width             : 90%;
        padding-top       : 10px;
        box-sizing        : border-box;
        // .itemSponsor{
        //   margin-bottom: 5px;
        // }

        // .itemSponsorTittle{
        //   width: 80%;
        //   height: 25px;
        // }
        // .itemSponsorImagen{
        //   width: 70%;
        //   height: 150px;
        // }
        // .itemSponsorDesc{
        //  width: 70%;
        //  height: 25px;
        // }
      }
    }

    .dropdown-menu {
      &.dropdown-menu-right {
        right: 0px;
        left : 2rem;

        &:before,
        &:after {
          right: rem(8px);
        }
      }
    }

    .test {
      width    : 100vw;
      max-width: 100vw;
    }

    .closeMenu {
      display: block;
    }

    &__topleft {
      max-width: 90%;
    }

    &__topright {
      max-width      : 10%;
      display        : flex;
      justify-content: flex-end;
      align-items    : center;
    }

    &__category {
      display: none;
    }

    &__description {
      margin     : rem(15px) 0 0 0;
      white-space: pre-line;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 400 !important;
      line-height: rem(29px);
      font-size  : rem(16px);
      color      : $topcolor-black;
      overflow   : visible;
    }
  }

  .contentTitlesMap {
    display: none;
  }

  .map-box__header {
    justify-content: flex-end !important;
  }

  // .ModalMap {
  //   width : 100%;
  //   height: 100%;

  //   .modal-mapa-imagenesPois {
  //     justify-content: end;
  //     margin-right   : 10px;
  //     height         : auto;
  //     align-content  : flex-start;
  //     align-items    : flex-start;

  //     .items {
  //       width : 30vw;
  //       height: 30vw;
  //       margin: 3px;
  //     }
  //   }

  //   .MapaImage {
  //     width: 100%;
  //   }

  //   .info {
  //     width     : 100%;
  //     margin-top: 20px;
  //   }
  // }

  .head-map-modal {
    position        : fixed;
    z-index         : 1000;
    background-color: white;
    padding-bottom  : 15px;
    padding-top     : 10px;
    margin-left     : 10px !important;
    margin-right    : 10px !important;
    border-bottom   : 1px dashed;

    .twoH {
      display: none !important;
    }
  }

}

@media screen and (min-width: 768px) {
  .ModalMap {
    width : 80%;
    height: 80%;

    .MapaImage {
      width: 100%;
    }

    .modal-mapa-imagenes {
      height: 70%;
    }
  }

  .mapRight {
    display: none;
  }

  .head-map-modal {
    position        : fixed;
    z-index         : 1000;
    background-color: white;
    width           : 96%;
    padding-top     : 10px;
    margin-left     : 15px !important;
    /* margin-right : 15px !important; */
  }
}

@media screen and (min-width: 992px) {
  .ModalMap {
    width        : 80%;
    height       : 90%;
    border-bottom: 10px solid white;

    &.modalPartner {
      width        : 800px !important;
      height       : auto !important;
      border-bottom: none;
    }

    .info {
      margin-top: 40px;
    }

    /* .twoH{
        margin-right: 30%;
      } */
    .threeH {
      width          : 280px !important;
      display        : flex;
      justify-content: flex-end;
      align-items    : flex-start;
    }

    .MapaImage {
      width: 70%;
    }

    .modal-mapa-imagenes {
      margin-top: 62px !important;
      height    : 90%;
    }
  }

  .mapRight {
    display: block;
  }

  .head-map-modal {
    padding-top     : 10px;
    padding-left    : 15px;
    position        : fixed;
    z-index         : 1;
    background-color: white;
    width           : 80%;
    margin          : 0 !important;
    padding-right   : 15px;
    /* width        : 68%;
      margin-left   : 15px !important;
      margin-right  : 15px !important; */
  }

  .contentPartner {
    display: none;
  }
}

@media screen and (min-width: 992px) and (min-height: 800px) {
  .ModalMap {
    width : 80%;
    height: 60%;
  }
}

@media screen and (min-width: 1200px) and (min-height: 800px) {
  .ModalMap {
    width        : 65%;
    height       : 80%;
    border-bottom: none;
  }

  .head-map-modal {
    position        : fixed;
    z-index         : 1000;
    background-color: white;
    width           : 65%;
  }
}

//final
@media screen and (max-width: 992px) {
  .ModalMap {
    width : 100% !important;
    height: 100% !important;

    .head-map-modal {
      width: 96% !important;
    }

    .MapaImage {
      width : 100% !important;
      height: 100% !important;

      &.infoBoxModal {
        height: auto !important;
      }
    }

    .mapRight {
      width    : 100% !important;
      max-width: 100% !important;
      height   : auto;
    }
  }
}