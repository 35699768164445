.register-option {
    border: 1px solid  $topcolor-blue;
    padding-top: 3rem;
    text-align: center;
    img { 
      float: left;
    }
    p {
      color: $topcolor-dark-grey;
      line-height: 1.4;
      text-align: left;
      &:first-of-type {
        color: $topcolor-light-black;
        margin-bottom: 0;
      }
    }
  }
.not-allowed {
  opacity: .2;
  pointer-events: none;
}
.price span {
  color: $topcolor-blue;
}


@media screen and (min-width: 768px) {
    .register-option {
      border: 0;
      padding: 0 3rem;
      &.b-right {
        border-right: 1px dashed $topcolor-black;
      }
      p { font-size: 1.1rem; }
      img { float: none; }
    }
    .price {
      padding-left: rem(50px);
      span {
          display: block;
          padding-left: rem(100px);
      }
    }
  }