.tour-file {

  background-color: $topcolor-grey;
  padding         : rem(100px) 0 rem(75px) 0;
  min-height      : 100vh;
  min-height      : calc(var(--vh, 1vh) * 100);
  margin-top      : 0;

  &__no-content-wrapper {
    text-align: center;
    margin-top: rem(60px);
  }

  .loadingScroll {
    width: 200px;
  }

  .textHeader {
    background-color: #a7d5ff;
    margin-bottom   : 10px;
    border-bottom   : 1px solid white;

    div {
      width : 70%;
      margin: 25px 0px 20px;

      h3 {
        color      : white;
        font-weight: 400;
      }
    }
  }


}

@media screen and (min-width: 992px) {
  .tour-file {
    padding-top: rem(56px);
  }
}

@media screen and (min-width: 1200px) {
  .tour-file {
    .container {
      max-width: rem(1045px);
    }
  }
}
/* #appWrapper {
  color: white;
} */