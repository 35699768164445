.promise-button {
  position: relative;
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(24px);
    &--primary {
      .promise-button__bounce {
        background-color: $topcolor-blue;
      }
    }
  }
  &__bounce {
    width: rem(20px);
    height: rem(20px);
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    &--first {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &--second {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}