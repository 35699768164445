/** COLORS **/
$dark: $topcolor-light-black;
$blue: $topcolor-blue;
$red: $topcolor-red;
$orange: $topcolor-orange;
$secondary: $secondary-blue;

$font-family-base: 'Oswald';
// $font-weight-base: 500;
/** FORMS **/
$input-border-radius: 0;
$input-bg: rgba($topcolor-light-blue, 0.3);
// $input-border-color: transparent;
// $input-focus-border-color: transparent;

$container-max-widths: (
  sm: 960px,
  md: 960px,
  lg: 960px,
  xl: 1140px
);


/** Buttons **/

$btn-border-radius: rem(10px);

/** NAVBAR **/
$navbar-nav-link-padding-x: rem(26px);
$navbar-dark-color: white;
