.tour-content-video-form {
  &__supported-text {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(18px);
    margin: 0 rem(10px) 0 0;
  }
  &__supported-type {
    display: inline-block;
    vertical-align: middle;
    svg {
      width: rem(40px);
      height: auto;
      margin-right: rem(5px);
    }
    & + .tour-content-video-form__supported-type {
      margin-left: rem(10px);
    }
  }
  &__label {
    text-align: center;
    font-family: 'Roboto-bold';
    font-weight: bold;
    line-height: 1.2;
    font-size: rem(18px);
    margin-bottom: rem(10px);
  }
  &__text-editor {
    min-height: rem(150px);
    min-width: 100%;
    // modifies the quill library rich text editor library styles...
    & .ql-toolbar {
      max-height: rem(45px);
    }
    & .ql-container {
      min-height: rem(100px);
    }
  }
}