.cookie-fixed-banner{
  background-color: rgba(44, 44, 44, 0.9);  
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-top: 1px double white;
  font-size: rem(16px);
  &__wrapper{
    position: relative;
    padding: rem(30px 10px 20px 10px);
  }
  &__text{
    color: white;
    text-align: center;
    margin: 0;
    font-family: 'Roboto-Regular';
  }
  &__close-btn{
    background: none;
    border: none;
    padding: 0;
    line-height: 1;
    top: rem(3px);
    right: rem(3px);
    position: absolute;
    svg{
      fill: white;
      width: rem(28px);
      height: rem(28px);
    }
  }
}

@media screen and (min-width: 992px) {
  .cookie-fixed-banner{
    font-size: rem(18px);
  }
}