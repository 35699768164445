.menu-tour-content-modal {
  width: 100%;
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: rem(50px);
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010;
  }
  &__header {
    background-color: $topcolor-black;
    margin-bottom: rem(20px);
    height: rem(50px);
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    &-actions {
      position: absolute;
      right: rem(20px);
      top: rem(5px);
      bottom: 0;
      margin: auto 0;
      width: auto;
      padding: 0;
    }
    &-btn {
      padding: 0;
      border: none;
      border-radius: 50%;
      height: rem(40px);
      width: rem(40px);
      line-height: 1;
      background-color: $topcolor-black;
      border: rem(2px) solid $topcolor-blue;
      vertical-align: middle;
      svg {
        height: 60%;
        width: 60%;
        fill: white;
      }
    }
  }
  &__content {
    padding: rem(15px);
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $topcolor-black;
    scrollbar-track-color: $topcolor-blue;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      background-color: $topcolor-black;
      width: rem(5px);
      &-thumb {
        background: $topcolor-blue;
      }
    }
  }
  &__list {
    list-style: none;
    padding: rem( 0 0 30px 0);
  }
  &__option {
    &-icon {
      svg {
        width: rem(50px);
        height: auto;
      }
    }
    &-title,
    &-btn {
      display: inline-block;
      vertical-align: middle;
    }
    &-title {
      font-size: rem(20px);
      font-family: 'Roboto-bold';
      font-weight: 500;
      max-width: calc(100% - 50px);
      margin-bottom: 0;
      color: $topcolor-black;
    }
    &-description {
      line-height: 1.2;
      color: $topcolor-black;
      margin-top: rem(10px);
    }
    &-btn {
      background-color: $topcolor-blue;
      width: rem(35px);
      height: rem(35px);
      border-radius: 50%;
      border: none;
      padding: 0;
      line-height: 1;
      margin-left: rem(10px);
      svg {
        height: 50%;
        width: auto;
        fill: white;
      }
      &:disabled {
        background-color: $topcolor-grey;
        cursor: not-allowed;
      }
      &:hover,
      &:focus {
        background-color: $topcolor-dark-blue;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .menu-tour-content-modal {
    width: 75vw;
    z-index: 1000;
    margin: auto;
    max-height: rem(565px);
    min-height: auto;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    &__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($topcolor-black, 0.9);
      z-index: 1010;
    }
    &__content {
      padding: rem(25px);
    }
    &__list {
      padding-bottom: 0;
      margin-bottom: 0;
      height: 100%;
      align-items: center;
    }
    &__option {
      &-title {
        font-size: rem(22px);
      }
      &-icon {
        svg {
          width: rem(65px);
          height: auto;
        }
      }
      &-btn {
        background-color: $topcolor-blue;
        width: rem(40px);
        height: rem(40px);
      }
    }
  }
}