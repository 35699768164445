.error-page {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 rem(15px);
  &__main-image {
    width: 100%;
    height: auto;
    &-wrapper {
      width: 100%;
      max-width: rem(500px);
      margin: 0 auto;
    }
  }
  &__wire-image {
    display: none;
    margin-bottom: 0;
  }
  &__title,
  &__description {
    margin-bottom: 0;
  }
  &__title {
    font-family: 'Roboto-Regular';
    font-weight: bold;
    color: $topcolor-blue;
    font-size: rem(64px);
    text-align: center;
  }
  &__description {
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(18px);
    &--primary {
      color: $topcolor-blue;
    }
    &--grey {
      color: $topcolor-dark-grey;
    }
  }
  &__link {
    display: inline-block;
    margin-top: rem(25px);
    font-size: rem(16px);
    color: $topcolor-black;
  }
}

@media screen and (min-width: 768px) {
  .error-page {
    &__title {
      font-size: rem(90px);
    }
    &__description {
      font-size: rem(22px);
    }
    &__wire-image {
      display: block;
      width: 100%;
      max-width: 30vw;
      min-width: rem(150px);
      height: auto;
      &--left {
        margin-left: auto;
      }
      &--right {
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .error-page {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}