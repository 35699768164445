.tour-content-tip-box {
  background-color: white;
  padding: rem(5px) rem(15px) 0 rem(15px);
  position: relative;
  margin-top: rem(50px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);

  &__header {
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: rem(-25px);
    left: 0;
    right: 0;
    margin: 0;

    &-icon {
      width: rem(55px);
      height: rem(25px);
      background-color: white;
      border-top-left-radius: rem(10px);
      border-top-right-radius: rem(10px);
      margin: 0 rem(-10px) 0 0;
      padding: rem(5px) 0 0 0;
      text-align: center;

      svg {
        height: auto;
        width: rem(25px);
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }

  &__content {
    padding: rem(15px) 0;
  }

  &__link {
    &:hover {
      svg {
        fill: $topcolor-dark-blue;
      }
    }

    svg {
      width: rem(40px);
      height: auto;
      fill: $topcolor-blue;
    }
  }

  &__slide-image {
    height: 66.3vw;
    object-fit: cover;
  }

  &__title {
    margin-bottom: 0;
    color: $topcolor-black;
    font-family: 'Roboto-bold';
    font-weight: bold;
    font-size: rem(20px);
    line-height: rem(24px);
    max-height: rem(48px);
    overflow: hidden;
  }

  &__text {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    white-space: pre-line;
    line-height: rem(29px);
    font-size: rem(19px);
    color: $topcolor-black;
    overflow: visible;
    margin-top: rem(15px);

    strong {
      color: $topcolor-blue;
      font-weight: 100;
    }

    a {
      text-decoration: underline;
    }

    p {
      margin-bottom: 0;
    }
  }

  .carousel {
    .slider-wrapper {
      overflow: visible;
    }

    .slide {
      background-color: transparent;
    }

    &.carousel-slider {
      padding-bottom: rem(35px);

      .control-arrow {
        padding: 0;
        width: rem(40px);
        height: rem(40px);
        margin: auto rem(5px);
        border-radius: 50%;
        opacity: 0.3;
        background-color: rgba(white, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &.control-prev:before,
        &.control-next:before {
          width: rem(15px);
          height: rem(15px);

          border-top: none;
          border-right: none;
          border-bottom: rem(3px) solid $topcolor-black;
          border-left: rem(3px) solid $topcolor-black;
        }

        &.control-prev:before {
          margin-left: rem(10px);
          transform: rotate(50deg) skewY(-15deg);
        }

        &.control-next:before {
          margin-right: rem(10px);
          transform: rotate(-135deg);
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .control-dots {
      padding-left: 0;

      .dot {
        box-shadow: none;
        background-color: $topcolor-dark-grey;
        width: rem(6px);
        height: rem(6px);
        margin: 0 rem(2px);

        &.selected {
          background-color: $topcolor-black;
        }
      }
    }
  }

  .simple-map {
    height: 66.3vw;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .tour-content-tip-box {
    margin-top: rem(85px);
    border-top-left-radius: rem(10px);
    border-bottom-right-radius: rem(10px);
    padding: rem(20px) rem(30px) rem(10px) rem(30px);

    &__header {
      align-items: center;
      position: absolute;
      top: rem(-40px);
      left: 0;
      right: 0;
      margin: 0;

      &-icon {
        display: inline-block;
        width: rem(80px);
        height: rem(40px);
        background-color: white;
        margin-right: rem(5px);
        padding: rem(8px) 0 0 0;
        text-align: center;

        svg {
          height: 100%;
          width: auto;
          display: inline-block;
          vertical-align: baseline;
        }
      }
    }

    &__category {
      font-size: rem(14px);
      font-family: 'Oswald';
      text-transform: uppercase;
      color: $topcolor-dark-grey;
    }

    &__content {
      padding-bottom: 0;
    }

    &__link {
      svg {
        width: rem(50px);
        height: auto;
      }
    }

    &__image {
      height: rem(604px);
    }

    &__title {
      font-size: rem(28px);
      font-weight: 500;
      line-height: rem(32px);
      max-height: rem(64px);
      margin-bottom: rem(10px);
    }

    &__text {
      margin: 0;
    }

    .simple-map,
    &__slide-image {
      height: rem(400px);
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-tip-box {

    .simple-map,
    &__slide-image {
      height: rem(450px);
    }
  }
}