.modal-preview-content {
  &::-webkit-scrollbar {
    background-color: #2c2c2c;
    width           : 0.3125rem;
  }

  .enhorabuena_text {
    color: rgb(90, 90, 90);
  }

  &::-webkit-scrollbar-thumb {
    background: #00affe;
  }

  top       : 40px;
  left      : 40px;
  right     : 40px;
  bottom    : 40px;
  border    : 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow  : auto;
  outline   : none;
  padding   : 10px 10px 20px 10px;
  width     : 40%;
  height    : 100%;

  &__overlay {
    justify-content : flex-end;
    display         : flex;
    align-items     : center;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    background-color: #212529D4
  }

  .contentModal {
    overflow-x    : hidden;
    overflow-y    : hidden;
    flex-direction: column;

    .nav {
      width: 100%;

      .closeIcon {
        background-color: transparent;
        display         : inline-block;
        border          : none;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          /* height: rem(24px);
          width : rem(24px); */
          width : 40px;
          height: 40px;
          fill  : black;
        }
      }

      .checkIcon {
        background-color: transparent;
        display         : inline-block;
        border          : none;
        vertical-align  : middle;
        line-height     : 1;
        background-color: #00affe;
        border-radius   : 50%;
        width           : 40px;
        height          : 40px;
        margin-right    : 10px;

        svg {
          height: rem(24px);
          width : rem(24px);
          fill  : white;
        }
      }

      .opacity {
        opacity: 0.5;
      }
    }

    .contentCenter {
      flex-direction: column;

      h3 {
        font-family           : 'Roboto', sans-serif !important;
        font-weight           : 700;
        font-size             : 1.625rem;
        line-height           : 2rem;
        /*         max-height : 4rem; */
      }

      p {
        font-size  : 19px;
        font-family: 'Roboto', sans-serif !important;
        font-weight: 100;

      }
    }

    .contentBot {
      margin-right: 100px;

      .acceso,
      .registrarme {
        align-items    : center;
        display        : flex;
        justify-content: center;
        margin         : 3px;
        height         : 35px;
        min-width      : 150px;
      }
    }

  }

  .buttonLike {
    z-index     : 500;
    background  : transparent;
    width       : 30px;
    height      : 30px;
    border-width: 0;
    padding     : 0;
    margin      : 0;
    text-align  : center;
    cursor      : pointer;

    img {
      width: 80%;
    }
  }

  .carousel {
    .slide {
      height: 100%;

      img {
        height: 100%;
      }
    }

    .slider-wrapper {
      overflow: visible;
      /* .selected{
      height  : 100%;
      } */
    }

    &.carousel-slider {
      padding-bottom: rem(35px);

      .control-arrow {
        padding         : 0;
        width           : rem(40px);
        height          : rem(40px);
        margin          : auto rem(5px);
        border-radius   : 50%;
        opacity         : 1;
        background-color: rgba(white, 0.5);
        display         : flex;
        justify-content : center;
        align-items     : center;
        cursor          : pointer;

        &.control-prev:before,
        &.control-next:before {
          width : rem(15px);
          height: rem(15px);

          border-top   : none;
          border-right : none;
          border-bottom: rem(3px) solid $topcolor-black;
          border-left  : rem(3px) solid $topcolor-black;
        }

        &.control-prev:before {
          margin-left: rem(10px);
          transform  : rotate(50deg) skewY(-15deg);
        }

        &.control-next:before {
          margin-right: rem(10px);
          transform   : rotate(-135deg);
        }

        /*         &:hover {
          opacity: 1;
        } */
      }
    }

    .control-dots {
      padding-left: 0;

      .dot {
        box-shadow      : none;
        background-color: black;
        width           : rem(6px);
        height          : rem(6px);
        margin          : 0 rem(2px);

        &.selected {
          background-color: $topcolor-black;
        }
      }
    }
  }

  &__info {
    list-style  : none;
    padding-left: 0;
    text-align  : left;
    margin      : 15px 0 0;

    &-item {
      display       : inline-block;
      vertical-align: middle;
      color         : $topcolor-black;
      font-size     : rem(16px);
      line-height   : 1;

      svg {
        display       : inline-block;
        vertical-align: middle;
        line-height   : 1;
        height        : rem(18px);
        width         : auto;
        max-width     : rem(20px);
        fill          : $topcolor-dark-grey;
        margin-right  : rem(5px);
      }

      &+.modal-preview-content__info-item {
        margin-left: rem(15px);

      }
    }
  }

  &__description {
    width      : 60%;
    margin     : .9375rem 0 0;
    white-space: pre-line;
    word-wrap  : break-word;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    line-height: 22px;
    font-size  : 16px !important;
    color      : $topcolor-black;
    overflow   : visible;

    p {
      margin     : 0px;
      white-space: pre-line;
      word-wrap  : break-word;
      font-family: 'Roboto',
        sans-serif !important;
      font-weight: 100;
      line-height: 22px;
      font-size  : 16px !important;
      color      : $topcolor-black;
      overflow   : visible;
    }
  }

  &__partnerContainer {
    width: 50%;
  }

  &__partner {
    /* width: 40%; */
    margin: rem(5px) 0;

    &-image {
      display: block;
      width  : 180px;
      height : 130px;
      margin : rem(15px) auto rem(15px) auto;
    }
  }

  &__header {
    align-items: center;
    top        : rem(-40px);
    left       : 0;
    right      : 0;
    margin     : 0;

    &-icon {
      display         : inline-block;
      width           : rem(80px);
      height          : rem(40px);
      background-color: white;
      margin-right    : rem(5px);
      padding         : rem(8px) 0 0 0;
      text-align      : center;

      svg {
        height        : 100%;
        width         : auto;
        display       : inline-block;
        vertical-align: baseline;
      }
    }
  }

  &__owner {
    font-size: rem(14px) !important;

    &-name {
      svg {
        height: rem(20px);
      }
    }
  }

  &__owner {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 100;
    width      : 100%;
    font-size  : rem(11px);
    margin-top : 2%;
    color      : black;

    &-name {
      margin-right  : 10px;
      display       : inline-block;
      font-family   : 'Roboto', sans-serif !important;
      font-weight   : 100;
      text-transform: capitalize;
      color         : black;
      font-size     : rem(14px);
      margin-left   : rem(5px);
      max-width     : calc(100vw - 190px);
      white-space   : nowrap;
      text-overflow : ellipsis;

      svg {
        display       : inline-block;
        vertical-align: baseline;
        margin-left   : rem(3px);
        height        : rem(14px);
        width         : auto;
        fill          : $topcolor-dark-grey;
      }
    }
  }

  .botLeft {
    flex-wrap: wrap;
    display  : flex;
    width    : 50%;

    /* height: fit-content; */
    .father {
      grid-gap             : 2px;
      display              : grid !important;
      flex-wrap            : wrap;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows   : repeat(3, 1fr);
      height               : calc(100% - 26px) !important;

      figure {
        /* width: 100%; */
      }

      .closeIcon {
        width           : 20px;
        position        : absolute;
        right           : 5px;
        background-color: white;
        border-radius   : 50%;
        border          : none;
        height          : 20px;
        top             : 1px;

        svg {
          width   : 25px;
          fill    : #d34c50;
          right   : 2px;
          position: relative;
        }
      }
    }

    h6 {
      width      : 100%;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700;
    }

    .previewImages {
      /* margin: 2px; */
      width     : 100%;
      height    : 100%;
      object-fit: cover;
    }

    .sinCargar {
      font-weight: 100 !important;
      font-size  : 15px;
      margin     : 1.5rem !important;

      span {
        color          : #00affe;
        text-decoration: underline;
      }
    }
  }

  .botRight {
    width: 50%;
  }

  .MapaImage {
    width        : auto !important;
    flex-grow    : 1;
    padding      : 0px 10px;
    /*     width : 70%; */
    /*     height: 100%; */
    height       : 300px;

    .simple-map {
      height: 100% !important;
    }
  }

  .division {
    background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size    : 6px 1px;
    background-repeat  : repeat-x;
    padding-top        : 10px;

    .boton {
      margin: 10px 10px 20px 10px;
    }

    .dropzone {
      height: 40px;
      border: none;

      .poi-images-box__dropzone {
        padding-top: 10px;
        min-height : 40px;

        p {
          font-size: 15px;
        }
      }

      .fine-uploader__files-wrapper {
        position        : fixed;
        z-index         : 1;
        background-color: white;
        padding-top     : 0px;
        width           : 220px;
        justify-content : center;
        height          : 150px;

        .thumbnails-wrapper__box {
          position: relative;
          right   : 250px;
          z-index : 8000;
          position: relative;
          width   : 182px;
          top     : -150px;
        }

        .react-fine-uploader-file-input-container {
          display: none !important;
        }
      }
    }

    .modal-warning__cross-icon {
      svg {
        padding-top: 5px;
        width      : 35px;
        height     : 35px;
      }
    }

    .modal-warning__footer {
      width: 60%;

      p {
        font-size: 15px;
      }
    }

    .verificando {
      width: 220px;
    }

    h3 {
      color: #00affe;
    }
  }

  .information_text {
    text-align : center;
    font-weight: 700 !important;
    margin-top : 25px;
  }

}

/* react-fine-uploader-file-input-container fine-uploader__file-input */
@media screen and (min-width: 992px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 212px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 250px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 282px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 320px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1800px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 358px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 2100px) and (min-height: 100px) {
  .modal-preview-content {
    .carousel {
      .slide {
        img {
          height: 480px !important;
        }
      }
    }
  }
}