.tour-config-modal {
  margin-top                : rem(50px);
  padding                   : 0;
  width                     : 100%;
  background-color          : white;
  min-height                : calc(100vh - 100px);
  min-height                : calc((var(--vh, 1vh) * 100) - 100px);
  max-height                : calc(100vh - 100px);
  max-height                : calc((var(--vh, 1vh) * 100) - 100px);
  overflow-y                : auto;
  scrollbar-width           : thin;
  scrollbar-color           : $topcolor-black;
  scrollbar-track-color     : $topcolor-blue;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width           : rem(5px);
  }

  &::-webkit-scrollbar-thumb {
    background: $topcolor-blue;
  }

  &__overlay {
    position: absolute;
    top     : 0;
    left    : 0;
    right   : 0;
    z-index : 1000 !important;
  }

  &__content {
    padding-top: rem(15px);
  }

  .editCampaignButton {
    height          : 22px;
    width           : 22px;
    padding         : 0;
    background-color: #00affe;
    border-radius   : 50%;
    border          : none;
    line-height     : 0;
    margin-left     : 5px;

    svg {
      width: 12px;
      fill : white;
    }
  }

  .externalCampaign {
    color      : #212529;
    font-weight: 400;
    font-size  : 16px;
  }

  .division2 {
    background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size    : 6px 1px;
    background-repeat  : repeat-x;
    padding-bottom     : 10px;
    margin             : 10px;
  }

  .cursor {
    cursor: pointer;
  }

  .checkIcon {
    height          : 2.5rem;
    width           : 2.5rem;
    background-color: #00affe;
    display         : inline-block;
    border          : none;
    border-radius   : 50%;
    vertical-align  : middle;
    line-height     : 1;
    margin-right    : 5px;

    svg {
      width : 60%;
      height: 60%;
      fill  : white;
    }
  }

  .closeIcon {
    height          : 2.5rem;
    width           : 2.5rem;
    background-color: black;
    display         : inline-block;
    border          : none;
    border-radius   : 50%;
    vertical-align  : middle;
    line-height     : 1;

    svg {
      width : 60%;
      height: 60%;
      fill  : white;
    }
  }

  .dropzone {
    border     : none;
    display    : flex;
    align-items: center;
  }

  .logotipo {
    display        : flex;
    justify-content: center;
    align-items    : flex-start;
  }

  .cuadrado {
    width : 250px;
    height: 230px;
    border: 1px solid black;

    .dropzone {
      border         : none;
      display        : flex;
      align-items    : center;
      justify-content: center;
      flex-direction : column;
    }

    .poi-images-box__dropzone {
      min-width : 150px;
      min-height: 175px;
    }

    .fine-uploader__upload-input {
      display: block !important;
      width  : 50px;
    }

    img {
      width     : 100%;
      height    : 100%;
      object-fit: cover;
    }
  }

  .rectangulo {
    width : 250px;
    height: 400px;
    border: 1px solid black;

    .dropzone {
      border     : none;
      display    : block;
      align-items: center;
    }

    .poi-images-box__dropzone {
      min-width : 200px;
      min-height: 175px;
    }

    .fine-uploader__upload-input {
      display: block !important;
      width  : 50px;
    }
  }

  .poi-images-box__dropzone {
    min-height: 10rem;
    min-width : 160px;
  }

  .fine-uploader__upload-input {
    width: 50px;
  }

  .textBlue {
    color: #00affe;
  }

  .left__category-item {
    list-style      : none;
    background-color: white;
  }

  .fixedObject {
    position        : fixed;
    top             : 0;
    z-index         : 1;
    background-color: white;
    height          : 60px;
    padding-right   : 10px;
  }
}

@media screen and (min-width: 992px) {
  .tour-config-modal {
    width              : 50vw;
    transition-duration: 0.5s;
    z-index            : 1000;
    transform          : translate(100vh, 0);
    transform          : translate(calc(var(--vh, 1vh) * 100), 0);
    margin             : 0 0 0 auto;
    padding            : rem(70px) 0 0 0;
    height             : 100vh;
    max-height         : unset;
    height             : calc((var(--vh, 1vh) * 100));
    position           : relative;
    overflow-y         : unset;

    &.ReactModal__Content--after-open {
      transform: translate(0, 0);
    }

    &__overlay {
      position        : fixed;
      left            : 0;
      top             : 0;
      right           : 0;
      bottom          : 0;
      background-color: rgba($topcolor-black, 0.9);
      z-index         : 1010 !important;
    }

    &__header {
      margin       : 0 rem(15px);
      padding      : rem(15px) 0;
      height       : auto;
      align-items  : center;
      text-align   : right;
      border-bottom: rem(1px) solid $topcolor-black;
      position     : absolute;
      top          : 0;
      left         : 0;
      right        : 0;
      z-index      : 1;

      &-title {
        display       : inline-block;
        vertical-align: middle;
        font-size     : rem(22px);
        margin        : 0 rem(10px) 0 0;
      }

      &-actions {
        padding: 0;
      }

      &-btn {
        padding         : 0;
        border          : none;
        border-radius   : 50%;
        height          : rem(40px);
        width           : rem(40px);
        line-height     : 1;
        background-color: $topcolor-black;
        vertical-align  : middle;

        svg {
          height: 60%;
          width : 60%;
          fill  : white;
        }

        &--blue {
          background-color: $topcolor-blue;
        }

        &+.tour-config-modal__header-btn {
          margin-left: rem(5px);
        }

        & .promise-button__bounce {
          width : rem(10px);
          height: rem(10px);
        }
      }
    }

    &__content {
      height                    : 100%;
      overflow-y                : auto;
      scrollbar-width           : thin;
      scrollbar-color           : $topcolor-black;
      scrollbar-track-color     : $topcolor-blue;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        background-color: $topcolor-black;
        width           : rem(5px);
      }

      &::-webkit-scrollbar-thumb {
        background: $topcolor-blue;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .profile-page {
    &__grey-bg--desktop {
      background-color: $topcolor-grey;
      position        : relative;

      &:after {
        content         : "";
        background-color: inherit;
        position        : fixed;
        width           : 100%;
        height          : 100%;
        top             : 0;
        left            : 0;
        z-index         : -1;
      }
    }

    &__top {
      &--public {
        margin-top: 0;
      }
    }

    &__top-traveler-wrapper {
      margin-top: rem(-20px);
    }

    &__social-list {
      text-align: left;
    }

    &__header {
      margin-bottom: rem(-70px);
      z-index      : 0;
      height       : auto;
      padding-top  : 0;
      overflow     : hidden;
      position     : relative;

      svg {
        display   : block;
        min-height: rem(200px);
        position  : absolute;
        bottom    : 0;
        left      : 0;
        width     : 100%;
        z-index   : 1;
      }
    }

    &__main-image {
      position: relative;
      left    : 0;
      width   : 100%;
      top     : 0;
      height  : 100%;
    }

    &__user {
      margin-top  : 0;
      border-right: 1px dashed $topcolor-light-black;
      text-align  : right;
      min-height  : rem(100px);

      &-name {
        position    : relative;
        display     : inline-block;
        padding-left: rem(50px);
        line-height : rem(40px);
        margin-top  : 0;
        text-align  : right;
        font-size   : rem(28px);
      }

      &-nickname {
        margin-top: 0;
        text-align: right;
        font-size : rem(20px);
      }

      &-logo {
        position: relative;

        svg {
          width : auto;
          height: rem(60px);
          left  : unset;
          right : 0;

          .text {
            transform: none;
          }
        }
      }
    }

    &__avatar {
      border: rem(3px) solid black;

      &-wrapper {
        top: rem(-90px);
      }
    }

    &__angel-btn {
      margin-right: rem(10px);
      left        : -52px;
      bottom      : 0;
      top         : 0;

      svg {
        position: relative;
      }
    }

    &__edit-btn {
      margin-right: rem(10px);
      left        : 0;
      bottom      : 0;
      top         : 0;

      svg {
        position: relative;
      }
    }

    &__website {
      text-align: left;
      min-height: rem(26px);

      &--private {
        margin-left: rem(50px);
      }
    }

    &__description {
      font-family   : 'Roboto', sans-serif;
      border        : 0;
      padding-bottom: 0;
      text-align    : center;
    }

    &__actions {
      margin: 0 0 rem(15px) 0;

      &+.profile-page__user {
        .profile-page__user-name {
          margin-top: 0;
        }
      }
    }

    &__tabs {
      &-button {
        svg {
          height: rem(100px);
        }
      }

      &-text {
        font-size     : 1.1rem;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .tour-config-modal {
    top       : 40px;
    left      : 40px;
    right     : 40px;
    bottom    : 40px;
    background: white;
    overflow  : auto;
    outline   : none;
    padding   : 10px 10px 20px 10px;
    width     : 100%;
    height    : 100%;

    &__contentModal {
      justify-content : flex-end;
      display         : flex;
      align-items     : center;
      position        : fixed;
      top             : 0px;
      left            : 0px;
      right           : 0px;
      bottom          : 0px;
      background-color: #212529d4;
    }

    .navbarLeft {
      display         : none !important;
      position        : fixed;
      flex-direction  : column;
      right           : 60vw;
      background-color: transparent;

      &__btn {
        width           : 3.75rem;
        height          : 3.75rem;
        padding         : 0.4375rem;
        background-color: white;
        border          : none;
        line-height     : 0;

        svg {
          width : 100%;
          height: auto;
          fill  : black;
        }

        &:hover,
        &:active {
          background-color: #00affe;
        }

        &--active {
          background-color: #00affe;

          svg {
            fill: white;
          }
        }
      }
    }

    .content {
      .cursor {
        cursor: pointer;
      }

      .añadir_azul {
        margin-top: 10px;
      }

      .textBlue {
        color: #00affe;
      }

      .imageSponsor {
        width     : 150px;
        height    : 140px;
        object-fit: cover;
      }

      .division2 {
        background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size    : 6px 1px;
        background-repeat  : repeat-x;
        padding-bottom     : 10px;
        margin             : 10px;
      }

      .division3 {
        border-bottom : 1px solid black;
        padding-bottom: 10px;
      }

      .division4 {
        border-right: 0.0625rem dashed #6f6f6f;
      }

      .checkIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: #00affe;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;
        margin-right    : 5px;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .closeIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: black;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .bar {
        display         : flex;
        padding         : 5px;
        background-color: #E9EBEF;

        .barContent {
          position        : fixed;
          flex-direction  : column;
          left            : 55.5rem;
          background-color: transparent;

          &__btn {
            width           : 80px;
            height          : 80px;
            padding         : 0.4375rem;
            background-color: white;
            border          : none;
            line-height     : 0;

            svg {
              width : 60%;
              height: auto;
              fill  : black;
            }

            &:hover,
            &:active {
              background-color: #00affe;
            }

            &--active {
              background-color: #00affe;

              svg {
                fill: white;
              }

              p {
                color: white;
              }
            }

            p {
              margin: 20px 0px;
            }
          }
        }
      }

      .left {
        &__category-item {
          list-style      : none;
          background-color: white;

          &+.filters-modal__category-item {
            margin-top: rem(3px);
          }

          .custom-checkbox {
            font-size   : rem(16px);
            line-height : rem(25px);
            padding-left: rem(40px);

            i {
              border       : rem(1px) solid $topcolor-black;
              border-radius: 0;
            }

            i,
            i:after {
              width : rem(25px);
              height: rem(25px);
            }
          }
        }

        &__label {
          text-align   : center;
          font-family  : 'Roboto-bold';
          font-weight  : bold;
          line-height  : 1.2;
          font-size    : rem(18px);
          margin-bottom: rem(10px);
        }

        &__text-editor {
          min-height: rem(150px);
          min-width : 100%;

          // modifies the quill library rich text editor library styles...
          & .ql-toolbar {
            max-height: rem(45px);
          }

          & .ql-container {
            min-height: rem(100px);
          }
        }

        .dropzone {
          width      : 80px;
          min-width  : 80px;
          border     : none;
          display    : flex;
          align-items: center;
        }

        .poi-images-box__dropzone {
          min-height: 10rem;
          min-width : 160px;
          display   : none;
        }

        .fine-uploader__upload-input {
          width: 50px;
        }

        .tour-content-video-form__supported-text {
          font-size: 13px !important;
        }

        .tour-content-video-form__supported-type {
          svg {
            width: 1.5rem !important;
          }
        }
      }

      .divisorLateral {
        margin-top : 130px;
        height     : 500px;
        width      : 1px;
        border-left: 1px solid;
      }

      .right {
        .logotipo {
          display        : flex;
          justify-content: center;
          align-items    : flex-start;

          .cuadrado {
            width : 250px;
            height: 180px;
            border: 1px solid black;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: cover;
            }

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 215px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }

          .rectangulo {
            // width : 150px;
            // height: 200px;
            border: 1px solid black;

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
              min-width  : 100px;
            }

            .poi-images-box__dropzone {
              min-width : 118px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tour-config-modal {
    top       : 40px;
    left      : 40px;
    right     : 40px;
    bottom    : 40px;
    background: white;
    overflow  : auto;
    outline   : none;
    padding   : 10px 10px 20px 10px;
    width     : 100%;
    height    : 100%;

    &__contentModal {
      justify-content : flex-end;
      display         : flex;
      align-items     : center;
      position        : fixed;
      top             : 0px;
      left            : 0px;
      right           : 0px;
      bottom          : 0px;
      background-color: #212529d4;
    }

    .navbarLeft {
      display         : none !important;
      position        : fixed;
      flex-direction  : column;
      right           : 60vw;
      background-color: transparent;

      &__btn {
        width           : 3.75rem;
        height          : 3.75rem;
        padding         : 0.4375rem;
        background-color: white;
        border          : none;
        line-height     : 0;

        svg {
          width : 100%;
          height: auto;
          fill  : black;
        }

        &:hover,
        &:active {
          background-color: #00affe;
        }

        &--active {
          background-color: #00affe;

          svg {
            fill: white;
          }
        }
      }
    }

    .content {
      .contentCenter {
        flex-direction: column-reverse;

        .campaign {
          max-width: 100%;

          h4 {
            margin-left: 0px;
          }

          h6 {
            padding-left: 0px !important;
          }
        }

        .sponsored-destinations {
          max-width : 100%;
          margin-top: 15px;
        }
      }

      .addCampaign {
        flex-direction: column;

        .left {
          max-width: 100%;
        }

        .divisorLateral {
          height       : 1px;
          width        : 100%;
          border-bottom: 1px solid;
        }

        .right {
          max-width: 100%;
        }

      }

      .seeCampaign {
        flex-direction: column;



        .left {
          max-width: 100%;
        }

        .divisorLateral {
          height       : 1px;
          width        : 100%;
          border-bottom: 1px solid;
        }

        .right {
          max-width: 100%;
        }

      }

      .cursor {
        cursor: pointer;
      }

      .añadir_azul {
        margin-top: 10px;
      }

      .textBlue {
        color: #00affe;
      }

      .imageSponsor {
        width     : 150px;
        height    : 140px;
        object-fit: cover;
      }

      .division2 {
        background-image   : linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size    : 6px 1px;
        background-repeat  : repeat-x;
        padding-bottom     : 10px;
        margin             : 10px;
      }

      .division3 {
        border-bottom : 1px solid black;
        padding-bottom: 10px;
      }

      .division4 {
        border-right: 0.0625rem dashed #6f6f6f;
      }

      .checkIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: #00affe;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;
        margin-right    : 5px;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .closeIcon {
        height          : 2.5rem;
        width           : 2.5rem;
        background-color: black;
        display         : inline-block;
        border          : none;
        border-radius   : 50%;
        vertical-align  : middle;
        line-height     : 1;

        svg {
          width : 60%;
          height: 60%;
          fill  : white;
        }
      }

      .bar {
        display         : flex;
        padding         : 5px;
        background-color: #E9EBEF;

        .barContent {
          position        : fixed;
          flex-direction  : column;
          left            : 55.5rem;
          background-color: transparent;

          &__btn {
            width           : 80px;
            height          : 80px;
            padding         : 0.4375rem;
            background-color: white;
            border          : none;
            line-height     : 0;

            svg {
              width : 60%;
              height: auto;
              fill  : black;
            }

            &:hover,
            &:active {
              background-color: #00affe;
            }

            &--active {
              background-color: #00affe;

              svg {
                fill: white;
              }

              p {
                color: white;
              }
            }

            p {
              margin: 20px 0px;
            }
          }
        }
      }

      .left {
        &__category-item {
          list-style      : none;
          background-color: white;

          &+.filters-modal__category-item {
            margin-top: rem(3px);
          }

          .custom-checkbox {
            font-size   : rem(16px);
            line-height : rem(25px);
            padding-left: rem(40px);

            i {
              border       : rem(1px) solid $topcolor-black;
              border-radius: 0;
            }

            i,
            i:after {
              width : rem(25px);
              height: rem(25px);
            }
          }
        }

        &__label {
          text-align   : center;
          font-family  : 'Roboto-bold';
          font-weight  : bold;
          line-height  : 1.2;
          font-size    : rem(18px);
          margin-bottom: rem(10px);
        }

        &__text-editor {
          min-height: rem(150px);
          min-width : 100%;

          // modifies the quill library rich text editor library styles...
          & .ql-toolbar {
            max-height: rem(45px);
          }

          & .ql-container {
            min-height: rem(100px);
          }
        }

        .dropzone {
          border     : none;
          display    : flex;
          align-items: center;
        }

        .poi-images-box__dropzone {
          min-height: 10rem;
          min-width : 160px;
          display   : none;
        }

        .fine-uploader__upload-input {
          width: 50px;
        }

        .tour-content-video-form__supported-text {
          font-size: 13px !important;
        }

        .tour-content-video-form__supported-type {
          svg {
            width: 1.5rem !important;
          }
        }
      }

      .divisorLateral {
        margin-top : 130px;
        height     : 500px;
        width      : 1px;
        border-left: 1px solid;
      }

      .right {
        .logotipo {
          display        : flex;
          justify-content: center;
          align-items    : flex-start;

          .cuadrado {
            width : 250px;
            height: 180px;
            border: 1px solid black;

            img {
              width     : 100%;
              height    : 100%;
              object-fit: cover;
            }

            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 215px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }

          .rectangulo {
            // width : 150px;
            // height: 200px;
            border: 1px solid black;


            .dropzone {
              border     : none;
              display    : flex;
              align-items: center;
            }

            .poi-images-box__dropzone {
              min-width : 118px;
              min-height: 175px;
              display   : none;
            }

            .fine-uploader__upload-input {
              display: block !important;
              width  : 50px;
            }
          }
        }
      }
    }
  }
}