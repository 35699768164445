.poi-info-page-preview {
  padding-bottom: rem(150px);
  background-color: white;
  color: $topcolor-light-black;
  &__main {
    &-deco {
      display: none;
    }
    .allFlagsTitle{
      margin-left: 76px;
    }
    .allFlagsDescription{
      margin-left: 145px;
    }
  }
  &__location {
    padding: rem(10px) 0 rem(10px) rem(25px);
    border-left: rem(1px) dashed grey;
    &-btn {
      background: none;
      border: none;
    }
    &-img {
      margin: 0 auto;
      height: rem(75px);
      width: rem(75px);
    }
    &-text {
      /* text-transform: uppercase; */
      margin-bottom: 0;
      display: none;
      font-family: 'Oswald';
      text-align: center;
      font-size: rem(18px);
    }
  }

  &__section-title {
    font-family: 'QuickSand';
    font-weight: 500;
    font-size: rem(22px);
    margin: rem(15px) 0 0 0;
  }

  &__range {
    &-img {
      width: auto;
      &--left {
        height: rem(30px);
      }
      &--right {
        height: rem(70px);
        margin-top: rem(-35px);
      }
    }
    &-text {
      text-transform: uppercase;
      margin-top: rem(15px);
      &--left {
        float: left;
      }
      &--right {
        float: right;
        margin-right: rem(-50px);
      }
    }
  }
  &__footer {
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: rem(15px) 0;
    box-shadow: 0 0 3px rgba(black, 0.2);
    z-index: 1;
  }
  .flag{
    width: 50px;
    background: none;
    border: none;
    height: 50px;
    padding: 0px;
    margin: 0px 5px;
  }
  .select{
    border-bottom: 5px solid #21B5FD;
  }
  .camp{
    border: none;
    background-color: rgba(129, 212, 251, 0.3);
    margin: 0px 10px;
  }
  .special{
    background-color: #F2ECD6;
  }
  .label{
    width: 180px;
  }
  .label2{
    width: 325px;
  }
  .selectpartner{
    background-color: #21B5FD !important;
  }
}

.navTop{
  padding: 10px;
  background-color: #efefef;
  align-items: center;
  h5{
    margin: 0px;
    font-size: 18px;
  }
  &__title{
    width: 60%;
    text-align: end;
    color: #00affe;
    font-weight: 700;
  }
  &__state{
    width: 40%;
    justify-content: flex-end;
    align-items: center;
    div{
    background-color: #BDDCB0;
    padding: 15px 40px 15px 40px;
    margin: 0px 10px 0px;
    }
  }
  .blueCheck{
    margin-left: 30px;
    background-color: #00affe;
  }
}

@media screen and (min-width: 992px) {
  .poi-info-page-preview {
    padding: rem(40px) rem(25px) rem(30px) rem(100px);
/*     border-top-left-radius: rem(15px);
    border-bottom-right-radius: rem(15px);
    box-shadow: 0 0 3px rgba(black, 0.2); */
    margin-bottom: rem(75px);
    &__main {
      /* border-left: rem(3px) solid $topcolor-light-blue; */
      margin: 0 0 rem(0px) rem(-23px);
      padding: rem(0px) 0 rem(0px) rem(30px);
      position: relative;
      /* &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: rem(3px);
        background-color: $topcolor-light-blue;
      } */
      &:before {
        top: 0;
        width: rem(110px);
      }
      &:after {
        bottom: 0;
        width: rem(200px);
      }
      &-deco {
        display: inline-block;
        position: absolute;
        z-index: 1;
        &--top {
          width: auto;
          height: rem(70px);
          top: rem(-60px);
          left: rem(110px);
        }
        &--bottom {
          bottom: 0;
          left: rem(188px);
          width: auto;
          height: rem(40px);
        }
      }
    }
    &__location {
      border: none;
      padding: 0;
      &-btn {
        margin-left: rem(15px);
      }
      &-text {
        display: block;
        /* text-transform: uppercase; */
        margin: rem(5px) 0 0 0;
      }
      &-img {
        width: rem(55px);
        height: rem(55px);
      }
    }

    &__section-title {
      font-size: rem(22px);
      margin: 0 rem(-15px) rem(15px) rem(-15px);
    }

    .fine-uploader-dropzone-container {
      border-radius: rem(15px);
      border: rem(1px) dashed $topcolor-black;
      height: auto;
      &.react-fine-uploader-dropzone-active {
        border: rem(1px) solid $topcolor-blue;
        background-color: rgba($topcolor-blue, 0.1);
        opacity: 0.75;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .poi-info-page-preview{
    .instagramMobile{
      margin-bottom: 100px !important;
    }
  }
}
