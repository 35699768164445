.tour-content-images-box {
  background-color: white;
  padding: rem(10px) rem(15px);
  margin-top: rem(25px);
  box-shadow: rem(1px) rem(1px) rem(2px) rgba(black, 0.3);
  &__content {
    & + .tour-content-images-box__content {
      margin-top: rem(15px);
    }
  }
  &__figure {
    position: relative;
    margin-bottom: 0;
  }

  &__img {
    width: 100%;
    max-width: 100%;
    height: auto;
    &-author {
      display: inline-block;
      max-width: 75%;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: rem(5px) rem(10px) rem(5px) rem(30px);
      &-avatar {
        position: absolute;
        bottom: rem(-10px);
        left: rem(10px);
        margin-left: rem(-40px);
        margin-bottom: 0;
        height: rem(50px);
        width: rem(50px);
        border: rem(2px) solid white;
        background-color: white;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      &-name {
        font-family: 'Oswald';
        text-transform: uppercase;
        font-size: rem(15px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
      }
    }
  }
  &__title,
  &__description {
    color: $topcolor-black;
    margin-bottom: 0;
    padding: 0 0.9375rem;
    margin-top: rem(5px);
    line-height: rem(29px);
    font-size: rem(19px);
  }
  &__title {
    margin-top: 15px;
    font-family:'Roboto', sans-serif !important ;
    font-weight: 700;
  }
  &__description {
    font-family:'Roboto', sans-serif !important ;
    font-weight: 100;
    white-space: pre-line;
    overflow: visible;
  }
}


@media screen and (min-width: 992px) {
  .tour-content-images-box {
    padding: rem(15px 30px);
    border-radius: rem(10px);
    margin-top: rem(25px);
    &__title,
  &__description {
    padding: 0px;
  }
  }
}
