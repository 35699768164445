.loading-spinner,
.loading-spinner__spinner svg,
.loading-spinner__text,
.loading-spinner__logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading-spinner {
  background-color: rgba($topcolor-black, 1);
  &--inline {
    position: unset;
    background-color: transparent;
    .loading-spinner__spinner svg {
      position: unset;
    }
  }
  
  &__text {
    z-index: 2;
    font-family: 'Roboto-bold';
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem(18px);
    color: white;
    bottom: 30%;
    top: unset;
    margin: auto;
    text-align: center;
    opacity: 1;
    animation: flickerAnimation 1.5s infinite;
  }
  &__spinner {
    svg {
      display: block;
      margin: auto;
      width: 50%;
      height: auto;
      z-index: 1;
      max-width: rem(200px);
      circle {
        fill: $topcolor-blue;
      }
      defs linearGradient stop {
        stop-color: $topcolor-blue;
      }
    }
  }
  &__logo {
    display: block;
    width: 33%;
    max-width: rem(150px);
    height: auto;
    margin: auto;
  }
}

@media screen and (min-width: 992px) {
  .loading-spinner {
    &__text {
      bottom: 15%;
    }
  }
}

@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
