.tour-edition-nav {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 rem(3px) rgba($topcolor-black, 0.1);
  z-index: 1000;
  &__button {
    display: block;
    width: 100%;
    text-align: center;
    height: rem(50px);
    border: none;
    padding: 0;
    background-color: transparent;
    svg {
      fill: $topcolor-black;
      height: 75%;
      width: auto;
    }

    &:hover,
    &--active {
      svg {
        fill: $topcolor-blue;
      }
    }
  }
}

.content-tour-edition-nav-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.tour-edition-nav-desktop {
  position: fixed;
  left: 0;
  padding: 5px 0;
  background-color: white;
  width: 45px;
  height: auto;
  z-index: 1000;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__button {
    display: block;
    width: 100%;
    text-align: center;
    height: rem(45px);
    border: none;
    padding: 0;
    background-color: transparent;
    svg {
      fill: $topcolor-black;
      height: 75%;
      width: auto;
    }
  }
}
.tooltipNavEditionDesktop {
  &.__react_component_tooltip.type-dark {
    font-size: 15px;
    background: white !important;
    color: black;
  }
  &.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: white;
  }

}