.tour-search {
  padding-top: rem(15px);

  &__title {
    display       : inline-block;
    vertical-align: middle;
    font-family   : 'Roboto-bold';
    font-size     : rem(28px);
    font-weight   : 500;
    color         : $topcolor-black;
    white-space   : nowrap;
    line-height   : rem(24px);
    margin-top    : rem(20px);

    &-legend {
      line-height   : rem(24px);
      display       : inline-block;
      vertical-align: middle;
      margin-left   : rem(20px);
      font-family   : 'Oswald';
      font-size     : rem(16px);
      text-transform: uppercase;

      svg {
        display         : inline-block;
        line-height     : rem(24px);
        vertical-align  : bottom;
        fill            : $topcolor-yellow;
        height          : rem(32px);
        width           : auto;
        margin-right    : rem(10px);
        padding         : 5px;
        border-radius   : 4px;
        background-color: #40444B;
      }
    }
  }

  &__search {
    display      : block;
    padding-right: rem(35px);
    position     : relative;
    margin-bottom: 0;
    font-family  : 'Roboto', sans-serif !important;
    font-weight  : 100;

    &-icon {
      svg {
        height  : rem(20px);
        width   : auto;
        position: absolute;
        right   : rem(10px);
        top     : rem(10px);
      }
    }
  }

  .autocomplete-tag {
    padding-top: rem(40px);
    position   : relative;

    .react-autosuggest__container {
      position: absolute;
      top     : 0;
      left    : 0;
      right   : 0;
      z-index : 1;
    }
  }

  .autosuggest-wrapper__input {
    border-radius : rem(10px);
    height        : rem(40px);
    text-transform: uppercase;
    margin        : 0;

    &-field {
      background-color: white;
    }
  }

  &__toggle {
    display    : inline-block;
    background : transparent;
    border     : none;
    padding    : 0;
    line-height: rem(38px);

    svg {
      width : auto;
      height: rem(28px);
    }

    &--blue {
      svg {
        fill: $topcolor-blue;
      }
    }
  }

  &__results {
    margin: rem(15px) 0;
  }

  &__guide {
    animation: showIn 0.3s;

    &-title {
      margin-top : rem(25px);
      font-family: 'Roboto-bold';
      color      : $topcolor-black;
      font-size  : rem(20px);
    }

    &-example {
      color      : $topcolor-black;
      font-weight: bold;
      font-size  : rem(18px);
      line-height: 1.2;

      span {
        display    : block;
        color      : $topcolor-dark-grey;
        font-size  : rem(16px);
        font-weight: 500;
      }
    }

    &--hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .tour-search {
    &__results {
      padding   : 0;
      overflow-x: hidden;
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-search {
    padding-top: 0;

    &__title {
      margin: 0;
    }

    &__results {
      border-right: rem(1px) dashed $topcolor-black;
    }

    &__guide {
      &--hidden {
        &+.tour-search__results {
          border-right: none;
        }
      }
    }
  }
}


@keyframes showIn {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}