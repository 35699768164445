.tour-content-modal {
  margin-top: rem(50px);
  padding: rem(45px) rem(15px) rem(15px) rem(15px);
  width: 100%;
  background-color: white;
  min-height: calc(100vh - 100px);
  min-height: calc((var(--vh, 1vh) * 100) - 100px);
  max-height: calc(100vh - 100px);
  max-height: calc((var(--vh, 1vh) * 100) - 100px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $topcolor-black;
  scrollbar-track-color: $topcolor-blue;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    background-color: $topcolor-black;
    width: rem(5px);
  }
  &::-webkit-scrollbar-thumb {
    background: $topcolor-blue;
  }
  &__overlay {
    // reverted changes
    // overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &__tuto {
    &-icon {
      padding: 0 rem(5px) 0 rem(15px);
      svg {
        width: auto;
        height: rem(50px);
      }
    }
    &-title {
      font-family: 'Oswald';
      text-transform: uppercase;
      font-size: rem(17px);
      margin-bottom: rem(5px);
    }
    &-text {
      line-height: 1.1;
      font-size: rem(12px);
      margin-bottom: 0;
    }
  }
  &__day {
    &-list {
      margin-top: rem(-40px);
      list-style: none;
      padding-left: rem(5px);
    }
    &-item {
      display: inline-block;
      vertical-align: middle;
      margin: rem(3px);
    }
    &-btn {
      background-color: $topcolor-black;
      color: white;
      font-family: 'Roboto-bold';
      border: none;
      position: relative;
      padding: 0;
      width: rem(35px);
      height: rem(35px);
      line-height: rem(35px);
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
      &--active {
        height: rem(45px);
        width: rem(45px);
        font-size: rem(28px);
        padding: 0;
        font-weight: bold;
        line-height: 1;
        padding: rem(15px) 0 rem(1px) 0;
        background-color: $topcolor-blue;
      }
      &--circle {
        svg {
          fill: white;
          height: 40%;
          width: 40%;
        }
      }
      & .promise-button__bounce {
        width: rem(10px);
        height: rem(10px);
      }
    }
    &-header {
      display: block;
      position: absolute;
      text-align: left;
      padding: rem(5px) rem(10px) 0 rem(5px);
      top: 0;
      left: 0;
      right: 0;
      font-size: rem(12px);
      line-height: 1;
    }
  }
  &__content-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    width: 100%;
    padding: rem(2.5px);
    &-item {
      flex-basis: calc(50vw - 10px);
      height: calc(50vw - 10px);
      flex-grow: 0;
      flex-shrink: 0;
      margin: rem(2.5px);
      margin-bottom: rem(2px);
      list-style: none;
      z-index: 5000;
      &--green {
        .tour-content-modal__content-list-item-btn {
          border: rem(3px) dashed $topcolor-green;
          background-color: rgba($topcolor-green, 0.3);
        }
      }
      &--blue {
        .tour-content-modal__content-list-item-btn {
          border: rem(3px) dashed $topcolor-blue;
          background-color: rgba($topcolor-blue, 0.3);
        }
      }
      & > div {
        height: 100%;
      }
      &-btn,
      &-wrapper,
      &-img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      &-img {
        object-fit: contain;
        padding: 20%;
        &--cover {
          padding: rem(5px);
          object-fit: cover;
        }
      }
      &-btn {
        position: relative;
        background-color: transparent;
        padding: rem(0);
        border: rem(1px) dashed $topcolor-dark-grey;
      }
      &-balloon {
        position: absolute;
        bottom: rem(5px);
        right: rem(5px);
        width: rem(30px);
        height: rem(30px);
        line-height: rem(30px);
        text-align: center;
        border-radius: 50%;
        background-color: $topcolor-blue;
        color: white;
        font-family: 'Roboto-bold';
        font-weight: bold;
      }
    }
  }
  &__add-btn {
    border: none;
    background-color: $topcolor-blue;
    width: 100%;
    height: 100%;
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    padding: 0;
    svg {
      width: 40%;
      height: 40%;
      margin: 0 auto;
      fill: white;
    }
  }
  &__select-wrapper {
    position: absolute;
    height: auto !important;
    top: 15px;
    &-menu {
      top: 90%;
    }
  }
  &__no-visible-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    // border-radius: rem(15px);
    svg {
      width: rem(30px);
      height: rem(30px);
      fill: $topcolor-black;
      stroke: $topcolor-black;
    }
  }
  &__action-wrapper {
    width: 100%;
  }
  &__action-wrapper:after {
    clear: both;
  }
  &__remove,
  &__show {
    background-color: transparent;
    border: none;
    padding: 0;
    svg {
      width: rem(25px);
      height: rem(25px);
    }
  }
  &__remove {
    float: right;
    svg {
      fill: $topcolor-red;
    }
  }
  &__show {
    float: left;
  }
  &__captions {
    justify-content: center;
    margin: 0;
    &-item {
      &-text,
      &-icon {
        display: inline-block;
        vertical-align: middle;
      }
      &-icon {
        width: rem(30px);
        height: rem(30px);
        border-radius: 50%;
        background-color: $topcolor-blue;
        margin-right: rem(10px);
        text-align: center;
        svg {
          width: 90%;
          height: auto;
          fill: white;
        }
      }
      &-text {
        font-size: rem(16px);
        font-family: 'Roboto-Regular';
      }
      &--blue,
      &--green {
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: rem(50px);
          height: rem(6px);
          margin-right: rem(10px);
        }
      }
      &--blue {
        &:before {
          background-color: $topcolor-blue;
        }
      }
      &--green {
        &:before {
          background-color: $topcolor-green;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) {
  .tour-content-modal {
    &__day {
      &-list {
        padding-left: rem(10px);
      }
      &-btn {
        width: rem(40px);
        height: rem(40px);
        line-height: rem(40px);
        &--active {
          height: rem(50px);
          width: rem(50px);
          font-size: rem(30px);
          line-height: 1;
          padding: rem(15px) 0 rem(1px) 0;
        }
      }
    }
    &__content-list {
      &-item {
        flex-basis: calc(33vw - 7.5px);
        height: calc(33vw - 7.5px);
        width: calc(33vw - 7.5px);
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .tour-content-modal {
    &__day {
      &-list {
        padding-left: rem(30px);
      }
      &-btn {
        width: rem(60px);
        height: rem(60px);
        line-height: rem(60px);
        font-size: rem(24px);
        &--active {
          height: rem(75px);
          width: rem(75px);
          font-size: rem(55px);
          line-height: 1;
        }
      }
      &-header {
        font-size: rem(16px);
      }
    }
    &__content-list {
      &-item {
        flex-basis: calc(25vw - 7.5px);
        height: calc(25vw - 7.5px);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .tour-content-modal {
    &__content-list {
      &-item {
        flex-basis: calc(20vw - 7px);
        height: calc(20vw - 7px);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .tour-content-modal {
    width: rem(900px);
    margin: 6vh auto 0 auto;
    margin-top: calc(var(--vh, 1vh) * 6);
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    padding: rem(70px) rem(15px) 0 rem(15px);
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.ReactModal__Content--after-open {
      animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    &__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($topcolor-black, 0.9);
      z-index: 1010;
    }

    &__header {
      align-items: center;
      text-align: center;
      z-index: 1;
      border-bottom: rem(1px) solid $topcolor-black;
      text-align: right;
      margin: 0 rem(15px);
      padding: rem(15px) 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      &-title {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(22px);
        margin: 0 rem(10px) 0 0;
      }
      &-btn {
        padding: 0;
        border: none;
        border-radius: 50%;
        height: rem(40px);
        width: rem(40px);
        line-height: 1;
        background-color: $topcolor-black;
        border: rem(2px) solid $topcolor-blue;
        vertical-align: middle;
        svg {
          height: 60%;
          width: 60%;
          fill: white;
        }
      }
    }
    &__tuto {
      &-text {
        line-height: 1.2;
        font-size: rem(16px);
      }
    }
    &__content {
      max-height: 100%;
      overflow-y: auto;
      padding: rem(15px) 0 rem(10px) 0;
      scrollbar-width: thin;
      scrollbar-color: $topcolor-black;
      scrollbar-track-color: $topcolor-blue;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        background-color: $topcolor-black;
        width: rem(5px);
      }
      &::-webkit-scrollbar-thumb {
        background: $topcolor-blue;
      }
    }
    &__day {
      &-list {
        margin: 0 auto;
        padding-left: rem(20px);
      }
      &-item {
        margin: rem(4px) rem(8px) rem(4px) 0;
      }
      &-btn {
        height: rem(40px);
        width: rem(80px);
        background-color: #fff;
        color: $topcolor-black;
        border: 1px solid $topcolor-black;
        line-height: rem(40px);
        text-align: center;
        font-weight: bold;
        font-size: rem(20px);
        border-radius: rem(7.5px);
        margin-top: rem(5px);
        &--active {
          padding: 0;
          width: rem(175px);
          background-color: $topcolor-black;
          color: white;
        }
        &--circle {
          width: rem(40px);
          border-radius: 50%;
          background-color: $topcolor-black;
          line-height: 1;
          svg {
            height: 100%;
            width: 50%;
          }
        }
      }
      &-header {
        display: inline-block;
        text-transform: uppercase;
        font-size: rem(20px);
        position: unset;
        padding: 0;
        margin-right: rem(5px);
      }
    }
    &__content-list {
      padding: 0 rem(50px);
      overflow-y: unset;
      &-item {
        z-index: 5000;
        padding-top: rem(50px);
        flex-basis: rem(153px);
        height: rem(203px);
        position: relative;
        margin-bottom: rem(2.5px);
        &-wrapper,
        &-img {
          height: 100%;
        }

        &--hid {
          opacity: 0.6;
          .tour-content-modal__show {
            &:before {
              content: '';
              display: block;
              width: rem(30px);
              border: rem(1px) solid $topcolor-red;
              transform: rotate(-45deg);
              position: absolute;
              top: rem(14px);
              left: rem(-2px);
            }
            svg {
              fill: $topcolor-red;
            }
          }
        }
      }
    }
    &__remove,
    &__show {
      position: absolute;
      top: rem(20px);
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        width: rem(25px);
        height: rem(25px);
      }
    }
    &__remove {
      right: 0;
      svg {
        fill: $topcolor-red;
      }
    }
    &__show {
      left: 0;
    }
    &__dropdown-menu {
      position: absolute;
      top: 98%;
    }
    &__captions {
      justify-content: left;
      margin-top: rem(15px);
      &-item {
        margin-bottom: 0;
        &-text {
          font-size: rem(18px);
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .tour-content-modal {
    width: 1000px;
    &__content-list {
      &-item {
        flex-basis: rem(173px);
        height: rem(223px);
      }
    }
  }
}
