$header-lg-height: rem(70px);
$header-xs-height: rem(50px);
.navbar {
  padding: 0;
  height: $header-xs-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  // z-index: 990;
   h3 {
    font-family: 'Roboto', sans-serif !important;
  }
  + * {
    margin-top: $header-xs-height;
  }
  .descubre{
    border-top: 1px dotted #6f6f6f;
    a{
      border-top: none;
    }
  }
  .search-filters__toggle{
    align-items: center;
    display: flex;
    padding-right: 8px;
    background-color: transparent;
    svg{
      width: 40px;
      margin: 0px !important;
      fill: black;
    }
    g{
      stroke: black;
    }
  }
  .search-filters__toggle--active{
    align-items: center;
    display: flex;
    padding-right: 8px;
    background-color: rgb(0, 175, 254);
    svg{
      width: 40px;
      margin: 0px !important;
      fill: white;
    }
    g{
      stroke: white;
    }
  }

  .user_not_approved {
    p {
      font-size: 18px;
      text-transform: none;
      &.mobile {
        color: $topcolor-blue;
        font-size: 18px;
      }
    }
        
    .dropdown-menu{
      min-width: 450px;
      padding: 10px 15px;
      box-sizing: border-box;
      background: white;
      &:before {
        border-bottom-color: white;
      }
      p {
        font-size: 16px;
      }
      h6 {
        text-align: right;
        color: $topcolor-blue;
        font-weight: 600;
      }
      h5 {
        font-weight: 600;
        color: $topcolor-blue;
      }
    }
  }
  .navbar-brand {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: rem(135px);
    height: rem(38px);
    &--text {
      font-family: 'Roboto-bold';
      font-weight: bold;
      max-width: calc(100% - 130px);
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
.navbar-nav {
  .nav-link {
    text-transform: uppercase;
    font-family: 'Oswald';
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.navbar-contextuals {
  padding: 0;
  flex-direction: row;
  z-index: 1;
  .nav-item {
    padding: 0;
    &--icon {
      .nav-link {
        padding: rem(2px);
        margin-right: 0;
        svg {
          width: rem(36px);
          height: rem(36px);
        }
      }
    }
    &--circle {
      .nav-link {
        padding: 0;
        border: none;
        border-radius: 50%;
        height: rem(40px);
        width: rem(40px);
        line-height: 1;
        background-color: $topcolor-black;
        border: rem(2px) solid $topcolor-blue;
        vertical-align: middle;
        svg {
          height: 60%;
          width: 60%;
          fill: white;
        }
        &--blue {
          background-color: $topcolor-blue;
        }
        
        & .promise-button__bounce {
          width: rem(10px);
          height: rem(10px);
        }
      }
      & + .nav-item {
        margin-left: rem(10px);
      }
    } 
  }
  .nav-link {
    background-color: transparent;
    border: none;
    margin-right: rem(10px);

    svg {
      fill: white;
      width: rem(26px);
      height: rem(26px);
    }
    &--active {
      background-color: $topcolor-blue;
    }
  }
}

@media screen and (min-width: 375px) {
  .navbar-contextuals {
    .nav-item {
      &--icon {
        .nav-link {
          svg {
            width: rem(46px);
            height: rem(46px);
          }
        }
      }
    }
  }
}

.version-beta {
  display: flex;
  align-items: center;
  margin-right: rem(-15px);
  margin-left: rem(15px);
  height: rem(56px);
  overflow: hidden;
  h2 {
    color: white;
    font-family: 'Roboto-bold';
    font-size: 1.25rem;
    margin: rem(10px) rem(10px) 0;
    text-align: right;
    text-transform: uppercase;
    span {
      display: block;
      font-size: .8rem;
      text-transform: initial;
    }
  }
  img {
    margin-bottom: rem(-2px);
  }
}


@media screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 rem(15px);
  }
  .textButtonDrpdown {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    height: rem(56px);
  }
  .dropdown-toggle.nav-link::after {
    // margin-bottom: 15px !important;
  }
  .navbar {
    height: rem(56px);
    padding: 0 rem(15px);
    position: unset;
    z-index: 2;
    .navbar-collapse {
      height: rem(56px);
    }
    & + * {
      margin-top: 0;
    }
    .navbar-brand {
      position: unset;
      margin-right: rem(15px);
      img {
        height: 35px;
      }
    }
    .form-inline {
      padding-left: rem(10px);
      .form-control {
        border-radius: rem(15px);
        background-color: #666666;
        color: white;
        width: 100%;
        min-width: rem(100px);
      }
    }
    .nav-icon {
      .svg-icon {
        margin-right: rem(10px);
      }
      &:hover {
        .svg-icon {
          fill: white;
        }
      }
      &--white {
        .svg-icon {
          fill: white;
        }
      }
      &--light-blue {
        .svg-icon {
          margin-top: rem(-5px);
          fill: $topcolor-light-blue;
        }
      }
      
    }
    .nav-icon,
    .nav-dropdown-toggle {
      .svg-icon {
        display: inline-block;
        vertical-align: middle;
        height: rem(24px);
        width: rem(24px);
      }
    }
    .dropdown-menu {
      background-color: $topcolor-blue;
      padding: rem(15px) rem(5px);
      box-shadow: 0 rem(3px) rem(3px) rgba(black, 0.3);
      margin-top: 0;
      .dropdown-item {
        min-width: rem(200px);
        font-family: 'Roboto-bold';
        font-weight: bold;
        color: white;
        & + .dropdown-item {
          margin-top: rem(5px);
        }
        &:hover,
        &--active {
          background-color: $topcolor-black;
        }
      }
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
       
        width: rem(30px);
        
        border-right: rem(15px) solid transparent;
        border-left: rem(15px) solid transparent;
      }
      &:before {
        border-bottom: rem(15px) solid $topcolor-blue;
        top: rem(-15px);
        z-index: 2;
      }
      &:after {
        border-bottom: rem(15px) solid $topcolor-black;
        top: rem(-16px);
        z-index: 1;
      }
      &.dropdown-menu-right {
        right: rem(-7px);
        &:before,
        &:after {
          right: rem(8px);
        }
      }
      &.dropdown-menu-left {
        left: rem(-7px);
        &:before,
        &:after {
          left: rem(40px);
        }
      }
    }
    &.dropdown.show {
      .dropdown-toggle,
      .nav-dropdown-toggle:hover {
        background-color: $topcolor-black;
      }
    }
    .dropdown-toggle,
    .nav-dropdown-toggle {
      &:disabled {
        cursor: not-allowed;
      }
    }

    .nav-dropdown-toggle {
      background-color: transparent;
      border: none;
      padding: rem(10px) rem(5px);
      height: rem(66px);
      &:after {
        display: none;
      }
      svg {
        margin-right: 0;
        fill: white;
      }
    }
  }
  .navbar-nav {
    align-items: center;
    .nav-item {
      .nav-link {
        display: flex;
        align-items: center;
        height: rem(56px) !important;
        font-size: rem(18px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: $header-lg-height;
        height: $header-lg-height;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: $topcolor-blue;
        }
        &--active {
          background-color: $topcolor-blue;
          color: white;
        }
      }
    }
    & + .form-inline,
    & .nav-item + .nav-item {
      position: relative;
      &::before {
        display: block;
        content: '';
        width: rem(1px);
        height: rem(56px) !important;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-left: rem(1px) dashed rgba(white, 0.3);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    .form-inline {
      padding-left: rem(20px);
    }
  }
  .nav-icon,
  .nav-dropdown-toggle {
    svg {
      margin-right: (15px)
    }
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 rem(30px);
  }
}

