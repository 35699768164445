/*===== The CSS  https://codepen.io/cmd430/pen/vOrVqp =====*/
.circular-bar {
  width: 90%;
  margin: 0 auto 20px auto;
  &__value,
  &__percent {
    font-family: 'Roboto-bold';
    font-weight: bold;
    fill: #485661;
    text-anchor: middle;
  }
  &__value {
    font-size: 20px;
  }
  &__percent {
    font-size: 16px;
  }

  &__progress {
    &--noselect {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: default;
    }
    &-track,
    &-fill {
      fill: rgba(0, 0, 0, 0);
      stroke-width: 8;
      transform: rotate(90deg) translate(0px, -80px);
    }
    &-track {
      stroke: #F5F5F5;
    }
    &-fill {
      stroke: #485661;
      stroke-dasharray: 219.99078369140625;
      stroke-dashoffset: -219.99078369140625;
      transition: stroke-dashoffset 1s;
    }
  }
}
