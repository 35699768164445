.btn-resetted {  
  background:none;
  border:none;
  padding:0;
  &:hover {
    text-decoration: none;
  }
}

.btn-rounded {
  border: rem(1px) solid;
  border-radius: rem(50px);
  &:hover {
    border: rem(1px) solid;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.tour-content-poi-box .carousel .control-arrow, .tour-content-poi-box .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: rgba( #fff, 1) !important;
}
.tour-content-poi-box .carousel .control-arrow, .tour-content-poi-box .carousel.carousel-slider .control-arrow{
  opacity: .7 !important;
  background-color: rgba( #fff, .7) !important;
}

.modal-preview-content-tour  .carousel .control-arrow, .modal-preview-content-tour  .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: rgba( #fff, 1) !important;
}
.modal-preview-content-tour .carousel .control-arrow, .modal-preview-content-tour  .carousel.carousel-slider .control-arrow{
  opacity: .7 !important;
  background-color: rgba( #fff, .7) !important;
}

.modal-preview-content .carousel .control-arrow, .modal-preview-content .carousel.carousel-slider .control-arrow:hover {
  opacity: 1 !important;
  background-color: rgba( #fff, 1) !important;
}
.modal-preview-content.carousel .control-arrow, .modal-preview-content .carousel.carousel-slider .control-arrow{
  opacity: .7 !important;
  background-color: rgba( #fff, .7) !important;
}
